import { Middleware, Dispatch } from "redux";

import { AppState } from "~/store";
import { Action } from "~/util/types";

/* Allow dispatching of action thunks
 *
 * Instead of dispatching action objects, we allow for delayed action thunks
 * (essentially to-be-called functions) that take in the state and dispatch
 * method and dispatch their action after running additional logic.
 */
const thunk: Middleware<{}, AppState, Dispatch<Action>> =
  ({ dispatch, getState }) =>
  (next) =>
  (action: (state: AppState) => Action) => {
    if (typeof action === "function") {
      return dispatch(action(getState()));
    }

    return next(action);
  };

export default thunk;
