import { BaseObject } from "./BaseObject";
import { Trait } from "./Trait";

/**
 * Interface that describes objects that can be selected
 */
export interface Select extends Trait {}

const SELECTABLE_TYPES = [
  "sticky",
  "label",
  "connection",
  "shape",
  "image",
  "line",
];

/**
 * Type guard that checks whether an object (BaseObject) implements the Select
 * trait.
 */
export const isSelect = <T extends BaseObject>(
  object: T
): object is T & Select => {
  return SELECTABLE_TYPES.includes(object.type);
};
