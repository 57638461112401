import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as User from "~/store/bundles/User";
import NameForm from "~/components/NameForm";
import { useAuth } from "~/context/AuthContext";
import { BoardType, useConfig } from "~/context/ConfigContext";
import { COLORS } from "~/util/constants";
import { pick } from "~/util/random";
import { generateRandomUser } from "~/util/user";

type Props = {
  children: React.ReactNode;
};

/*
 * WithUser handles the logic of ensuring that a user is either restored (status
 * set to 'online'), or created if the user doesn't exist yet. If there is no
 * user present in the app state with this client's userId, we show a name form.
 */
const WithUser = ({ children }: Props) => {
  let dispatch = useDispatch();
  let userId = useSelector(Client.getUserId);
  let user = useSelector(User.getWithoutCursor(userId));
  let userExists = !!user;
  let isOnline = !!user?.online;
  let { account } = useAuth();
  const isSandbox = useConfig().type === BoardType.Sandbox;

  useAccountSynchronization();

  useEffect(() => {
    // If there's a user record present, but it's set to "offline",  simply set
    // them to "online"
    if (userExists && !isOnline) {
      dispatch(User.restore(userId));
    }

    // If the user has an account, don't need to show a name form
    else if (!userExists && account) {
      dispatch(
        User.add({
          id: userId,
          name: `${account.firstName} ${account.lastName}`,
          color: pick(COLORS),
        })
      );
    }

    // Assign anonymous users a random name/color on `/try`
    else if (!userExists && isSandbox) {
      const { name, color } = generateRandomUser();
      dispatch(User.add({ id: userId, name, color }));
      return;
    }
  }, [userExists, isOnline, userId, dispatch, account, isSandbox]);

  return user ? <>{children}</> : <NameForm />;
};

/**
 * Hook that runs when joining the board that syncs up any changes to the
 * account (cursor color, avatar, name) with an existing user record
 */
const useAccountSynchronization = () => {
  const dispatch = useDispatch();
  const userId = useSelector(Client.getUserId);
  const userExists = !!useSelector(User.getById(userId));
  const { account } = useAuth();

  useEffect(() => {
    if (account && userExists) {
      dispatch(
        User.update({
          id: userId,
          name: `${account.firstName} ${account.lastName}`,
          color: account.cursorColor ?? pick(COLORS),
          picture: account.profilePicture,
        })
      );
    }
  }, [userExists, userId, dispatch, account]);
};

export default WithUser;
