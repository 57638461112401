import { createSelector } from "reselect";

import * as Client from "~/store/bundles/Client";
import * as Line from "~/store/bundles/Line";
import * as Vertex from "~/store/bundles/Vertex";
import * as Vector from "~/util/geometry/vector";
import { isConnection } from "~/store/bundles/Connection";
import assert from "~/util/assert";
import { Point } from "~/util/geometry";
import { createShallowEqualSelector } from "~/util/selectors";
import { UpdateMany } from "~/util/types";

import * as Alive from "./Alive";
import * as BaseObject from "./BaseObject";
import * as Lock from "./Lock";
import { AppState, State, Action as AppAction } from "../index";
import { BaseAction, emptyAction, EmptyAction } from "../shared";
import { Position } from "./Position";

/**
 */
export interface GrabData {
  /**
   * Id of the user performing the grab action
   */
  userId: string;

  /**
   * Cursor position the grab was initiated at
   */
  start: Point;

  /**
   * Current cursor position
   */
  current: Point;

  /**
   * Delta between the initial and current cursor positions
   */
  delta: Vector.Vector;

  /**
   * Timestamp when the grab was initiated
   */
  initiatedAt: number;

  /**
   * Initial position of the object before initiating the grab
   */
  initialPosition: Point;
}

/**
 * Interface that describes things that can be grabbed/moved.
 */
export interface Grab extends Position {
  grab: GrabData | null;
}

/**
 * Type guard that checks whether an object (BaseObject) implements the Grab
 * trait.
 *
 * NOTE: We exclude connections explicitly because, while they have a `grabbing` field,
 * they do not behave like `Grab` objects.
 */
export const isGrab = <T extends BaseObject.BaseObject>(
  object: T
): object is T & Grab => {
  return (
    ("grab" in object && !isConnection(object)) || // New Grab format
    ("grabbing" in object && "grabOffset" in object && !isConnection(object))
  ); // Old Grab format
};

/**
 * Schema for actions returned by the `Grab.startGrabbing()` action creator.
 */
export interface StartGrabbingAction extends BaseAction {
  type: "grab/start_grabbing";
  data: Pick<Grab, "id" | "grab">;
}

export const startGrabbing =
  (id: string, userId: string, position: Point) =>
  (state: AppState): StartGrabbingAction | EmptyAction => {
    let object = getById(id)(state);
    if (!object) return emptyAction();

    // Don't grab the object if its locked
    if (Lock.isLock(object) && object.locked) return emptyAction();

    let scene = Client.getScene(state);
    let scenePosition = Client.toSceneCoordinates(scene, position);

    const grab = {
      userId,
      start: scenePosition,
      initialPosition: object.position,
      initiatedAt: Date.now(),
      current: scenePosition,
      delta: { x: 0, y: 0 },
    };

    return {
      type: "grab/start_grabbing",
      data: { id, grab },
      effects: {
        broadcast: true,
        persist: true,
        dispatch: [Alive.touch(id)],
      },
    };
  };

/**
 * Schema for actions returned by the `Grab.startGrabbingMany()` action creator.
 */
export interface StartGrabbingManyAction extends BaseAction {
  type: "grab/start_grabbing_many";
  data: UpdateMany<Grab, "grab">;
}

export const startGrabbingMany =
  (ids: string[], userId: string, position: Point) =>
  (state: AppState): StartGrabbingManyAction => {
    const data: UpdateMany<Grab, "grab"> = {};

    for (const id of ids) {
      let object = getById(id)(state);
      if (!object) continue;

      // Don't grab the object if its locked
      if (Lock.isLock(object) && object.locked) continue;

      let scene = Client.getScene(state);
      let scenePosition = Client.toSceneCoordinates(scene, position);

      const grab = {
        userId,
        start: scenePosition,
        initialPosition: object.position,
        initiatedAt: Date.now(),
        current: scenePosition,
        delta: { x: 0, y: 0 },
      };

      data[id] = { grab };
    }

    return {
      type: "grab/start_grabbing_many",
      data,
      effects: {
        broadcast: true,
        persist: true,
        dispatch: [Alive.touchMany(ids)],
      },
    };
  };

/**
 * Schema for actions returned by the `Grab.finishGrabbing()` action creator.
 */
export interface FinishGrabbingAction extends BaseAction {
  type: "grab/finish_grabbing";
  data: Pick<Grab, "id" | "grab" | "position" | "updatedAt" | "updatedBy">;
}

export const finishGrabbing =
  (id: string) =>
  (state: AppState): FinishGrabbingAction | EmptyAction => {
    let object = getById(id)(state);
    if (!object || !isGrab(object)) return emptyAction();

    return {
      type: "grab/finish_grabbing",
      data: {
        id: object.id,
        position: object.position,
        grab: null,
        updatedAt: Date.now(),
        updatedBy: object.grab?.userId ?? undefined,
      },
      effects: { broadcast: true, persist: true },
    };
  };

/**
 * Schema for actions returned by the `Grab.finishGrabbingMany()` action creator.
 */
export interface FinishGrabbingManyAction extends BaseAction {
  type: "grab/finish_grabbing_many";
  data: UpdateMany<Grab, "grab" | "position">;
}

export const finishGrabbingMany =
  (ids: string[]) =>
  (state: AppState): FinishGrabbingManyAction => {
    const data: UpdateMany<Grab, "grab" | "position"> = {};

    for (const id of ids) {
      let object = getById(id)(state);
      if (!object || !isGrab(object)) continue;

      data[id] = { position: object.position, grab: null };
    }

    return {
      type: "grab/finish_grabbing_many",
      data,
      effects: { broadcast: true, persist: true },
    };
  };

/**
 * Schema for actions returned by the `Grab.cancelGrabbingMany()` action creator.
 */
export interface CancelGrabbingManyAction extends BaseAction {
  type: "grab/cancel_grabbing_many";
  data: UpdateMany<Grab, "grab" | "position">;
}

export const cancelGrabbingMany =
  (ids: string[]) =>
  (state: AppState): CancelGrabbingManyAction => {
    const data: UpdateMany<Grab, "grab" | "position"> = {};

    for (const id of ids) {
      let object = getById(id)(state);
      if (!object || !isGrab(object) || !object.grab) continue;

      data[id] = { position: object.grab.initialPosition, grab: null };
    }

    return {
      type: "grab/cancel_grabbing_many",
      data,
      effects: { broadcast: true, persist: true },
    };
  };

/**
 * Schema for actions returned by the `Grab.move()` action creator.
 */
export interface MoveAction extends BaseAction {
  type: "grab/move";
  data: Pick<Grab, "id" | "grab" | "position" | "updatedAt" | "updatedBy">;
}

/**
 * Move an object to a given position, updating it's `grab` field in the
 * process.
 *
 * Calculate the delta from the initial mouse position when the grab was
 * initiated to the current position, and use this delta to move the object.
 *
 * @param id - The id of the object to move
 * @param position - The position to move the object to
 */
export const move =
  (id: string, position: Point) =>
  (state: AppState): MoveAction | EmptyAction => {
    let object = getById(id)(state);
    if (!object?.grab) return emptyAction();

    let scene = Client.getScene(state);
    let scenePosition = Client.toSceneCoordinates(scene, position);
    let delta = Vector.vec(object.grab.start, scenePosition);

    const grab = { ...object.grab, current: scenePosition, delta };
    const newPosition = Vector.add(object.grab.initialPosition, delta);

    return {
      type: "grab/move",
      data: {
        id: object.id,
        grab,
        position: newPosition,
        updatedAt: Date.now(),
        updatedBy: grab.userId,
      },
      effects: { broadcast: true, persist: true },
    };
  };

/**
 * Schema for actions returned by the `Grab.moveMany()` action creator.
 */
export interface MoveManyAction extends BaseAction {
  type: "grab/move_many";
  data: UpdateMany<Grab, "grab" | "position" | "updatedAt" | "updatedBy">;
}

/**
 * Move several objects, updating their `grab` fields in the process.
 *
 * Calculate the delta from the initial mouse position when the grab was
 * initiated to the current position, and use this delta to move the objects.
 * *
 * @param ids - The ids of the objects to move
 * @param position - The current mouse position
 */
export const moveMany =
  (ids: string[], position: Point, opts?: { snap?: boolean }) =>
  (state: AppState): MoveManyAction => {
    const data: UpdateMany<
      Grab,
      "grab" | "position" | "updatedAt" | "updatedBy"
    > = {};
    const now = Date.now();

    for (const id of ids) {
      let object = getById(id)(state);
      if (!object?.grab) continue;

      let scene = Client.getScene(state);
      let scenePosition = Client.toSceneCoordinates(scene, position);
      let delta = Vector.vec(object.grab.start, scenePosition);

      const grab = { ...object.grab, current: scenePosition, delta };

      let newPosition = Vector.add(object.grab.initialPosition, delta);

      if (opts?.snap) {
        // Snap the vertex being positioned to the coordinates of its pair.
        if (Vertex.isVertex(object)) {
          let parent = Line.getById(object.parent)(state);

          if (Line.isLine(parent)) {
            let controlId =
              object.id === parent.vertices[0]
                ? parent.vertices[1]
                : parent.vertices[0];
            let control = Vertex.getById(controlId)(state);

            const inc = 20;

            newPosition = {
              x:
                Math.round(newPosition.x / inc) * inc +
                (control.position.x % inc),
              y:
                Math.round(newPosition.y / inc) * inc +
                (control.position.y % inc),
            };
          }
        }
      }

      data[id] = {
        grab,
        position: newPosition,
        updatedAt: now,
        updatedBy: grab.userId,
      };
    }
    return {
      type: "grab/move_many",
      data,
      effects: { broadcast: true, persist: true },
    };
  };

export const startGrabbingSelection =
  (userId: string, position: Point) =>
  (state: AppState): StartGrabbingManyAction => {
    const ids = Client.getSelected(state);
    return startGrabbingMany(ids, userId, position)(state);
  };

/**
 * Return a list of finishGrabbing actions for each object currently being
 * grabbed by a given user.
 */
export const releaseAllBy = (userId: string) => (state: AppState) => {
  const grabbed = getGrabbingBy(userId)(state);
  return grabbed.map((obj) => finishGrabbing(obj.id)(state));
};

/**
 * Union type of all Grab-related actions.
 */
export type Action =
  | StartGrabbingAction
  | StartGrabbingManyAction
  | MoveAction
  | MoveManyAction
  | FinishGrabbingAction
  | FinishGrabbingManyAction
  | CancelGrabbingManyAction;

/**
 * Get all grabbale objects from an app state.
 */
export const getAll = createShallowEqualSelector(BaseObject.getAll, (objects) =>
  Object.values(objects).filter(isGrab)
);

/**
 * Get all objects from an app state being grabbed by given user.
 */
export const getGrabbingBy = (userId: string) =>
  createSelector(getAll, (objects) =>
    objects.filter(({ grab }) => grab?.userId === userId)
  );

/**
 * Check whether an object is grabbed or not
 */
export const getIsGrabbed = (id: string) => (state: AppState) => {
  return !!getById(id)(state)?.grab;
};

/**
 * Get Grab object with given id.
 */
export const getById = (id: string) => (state: AppState) => {
  const object = BaseObject.getById(id)(state);
  if (object && isGrab(object)) return object;
};

/**
 * Reducer that handles any Grab-related actions
 */
export const reducer = (state: State = {}, action: AppAction): State => {
  switch (action.type) {
    case "grab/start_grabbing":
    case "grab/move":
    case "grab/finish_grabbing": {
      const object = state[action.data.id];
      assert(isGrab(object), "Object does not implement the Grab trait.");

      return {
        ...state,
        [action.data.id]: { ...object, ...action.data },
      };
    }

    case "grab/start_grabbing_many":
    case "grab/move_many":
    case "grab/cancel_grabbing_many":
    case "grab/finish_grabbing_many": {
      let newState = { ...state };

      for (const id in action.data) {
        const object = newState[id];
        assert(isGrab(object), "Object does not implement the Grab trait.");

        newState[id] = { ...newState[id], ...action.data[id] };
      }

      return newState;
    }

    default:
      return state;
  }
};
