import "./BoardInfoEditor.css";

import {
  Formik,
  FormikErrors,
  FormikHelpers,
  useField,
  useFormikContext,
} from "formik";
import { useState } from "react";

import * as BoardApi from "~/util/BoardClient";
import { useAuth } from "~/context/AuthContext";
import { Font, DEFAULT_FONT } from "~/store/bundles/Board";
import { FathomEvents, trackAnalyticsEvent } from "~/util/analytics";

import { Box } from "./Box";
import { Button } from "./Button";
import {
  FormField,
  FormFieldHeader,
  FormFieldLabel,
  FormFieldMessage,
  FormFieldValidationMessage,
} from "./FormField";
import { Modal, ModalPanel, ModalProps } from "./Modal";
import { TextArea } from "./TextArea";
import { TextInput } from "./TextInput";

interface Values {
  name: string;
  description: string;
  font?: Font;
}

function validate(values: Values) {
  let errors: FormikErrors<Values> = {};

  if (values.name === "") errors.name = "Board name is required";

  if (values.name.length > 200) errors.name = "Board name is too long";

  if (values.description.length > 2000)
    errors.description = "Description is too long";

  return errors;
}

/**
 * If a search returns no results then we prefill the search string as
 * the board name if the user clicks "Create New Board" from the empty
 * results component.
 */
function parseBoardNameFromQueryParams() {
  let query = new URLSearchParams(window.location.search);
  let name = query.get("name") || "";
  return decodeURIComponent(name);
}

interface BoardInfoCreateProps {
  name?: string;
  onCreate(boardId: string): void;
}

interface BoardInfoUpdateProps {
  board: { id: string } & Values;
  onUpdate(): void;
}

export type BoardInfoEditorProps = BoardInfoCreateProps | BoardInfoUpdateProps;

export let BoardInfoEditor = (props: BoardInfoEditorProps) => {
  let auth = useAuth();

  let [error, setError] = useState<string | undefined>();
  let board = "board" in props ? props.board : undefined;

  let initialValues: Values = {
    name: board?.name ?? parseBoardNameFromQueryParams(),
    description: "",
    font: board?.font ?? DEFAULT_FONT,
  };

  async function updateBoard(values: Values): Promise<string> {
    let { name, description, font } = values;
    let updates = { name, description, font };
    let response = await BoardApi.updateBoard(board!.id, updates);

    if (response.status === "failed") {
      throw new Error(response.msg);
    }

    return board!.id;
  }

  async function createBoard(values: Values): Promise<string> {
    let { name, description } = values;
    let response = await BoardApi.createBoard(name, description);

    if (response.status === "success") {
      trackAnalyticsEvent(FathomEvents.CreateBoard);
      return response.data.id;
    } else if (response.status === "failed") {
      throw new Error(response.msg);
    } else {
      throw new Error("Something went wrong");
    }
  }

  async function onSubmit(values: Values, helpers: FormikHelpers<Values>) {
    let update = "board" in props ? updateBoard : createBoard;
    let done = "board" in props ? props.onUpdate : props.onCreate;
    let boardId: string | undefined;

    try {
      setError(undefined);
      boardId = await update(values);
      if (update === createBoard) {
        // Refresh the account meta to reflect the
        // newly-created board.
        await auth.refresh();
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      helpers.setSubmitting(false);
    }

    if (boardId) {
      done(boardId);
    }
  }

  return (
    <Formik
      initialValues={board ?? initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit }) => (
        <Box as="form" onSubmit={handleSubmit} gap={6}>
          <BoardNameField />
          <BoardDescriptionField />
          {board && <BoardFontField />}
          {board ? <UpdateBoardButton /> : <CreateBoardButton />}
          {error}
        </Box>
      )}
    </Formik>
  );
};

export let UpdateBoardButton = () => {
  let { isSubmitting } = useFormikContext();

  return (
    <Button
      type="submit"
      variant="primary"
      loading={isSubmitting}
      data-cy="update-board-button"
    >
      Update Board
    </Button>
  );
};

let CreateBoardButton = () => {
  let { isSubmitting } = useFormikContext();

  return (
    <Button type="submit" variant="primary" loading={isSubmitting}>
      Create Board
    </Button>
  );
};

let BoardNameField = () => {
  let [field] = useField("name");

  return (
    <FormField>
      <FormFieldHeader>
        <FormFieldLabel>Board name</FormFieldLabel>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldHeader>
      <TextInput
        {...field}
        autoFocus
        placeholder="Pick a descriptive board name"
        data-cy="board-name-input"
      />
    </FormField>
  );
};

let BoardDescriptionField = () => {
  let [field] = useField("description");

  return (
    <FormField>
      <FormFieldHeader>
        <FormFieldLabel>Board description</FormFieldLabel>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldHeader>
      <TextArea
        variant="grey"
        placeholder="Optional board description"
        {...field}
        data-cy="board-description-input"
      />
    </FormField>
  );
};

const fonts: Font[] = ["handwritten", "sans-serif", "serif"];

let BoardFontField = () => {
  let [field] = useField("font");

  let onChange = (event: React.MouseEvent, value: string) => {
    event.preventDefault();

    field.onChange({ target: { value, name: field.name } });
  };

  const value = field?.value ?? DEFAULT_FONT;

  return (
    <FormField>
      <FormFieldHeader>
        <FormFieldLabel>Board font</FormFieldLabel>
      </FormFieldHeader>
      <Box direction="row" gap={2}>
        {fonts.map((font) => (
          <Button
            key={font}
            variant={value === font ? "primary" : "grey"}
            className={`board-info-editor-button--${font}`}
            onClick={(event) => onChange(event, font)}
          >
            Abc
          </Button>
        ))}
      </Box>
    </FormField>
  );
};

export type BoardProfileEditorModalProps = BoardInfoEditorProps & ModalProps;

export let BoardInfoEditorModal = ({
  open,
  onClose,
  ...props
}: BoardProfileEditorModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalPanel size="lg">
        <BoardInfoEditor {...props} />
      </ModalPanel>
    </Modal>
  );
};
