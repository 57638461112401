import "./CommentThread.css";

import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Board from "~/store/bundles/Board";
import * as BoardObject from "~/store/bundles/BoardObject";
import * as Client from "~/store/bundles/Client";
import * as Comments from "~/store/bundles/Comments";
import * as User from "~/store/bundles/User";
import RichText from "~/components/RichText";
import { useConfig } from "~/context/ConfigContext";
import uid from "~/util/uid";
import usePermission from "~/util/usePermission";

import { confirm } from "./AlertDialog";
import { Box } from "./Box";
import {
  CommentWrapper,
  CommentHeader,
  CommentAuthor,
  CommentTimestamp,
  CommentBody,
  CommentActions,
  CommentEditAction,
  CommentDeleteAction,
  CommentEditor,
} from "./Comment";
import { PanelStack, Panel } from "./Panel";

export let CommentThread = ({ id }: { id: string }) => {
  let object = useSelector(BoardObject.getById(id));
  let boardId = useSelector(Board.getBoardId);

  let isObjectComments = !!object;

  if (object == null && boardId !== id) return null;

  return isObjectComments ? (
    <ObjectCommentThread id={id} />
  ) : (
    <BoardCommentThread boardId={boardId} />
  );
};

let ObjectCommentThread = ({ id }: { id: string }) => {
  const dispatch = useDispatch();
  const config = useConfig();
  const comments = useSelector(Comments.getByObjectId(id));
  const userId = useSelector(Client.getUserId);

  const addComment = (body: string) => {
    // If websocket is disabled (i.e. in sandbox), add the comment
    // to the store directly.
    dispatch(
      config.websocket
        ? Comments.create({
            id: uid(),
            type: "object",
            objectId: id,
            parentId: id,
            body,
          })
        : {
            type: "comment/create",
            data: {
              id: uid(),
              type: "object",
              objectId: id,
              parentId: id,
              authorId: userId,
              createdAt: new Date().getTime(),
              body,
            },
          }
    );
  };

  return <CommentsView comments={comments} onAddComment={addComment} />;
};

let BoardCommentThread = ({ boardId }: { boardId: string }) => {
  const dispatch = useDispatch();
  const comments = useSelector(Comments.getByBoardId(boardId));
  const config = useConfig();
  const userId = useSelector(Client.getUserId);

  const addComment = (body: string) => {
    // If websocket is disabled (i.e. in sandbox), add the comment
    // to the store directly.
    dispatch(
      config.websocket
        ? Comments.create({ id: uid(), type: "board", parentId: boardId, body })
        : {
            type: "comment/create",
            data: {
              id: uid(),
              type: "board",
              boardId,
              authorId: userId,
              createdAt: new Date().getTime(),
              body,
            },
          }
    );
  };

  return <CommentsView comments={comments} onAddComment={addComment} />;
};

type Props = {
  comments: Comments.Comment[];
  onAddComment(body: string): void;
};

export let CommentsView = ({ comments, onAddComment }: Props) => {
  const commentGroups = useMemo(() => groupComments(comments), [comments]);

  return (
    <PanelStack size="sm" className="comment-thread">
      {commentGroups.map((group, i) => (
        <CommentGroup key={`${group.authorId}-${group.time}`} group={group} />
      ))}
      <Panel className="relative">
        <CommentEditor
          autoFocus
          placeholder="Add a comment"
          onSubmit={onAddComment}
        />
      </Panel>
    </PanelStack>
  );
};

export type CommentGroupType = {
  authorId: string;
  time: string;
  comments: Comments.Comment[];
};

export const CommentGroup = ({ group }: { group: CommentGroupType }) => {
  const author = useSelector(User.getWithoutCursor(group.authorId));
  if (!author) return null;

  return (
    <Panel className="comment-thread-item">
      <CommentHeader>
        <CommentAuthor color={author.color}>{author.name}</CommentAuthor>
        <CommentTimestamp timestamp={group.time} />
      </CommentHeader>
      <Box className="comment-thread-item-body">
        {group.comments.map((comment) => (
          <Comment key={comment.id} comment={comment} />
        ))}
      </Box>
    </Panel>
  );
};

interface CommentProps {
  comment: Comments.Comment;
}

export let Comment = ({ comment }: CommentProps) => {
  let [editing, setEditing] = useState(false);
  let dispatch = useDispatch();
  let userId = useSelector(Client.getUserId);
  let author = useSelector(User.getWithoutCursor(comment.authorId));
  let can = usePermission();

  let deleteComment = async () => {
    let ok = await confirm({
      title: "Delete Comment",
      okButtonText: "Delete",
      description: "Permanently delete this comment?",
    });

    if (ok) {
      dispatch(Comments.remove(comment.id));
    }
  };

  let editComment = (event: React.MouseEvent) => {
    event.stopPropagation();
    setEditing(true);
  };

  let updateComment = (content: string) => {
    dispatch(Comments.update(comment.id, content));
    setEditing(false);
  };

  return (
    <CommentWrapper key={comment.id}>
      {editing ? (
        <CommentEditor
          defaultValue={comment.body}
          placeholder="Edit this comment"
          autoFocus={true}
          onSubmit={updateComment}
          onCancel={() => setEditing(false)}
        />
      ) : (
        <CommentBody>
          <RichText value={comment.body} />
          {author?.id === userId && can("comment") && (
            <CommentActions>
              <CommentEditAction onEdit={editComment} />
              <CommentDeleteAction onDelete={deleteComment} />
            </CommentActions>
          )}
        </CommentBody>
      )}
    </CommentWrapper>
  );
};

export const groupComments = (
  comments: Comments.Comment[],
  groupWithinTimeinterval = 1000 * 60 * 5
) => {
  const commentGroups: CommentGroupType[] = [];

  for (let i = 0; i < comments.length; i++) {
    if (i === 0) {
      commentGroups.push({
        authorId: comments[i].authorId,
        time: comments[i].createdAt,
        comments: [comments[i]],
      });
      continue;
    }
    const timeSincePrevious =
      new Date(comments[i].createdAt).getTime() -
      new Date(comments[i - 1].createdAt).getTime();

    if (
      comments[i].authorId === comments[i - 1].authorId &&
      timeSincePrevious <= groupWithinTimeinterval
    ) {
      commentGroups[commentGroups.length - 1].comments.push(comments[i]);
    } else {
      commentGroups.push({
        authorId: comments[i].authorId,
        time: comments[i].createdAt,
        comments: [comments[i]],
      });
    }
  }
  return commentGroups;
};
