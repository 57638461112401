/**
 * Helper functions for interacting with Sticky Studio's Board and Template
 * related API endpoints.
 */
import { useState, useEffect } from "react";

import { Permission } from "~/context/ConfigContext";
import { AppState, getObjects, getComments } from "~/store";
import { Font, Plan } from "~/store/bundles/Board";
import { Comment } from "~/store/bundles/Comments";
import { BaseObject } from "~/store/traits/BaseObject";

import { postJson, getJson, FetchResponse, Fetch } from "./ApiClient";

export { Permission };

export type AccessMode = "invite-only" | "share-link";

export type BoardData = {
  id: string;
  name: string;
  description: string;
  template: boolean;
  createdBy: string;
  slug?: string;
  createdAt: string;
  deletedAt?: string;
  lastVisitedAt?: string;
  permissions: Permission;
  auth: { userId?: string };
  font?: Font;
  accessMode: AccessMode;
  invite?: string;
  plan: Plan;
};

export type FullBoardData = BoardData & {
  comments: Comment[];
  objects: BaseObject[];
};

export type FeaturedTemplateData = {
  id: string;
  boardId: string;
  name: string;
  description: string;
  thumbnail: string;
  creator: string;
  createdAt: string;
  deletedAt?: string;
};

export type TemplatesData = {
  templates: BoardData[];
  featured: FeaturedTemplateData[];
};

export const getBoardsByAccount = async (
  accountId: string
): Promise<FetchResponse<BoardData[]>> => {
  return getJson(`/account/${accountId}/boards`);
};

export const getTemplatesByAccount = async (
  accountId: string
): Promise<FetchResponse<TemplatesData>> => {
  return getJson(`/account/${accountId}/templates`);
};

/**
 * Fetches a board state. Given the boardId, it fetches the board data.
 */
export const getBoard = async (
  boardId: string
): Promise<FetchResponse<BoardData>> => {
  return await getJson(`/board/${boardId}`);
};

/**
 * Fetches a full board state, including all objects and comments.
 */
export const getFullBoard = async (
  boardId: string
): Promise<FetchResponse<FullBoardData>> => {
  return await getJson(`/board/${boardId}?full`);
};

/**
 * Utility hook that fetches a board from the API.
 */
export const useGetFullBoard = (boardId: string) => {
  const [response, setResponse] = useState<Fetch<FullBoardData>>({
    status: "loading",
  });

  useEffect(() => {
    getFullBoard(boardId).then(setResponse);
  }, [boardId]);

  return response;
};

/**
 * Creates a board.
 * Converts the board information, and an optional initial state, into a shape
 * the server understands, and sends over the data, returning the response.
 */
export const createBoard = async (
  name: string,
  description: string,
  initialState?: AppState
) => {
  const CREATE_BOARD_ENDPOINT = `/board/create`;

  const payload = {
    name,
    description,
    objects: initialState ? getObjects(initialState) : [],
    comments: initialState ? getComments(initialState) : [],
  };

  return postJson(CREATE_BOARD_ENDPOINT, payload);
};

/**
 * Deletes a board.
 */
export const deleteBoard = (boardId: string) => {
  const DELETE_BOARD_ENDPOINT = `/board/${boardId}`;
  return postJson(DELETE_BOARD_ENDPOINT, {}, "delete");
};

/**
 * Updates a board
 */
export const updateBoard = async (
  boardId: string,
  attributes: Partial<BoardData>
) => {
  const UPDATE_BOARD_ENDPOINT = `/board/${boardId}`;
  return postJson(UPDATE_BOARD_ENDPOINT, attributes, "put");
};
