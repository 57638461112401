import * as ApiClient from "./ApiClient";

/**
 * Helper interface that makes it easy to measure time relative to some given
 * reference time. Defaults to system clock.
 */
const Clock = () => {
  let offset = 0;

  return {
    now: () => Date.now() + offset,
    set: (time = Date.now()) => (offset = time - Date.now()),
  };
};

export const clock = Clock();

export async function init() {
  let response = await ApiClient.getJson<{ time: number }>("/time");
  if (response.status === "success") {
    clock.set(response.data.time);
  }
}
