import "./Board.css";

import { useStore, useSelector } from "react-redux";

import * as Boards from "~/store/bundles/Board";
import * as Client from "~/store/bundles/Client";
import { useConfig } from "~/context/ConfigContext";
import { ConfigProvider, Config } from "~/context/ConfigContext";
import { HuddleProvider } from "~/context/HuddleContext";
import { KeybindingsProvider } from "~/context/KeybindingsContext";
import ReduxProvider from "~/context/ReduxContext";
import { WebsocketProvider } from "~/context/WebsocketContext";
import { useCleanupLoop } from "~/hooks/useCleanupLoop";
import { useSelectionHash } from "~/hooks/useSelectionHash";
import HTMLCanvas from "~/renderers/html";
import { AppState } from "~/store";
import {
  useTitle,
  useScrollLock,
  useSetVisibleViewportHeight,
} from "~/util/hooks";

import { BoardControls } from "./BoardControls";
import { BoardRoutes } from "./BoardRoutes";
import { ContextMenu } from "./ContextMenu";
import MouseEvents from "./MouseEvents";
import { Toaster } from "./Toaster";
import WithUser from "./WithUser";

type Props = {
  config: Config;
  initialState: AppState;
};

export const BoardWithProviders = ({ config, initialState }: Props) => {
  return (
    <ConfigProvider config={config}>
      <WebsocketProvider>
        <ReduxProvider initialState={initialState}>
          <HuddleProvider>
            <MouseEvents>
              <KeybindingsProvider>
                <WithUser>
                  <Board />
                </WithUser>
              </KeybindingsProvider>
            </MouseEvents>
          </HuddleProvider>
        </ReduxProvider>
      </WebsocketProvider>
    </ConfigProvider>
  );
};

export const Board = () => {
  const name = useSelector(Boards.getName);
  const font = useSelector(Boards.getFont);
  const config = useConfig();
  const store = useStore();
  const showContextMenu = !!useSelector(Client.getContextMenu);

  useTitle(`${name} • Sticky Studio`);
  useScrollLock();
  useCleanupLoop();
  useSelectionHash();
  useSetVisibleViewportHeight();

  return (
    <div className={`board board--${font} flex`}>
      <HTMLCanvas store={store} config={config} />
      {showContextMenu && <ContextMenu />}
      <BoardControls />
      <BoardRoutes />
      <Toaster />
    </div>
  );
};
