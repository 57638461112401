import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useAccount } from "~/context/AuthContext";
import { getCreatedBy } from "~/store/bundles/Board";
import { useBoardPath } from "~/util/hooks";

import { BoardModal } from "./BoardModal";
import { Box } from "./Box";
import { Button } from "./Button";
import { Headphones, Logo } from "./Icons";
import { Message } from "./Message";
import { ModalDescription, ModalPanel, ModalProps, ModalTitle } from "./Modal";

export const UpgradeLink = ({ children }: { children: ReactNode }) => {
  let boardPath = useBoardPath();
  return (
    <Box as={Link} to={`${boardPath}/pro`}>
      {children}
    </Box>
  );
};

export const StickyStudioPlus = () => (
  <span>
    <Logo width={24} height={24} /> <strong>Sticky Studio</strong>{" "}
    <strong style={{ color: "var(--yellow-3)" }}>Plus</strong>
  </span>
);

function useIsOwner() {
  let account = useAccount();
  let owner = useSelector(getCreatedBy);
  return account?.id === owner;
}

export function useIsPlus() {
  let account = useAccount();
  return account?.plan === "plus";
}

export const CommentsUpsell = () => {
  let isOwner = useIsOwner();

  return (
    <Box gap={4}>
      {isOwner ? (
        <>
          <StickyStudioPlus />
          <div>
            Upgrading to Sticky Studio Plus will allow everyone in your boards
            to use comments!
          </div>
          <UpgradeLink>
            <Button variant="primary">Upgrade</Button>
          </UpgradeLink>
        </>
      ) : (
        <>
          <StickyStudioPlus />
          <Box>
            The owner of this board needs to upgrade to enable comments.
          </Box>
        </>
      )}
    </Box>
  );
};

export const ExportsUpsell = () => {
  let isOwner = useIsOwner();

  return (
    <Box gap={4}>
      {isOwner ? (
        <>
          <Message variant="warning">
            Exports are a Sticky Studio Plus feature!
          </Message>
          <div>
            Upgrading to <StickyStudioPlus /> will allow everyone in your boards
            to export them to Kumu, and to PDF or SVG files.
          </div>
          <UpgradeLink>
            <Button variant="primary">Upgrade</Button>
          </UpgradeLink>
        </>
      ) : (
        <>
          <StickyStudioPlus />
          <Box>The owner of this board needs to upgrade to enable exports.</Box>
        </>
      )}
    </Box>
  );
};

export const TemplatesUpsell = () => {
  return (
    <Box gap={4}>
      <Message variant="warning">
        Templates are a Sticky Studio Plus feature!
      </Message>
      <div>
        Upgrading to <StickyStudioPlus /> will allow you to create templates to
        speed up creation of new boards.
      </div>
      <UpgradeLink>
        <Button variant="primary">Upgrade</Button>
      </UpgradeLink>
    </Box>
  );
};

export let HuddlesUpsell = () => {
  let isOwner = useIsOwner();

  return (
    <Box gap={4}>
      {isOwner ? (
        <>
          <div>
            Upgrading to <StickyStudioPlus /> will allow everyone in your boards
            to use huddles!
          </div>
          <UpgradeLink>
            <Button variant="primary">Upgrade</Button>
          </UpgradeLink>
        </>
      ) : (
        <>
          <StickyStudioPlus />
          <Box>The owner of this board needs to upgrade to enable huddles.</Box>
        </>
      )}
    </Box>
  );
};

export let HuddlesUpsellModal = (props: ModalProps) => (
  <BoardModal {...props}>
    <ModalPanel className="feedback-modal" size="lg">
      <ModalTitle>
        <Headphones color="var(--grey-4)" /> Huddles
      </ModalTitle>
      <ModalDescription>
        <Box gap={4}>
          <HuddlesUpsell />
        </Box>
      </ModalDescription>
    </ModalPanel>
  </BoardModal>
);
