import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from "react";
import { useStore } from "react-redux";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { Store } from "redux";

import * as Board from "~/store/bundles/Board";
import * as BoardObject from "~/store/bundles/BoardObject";
import * as Client from "~/store/bundles/Client";
import * as Delete from "~/store/traits/Delete";
import * as Edit from "~/store/traits/Edit";
import * as Lock from "~/store/traits/Lock";
import * as Position from "~/store/traits/Position";
import * as Select from "~/store/traits/Select";
import * as Vec from "~/util/geometry/vector";
import { AppState } from "~/store";
import { BaseObject } from "~/store/traits/BaseObject";
import {
  insertFromBlob,
  objectsToBlobs,
  readBlobFromClipboard,
  writeBlobsToClipboard,
} from "~/util/clipboard";
import { Vector } from "~/util/geometry";
import { useWindowEventListener } from "~/util/hooks";

import { BoardType, Config, useConfig } from "./ConfigContext";

/**
 * Distinguish between different keybinding "modes", where different
 * keybindings can be active in different contexts. Important so we can't
 * accidentally delete objects while a modal is open, for example.
 */
type Domain = "default" | "modal";

/**
 * A key combination that triggers a given shortcut
 */
type Combination = {
  key: string;
  ctrl?: boolean;
  shift?: boolean;
  alt?: boolean;
};

/**
 * Describes an action that can be triggered through a keyboard shortcut.
 *
 * Note that a single action can have multiple key combinations associated with
 * it. For example, both Delete and Backspace will trigger a "delete" event.
 */
export type Keybinding = {
  id: string;
  description: string;
  combinations: Combination[];
  handler: KeybindingHandler;
};

/**
 * Processes a keybinding, returning a boolean indicating whether or not
 * the associated action was handled. Any subsequent matching keybindings
 * will be processed afterwards if the current keybinding was unhandled.
 */
type KeybindingHandler = (
  store: Store<AppState, any>,
  navigate: NavigateFunction,
  config: Config
) => boolean | Promise<boolean>;

/******************************************************************************
 * Keybinding Context
 *****************************************************************************/

export const KeybindingsContext = React.createContext<
  | {
      bindings: Record<Domain, Keybinding[]>;
      domain: Domain;
      setDomain: (domain: Domain) => void;
    }
  | undefined
>(undefined);

/**
 * Keybinding provider
 *
 * This component is responsible for both setting the current set of
 * keybindings, as well as making available the defined shortcuts and helpers
 * for setting the keybinding "domain".
 */
export const KeybindingsProvider = ({ children }: { children: ReactNode }) => {
  const [domain, setDomain] = useState<Domain>("default");
  const config = useConfig();
  const store = useStore();
  const navigate = useNavigate();

  const currentKeybindings = keybindings[domain];

  const onKeyDown = async (event: React.KeyboardEvent) => {
    if (
      ((event.target as HTMLElement).tagName === "INPUT" ||
        (event.target as HTMLElement).tagName === "TEXTAREA" ||
        (event.target as HTMLElement).tagName === "SELECT") &&
      event.key !== "Escape"
    ) {
      return;
    }

    for (const keybinding of currentKeybindings) {
      if (matches(event, keybinding)) {
        const handled = await keybinding.handler(store, navigate, config);

        event.preventDefault();
        event.stopPropagation();

        // For each keystroke, process no more than the first matching,
        // handleable keybinding.
        if (handled) break;
      }
    }
  };

  useWindowEventListener("keydown", onKeyDown, false);

  const context = {
    bindings: keybindings,
    domain,
    setDomain,
  };

  return (
    <KeybindingsContext.Provider value={context}>
      {children}
    </KeybindingsContext.Provider>
  );
};

/**
 * Expose the current set of keybindings through a context hook.
 */
export const useKeybindings = () => {
  const context = useContext(KeybindingsContext);

  if (context === undefined) {
    throw new Error("Hook must be used within a KeybindingsContext provider.");
  }

  return context;
};

export const useKeybinding = (id: string) => {
  const { bindings, domain } = useKeybindings();

  return useMemo(() => {
    return bindings[domain].find((binding) => binding.id === id);
  }, [id, domain, bindings]);
};

/**
 * Push a new keybinding layer/domain to the stack, and remove it when the
 * component unmounts to restore the previously active keybindings.
 *
 * @param domain - The new keybinding domain to activate
 */
export const useKeybindingDomain = (domain: Domain) => {
  const { domain: currentDomain, setDomain } = useKeybindings();
  const { current: initialDomain } = useRef<Domain>(currentDomain);

  useEffect(() => {
    // If the initial domain is the same as the requested domain, the
    // restoration of the initial domain is already being handled by
    // another instance of the hook.
    if (initialDomain === domain) return;

    setDomain(domain);
    return () => setDomain(initialDomain);
  }, [initialDomain, domain, setDomain]);
};

/**
 * Check whether a React.KeyboardEvent matches any of a given binding's key
 * combinations.
 *
 * We only consider the modifier keys if they are explicitly defined.
 * That way, we can have `{ key: "?" }` match "?" without having to explicitly
 * define `{ key: "?", shift: true }`.
 *
 * @param event - The React keyboard event
 * @param binding - The keybinding to check against
 */
const matches = (
  { key, shiftKey, altKey, ctrlKey, metaKey }: React.KeyboardEvent,
  binding: Keybinding
) => {
  return binding.combinations.some((combination) => {
    // Does the actual key/character match?
    const keyMatches = key === combination.key;

    // Only match shift key value if the binding explicitly sets it.
    const shiftMatches =
      combination.shift !== undefined ? shiftKey === combination.shift : true;

    // Only match alt key value if the binding explicitly sets it.
    const altMatches =
      combination.alt !== undefined ? altKey === combination.alt : true;

    // Only match ctrl key value if the binding explicitly sets it.
    const ctrlMatches =
      combination.ctrl !== undefined
        ? ctrlKey === combination.ctrl || metaKey === combination.ctrl
        : true;

    return keyMatches && shiftMatches && altMatches && ctrlMatches;
  });
};

/******************************************************************************
 * Keybindings
 *****************************************************************************/

const keybindings: Record<Domain, Keybinding[]> = {
  /**
   * Default set of keybindings that are active when on a Sticky Studio board
   */
  default: [
    /**
     * Copy the selection to the clipboard
     */
    {
      id: "copy",
      description: "Copy selection",
      combinations: [{ key: "c", ctrl: true }, { key: "y" }],
      handler: async (store) => {
        let selection = Client.getSelectedWithDependencies(store.getState());
        let blobs = await objectsToBlobs(selection);
        await writeBlobsToClipboard(blobs);
        return blobs.length > 0;
      },
    },

    /**
     * Paste the contents of the clipboard
     */
    {
      id: "paste",
      description: "Paste",
      combinations: [{ key: "v", ctrl: true }, { key: "P" }],
      handler: async (store) => {
        let blob = await readBlobFromClipboard();
        if (blob) await insertFromBlob(blob, store);
        return !!blob;
      },
    },

    /**
     * Delete the selection
     */
    {
      id: "delete",
      description: "Delete selection",
      combinations: [{ key: "Delete" }, { key: "Backspace" }],
      handler: (store) => {
        const state = store.getState();
        const selected = Client.getSelected(state);

        store.dispatch(Delete.deleteMany(selected));

        return selected.length > 0;
      },
    },

    /**
     * Lock/Unlock all selected (and lockable) objects
     */
    {
      id: "lock",
      description: "Lock/Unlock selection",
      combinations: [{ key: "l" }],
      handler: (store) => {
        const state = store.getState();
        const selected = Client.getSelected(state);

        for (const id of selected) {
          store.dispatch(Lock.toggleLock(id));
        }

        return selected.length > 0;
      },
    },

    /**
     * Zoom in
     */
    {
      id: "zoomIn",
      description: "Zoom in",
      combinations: [{ key: "+", ctrl: true }],
      handler: (store) => {
        store.dispatch(Client.scale(1.25));
        return true;
      },
    },

    /**
     * Zoom out
     */
    {
      id: "zoomOut",
      description: "Zoom out",
      combinations: [{ key: "-", ctrl: true }],
      handler: (store) => {
        store.dispatch(Client.scale(0.75));
        return true;
      },
    },

    /**
     * Zoom to fit the board contents
     */
    {
      id: "zoomFit",
      description: "Zoom to fit board",
      combinations: [{ key: "0", ctrl: true }],
      handler: (store) => {
        store.dispatch(Client.zoomToFitBoard);
        return true;
      },
    },

    /**
     * Edit the currently selected object (only if there's only one object selected.
     */
    {
      id: "edit",
      description: "Edit object",
      combinations: [{ key: "Enter" }, { key: "i" }],
      handler: (store) => {
        const state = store.getState();
        const selected = Client.getSelected(state);
        const objects = selected
          .map((id) => BoardObject.getById(id)(state))
          .filter(Edit.isEdit);

        if (selected.length !== 1 || objects.length !== 1) return false;

        const userId = Client.getUserId(state);
        store.dispatch(Edit.startEditing(objects[0].id, userId));

        return true;
      },
    },

    /**
     * Select all (selectable) objects on the board
     */
    {
      id: "selectAll",
      description: "Select all objects",
      combinations: [{ key: "a", ctrl: true }],
      handler: (store) => {
        const state = store.getState();
        const ids = Object.values(BoardObject.getAll(state))
          .filter(Select.isSelect)
          .filter((obj) => !Delete.isDelete(obj) || !obj.deleted)
          .map((object) => object.id);

        store.dispatch(Client.selectMany(ids));

        return ids.length > 0;
      },
    },

    /**
     * Move all selected objects up by a fixed amount
     */
    {
      id: "nudgeUp",
      description: "Move selection up",
      combinations: [{ key: "ArrowUp", shift: true }, { key: "K" }],
      handler: (store) => {
        return moveSelectionBy({ x: 0, y: -10 })(store);
      },
    },

    /**
     * Move all selected objects down by a fixed amount
     */
    {
      id: "nudgeDown",
      description: "Move selection down",
      combinations: [{ key: "ArrowDown", shift: true }, { key: "J" }],
      handler: (store) => {
        return moveSelectionBy({ x: 0, y: 10 })(store);
      },
    },

    /**
     * Move all selected objects left by a fixed amount
     */
    {
      id: "nudgeLeft",
      description: "Move selection left",
      combinations: [{ key: "ArrowLeft", shift: true }, { key: "H" }],
      handler: (store) => {
        return moveSelectionBy({ x: -10, y: 0 })(store);
      },
    },

    /**
     * Move all selected objects right by a fixed amount
     */
    {
      id: "nudgeRight",
      description: "Move selection right",
      combinations: [{ key: "ArrowRight", shift: true }, { key: "L" }],
      handler: (store) => {
        return moveSelectionBy({ x: 10, y: 0 })(store);
      },
    },

    /**
     * Pan the canvas up
     */
    {
      id: "moveUp",
      description: "Pan the canvas up",
      combinations: [{ key: "ArrowUp" }, { key: "k" }],
      handler: (store) => {
        moveBy({ x: 0, y: -1 })(store);
        return true;
      },
    },

    /**
     * Pan the canvas down
     */
    {
      id: "moveDown",
      description: "Pan the canvas down",
      combinations: [{ key: "ArrowDown" }, { key: "j" }],
      handler: (store) => {
        moveBy({ x: 0, y: 1 })(store);
        return true;
      },
    },

    /**
     * Pan the canvas left
     */
    {
      id: "moveLeft",
      description: "Pan the canvas left",
      combinations: [{ key: "ArrowLeft" }, { key: "h" }],
      handler: (store) => {
        moveBy({ x: -1, y: 0 })(store);
        return true;
      },
    },

    /**
     * Pan the canvas right
     */
    {
      id: "moveRight",
      description: "Pan the canvas right",
      combinations: [{ key: "ArrowRight" }, { key: "l" }],
      handler: (store) => {
        moveBy({ x: 1, y: 0 })(store);
        return true;
      },
    },

    /**
     * Undo a selection deletion (no other actions are undable at the moment)
     */
    {
      id: "undo",
      description: "Undo delete",
      combinations: [{ key: "z", ctrl: true }],
      handler: (store) => {
        store.dispatch(Client.undo());
        return true;
      },
    },

    /**
     * Redo a selection deletion (no other actions are redoable at the moment)
     */
    {
      id: "redo",
      description: "Redo delete",
      combinations: [{ key: "Z", ctrl: true, shift: true }],
      handler: (store) => {
        store.dispatch(Client.redo());
        return true;
      },
    },

    /**
     * Select the next object
     */
    {
      id: "selectNext",
      description: "Select next object",
      combinations: [{ key: "Tab", shift: false }, { key: "n" }],
      handler: (store) => {
        let state = store.getState();
        const [selected] = Client.getSelected(state);
        const objects = Object.values(BoardObject.getAll(state)).filter(
          canSelect
        );

        const selectedIdx = objects.findIndex((obj) => obj.id === selected);
        const nextIdx =
          selectedIdx >= 0 ? (selectedIdx + 1) % objects.length : 0;

        const nextObject = objects[nextIdx];
        if (nextObject) store.dispatch(Client.selectOnly(nextObject.id));

        return !!nextObject;
      },
    },

    /**
     * Select the previous object
     */
    {
      id: "selectPrevious",
      description: "Select previous object",
      combinations: [{ key: "Tab", shift: true }, { key: "p" }],
      handler: (store) => {
        let state = store.getState();
        const [selected] = Client.getSelected(state);
        const objects = Object.values(BoardObject.getAll(state)).filter(
          canSelect
        );

        const selectedIdx = objects.findIndex((obj) => obj.id === selected);

        const prevIdx =
          selectedIdx >= 0
            ? (selectedIdx + objects.length - 1) % objects.length
            : 0;

        const prevObject = objects[prevIdx];

        if (prevObject) store.dispatch(Client.selectOnly(prevObject.id));

        return !!prevObject;
      },
    },

    /**
     * Open the keyboard shortcuts modal
     */
    {
      id: "openShortcuts",
      description: "Open shortcuts overview",
      combinations: [{ key: "?" }],
      handler: (store, navigate, config) => {
        const boardId = Board.getBoardId(store.getState());

        const boardPath =
          config.type === BoardType.Template
            ? config.websocket
              ? `/template/${boardId}/edit`
              : `/template/${boardId}`
            : config.type === BoardType.Sandbox
            ? `/try`
            : `/${boardId}`;

        navigate(`${boardPath}/shortcuts`);

        return true;
      },
    },
  ],

  /**
   * Keybindings that are active when viewing a modal.
   *
   * Mostly, we want the usual keybindings to be disabled so we can't
   * accidentally interact (accidental deletes, etc...)
   */
  modal: [],
};

/******************************************************************************
 * Helpers
 *****************************************************************************/

const moveSelectionBy = (delta: Vector) => (store: Store<AppState, any>) => {
  const state = store.getState();
  const selected = Client.getSelected(state);
  const objects = selected
    .map((id) => BoardObject.getById(id)(state))
    .filter(Position.isPosition);

  for (const object of objects) {
    store.dispatch(Position.positionBy(object.id, delta));
  }

  return selected.length > 0;
};

const moveBy = (direction: Vector) => (store: Store<AppState, any>) => {
  const state = store.getState();
  const { scale, origin } = Client.getClient(state);

  // Multiply by scale to jump a consistent amount regardless of zoom level
  const delta = Vec.scale(direction, -100 * scale);

  store.dispatch(Client.panTo(Vec.add(origin, delta)));
};

/**
 * Check whether an object is selectable, and if it is deleteable, then check
 * whether it's deleted.
 */
const canSelect = (obj: BaseObject) => {
  return Select.isSelect(obj) && (Delete.isDelete(obj) ? !obj.deleted : true);
};
