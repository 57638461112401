import "./CaseStudy.css";

import { Link } from "react-router-dom";

import collabSrc from "~/assets/studies/engaging-inquiry-collab.jpeg";
import screenshotSrc from "~/assets/studies/engaging-inquiry-screenshot.png";

import * as Icons from "./Icons";
import { Box } from "./Box";
import { Page, Header, Footer, Section } from "./Page";
import { Heading, Text } from "./Typography";

export const CaseStudyEngagingInquiry = () => (
  <Page className="case-study">
    <Header />
    <Section className="article">
      <Box as="header">
        <Text variant="muted" size={2}>
          Case Study
        </Text>
        <Heading level={2}>
          Making sense of pandemic disruptions on schools in Rio de Janeiro
        </Heading>
      </Box>
      <Box gap={7}>
        <Text as="p" className="italic" size={2}>
          <a
            href="https://engaginginquiry.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Engaging Inquiry
          </a>{" "}
          accompanied a team from the Rio de Janeiro school district to make
          sense of the ripple effects of the pandemic disruption using Sticky
          Studio. Teachers across the region submitted audio recordings of when
          learning was most alive for them and their students in the past very
          difficult year and shared perspectives on enablers and inhibitors
          relating to those experiences. These perspectives and insights were
          analyzed through small group conversations and used to build out loops
          in Sticky Studio that represent common, recurring patterns. By using
          Sticky Studio, the Engaging Inquiry team was able to support the local
          Rio de Janeiro team virtually in building their capacity for systems
          thinking and systemic story telling, furthering insights for better
          educational outcomes. A dominant paradigm that learning happens only
          face to face emerged as the deep structure in this system:
        </Text>
        <Text as="p" size={2}>
          <img src={screenshotSrc} alt="Sticky board" />
        </Text>
        <Text as="p" size={2}>
          The deep structure tells a central story of a learning system that is
          organized almost entirely around face-to-face learning. This high
          focus on in person teaching meant that we were caught off guard and
          not well prepared for the level of disruption caused by school
          closures during this pandemic. The lack of preparedness for the shift
          to remote learning led to a great deal of overwhelm and stress of
          everyone involved: teachers, parents, students, and administrators.
          The high levels of stress and overwhelm caused a fraying of the social
          fabric as staff, families, and communities struggled to cope. This
          also increased vulnerability and mental health challenges, especially
          for those already at risk. The loss of social fabric, connection and
          support led to a decrease in learning success for almost all learners,
          but once again, especially those who were already at risk or resource
          constrained to begin with. For most, the lack of success in learning
          seemed to reinforce the centrality of in-person learning, rather than
          cause us to reflect deeply on what we have learned because of how the
          system performed under this level of disruption.
        </Text>
        <Text as="p" size={2}>
          The Brazilian education system, both public and private, especially
          with regard to the elementary stages, is based on the premise of
          face-to-face education. The entire system, in the different spheres
          (federal, state and municipal) is aimed at organizing face-to-face
          education in its multiple social aspects and functions. In the last
          years, almost all children enrolled (official data?) were reached,
          considering the universalization of education as a goal achieved. The
          sudden occurrence of the Covid-19 pandemic, however, revealed how
          fragile the educational system is, solely dependent on face-to-face
          education in terms of its resilience in the face of disruptions that
          bring up structural vulnerabilities of society - social, economic,
          environmental, cultural , ethnic, racial, gender. No social actor was
          prepared to face such disruptions and to exercise their social role
          promptly and fully. The immense uncertainty took over all spheres of
          decision and revealed the unpreparedness of the educational system as
          a whole when the figure of the physical school had to be replaced by
          social distance and remote teaching.
        </Text>
        <Text as="p" size={2}>
          From the point of view of families, the school plays a very important
          role, especially the most vulnerable families, not only as an
          educational environment, which has a great value, but as a physical
          space for their children to be while they work, where, in addition to
          learning, they will have security and guaranteed food. This social
          function of the school creates a relationship of dependency with the
          face-to-face teaching format. There was no space to consider remote
          education with so many families without structure and with this need
          for young people to be in a safe space.
        </Text>
        <Text>
          <img src={collabSrc} alt="Sticky with Zoom" />
        </Text>
        <Text size={2}>
          The entire system is aimed at face-to-face education as the valid form
          of formal education. All public beliefs, structures, norms and
          policies are directed to face-to-face teaching. One of the direct
          effects of this is the lack of preparation for remote education forced
          by the pandemic. SME, school management, teachers, family and students
          were not in the least prepared for this disruption. This generated a
          very strong feeling of overload and stress when trying to cope with
          this new reality. The real overload felt by everyone is also revealed
          symbolically in the unpreparedness to face a period of uncertainty as
          a period of experimentation. When all the certainties were put in
          check, there was a desire and expectation that "someone" (especially
          the government through public policies) could offer a map of how to
          navigate this period. The absence of this position and also of an
          explicit invitation to experiment with new ways of doing distance
          education has generated a great increase in the vulnerability of
          families and children, not only due to the absence of an immediate
          response from public educational policies, but in addition, the
          absence of a set of social, economic, sanitary policies, etc. that
          could account for the cascading problems that unfolded along with the
          pandemic. All this boiling of vulnerabilities led to widespread
          pressure towards the thinning of the social fabric and the expansion
          of risks (unemployment, impoverishment, domestic violence, mental
          health problems, etc.). In this scenario, the learning and achievement
          of educational objectives was directly impaired, resulting in
          discouragement in relation to education and educational disengagement
          (even without formal evasion). This whole situation generated a
          feeling in the different actors that the 'solution' to this whole
          crisis is the return to face-to-face classes, that is, reinforcing the
          idea that only face-to-face education 'saves'. The prevalence of this
          thinking further reinforces the dependence on education in its
          face-to-face modality as the only valid form of education.
        </Text>
      </Box>
    </Section>
    <Section>
      <Link to="/signup">
        <Box gap={1} direction="row" align="center" justify="center">
          <Icons.Logo width={24} height={24} />
          <Text size={2}>
            Sign up for <strong>Sticky Studio</strong>
          </Text>
        </Box>
      </Link>
    </Section>
    <Footer />
  </Page>
);
