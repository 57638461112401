import "./StickerGroup.css";

import React, { ReactNode } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Sticker from "~/store/bundles/Sticker";
import * as Sticky from "~/store/bundles/Sticky";
import * as Position from "~/store/traits/Position";
import { FixedScale } from "~/components/FixedScale";
import { vec } from "~/util/geometry";

import { ReactionSticker, VoteSticker, AvatarSticker } from "./Sticker";
import { Box } from "./shared/Box";
import { ContextMenuTarget } from "./shared/ContextMenuTarget";
import { GrabTarget } from "./shared/GrabTarget";
import { ShowcaseTarget } from "./shared/ShowcaseTarget";

/**
 * Unlike stickers, we don't remove the base sticker from it's
 * associated sticky in order to keep them grouped while dragging
 * (sticker recipient is used for grouping).
 */
export const StickerGroup = React.memo(
  ({ stickerGroup }: { stickerGroup: Sticker.StickerGroup }) => {
    const dispatch = useDispatch();
    const { getState } = useStore();
    const { id, width, height, user, grab, stickers } = stickerGroup;
    const recipient = useSelector(Sticker.getRecipient(stickerGroup.id));

    const userId = useSelector(Client.getUserId);
    const collapseThreshold = Sticker.collapseThreshold(stickerGroup);
    const isOwner = userId === user;
    const isGrabbing = userId === grab?.userId;

    // StickerGroups are positioned according to the most recently-updated
    // sticker, which may or may not match the "base" sticker which defaults
    // to the most recent sticker the user owns. Because of that, and to prevent
    // a jump in sticker position on grab, we set the base sticker position
    // to the StickerGroup's position before we start grabbing.
    const onBeforeGrab = () => {
      const state = getState();
      const scene = Client.getScene(state);
      dispatch(
        Position.position(
          id,
          Client.toScreenCoordinates(scene, stickerGroup.position)
        )
      );
    };

    const onGrab = () => {
      if (recipient) {
        dispatch(Sticker.removeRecipient(stickerGroup.id));
        dispatch(Sticky.removeSticker(recipient.id, stickerGroup.id));
      }
    };

    // Show a slight change in scale based on group size.
    const size = Math.min(stickers.length - collapseThreshold + 1, 10);
    const scale = 1 + (1 / 10) * size;
    const transform = `scale(${scale})`;

    const position = recipient
      ? vec(recipient.position, stickerGroup.position)
      : stickerGroup.position;

    return (
      <Box
        className={`sticker-group absolute flex ${
          isGrabbing
            ? "cursor-grabbing"
            : isOwner
            ? "cursor-grab"
            : "cursor-default"
        }`}
        {...{ position, width, height }}
      >
        <div className="relative m-auto" style={{ width, height, transform }}>
          <ShowcaseTarget id={id} userId={stickerGroup.user}>
            <GrabTarget id={id} onBeforeGrab={onBeforeGrab} onGrab={onGrab}>
              <ContextMenuTarget id={id}>
                {Sticker.isReactionGroup(stickerGroup) ? (
                  <ReactionSticker
                    shortcode={stickerGroup.shortcode}
                    isOwner={isOwner}
                    isGrabbing={isGrabbing}
                  />
                ) : Sticker.isAvatarGroup(stickerGroup) ? (
                  <AvatarSticker
                    userId={stickerGroup.avatarUserId}
                    isOwner={isOwner}
                    isGrabbing={isGrabbing}
                  />
                ) : (
                  <VoteSticker
                    color={stickerGroup.color}
                    isOwner={isOwner}
                    isGrabbing={isGrabbing}
                  />
                )}
              </ContextMenuTarget>
            </GrabTarget>
            <Count>{stickers.length}</Count>
          </ShowcaseTarget>
        </div>
      </Box>
    );
  }
);

const Count = ({ children }: { children: ReactNode }) => (
  <div className="sticker-group-count">
    <FixedScale min={0.5} max={1} origin="bottom left">
      <span className="rounded-full text-lg bg-white w-[30px] h-[30px] flex items-center justify-center font-bold shadow-lg z-10">
        {children}
      </span>
    </FixedScale>
  </div>
);

StickerGroup.displayName = "StickerGroup";
