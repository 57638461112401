import "./BoardToolbar.css";

import { Children, ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Connection from "~/store/bundles/Connection";
import * as Label from "~/store/bundles/Label";
import * as Sticky from "~/store/bundles/Sticky";
import * as Edit from "~/store/traits/Edit";
import { Paintbucket } from "~/assets/icons";
import { BoardType, useConfig } from "~/context/ConfigContext";
import { BaseObject, Color, Delete, Lock } from "~/store/traits";
import assert from "~/util/assert";
import uid from "~/util/uid";
import usePermission from "~/util/usePermission";

import * as Icons from "./Icons";
import { ButtonGroup, IconButton, ButtonGroupProps } from "./Button";
import {
  ToolbarContextMenu,
  ToolbarContextMenuButton,
  ToolbarContextMenuItem,
  ToolbarContextMenuItems,
} from "./ToolbarContextMenu";
import { Tooltip } from "./Tooltip";

type Selection = BaseObject.BaseObject[];

let canLockSelection = (objects: Selection) => objects.some(Lock.isLock);

let canChangeColor = (objects: Selection) => objects.some(Color.isColor);

let canDeleteSelection = (objects: Selection) => objects.some(Delete.isDelete);

let canOpenComments = (objects: Selection) => objects.length === 1;

let canDirectConnection = (objects: Selection) =>
  objects.length > 0 && objects.every(Connection.isConnection);

let canConnectStickies = (objects: Selection) =>
  objects.length >= 2 && objects.every(Sticky.isSticky);

let canEditSelection = (objects: Selection) =>
  objects.length === 1 && objects.every(Edit.isEdit);

let isLabelSelection = (objects: Selection) =>
  objects.length > 0 && objects.every(Label.isLabel);

export let BoardToolbar = () => {
  let config = useConfig();
  const can = usePermission();
  let isTemplate = config.type === BoardType.Template;
  let selection = useSelector(Client.getSelectedObjects);
  let canEditAll = selection.map((obj) => can("edit", obj));

  let canComment = !isTemplate && canOpenComments(selection) && can("comment");
  let canLock = canLockSelection(selection) && canEditAll;
  let canDelete = canDeleteSelection(selection) && canEditAll;
  let canConnect = canConnectStickies(selection) && canEditAll;
  let canRedirect = canDirectConnection(selection) && canEditAll;
  let canEdit = canEditSelection(selection) && canEditAll;
  let canSelectLabelStyle = isLabelSelection(selection) && canEditAll;
  let canColor = canChangeColor(selection) && canEditAll;

  return (
    <ConditionalButtonGroup className="board-toolbar">
      {canEdit && <EditTool selection={selection} />}
      {canColor && <ChangeColorTool selection={selection} />}
      {canConnect && <StickyConnectTool selection={selection} />}
      {canRedirect && <ConnectionDirectionTool selection={selection} />}
      {canSelectLabelStyle && <LabelStyleTool selection={selection} />}
      {canComment && <OpenCommentsTool selection={selection} />}
      {canLock && <LockTool selection={selection} />}
      {canDelete && <DeleteTool selection={selection} />}
    </ConditionalButtonGroup>
  );
};

let ConditionalButtonGroup = ({ children, ...props }: ButtonGroupProps) => {
  let present = Children.toArray(children).some((child) => child);
  return present ? <ButtonGroup {...props}>{children}</ButtonGroup> : null;
};

let LabelStyleIcons: Record<Label.Style, ReactNode> = {
  title: <Icons.Type />,
  heading: <Icons.Heading />,
  paragraph: <Icons.Paragraph />,
};

export let LabelStyleTool = ({
  selection,
}: {
  selection: BaseObject.BaseObject[];
}) => {
  let dispatch = useDispatch();
  assert(selection.every(Label.isLabel), "Must be labels");
  assert(selection.length > 0, "Must have selection");
  let unlockedLabels = selection.filter((label) => !label.locked);
  let disabled = unlockedLabels.length === 0;

  // There will only be a selected style if all the styles in the selection are the same.
  let selectedStyle = selection.every(
    (label) => label.style === selection[0].style
  )
    ? selection[0].style
    : null;

  let onChange = (style: Label.Style) => {
    for (let label of unlockedLabels) {
      dispatch(Label.setStyle(label.id, style));
    }
  };

  return (
    <ToolbarContextMenu
      value={selectedStyle}
      onChange={onChange}
      horizontal
      disabled={disabled}
    >
      <ToolbarContextMenuButton>
        <Tooltip label="Label styles" position="bottom">
          <IconButton
            icon={<Icons.LabelStyle />}
            aria-label="Label styles"
            disabled={disabled}
          />
        </Tooltip>
      </ToolbarContextMenuButton>
      <ToolbarContextMenuItems>
        <Tooltip label="Title" position="bottom">
          <ToolbarContextMenuItem value="title">
            {LabelStyleIcons.title}
          </ToolbarContextMenuItem>
        </Tooltip>
        <Tooltip label="Heading" position="bottom">
          <ToolbarContextMenuItem value="heading">
            {LabelStyleIcons.heading}
          </ToolbarContextMenuItem>
        </Tooltip>
        <Tooltip label="Paragraph" position="bottom">
          <ToolbarContextMenuItem value="paragraph">
            {LabelStyleIcons.paragraph}
          </ToolbarContextMenuItem>
        </Tooltip>
      </ToolbarContextMenuItems>
    </ToolbarContextMenu>
  );
};

enum StickyColor {
  red = "sticky2",
  blue = "sticky4",
  green = "sticky1",
  yellow = "sticky3",
}

export let ChangeColorTool = ({
  selection,
}: {
  selection: BaseObject.BaseObject[];
}) => {
  let dispatch = useDispatch();
  let colorable = selection.filter(Color.isColor);
  let colorableAndUnlocked = colorable
    .filter(Lock.isLock)
    .filter((object) => !object.locked);
  let disabled = colorableAndUnlocked.length === 0;

  // There will only be a selected color if all the colors in the selection are the same.
  let selectedColor = colorable.every(
    (sticky) => sticky.color === colorable[0].color
  )
    ? colorable[0].color
    : null;

  let onChange = (color: StickyColor) => {
    for (let object of colorableAndUnlocked) {
      dispatch(Color.setColor(object.id, color));
    }
  };

  return (
    <ToolbarContextMenu
      value={selectedColor}
      onChange={onChange}
      horizontal
      disabled={disabled}
    >
      <ToolbarContextMenuButton>
        <Tooltip label="Change Color" position="bottom">
          <IconButton
            icon={<Paintbucket />}
            aria-label="Change Color"
            disabled={disabled}
          />
        </Tooltip>
      </ToolbarContextMenuButton>
      <ToolbarContextMenuItems>
        <Tooltip label="Red" position="bottom">
          <ToolbarContextMenuItem value={StickyColor.red}>
            <Icons.Sticky color="var(--sticky2" />
          </ToolbarContextMenuItem>
        </Tooltip>
        <Tooltip label="Blue" position="bottom">
          <ToolbarContextMenuItem value={StickyColor.blue}>
            <Icons.Sticky color="var(--sticky4" />
          </ToolbarContextMenuItem>
        </Tooltip>
        <Tooltip label="Green" position="bottom">
          <ToolbarContextMenuItem value={StickyColor.green}>
            <Icons.Sticky color="var(--sticky1" />
          </ToolbarContextMenuItem>
        </Tooltip>
        <Tooltip label="Yellow" position="bottom">
          <ToolbarContextMenuItem value={StickyColor.yellow}>
            <Icons.Sticky color="var(--sticky3" />
          </ToolbarContextMenuItem>
        </Tooltip>
      </ToolbarContextMenuItems>
    </ToolbarContextMenu>
  );
};

export let StickyConnectTool = ({
  selection,
}: {
  selection: BaseObject.BaseObject[];
}) => {
  let dispatch = useDispatch();
  let stickies = selection;
  let userId = useSelector(Client.getUserId);
  assert(stickies.every(Sticky.isSticky));

  let onClick = () => {
    for (let i = 0; i < stickies.length - 1; i++) {
      let from = stickies[i];
      let to = stickies[i + 1];
      let id = uid();
      dispatch(Connection.add({ id, from: from.id, to: to.id, userId }));
    }
  };

  return (
    <Tooltip label="Connect" position="bottom">
      <IconButton
        icon={<Icons.Connect />}
        aria-label="Connect"
        onClick={onClick}
      />
    </Tooltip>
  );
};

type Direction = Connection.Connection["direction"];

let ConnectionIcons: Record<Direction, ReactNode> = {
  to: <Icons.ConnectionRight />,
  reverse: <Icons.ConnectionLeft />,
  both: <Icons.ConnectionBoth />,
  undirected: <Icons.ConnectionUndirected />,
};

export let ConnectionDirectionTool = ({
  selection,
}: {
  selection: BaseObject.BaseObject[];
}) => {
  let dispatch = useDispatch();
  const connections = selection;
  assert(connections.every(Connection.isConnection));
  let locked = connections.every((connection) => connection.locked);

  // There will only be a selected direction if all the connections in the selection have the same direction.
  let selectedDirection = connections.every(
    (connection) => connection.direction === connections[0].direction
  )
    ? connections[0].direction
    : null;

  let onChange = (direction: Connection.Connection["direction"]) => {
    for (let connection of connections) {
      if (connection.locked) continue;
      dispatch(Connection.setDirection(connection.id, direction));
    }
  };

  return (
    <ToolbarContextMenu
      value={selectedDirection}
      onChange={onChange}
      horizontal
    >
      <ToolbarContextMenuButton>
        <IconButton
          icon={ConnectionIcons[selectedDirection || "both"]}
          aria-label="Direction"
          disabled={locked}
        />
      </ToolbarContextMenuButton>
      <ToolbarContextMenuItems>
        <ToolbarContextMenuItem value="to">
          <Icons.ConnectionRight />
        </ToolbarContextMenuItem>
        <ToolbarContextMenuItem value="reverse">
          <Icons.ConnectionLeft />
        </ToolbarContextMenuItem>
        <ToolbarContextMenuItem value="both">
          <Icons.ConnectionBoth />
        </ToolbarContextMenuItem>
        <ToolbarContextMenuItem value="undirected">
          <Icons.ConnectionUndirected />
        </ToolbarContextMenuItem>
      </ToolbarContextMenuItems>
    </ToolbarContextMenu>
  );
};

export let EditTool = ({
  selection,
}: {
  selection: BaseObject.BaseObject[];
}) => {
  let dispatch = useDispatch();
  let userId = useSelector(Client.getUserId);
  let objects = selection;
  let object = objects[0];
  assert(Edit.isEdit(object), "Object must be editable");

  let disabled =
    // Can't edit if the object is locked
    (Lock.isLock(object) ? object.locked : false) ||
    // Can't edit if someone else is currently editing
    (object.editing !== null && object.editing !== userId);
  let toggled = object.editing === userId;
  const [wasToggledWhenMouseDown, setWasToggledWhenMouseDown] = useState(false);

  // Toggle editing
  let onClick = () => {
    // In case something is being edited a mouse down event outside the edit will trigger a finish editing
    // because this by the time onClick triggers toggled is already false and thereby in here toggled is always false
    // therefore we determine based the state of toggled when the mouse down event triggered on this button
    if (!wasToggledWhenMouseDown) {
      dispatch(Edit.startEditing(object.id, userId));
    }
  };

  let onMouseDown = () => {
    setWasToggledWhenMouseDown(toggled);
  };

  return (
    <Tooltip label="Edit" position="bottom">
      <IconButton
        icon={<Icons.Edit />}
        aria-label="Edit"
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
        toggled={toggled}
      />
    </Tooltip>
  );
};

export let OpenCommentsTool = ({
  selection,
}: {
  selection: BaseObject.BaseObject[];
}) => {
  let dispatch = useDispatch();
  let object = selection[0];
  const commentObjectId = useSelector(Client.getCommentObjectId);
  let toggled = commentObjectId === object.id;

  // Toggle commenting
  let onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!toggled) {
      dispatch(Client.setCommentObjectId(object.id));
    } else {
      dispatch(Client.unsetCommentObjectId());
    }
  };

  return (
    <Tooltip label="Comments" position="bottom">
      <IconButton
        icon={<Icons.MessageCircle />}
        aria-label="Comments"
        onClick={onClick}
        toggled={toggled}
      />
    </Tooltip>
  );
};

export let LockTool = ({
  selection,
}: {
  selection: BaseObject.BaseObject[];
}) => {
  let dispatch = useDispatch();
  let objects = selection.filter(Lock.isLock);
  let locked = objects.filter(Lock.isLock).every((object) => object.locked);

  let onClick = () => {
    for (let object of objects) {
      if (locked) {
        dispatch(Lock.unlock(object.id));
      } else {
        dispatch(Lock.lock(object.id));
      }
    }
  };

  const label = locked ? "Unlock" : "Lock";

  return (
    <Tooltip label={label} position="bottom">
      <IconButton
        toggled={locked}
        icon={locked ? <Icons.Unlock /> : <Icons.Lock />}
        aria-label={label}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export let DeleteTool = ({
  selection,
}: {
  selection: BaseObject.BaseObject[];
}) => {
  let dispatch = useDispatch();

  let selected = selection
    .filter(Delete.isDelete)
    .filter((object) => !Lock.isLock(object) || !object.locked)
    .map((object) => object.id);

  let onClick = () => {
    dispatch(Delete.deleteMany(selected));
  };

  return (
    <Tooltip label="Delete" position="bottom">
      <IconButton
        icon={<Icons.Trash />}
        aria-label="Delete"
        disabled={selected.length === 0}
        onClick={onClick}
      />
    </Tooltip>
  );
};
