import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import { Create } from "~/store/traits";
import usePermission from "~/util/usePermission";

/**
 * Component that encapsulates the selection behavior of objects. There
 * essetially three scenarios:
 *
 * 1. Shift + mouseDown: Toggle the current object's selection state, so either
 *    include or exclude it from the total selection.
 *
 * 2. mouseDown on non-selected object: drop selection and select the object
 *    instead
 *
 * 3. mousedown on selected object: We can't know yet if it'll be a click or a
 *    drag, so we wait for the mouseup (in the onClick handler) to determine
 *    whether to drop the selection.
 */
export const SelectTarget = ({
  id,
  children,
}: {
  id: string;
  children: ReactNode;
}) => {
  const dispatch = useDispatch();
  const isDragging = useSelector(Client.getIsDragging);
  const selection = useSelector(Client.getSelected);
  const isSelected = selection.includes(id);
  const can = usePermission();
  const object = useSelector(Create.getById(id));

  const onPointerDown = (event: React.MouseEvent) => {
    if (!can("edit", object)) return;
    // if (event.button !== 0) return;

    // Modifying the selection should close the contextMenu if open, so we don't
    // show stale or changing options.
    dispatch(Client.unsetContextMenu());

    if (event.shiftKey) {
      // Add/remove single object from the selection
      dispatch(Client.toggleSelect(id));
    } else if (!isSelected) {
      // Drop the selection and select this object instead
      dispatch(Client.selectOnly(id));
    }
  };

  const onPointerUp = (event: React.MouseEvent) => {
    if (!can("edit", object)) return;
    if (event.button !== 0) return;
    if (isDragging) return; // Don't modify the selection state if we're dragging a selection around.
    if (event.shiftKey) return;

    if (isSelected && selection.length > 0) dispatch(Client.selectOnly(id));
  };

  return (
    <div onPointerDown={onPointerDown} onPointerUp={onPointerUp}>
      {children}
    </div>
  );
};
