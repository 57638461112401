import Bugsnag from "@bugsnag/js";

import assert from "~/util/assert";

import * as BaseObject from "./BaseObject";
import { AppState, State, Action as AppAction } from "../index";
import { BaseAction } from "../shared";
import { Trait } from "./Trait";

/**
 * Interface that describes objects that have a color
 */
export interface Color extends Trait {
  color: string;
}

const COLORABLE_TYPES = ["sticky"];

/**
 * Type guard that checks whether an object (BaseObject) implements the Color
 * trait.
 *
 * Because some color-related controls use this type guard to decide if they
 * should be enabled, we reference the list of types as a narrowing expression
 * rather than check whether or not the `color` property is present. Once the
 * UI is in place to update the color for all colorable objects, revert to type
 * narrowing via the latter method.
 */
export const isColor = (object: BaseObject.BaseObject): object is Color => {
  return COLORABLE_TYPES.includes(object.type);
};

/**
 * Schema for actions returned by the `Color.setColor()` action creator.
 */
export interface SetColorAction extends BaseAction {
  type: "color/set_color";
  data: Pick<Color, "id" | "color">;
}

/**
 * Action creator that changes an object's color.
 */
export const setColor = (id: string, color: string): SetColorAction => {
  return {
    type: "color/set_color",
    data: { id, color },
    effects: { persist: true, broadcast: true },
  };
};

/**
 * Union type of all Color related actions.
 */
export type Action = SetColorAction;

/**
 * Get all colored objects from an app state.
 */
export const getAll = (state: AppState) =>
  Object.values(state.objects).filter(isColor);

/**
 * Get Color object with given id.
 */
export const getById = (id: string) => (state: AppState) => {
  const object = BaseObject.getById(id)(state);
  if (object && isColor(object)) return object;
};

/**
 * Reducer that handles any Color-related actions
 */
export const reducer = (state: State = {}, action: AppAction): State => {
  switch (action.type) {
    case "color/set_color": {
      const object = state[action.data.id];
      assert(isColor(object), "Object does not implement the Color trait.");

      if (!isColor(object)) {
        Bugsnag.notify(new Error("Object does not implement the Color trait."));
        return state;
      }

      return {
        ...state,
        [action.data.id]: { ...object, ...action.data },
      };
    }
    default:
      return state;
  }
};
