import "./FormStatus.css";

import { useFormikContext } from "formik";
import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

import { classNames } from "~/util/classNames";

import { Box } from "./Box";

export type Status =
  | { status: "initial"; msg?: string }
  | { status: "waiting"; msg?: string }
  | { status: "success"; msg?: string }
  | { status: "failed"; msg?: string };

type Props = {
  status?: Status;
  className?: string;
};

export const FormStatus = (props: Props) => {
  const [visible, setVisible] = useState(false);

  let { status } = useFormikContext() as Props;

  useEffect(() => {
    if (status && status?.status !== "initial") setVisible(true);
  }, [status]);

  const onEnter = () => {
    setTimeout(() => setVisible(false), 3000);
  };

  return status && status?.status === "waiting" ? (
    <div>Waiting...</div>
  ) : (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear
      onEnter={onEnter}
      in={visible}
      timeout={300}
      classNames="form-status"
    >
      <Box
        align="center"
        justify="center"
        className={classNames(
          "form-status",
          status && `form-status-${status.status}`,
          props.className
        )}
      >
        {getStatus(status)}
      </Box>
    </CSSTransition>
  );
};

/**
 * Handles returning a default status message for a
 * request if none was provided in the response.
 *
 * @param status Status object
 * @returns string
 */
function getStatus(status: Status | undefined) {
  if (status?.msg) {
    return status.msg;
  }

  if (!status?.status) return "";

  if (status.status === "success") {
    return "Your values have been saved.";
  } else if (status.status === "failed") {
    return "Something went wrong.";
  }

  return "";
}
