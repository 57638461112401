import * as Rect from "~/util/geometry/rectangle";
import { Rectangle } from "~/util/geometry";
import { createShallowEqualSelector } from "~/util/selectors";

import * as Connection from "../bundles/Connection";
import { AppState } from "..";
import { BaseObject } from "../traits/BaseObject";
import { isDelete } from "../traits/Delete";
import { isSize } from "../traits/Size";

/**
 * Selector that returns a record of all objects in the app state, keyed by
 * their id.
 *
 * @param {AppState} state - The current app state.
 * @returns  A record of all objects in the app state.
 */
export const getAll = createShallowEqualSelector(
  (state: AppState) => state.objects,
  (objects) => objects
);

/**
 * Selector creator that returns an object with the given id.
 *
 * @param {string} id - The id of the object to return.
 * @returns The object with the desired id.
 */
export const getById = (id: string) => (state: AppState) => state.objects[id];

/**
 * Return the bounding rectangle for a collection of objects.
 *
 * This is a simple wrapper that switches on whether the object is `Size`, or
 * if a custom notion of "bounding box" needs to be considered (e.g., for
 * Connections)
 *
 * @param ids - The list of object ids to include in the bounding box
 */
export const getBoundingRect =
  (objects: BaseObject[]) =>
  (state: AppState): Rectangle => {
    let rectangles: Rectangle[] = [];

    for (let object of objects) {
      if (isDelete(object) && object.deleted) continue;

      if (isSize(object)) {
        rectangles.push(object); // Size objects walk and quack like Rectangles
      }

      if (Connection.isConnection(object)) {
        const boundingRect = Connection.getBoundingRect(object)(state);

        if (boundingRect) rectangles.push(boundingRect);
      }
    }

    return Rect.getBoundingRect(rectangles);
  };
