import assert from "~/util/assert";

import * as BaseObject from "./BaseObject";
import { AppState, State, Action as AppAction } from "../index";
import { BaseAction, emptyAction, EmptyAction } from "../shared";
import { Trait } from "./Trait";

/**
 * Interface that describes objects that can be locked.
 */
export interface Lock extends Trait {
  locked: boolean;
}

/**
 * Type guard that checks whether an object (BaseObject) implements the Lock
 * trait.
 */
export const isLock = <T extends BaseObject.BaseObject>(
  object: T
): object is T & Lock => "locked" in object;

/**
 * Schema for actions returned by the `Lock.lock()` action creator.
 */
export interface LockAction extends BaseAction {
  type: "lock/lock";
  data: Pick<Lock, "id" | "locked">;
}

/**
 * Action creator that locks an object with a given `id`.
 */
export const lock = (id: string): LockAction => {
  return {
    type: "lock/lock",
    data: { id, locked: true },
    effects: { broadcast: true, persist: true },
  };
};

/**
 * Schema for actions returned by the `Lock.unlock()` action creator.
 */
export interface UnlockAction extends BaseAction {
  type: "lock/unlock";
  data: Pick<Lock, "id" | "locked">;
}

/**
 * Action creator that unlocks an object with a given `id`.
 */
export const unlock = (id: string): UnlockAction => {
  return {
    type: "lock/unlock",
    data: { id, locked: false },
    effects: { broadcast: true, persist: true },
  };
};

/**
 * Schema for actions returned by the `Lock.toggleLock()` action creator.
 */
export interface ToggleLockAction extends BaseAction {
  type: "lock/toggle_lock";
  data: Pick<Lock, "id" | "locked">;
}

/**
 * Action creator that toggles the locked flag for an object with a given `id`.
 */
export const toggleLock =
  (id: string) =>
  (state: AppState): ToggleLockAction | EmptyAction => {
    const object = getById(id)(state);
    if (!object) return emptyAction();

    return {
      type: "lock/toggle_lock",
      data: { id, locked: !object.locked },
      effects: { broadcast: true, persist: true },
    };
  };

/**
 * Union type of all Lock related actions.
 */
export type Action = LockAction | UnlockAction | ToggleLockAction;

/**
 * Get all lockable objects from an app state.
 */
export const getAll = (state: AppState) =>
  Object.values(state.objects).filter(isLock);

/**
 * Get Lock object with given id.
 */
export const getById = (id: string) => (state: AppState) => {
  const object = BaseObject.getById(id)(state);
  if (object && isLock(object)) return object;
};

/**
 * Reducer that handles any Lock-related actions
 */
export const reducer = (state: State = {}, action: AppAction): State => {
  switch (action.type) {
    case "lock/lock":
    case "lock/unlock":
    case "lock/toggle_lock": {
      const object = state[action.data.id];
      assert(isLock(object), "Object does not implement the Lock trait.");

      return {
        ...state,
        [action.data.id]: { ...object, ...action.data },
      };
    }
    default:
      return state;
  }
};
