import Bugsnag from "@bugsnag/js";
import { Store } from "redux";

import * as Sticky from "~/store/bundles/Sticky";
import { NODE_ENV, VERSION } from "~/config";
import { fork } from "~/store";
import { getClient, getUserId, selectMany } from "~/store/bundles/Client";
import { isLabel, Label } from "~/store/bundles/Label";
import { isSticky } from "~/store/bundles/Sticky";
import { isUpload } from "~/store/bundles/Upload";
import { BaseObject } from "~/store/traits";
import { insertMany } from "~/store/traits/BaseObject";
import { isPosition } from "~/store/traits/Position";
import { isSize } from "~/store/traits/Size";
import { uploadImageFromFile } from "~/util/upload";

import { add, getBoundingRect, getCenter, vec } from "./geometry";
import uid from "./uid";

/**
 * Chrome and Edge allows for custom mime types starting with "web ".
 * Only sticky studio will accept this.
 * https://developer.chrome.com/blog/web-custom-formats-for-the-async-clipboard-api/
 */
const STICKY_MIME_TYPE = "web application/sticky-studio";

type MimeType = typeof STICKY_MIME_TYPE | "image/png" | "text/plain";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Copy blobs into clipboard.
 */
export async function writeBlobsToClipboard(blobs: Blob[]) {
  // If browser doesn't support clipboard.write use localstorage and clipboard.writeText if that exist instead
  if (!navigator.clipboard?.write) {
    for (let blob of blobs) {
      if (blob.type === STICKY_MIME_TYPE) {
        localStorage.setItem("clipboard", await blob.text());
      } else if (blob.type === "text/plain" && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(await blob.text());
      }
    }
    return;
  }

  const blobsObject: { [key in MimeType]?: Blob } = {};
  for (let blob of blobs) {
    // Safari doesn't support custom mime types like edge and chrome therefore
    // store the board objects in localstorage for Safari.
    if (blob.type === STICKY_MIME_TYPE && isSafari) {
      localStorage.setItem("clipboard", await blob.text());
      continue;
    }
    blobsObject[blob.type as MimeType] = blob;
  }

  // Safari has some annoying logic which means that you can only write to
  // the clipboard synchronously during an event handler.
  // See: https://bugs.webkit.org/show_bug.cgi?id=222262
  //
  // This won't ever be the case here beause `objectsToBlobs` is async and
  // that gets called first. The only bypass would be doing a sync write and
  // an async write separately (e.g. text first, then images second, for
  // browsers that allow) but that seems too complicated considering that
  // Safari users already have the localStorage fallback above for in-app
  // copy/paste.

  try {
    await navigator.clipboard.write([new ClipboardItem(blobsObject)]);
  } catch (err: any) {
    // If we fall into this catch it's probably because the user did not grant
    // us permission to use their clipboard, or because of the Safari issue
    // described above.
    Bugsnag.notify(err);
    throw err;
  }
}

/**
 *  Paste the contents of the clipboard into board at mouse position.
 *
 * 	1. choice is to paste type "web application/sticky-studio"
 * 	   if that is not available.
 *
 *  2. choice is type "image/png" where image will be uploaded.
 *
 *  3. choice is type "text/plain" where the text will be placed in a sticky.
 * 	   Only one data type will be pasted.
 *
 */
export async function readBlobFromClipboard(): Promise<Blob | null> {
  // If clipboard data has been stored to localStorage that means the clients clipboard doens't
  // support STICKY_MIME_TYPE therefore create blob from localStorage data.
  const clipboardData = localStorage.getItem("clipboard");
  if (clipboardData)
    return new Blob([clipboardData], { type: STICKY_MIME_TYPE });

  // @ts-ignore if the browser doesn't support clipboard.read look if there's text inside clipboard.readText
  if (!navigator.clipboard?.read) {
    if (navigator.clipboard?.readText) {
      const clipboardString = await navigator.clipboard.readText();
      if (clipboardString)
        return new Blob([clipboardString], { type: "text/plain" });
    }
    return null;
  }

  const [clipboardItem] = await navigator.clipboard.read();
  const types = clipboardItem.types as MimeType[];

  if (types.includes(STICKY_MIME_TYPE)) {
    return await clipboardItem.getType(STICKY_MIME_TYPE);
  } else if (types.includes("image/png")) {
    return await clipboardItem.getType("image/png");
  } else if (types.includes("text/plain")) {
    return await clipboardItem.getType("text/plain");
  } else {
    return null;
  }
}

/**
 *  Insert blob into the board
 */
export async function insertFromBlob(blob: Blob, store: Store) {
  const state = store.getState();
  const { cursor } = getClient(state);

  switch (blob.type as MimeType) {
    case STICKY_MIME_TYPE:
      const { objects: parsedObjects, clientVersion } = JSON.parse(
        await blob.text()
      ) as { objects: BaseObject.BaseObject[]; clientVersion: string };
      if (clientVersion !== VERSION && NODE_ENV === "production") {
        throw Error(
          "The client version and the version in the clipboard does not match"
        );
      }
      const forkedObjects = fork(parsedObjects);

      // Move objects so the center of the clipboard bounding box aligns with
      // the cursor position
      const boundingBox = getBoundingRect(forkedObjects.filter(isSize));
      const center = getCenter(boundingBox);
      const delta = vec(center, cursor.scenePosition);
      for (let object of forkedObjects) {
        if (isPosition(object)) {
          object.position = add(object.position, delta);
        }
      }
      store.dispatch(insertMany(forkedObjects));
      store.dispatch(selectMany(forkedObjects.map(({ id }) => id)));
      break;
    case "image/png":
      const file = new File([blob], uid(), { type: blob.type });
      uploadImageFromFile({
        file,
        dispatch: store.dispatch,
        position: cursor.scenePosition,
        userId: getUserId(state),
      });
      break;
    case "text/plain":
      const id = uid();
      const text = await blob.text();
      store.dispatch(
        Sticky.add({
          id: uid(),
          mousePosition: cursor.screenPosition,
          width: 200,
          height: 200,
          content: text,
        })(state)
      );

      store.dispatch(selectMany([id]));
      break;
  }
}

/**
 * Turn objects into blobs that can be copied into clipboard
 */
export async function objectsToBlobs(objects: BaseObject.BaseObject[]) {
  const blobs: Blob[] = [];

  // Create a blob of the sticky studio objects as "web application/sticky-studio"
  const json = JSON.stringify({ objects, clientVersion: VERSION });
  const stickyBlob = new Blob([json], { type: STICKY_MIME_TYPE });
  blobs.push(stickyBlob);

  // If all of the selected objects are text-based, we copy all of them, separated as paragraphs (separated by two newlines)
  let textbasedObjects = objects.filter(
    (object) => isLabel(object) || isSticky(object)
  ) as (Sticky.Sticky | Label)[];

  if (objects.length > 0 && textbasedObjects.length === objects.length) {
    let text = textbasedObjects.map((object) => object.content).join("\n\n");
    const textBlob = new Blob([text], { type: "text/plain" });
    blobs.push(textBlob);
  }

  // if objects only contains 1 image and it's upload then fetch the image and put it in the clipboard
  if (objects.length === 2) {
    const uploads = objects.filter(isUpload);
    if (uploads.length === 1) {
      const upload = uploads[0];
      if (upload.status === "success") {
        const response = await fetch(upload.url);

        // Chrome and Edge cannot save type image/jpeg to clipboard so in order to save image of that type it's required to store it as type image/png
        const imageBlob = new Blob([await response.blob()], {
          type: "image/png",
        });
        blobs.push(imageBlob);
      }
    }
  }

  return blobs;
}
