import { useState, useRef, useEffect } from "react";

/**
 * Used to set a specific className for a short time after a value changes
 * so that you can play a short animation as the UI changes.
 */
export function useClassOnChange({
  value,
  className,
  durationInMs = 1000,
}: {
  value: any;
  className: string;
  durationInMs?: number;
}): string {
  let [active, setActive] = useState(false);
  let previousRef = useRef();
  // NOTE: Need to keep manual track of the `value` dependency because React
  // double-mounts the component, so the effect will run twice.
  useEffect(() => {
    if (previousRef.current && previousRef.current !== value) {
      setActive(true);
      let end = () => setActive(false);
      let handle = setTimeout(end, durationInMs);
      return () => clearTimeout(handle);
    }
    previousRef.current = value;
  }, [value, durationInMs]);
  return active ? className : "";
}
