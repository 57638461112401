/**
 * Rough sketch of Fathom client.
 */
interface FathomClient {
  trackEvent(name: string, payload?: any): void;
  trackGoal(code: string, cents: number): void;
  trackPageview(params: any): void;
}

declare global {
  interface Window {
    /**
     * We load Fathom through a CDN rather than an import. It won't show up in
     * development, or for users with adblockers though.
     */
    fathom: FathomClient | undefined;
  }
}

/**
 * Friendly names for Events that we have configured in Fathom.
 * See: https://app.usefathom.com/sites/YXCEIVDZ/events
 */
export enum FathomEvents {
  Try = "QP0DTXCN",
  SignUp = "YYTMLMZ6",
  ExportToKumu = "QDWMX8RI",
  CreateBoard = "VXBBTYEZ",
  CopyShareLink = "PCNJGYBZ",
  ChangeBoardPermissions = "EH1JQAU1",
}

/**
 * @param eventId The ID of the event in Fathom.
 * @param value The value associated with this event.
 */
export function trackAnalyticsEvent(eventId: FathomEvents, value = 0) {
  window.fathom?.trackGoal(eventId, value);
}
