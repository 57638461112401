/* Establish and interact with websocket connection
*
* Sets up a websocket connection and attaches some event handlers
*
* Websocket communication happens in both directions:
*
* 1. Incoming events are handled by event listeners attached to the socket.
*   e.g.: 
*```ts
  socket.on("REMOVE_USER", (userId: string) => {
    dispatch(User.remove(userId));
  });
*```
*
* 2. Incoming actions intercepted by the middleware are processed by an 
* "Emitter". Handlers may be added to an Emitter object that will in turn
* inspect the incoming actions and emit websocket events through accordingly.
*/
import type { Middleware } from "redux";
import type { Socket } from "socket.io-client";

import * as Board from "~/store/bundles/Board";
import * as Client from "~/store/bundles/Client";
import * as Global from "~/store/bundles/Global";
import * as User from "~/store/bundles/User";
import * as BaseObject from "~/store/traits/BaseObject";
import * as BoardApi from "~/util/BoardClient";
import { Config } from "~/context/ConfigContext";
import { ConnectionAction } from "~/store/bundles/Connection";
import { ImageAction } from "~/store/bundles/Image";
import { LabelAction } from "~/store/bundles/Label";
import { LineAction } from "~/store/bundles/Line";
import { ShapeAction } from "~/store/bundles/Shape";
import { StickerAction } from "~/store/bundles/Sticker";
import { StickyAction } from "~/store/bundles/Sticky";
import { UploadAction } from "~/store/bundles/Upload";
import { UserAction } from "~/store/bundles/User";
import { VertexAction } from "~/store/bundles/Vertex";
import log from "~/util/logger";
import type { Action } from "~/util/types";

import { createEmitter } from "./emitter";

const websocket =
  ({ boardId, userId }: Config, socket: Socket): Middleware =>
  ({ dispatch, getState }) =>
  (next) => {
    // Register action processors responsible for sending out websocket events.
    const emitter = createEmitter(socket);
    emitter.add(comments);
    emitter.add(persistAction);
    emitter.add(relayAction);

    const handlers = {
      connect: () => {
        socket.emit("JOIN_BOARD", boardId);
        log.info("Connected to ", socket.id);
        dispatch(Client.setConnectionStatus(true));
      },

      disconnect: () => {
        dispatch(Client.setConnectionStatus(false));
      },

      REMOVE_USER: (userId: string) => {
        if (User.getById(userId)(getState())) {
          dispatch(User.remove(userId));
        }
      },

      CLEAR_USERS: () => {
        const onlineUsers = Object.values(User.getUsers(getState()))
          .filter((user) => user.online)
          .filter((user) => user.id !== userId);

        for (const user of onlineUsers) {
          dispatch(User.remove(user.id));
        }
      },

      RELAY_ACTION: (action: Action) => {
        next(action);
      },

      BROADCAST_ACTION: (action: Action) => {
        dispatch(action);
      },
    };

    for (let [event, handler] of Object.entries(handlers)) {
      socket.on(event, handler);
    }

    // Reconnect events are sent to the manager, not the socket,
    // hence attaching the handler to `socket.io` rather than `socket`.
    socket.io.on("reconnect", async () => {
      dispatch(Client.setConnectionStatus(true));
      const boardDataResponse = await BoardApi.getFullBoard(boardId);

      if (boardDataResponse.status === "success") {
        const { id, name, description, objects, plan } = boardDataResponse.data;
        dispatch(Global.loadState({ id, name, description, plan }, objects));
      }
    });

    return (action: Action) => {
      // Perform any store teardown logic
      if (action.type === "teardown") {
        socket.io.removeAllListeners("reconnect");

        for (let event of Object.keys(handlers)) {
          socket.removeAllListeners(event);
        }

        return;
      }

      emitter.process(action);
      return next(action);
    };
  };

type CreateAction =
  | BaseObject.Action
  | StickyAction
  | LabelAction
  | ConnectionAction
  | ImageAction
  | StickerAction
  | ShapeAction
  | UploadAction
  | UserAction
  | VertexAction
  | LineAction;

const CREATE_ACTIONS: Set<CreateAction["type"]> = new Set([
  "base_object/insert",
  "base_object/insert_many",
  "sticky/add",
  "label/add",
  "connection/add",
  "image/add",
  "sticker/add",
  "upload/add",
  "shape/add",
  "user/add",
  "line/add",
  "line/insert",
  "vertex/add",
] as const);

type BoardAction = Board.BoardAction;

const BOARD_ACTIONS: Set<BoardAction["type"]> = new Set([
  "board/set_info",
  "board/set_name",
  "board/set_description",
  "board/set_font",
] as const);

const CREATE_REACTION_ACTION = "reaction/create";
const REMOVE_REACTION_ACTION = "reaction/remove";

/**
 * Action processor that handles any `persist` effects by sending an event
 * over the websocket to persist a change to the database.
 *
 * Handler is meant to be registered to an Emitter.
 */
const persistAction = (socket: Socket, action: Action) => {
  if (!action?.effects?.persist) return;

  if (CREATE_ACTIONS.has(action.type as CreateAction["type"])) {
    socket.emit("CREATE_OBJECT", action);
  } else if (BOARD_ACTIONS.has(action.type as BoardAction["type"])) {
    socket.emit("UPDATE_BOARD", action);
  } else if (CREATE_REACTION_ACTION === action.type) {
    socket.emit("CREATE_REACTION", action);
  } else if (REMOVE_REACTION_ACTION === action.type) {
    socket.emit("REMOVE_REACTION", action);
  } else {
    socket.emit("UPDATE_OBJECT", action);
  }
};

/**
 * Action processor that handles any `broadcast` effects.
 *
 * Handler is meant to be registered to an Emitter.
 */
const relayAction = (socket: Socket, action: Action) => {
  if (action?.effects?.broadcast) {
    socket.emit("RELAY_ACTION", action);
  }
};

/**
 * Create/update comment on the server.
 *
 * Because comments include data that needs to be added on the server, we can't
 * just reduce an action locally and broadcast it. Instead, we send a socket
 * request to have the server create the comment, add it to the database, and
 * have the server broadcast an appropriate redux action to all the clients.
 */
const comments = (socket: Socket, action: Action) => {
  if (action.type === "websocket/create_comment") {
    socket.emit("CREATE_COMMENT", action);
  } else if (action.type === "comment/update") {
    socket.emit("UPDATE_COMMENT", action);
  } else if (action.type === "comment/remove") {
    socket.emit("REMOVE_COMMENT", action);
  }
};

export default websocket;
