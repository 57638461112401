import "./ShowcaseTarget.css";

import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Grab from "~/store/traits/Grab";
import * as Resize from "~/store/traits/Resize";
import { classNames } from "~/util/classNames";

type Props = {
  id: string;
  userId?: string;
  children: ReactNode;
};

/**
 * Component that encapsulates the showcase-related mouse event behavior.
 */
export const ShowcaseTarget = ({
  id,
  children,
  userId: showcaseUserId,
}: Props) => {
  const showcasedObjectIds = useSelector(Client.getShowcaseObjectIds);
  const hasShowcase = showcasedObjectIds !== null;
  const userId = useSelector(Client.getUserId);
  const isGrabbing = useSelector(Grab.getGrabbingBy(userId)).length > 0;
  const isResizing = useSelector(Resize.getResizingBy(userId)).length > 0;
  const dispatch = useDispatch();

  const onPointerEnter = () => {
    if (!showcaseUserId) return;
    // Don't showcase while grabbing or resizing
    if (isGrabbing || isResizing) return;
    // Showcase the user who created the object
    dispatch(Client.setShowcaseUserId(showcaseUserId));
  };

  const onPointerLeave = () => {
    if (!showcaseUserId) return;
    // Unset the showcased user
    dispatch(Client.unsetShowcaseUserId());
  };

  return (
    <div
      className={classNames(
        "showcase-target",
        hasShowcase &&
          !showcasedObjectIds?.includes(id) &&
          "showcase-target--unfocused"
      )}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      {children}
    </div>
  );
};
