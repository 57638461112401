import {
  Formik,
  FormikErrors,
  FormikHelpers,
  useField,
  useFormikContext,
} from "formik";
import { useState } from "react";

import * as BoardApi from "~/util/BoardClient";

import * as Icons from "./Icons";
import { BoardModal } from "./BoardModal";
import { Box } from "./Box";
import { Button } from "./Button";
import {
  FormField,
  FormFieldHeader,
  FormFieldLabel,
  FormFieldMessage,
  FormFieldValidationMessage,
} from "./FormField";
import { ModalPanel, ModalProps, ModalTitle, ModalDescription } from "./Modal";
import { TemplatesUpsell, useIsPlus } from "./StickyStudioPlus";
import { TextArea } from "./TextArea";
import { TextInput } from "./TextInput";

interface Values {
  name: string;
  description: string;
}

interface ConvertToTemplateModalProps extends ModalProps {
  boardId: string;
  onCreate(templateId: string): void;
}

export let ConvertToTemplateModal = (props: ConvertToTemplateModalProps) =>
  useIsPlus() ? (
    <BoardModal open={props.open} onClose={props.onClose}>
      <ModalPanel size="lg">
        <ModalTitle>
          <Box direction="row" justify="center" align="center" gap={2}>
            <Icons.Package /> Convert to Template
          </Box>
        </ModalTitle>
        <ModalDescription>
          Add a name and description for this template so you'll have an easier
          time finding it later. After you convert it, it will be listed on the
          dashboard as a Template.
        </ModalDescription>
        <ConvertToTemplateModalBody {...props} />
      </ModalPanel>
    </BoardModal>
  ) : (
    <BoardModal open={props.open} onClose={props.onClose}>
      <ModalPanel size="lg">
        <TemplatesUpsell />
      </ModalPanel>
    </BoardModal>
  );

let ConvertToTemplateModalBody = (props: ConvertToTemplateModalProps) => {
  let [error, setError] = useState<string | undefined>();
  let initialValues: Values = {
    name: "",
    description: "",
  };

  async function createTemplate(values: Values): Promise<string> {
    let { name, description } = values;
    let response = await BoardApi.updateBoard(props.boardId, {
      name,
      description,
      template: true,
    });

    if (response.status === "success") {
      return response.data.id;
    } else if (response.status === "failed") {
      throw new Error(response.msg);
    } else {
      throw new Error("Something went wrong");
    }
  }

  async function onSubmit(values: Values, helpers: FormikHelpers<Values>) {
    let templateId: string | undefined;

    try {
      setError(undefined);
      templateId = await createTemplate(values);
    } catch (err: any) {
      setError(err.message);
    } finally {
      helpers.setSubmitting(false);
    }

    if (templateId) {
      props.onCreate(templateId);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit }) => (
        <Box as="form" onSubmit={handleSubmit} gap={6}>
          <ConvertToTemplateNameField />
          <ConvertToTemplateDescriptionField />
          <ConvertToTemplateButton />
          {error}
        </Box>
      )}
    </Formik>
  );
};

let ConvertToTemplateButton = () => {
  let { isSubmitting } = useFormikContext();

  return (
    <Button type="submit" variant="primary" loading={isSubmitting}>
      Convert to Template
    </Button>
  );
};

let ConvertToTemplateNameField = () => {
  let [field] = useField("name");

  return (
    <FormField>
      <FormFieldHeader>
        <FormFieldLabel>Template name</FormFieldLabel>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldHeader>
      <TextInput
        {...field}
        autoFocus
        placeholder="Pick a descriptive template name"
      />
    </FormField>
  );
};

let ConvertToTemplateDescriptionField = () => {
  let [field] = useField("description");

  return (
    <FormField>
      <FormFieldHeader>
        <FormFieldLabel>Template description</FormFieldLabel>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldHeader>
      <TextArea
        variant="grey"
        placeholder="Optional template description"
        {...field}
      />
    </FormField>
  );
};

function validate(values: Values) {
  let errors: FormikErrors<Values> = {};

  if (values.name === "") errors.name = "Template name is required";

  if (values.name.length > 200) errors.name = "Template name is too long";

  if (values.description.length > 2000)
    errors.description = "Description is too long";

  return errors;
}
