import "./Vertex.css";

import React from "react";
import { useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Vertices from "~/store/bundles/Vertex";

import { Box } from "./shared/Box";
import { GrabTarget } from "./shared/GrabTarget";
import { Handle } from "./shared/Handle";

export const Vertex = React.memo(({ vertex }: { vertex: Vertices.Vertex }) => {
  const isSelected = useSelector(Client.getIsSelected(vertex.parent));
  const { position, locked } = vertex;

  // Render vertices only if the line they belong to is selected and not locked
  if (!isSelected || locked) return null;

  let className = `vertex__wrapper
  group cursor-move
  `;

  return (
    <Box {...{ position, width: 20, height: 20, className }}>
      <GrabTarget id={vertex.id}>
        <Handle className="-translate-x-1/2 -translate-y-1/2" cursor="move" />
      </GrabTarget>
    </Box>
  );
});

Vertex.displayName = "Vertex";
