import { useElements, useStripe } from "@stripe/react-stripe-js";
import { ReactNode, useState } from "react";

import * as Billing from "~/util/BillingClient";
import { useAccount, useAuth } from "~/context/AuthContext";
import { withStripeElements } from "~/context/StripeElementsContext";
import { useQuery } from "~/hooks/useQuery";

import { confirm } from "./AlertDialog";
import {
  BillingCardInput,
  BillingCardPreview,
  BillingErrorMessage,
  BillingPlan,
  BillingStatusPastDue,
  BillingStatusUnpaid,
  createTokenFromElement,
  StickyStudioPlusAlready,
  StickyStudioPlusFeatures,
  StickyStudioPlusHeading,
  StickyStudioPlusPricing,
} from "./Billing";
import { Box, VStack } from "./Box";
import { Button } from "./Button";
import { FreeForever } from "./Coupons";
import { Message } from "./Message";
import { Panel, PanelStack } from "./Panel";
import { TextWithLinks } from "./TextWithLinks";
import { Heading, Text } from "./Typography";

export let AccountBilling = withStripeElements(() => {
  let auth = useAuth();
  let stripe = useStripe();
  let elements = useElements();
  let account = useAccount()!;

  let [busy, setBusy] = useState(false);
  let [error, setError] = useState<string | undefined>();
  let [isEditingCard, setIsEditingCard] = useState(false);
  let { data: card } = useQuery(() => Billing.getCard());

  let plan = account.plan as BillingPlan;
  let hasUpdatedCard = isEditingCard || card == null;
  let isPlus = account.plan === "plus";

  // Prevent users with a lifetime 100% off from being able to input billing
  // details or being able to downgrade their account to the free plan.
  let canDowngrade = account.coupon !== "forever";
  let canAddCard = account.coupon !== "forever";

  async function update(newPlan: BillingPlan = plan) {
    try {
      setBusy(true);
      await tryUpdate(newPlan);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setBusy(false);
    }
  }

  async function tryUpdate(newPlan: BillingPlan = plan) {
    if (hasUpdatedCard) {
      let token = await createTokenFromElement(stripe, elements);
      await Billing.updateCard(token.id);
    }

    if (plan !== newPlan) {
      await Billing.updatePlan(newPlan);
    }

    // Update the auth context for the rest of the app.
    await auth.authenticate();
  }

  async function upgrade() {
    await update("plus");
  }

  let couponPreview: ReactNode = null;

  if (account.coupon === "forever") {
    couponPreview = <FreeForever />;
  }

  async function downgrade() {
    let cancel = await confirm({
      title: "Are you sure you want to cancel your subscription?",
      cancelButtonText: "It was an accident 🤷",
      okButtonVariant: "danger",
      okButtonText: "Cancel subscription",
      description: (
        <Box gap={3}>
          <TextWithLinks>
            We hate to be annoying, but we need to make sure you didn't click
            this button by accident.
          </TextWithLinks>
        </Box>
      ),
    });

    if (cancel) {
      await update("free");
    }
  }

  return (
    <VStack gap={8}>
      <PanelStack group>
        {account?.billingStatus === "unpaid" && (
          <Panel>
            <BillingStatusUnpaid />
          </Panel>
        )}
        {account?.billingStatus === "past_due" && (
          <Panel>
            <BillingStatusPastDue />
          </Panel>
        )}
        <Panel>
          <Box align="center" gap={4}>
            <Heading level={3}>
              <StickyStudioPlusHeading />
            </Heading>
            {isPlus && <StickyStudioPlusAlready />}
          </Box>
        </Panel>

        <Panel>
          <StickyStudioPlusFeatures />
        </Panel>

        {canAddCard && (
          <Panel>
            <Box gap={4}>
              <Box direction="row" justify="between">
                <StickyStudioPlusHeading />
                <StickyStudioPlusPricing />
              </Box>
              {card && !isEditingCard && (
                <button onClick={() => setIsEditingCard(true)}>
                  <BillingCardPreview
                    brand={card.brand}
                    expiryMonth={card.exp_month}
                    expiryYear={card.exp_year}
                    last4Digits={card.last4}
                  />
                </button>
              )}

              {(card == null || isEditingCard) && (
                <BillingCardInput onChange={() => setError(undefined)} />
              )}

              {isPlus ? (
                <Button
                  variant="primary"
                  loading={busy}
                  disabled={!hasUpdatedCard}
                  onClick={() => update()}
                >
                  Update Card
                </Button>
              ) : (
                <Button variant="primary" loading={busy} onClick={upgrade}>
                  Upgrade 🚀
                </Button>
              )}

              {error && <BillingErrorMessage>{error}</BillingErrorMessage>}
            </Box>
          </Panel>
        )}

        {account.plan === "plus" && canDowngrade && (
          <Panel>
            <Box gap={4}>
              <Message variant="danger">Danger Zone</Message>
              <Text>
                If it's time to go, then it's time to go! We won't delete any of
                your data, but you will lose access to some features.
              </Text>
              <Text>
                You won't be able to change the permissions or invites in your
                existing boards.
              </Text>
              <Text>Everyone will lose access to comments in your boards.</Text>
              <Text>
                If you have more than 5 boards, you won't be able to make any
                more.
              </Text>
              <Button variant="danger" loading={busy} onClick={downgrade}>
                Cancel Subscription
              </Button>
            </Box>
          </Panel>
        )}

        {couponPreview && (
          <Panel>
            <Box align="center">{couponPreview}</Box>
          </Panel>
        )}
      </PanelStack>
    </VStack>
  );
});
