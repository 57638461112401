import { AppState } from "~/store";
import { BaseAction } from "~/store/shared";
import { BaseObject, Grab, Create, Edit, Resize } from "~/store/traits";

/**
 * Union type of all possible global actions.
 */
export type GlobalAction = LoadStateAction | ReleaseAllByAction;

export interface ReleaseAllByAction extends BaseAction {
  type: "release_all_by";
}

export const releaseAllBy =
  (userId: string) =>
  (state: AppState): ReleaseAllByAction => {
    return {
      type: "release_all_by",
      effects: {
        dispatch: [
          Create.cancelCreatingBy(userId)(state),
          ...Grab.releaseAllBy(userId)(state),
          ...Resize.releaseAllBy(userId)(state),
          ...Edit.releaseAllBy(userId)(state),
        ],
      },
    };
  };

export interface LoadStateAction extends BaseAction {
  type: "load_state";
  data: { board: AppState["board"]; objects: BaseObject.BaseObject[] };
}

/**
 * Action creator for loading a given board state into the redux store.
 *
 * NOTE: This wipes all existing objects from the current board state.
 * @param {AppState["board"]} board - The board data.
 * @param {BaseObject[]} objects - Array of objects to load.
 * @returns {LoadStateAction} The action to dispatch to the store.
 */
export const loadState = (
  board: AppState["board"],
  objects: BaseObject.BaseObject[]
): LoadStateAction => {
  return {
    type: "load_state" as const,
    data: { board, objects },
  };
};
