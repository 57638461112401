import { compose, createStore, applyMiddleware, Reducer } from "redux";
import { Socket } from "socket.io-client";

import * as Board from "~/store/bundles/Board";
import * as Client from "~/store/bundles/Client";
import * as Comments from "~/store/bundles/Comments";
import * as Connection from "~/store/bundles/Connection";
import * as Global from "~/store/bundles/Global";
import * as Image from "~/store/bundles/Image";
import * as Label from "~/store/bundles/Label";
import * as Line from "~/store/bundles/Line";
import * as Shape from "~/store/bundles/Shape";
import * as Sticker from "~/store/bundles/Sticker";
import * as Sticky from "~/store/bundles/Sticky";
import * as Upload from "~/store/bundles/Upload";
import * as User from "~/store/bundles/User";
import * as Vertex from "~/store/bundles/Vertex";
import * as Alive from "~/store/traits/Alive";
import * as Color from "~/store/traits/Color";
import * as Create from "~/store/traits/Create";
import * as Delete from "~/store/traits/Delete";
import * as Edit from "~/store/traits/Edit";
import * as Grab from "~/store/traits/Grab";
import * as Lock from "~/store/traits/Lock";
import * as Position from "~/store/traits/Position";
import * as Resize from "~/store/traits/Resize";
import { Config } from "~/context/ConfigContext";
import instantiateMiddleware from "~/store/middleware";
import uid from "~/util/uid";

import * as BaseObject from "./traits/BaseObject";

export type AppState = {
  client: Client.Client;
  board: Board.Board;
  objects: Record<string, BaseObject.BaseObject>;
  comments: Record<string, Comments.Comment>;
};

export type BoardState = Omit<AppState, "client">;

export type StoreConfig = {
  boardId: string;
  userId: string;
};

export type BoardObject =
  | Sticky.Sticky
  | Connection.Connection
  | Label.Label
  | Sticker.Sticker
  | Shape.Shape
  | Line.Line
  | User.User
  | Upload.Upload
  | Vertex.Vertex;

export type State = AppState["objects"];

type StoreAction = { type: "teardown" };

export type Action =
  | Sticky.StickyAction
  | Connection.ConnectionAction
  | Image.ImageAction
  | Label.LabelAction
  | Shape.ShapeAction
  | Upload.UploadAction
  | User.UserAction
  | Sticker.StickerAction
  | Line.LineAction
  | Vertex.VertexAction
  | Comments.CommentAction
  | Client.ClientAction
  | Board.BoardAction
  | Global.GlobalAction
  | Lock.Action
  | Delete.Action
  | Create.Action
  | Grab.Action
  | Color.Action
  | Edit.Action
  | Resize.Action
  | Position.Action
  | Alive.Action
  | BaseObject.Action
  | StoreAction;

const reducer: Reducer<AppState, Action> = (state, action) => {
  if (state && action.type === "load_state") {
    return {
      ...state,
      board: action.data.board,
      objects: createObjectState(action.data.objects),
    };
  }

  return {
    client: Client.reducer(state?.client, action),
    board: Board.reducer(state?.board, action),
    comments: Comments.reducer(state?.comments, action),
    objects: sequenceReducers([
      Sticky.reducer,
      Connection.reducer,
      Image.reducer,
      Label.reducer,
      Shape.reducer,
      Upload.reducer,
      User.reducer,
      Sticker.reducer,
      Line.reducer,
      Vertex.reducer,
      Lock.reducer,
      Delete.reducer,
      Create.reducer,
      Grab.reducer,
      Color.reducer,
      Edit.reducer,
      Resize.reducer,
      Position.reducer,
      Alive.reducer,
      BaseObject.reducer,
    ])(state?.objects, action),
  };
};

/**
 * Return an array of all the objects contained in the app state
 */
export const getObjects = (state: AppState): BaseObject.BaseObject[] =>
  Object.values(state.objects);

/**
 * Return an array of all the comments contained in the app state
 */
export const getComments = (state: AppState): Comments.Comment[] =>
  Object.values(state.comments);

type BoardStateParams = {
  boardId: string;
  name: string;
  description: string;
  createdBy?: string;
  template?: boolean;
  font?: Board.Font;
  plan: Board.Plan;
};

type AppStateParams = {
  userId: string;
  board: BoardStateParams;
  objects: BaseObject.BaseObject[];
  comments: Comments.Comment[];
  selection?: string[];
};

/**
 * Create an AppState from board metadata and a list of objects.
 */
export const createAppState = ({
  userId,
  board,
  objects,
  comments,
  selection,
}: AppStateParams): AppState => {
  return {
    board: Board.createState(board),
    client: Client.createState(userId, selection),
    objects: createObjectState(objects),
    comments: createCommentState(comments),
  };
};

/**
 * Create a substate from a collection of objects
 */
export const createObjectState = (
  objects: BaseObject.BaseObject[]
): Record<string, BaseObject.BaseObject> => {
  return objects.reduce(
    (state, object) => ({ ...state, [object.id]: object }),
    {}
  );
};

/**
 * Create a substate from a collection of comments
 */
export const createCommentState = (
  comments: Comments.Comment[]
): Record<string, Comments.Comment> => {
  return comments.reduce(
    (state, comment) => ({ ...state, [comment.id]: comment }),
    {}
  );
};

/**
 * Fork a collection of objects by delegating to each object type's
 * implementation of `fork`.
 */
export const fork = (objects: BaseObject.BaseObject[]) => {
  const lookup = remapIds(objects);

  return objects.map((object) => {
    if (Sticky.isSticky(object)) return Sticky.fork(object, lookup);
    if (Label.isLabel(object)) return Label.fork(object, lookup);
    if (Connection.isConnection(object)) return Connection.fork(object, lookup);
    if (Image.isImage(object)) return Image.fork(object, lookup);
    if (Shape.isShape(object)) return Shape.fork(object, lookup);
    if (Upload.isUpload(object)) return Upload.fork(object, lookup);
    if (User.isUser(object)) return User.fork(object, lookup);
    if (Sticker.isSticker(object)) return Sticker.fork(object, lookup);
    if (Line.isLine(object)) return Line.fork(object, lookup);
    if (Vertex.isVertex(object)) return Vertex.fork(object, lookup);
    return object;
  });
};

/**
 * Turn an AppState into a template by dropping any data we don't want to keep
 * (Stickers, comments, User, etc...)
 */
export const template = (state: AppState): AppState => {
  const objects = fork(getObjects(state));

  const objectTemplates = objects.map((object) => {
    if (Sticky.isSticky(object)) return Sticky.template(object);
    if (Label.isLabel(object)) return Label.template(object);
    if (Connection.isConnection(object)) return Connection.template(object);
    if (Image.isImage(object)) return Image.template(object);
    if (Shape.isShape(object)) return Shape.template(object);
    if (Upload.isUpload(object)) return Upload.template(object);
    if (User.isUser(object)) return User.template(object);
    if (Sticker.isSticker(object)) return Sticker.template(object);
    if (Line.isLine(object)) return Line.template(object);
    if (Vertex.isVertex(object)) return Vertex.template(object);
    return object;
  });

  return {
    client: Client.template(state.client),
    board: state.board,
    objects: createObjectState(objectTemplates),
    comments: {},
  };
};

/**
 * Take a Config and an initial state and create a Redux store
 */
export const createBoardStore = (
  initialState: AppState,
  config: Config,
  websocket?: Socket
) => {
  const middleware = instantiateMiddleware(config, websocket);
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  let store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  return store;
};

/**
 * Generate new ids for all objects, and create a lookup table mapping the old
 * to the new ids.
 */
const remapIds = (objects: BaseObject.BaseObject[]) => {
  const newIds: Record<string, string> = objects.reduce(
    (dict, obj) => ({ ...dict, [obj.id]: uid() }),
    {}
  );

  return (id: string) => newIds[id] ?? id;
};

/**
 * Sequence a list of reducers
 *
 * Takes an array of reducers and returns a reducer that applies the reducers
 * sequentially.
 */
export const sequenceReducers = <S, A extends Action>(
  reducers: Reducer<S, A>[]
): Reducer<S, A> => {
  return reducers.reduce(
    (combined, reducer) => (state, action) =>
      reducer(combined(state, action), action)
  );
};

export default createBoardStore;
