import "./Canvas.css";

import { useWheel } from "@use-gesture/react";
import { useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useDoubleTap } from "use-double-tap";

import * as Client from "~/store/bundles/Client";
import * as Connection from "~/store/bundles/Connection";
import * as Sticky from "~/store/bundles/Sticky";
import { Create, Grab, Resize } from "~/store/traits";
import { useBoardPath, useWindowEventListener, useZoomFit } from "~/util/hooks";
import uid from "~/util/uid";
import { uploadImageFromFile } from "~/util/upload";

import { Scene } from "./Scene";
import { SelectionArea } from "./SelectionArea";

const INITIAL_STICKY_WIDTH = 200;
const INITIAL_STICKY_HEIGHT = 200;
const DEFAULT_STICKY_COLOR = "sticky3";

const Canvas = () => {
  let dispatch = useDispatch();
  let location = useLocation();
  let boardPath = useBoardPath();
  let store = useStore();
  let selectionArea = useSelector(Client.getNormalizedSelectionArea);
  let transform = useSelector(Client.getTransform);
  let isTour = location.pathname === `${boardPath}/tour`;
  let navigate = useNavigate();

  const canvasRef = useRef<HTMLDivElement>(null);
  const userId = useSelector(Client.getUserId);

  const className =
    "canvas" + (transform === "translate" ? " canvas--panning" : "");

  /*
   * Get the live canvas dimensions
   */
  useEffect(() => {
    const setSize = () => {
      if (canvasRef.current) {
        dispatch(Client.setViewport(canvasRef.current.getBoundingClientRect()));
      }
    };

    setSize();

    window.addEventListener("resize", setSize);
    return () => {
      window.removeEventListener("resize", setSize);
    };
  }, [dispatch]);

  useZoomFit();

  useWheel(
    ({ event }) => {
      // Prevent over-zooming / over-panning
      event.preventDefault();

      const state = store.getState();
      const { origin } = Client.getClient(state);
      const { deltaX: dx, deltaY: dy, clientX: x, clientY: y } = event;

      if (event.ctrlKey || event.altKey) {
        // Scale by a fixed amount per event to patch over browser differences
        // in `dy`.
        let scaleBy = 1 - 0.05 * Math.sign(dy);
        dispatch(Client.scale(scaleBy, { x, y }));
      } else {
        dispatch(Client.panTo({ x: origin.x - dx, y: origin.y - dy }));
      }
    },
    {
      target: canvasRef,
      eventOptions: { passive: false },
    }
  );

  const onPointerDown = (event: React.PointerEvent) => {
    if (event.target !== event.currentTarget) return;
    if (event.button !== 0) return;
    let position = { x: event.clientX, y: event.clientY };

    dispatch(Client.unsetContextMenu());
    dispatch(Client.unselectAll());
    if (!isTour) navigate(boardPath, { replace: true });

    if (event.shiftKey) {
      dispatch(Client.createSelectionArea(position));
    } else {
      dispatch(Client.startPanning(position));
    }
  };

  const onPointerUp = () => {
    if (selectionArea) {
      dispatch(Client.finishSelectionArea());
    }
  };

  const onClickCapture = () => {
    dispatch(Client.unsetContextMenu());
  };

  const onRightClick = (event: React.MouseEvent) => {
    if (event.target !== event.currentTarget) return;
    event.preventDefault();
    let position = { x: event.clientX, y: event.clientY };
    dispatch(Client.unselectAll());
    dispatch(Client.setContextMenu("canvas", position));
  };

  const onDragOver = (event: React.DragEvent) => {
    // Needs to be prevented for onDrop to trigger
    event.preventDefault();
  };

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);

    for (let file of files) {
      uploadImageFromFile({
        file,
        userId,
        dispatch,
        position: { x: event.clientX, y: event.clientY },
      });
    }
  };

  const handleDoubleClick = useDoubleTap((event: React.MouseEvent) => {
    // Disable double-click-to-add behavior on mobile.
    if (isMobile) return;
    if (event.target !== event.currentTarget) return;
    event.preventDefault();

    const id = uid();
    const position = { x: event.clientX, y: event.clientY };

    const state = store.getState();
    const color = Client.getCurrentStickyColor(state);

    dispatch(
      Sticky.add({
        id,
        mousePosition: position,
        width: INITIAL_STICKY_WIDTH,
        height: INITIAL_STICKY_HEIGHT,
        color: color || DEFAULT_STICKY_COLOR,
      })
    );

    dispatch(Create.startCreating(id, userId));
    dispatch(Create.finishCreating(id));
  });

  // Cancel common interactions on Escape
  let resizing = useSelector(Connection.getGrabbingBy(userId));
  let reshaping = useSelector(Connection.getGrabbingBy(userId));
  let grabbed = useSelector(Grab.getGrabbingBy(userId));

  const onEscape = (event: React.KeyboardEvent) => {
    if (event.key !== "Escape") return;
    dispatch(Client.unselectAll());
    dispatch(Create.cancelCreatingBy(userId));
    dispatch(Client.unsetCommentObjectId());

    const grabbedIds = grabbed.map((obj) => obj.id);
    dispatch(Grab.cancelGrabbingMany(grabbedIds));

    for (let connection of reshaping) {
      dispatch(Connection.cancelReshaping(connection.id));
    }

    for (let object of resizing) {
      dispatch(Resize.cancelResizing(object.id));
    }
  };

  useWindowEventListener("keydown", onEscape, false);

  return (
    <>
      <div
        id="canvas"
        ref={canvasRef}
        className={className}
        onPointerDown={onPointerDown}
        onClickCapture={onClickCapture}
        onPointerUp={onPointerUp}
        onContextMenu={onRightClick}
        onDragOver={onDragOver}
        onDrop={onDrop}
        {...handleDoubleClick}
      >
        <Scene />
        <div id="control-overlay"></div>
        {selectionArea && <SelectionArea area={selectionArea} />}
      </div>
    </>
  );
};
export default Canvas;
