import { orderBy } from "lodash";
import { Middleware, Dispatch } from "redux";
import { createSelector } from "reselect";

import * as BaseObject from "~/store/traits/BaseObject";
import * as Circ from "~/util/geometry/circle";
import * as Rect from "~/util/geometry/rectangle";
import { getAttachmentPoints } from "~/renderers/html/circular";
import { Action, AppState } from "~/store";
import { BaseAction, emptyAction, EmptyAction } from "~/store/shared";
import {
  Alive,
  Create,
  Select,
  Lock,
  Edit,
  Delete,
  Size,
  Grab,
} from "~/store/traits";
import assert from "~/util/assert";
import { filterObject } from "~/util/filterObject";
import { Point, Rectangle } from "~/util/geometry";
import { createShallowEqualSelector } from "~/util/selectors";

import * as Client from "./Client";

const LABEL_MIN_WIDTH = 250;
const LABEL_MIN_HEIGHT = 75;

/**
 * Interface that defines a `Connection` object.
 */
export interface Connection
  extends BaseObject.BaseObject,
    Alive.Alive,
    Create.Create,
    Delete.Delete,
    Edit.Edit,
    Lock.Lock,
    Select.Select {
  type: "connection";
  from: string;
  to: string | null;
  controlPoint?: Point | null;
  grabbing: string | null;
  labelWidth: number;
  labelHeight: number;
  curvature?: number;
  initialCurvature?: number | null;
  sense?: 1 | -1;
  initialSense?: 1 | -1 | null;
  direction: "to" | "both" | "reverse" | "undirected";
}

/**
 * Type guard for narrowing a `BaseObject` type to a `Connection`.
 *
 * @param {BaseObject} object - The object whose type we want to narrow.
 * @returns {boolean} Whether or not the object is of type `Connection`.
 */
export const isConnection = (
  object: BaseObject.BaseObject
): object is Connection => {
  return object.type === "connection";
};

/**
 * Swap out any IDs according to a provided lookup table.
 *
 * @param {Connection} connection - The connection object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Connection} The modified connection with ids replaced.
 */
const swapIds = (
  connection: Connection,
  lookup: (id: string) => string
): Connection => {
  return {
    ...connection,
    id: lookup(connection.id),
    creating: connection.creating ? lookup(connection.creating) : null,
    grabbing: connection.grabbing ? lookup(connection.grabbing) : null,
    editing: connection.editing ? lookup(connection.editing) : null,
    from: lookup(connection.from),
    to: connection.to ? lookup(connection.to) : null,
  };
};

/**
 * Remove any secondary information that we wouldn't want to appear, in a
 * template/fork.
 *
 * @param {Connection} connection - Connection to convert into a template
 * @returns {Connection} Template created from the provided `Connection` object.
 */
export const template = (connection: Connection): Connection => ({
  ...connection,
  creating: null,
  grabbing: null,
  editing: null,
  keepAlive: null,
});

/**
 * Fork a connection by swapping out ids using a provided lookup table.
 *
 * @param {Connection} connection - The connection object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Connection} The modified connection with ids replaced.
 */
export const fork = swapIds;

/**
 * Union of all possible `Connection` actions.
 */
export type ConnectionAction =
  | AddAction
  | ReshapeAction
  | StartReshapingAction
  | FinishReshapingAction
  | CancelReshapingAction
  | GrowAction
  | SetToIdAction
  | ClearToIdAction
  | SetDirectionAction;

export interface AddAction extends BaseAction {
  type: "connection/add";
  data: Connection;
}

/**
 * Type that describes the named arguments for adding a connection.
 */
interface AddArgs {
  /**
   * The id of the to-be-added connection.
   */
  id: string;
  /**
   * The id of the sticky the connection originates from
   */
  from: string;
  /**
   * The id of the sticky the connection end in.
   */
  to: string | null;
  /**
   * The id of the user creating the connection.
   */
  userId: string;
}

/**
 * Action creator for adding a connection to the app state.
 *
 * @param {AddArgs} args - The parameters for the connection.
 * @returns {AddAction} The action to be dispatched to the store.
 */
export const add = ({ id, from, to, userId }: AddArgs): AddAction => {
  return {
    type: "connection/add",
    data: {
      id,
      type: "connection",
      from,
      to: to ?? from,
      creating: to ? null : userId, // Mark as creating if no 'to' sticky is supplied
      grabbing: null,
      editing: null,
      content: "",
      draft: "",
      labelWidth: LABEL_MIN_WIDTH,
      labelHeight: LABEL_MIN_HEIGHT,
      curvature: 1,
      sense: 1,
      direction: "to",
      deleted: false,
      keepAlive: null,
      locked: false,
    },
    effects: { broadcast: true, persist: true },
  };
};

export interface ReshapeAction extends BaseAction {
  type: "connection/reshape";
  data: Pick<Connection, "id" | "curvature" | "sense">;
}

/**
 * Action creator that reshapes a connection.
 *
 * Reshaping a connection sets the curvature of the connection such that it
 * passes both through the `from` and `to` sticky centers, as well as the current
 * cursor position.
 *
 * @param {string} id - The id of the connection we wish to reshape.
 * @param {Point} position - The current mouse cursor position (in screen
 * coordinates)
 * @returns The action thunk to be dispatched to the store.
 */
export const reshape =
  (id: string, position: Point) =>
  (state: AppState): ReshapeAction | EmptyAction => {
    let connection = getById(id)(state);
    let scene = Client.getScene(state);
    let scenePosition = Client.toSceneCoordinates(scene, position);

    const fromObject = Size.getById(connection.from)(state);
    const toObject = connection.to
      ? Size.getById(connection.to)(state)
      : undefined;

    if (!fromObject) return emptyAction();

    const fromPosition = Rect.getCenter(fromObject);
    const toPosition = toObject ? Rect.getCenter(toObject) : scenePosition;

    const curvature = Circ.getCurvature(
      fromPosition,
      scenePosition,
      toPosition
    );
    const sense = Circ.getSense(fromPosition, scenePosition, toPosition);

    return {
      type: "connection/reshape",
      data: { id, curvature, sense },
      effects: {
        broadcast: true,
        dispatch: [Alive.touch(id)],
      },
    };
  };

export interface StartReshapingAction extends BaseAction {
  type: "connection/start_reshaping";
  data: Pick<
    Connection,
    "id" | "grabbing" | "initialCurvature" | "initialSense"
  >;
}

/**
 * Action creator that starts reshaping a connection.
 *
 * @see reshape
 * @param {string} id - The id of the connection we wish to start reshaping.
 * @param {string} userId - The id of the user doing the reshaping.
 * @returns  The action thunk to be dispatched to the store.
 */
export const startReshaping =
  (id: string, userId: string) =>
  (state: AppState): StartReshapingAction | EmptyAction => {
    let connection = getById(id)(state);
    if (!connection || connection.locked) return emptyAction();

    return {
      type: "connection/start_reshaping",
      data: {
        id,
        grabbing: userId,
        initialCurvature: connection.curvature,
        initialSense: connection.sense,
      },
    };
  };

export interface FinishReshapingAction extends BaseAction {
  type: "connection/finish_reshaping";
  data: Pick<
    Connection,
    "id" | "grabbing" | "initialCurvature" | "initialSense"
  >;
}

/**
 * Action creator that finishes reshaping a connection.
 *
 * @see reshape
 * @param {string} id - The id of the connection we wish to finish reshaping.
 * @returns The action thunk to be dispatched to the store.
 */
export const finishReshaping =
  (id: string) =>
  (state: AppState): FinishReshapingAction | EmptyAction => {
    let object = getById(id)(state);
    if (!object) return emptyAction();

    return {
      type: "connection/finish_reshaping",
      data: {
        ...object,
        grabbing: null,
        initialCurvature: null,
        initialSense: null,
      },
      effects: {
        persist: true,
        broadcast: true,
      },
    };
  };

export interface CancelReshapingAction extends BaseAction {
  type: "connection/cancel_reshaping";
  data: Pick<
    Connection,
    | "id"
    | "grabbing"
    | "initialCurvature"
    | "curvature"
    | "initialSense"
    | "sense"
  >;
}

export const cancelReshaping =
  (id: string) =>
  (state: AppState): CancelReshapingAction | EmptyAction => {
    let connection = getById(id)(state);
    if (!connection || !connection.initialCurvature || !connection.initialSense)
      return emptyAction();

    return {
      type: "connection/cancel_reshaping",
      data: {
        ...connection,
        grabbing: null,
        initialCurvature: null,
        curvature: connection.initialCurvature,
        initialSense: null,
        sense: connection.initialSense,
      },
      effects: {
        persist: true,
        broadcast: true,
      },
    };
  };

export interface GrowAction extends BaseAction {
  type: "connection/grow";
  data: Pick<Connection, "id" | "labelWidth" | "labelHeight">;
}

export const grow =
  /**
   * Action creator for growing a connection's label dimensions.
   *
   * @param {string} id - The id of the connection whose label we wish to grow.
   * @param {Point} delta - The delta x and y we wish to grow the label by.
   * @returns The action thunk to dispatch to the store.
   */


    (id: string, delta: Point) =>
    (state: AppState): GrowAction => {
      let { labelWidth, labelHeight } = getById(id)(state);

      let newWidth = Math.max(LABEL_MIN_WIDTH, labelWidth + delta.x);
      let newHeight = Math.max(LABEL_MIN_HEIGHT, labelHeight + delta.y);

      return {
        type: "connection/grow",
        data: { id, labelWidth: newWidth, labelHeight: newHeight },
        effects: { broadcast: true, persist: true },
      };
    };

export interface SetToIdAction extends BaseAction {
  type: "connection/set_to_id";
  data: Pick<Connection, "id" | "to">;
}

/**
 * Action creator that sets a connection's `to` field.
 *
 * @param {string} id - The id of the connection we wish to update
 * @param {string} stickyId - The id of the sticky we wish to set the `to` field
 * to.
 * @returns {SetToIdAction} The action to dispatch to the store.
 */
export const setToId = (id: string, stickyId: string): SetToIdAction => {
  return {
    type: "connection/set_to_id",
    data: { id, to: stickyId },
    effects: { broadcast: true, persist: true },
  };
};

export interface ClearToIdAction extends BaseAction {
  type: "connection/clear_to_id";
  data: Pick<Connection, "id" | "to">;
}

/**
 * Action creator that clears a connection's `to` field.
 *
 * @param {string} id - The id of the connection we wish to update
 * @returns {ClearToIdAction} The action to dispatch to the store.
 */
export const clearToId = (id: string): ClearToIdAction => {
  return {
    type: "connection/clear_to_id",
    data: { id, to: null },
    effects: { broadcast: true, persist: true },
  };
};

export interface SetDirectionAction extends BaseAction {
  type: "connection/set_direction";
  data: Pick<Connection, "id" | "direction">;
}

/**
 * Action creator that sets the direction of a connection.
 *
 * @param {string} id - The id of the connection we wish to update.
 * @param {Connection["direction"]} direction - The direction we wish to set.
 * @returns {SetDirectionAction} The action to dispatch to the store.
 */
export const setDirection = (
  id: string,
  direction: Connection["direction"]
): SetDirectionAction => {
  return {
    type: "connection/set_direction",
    data: { id, direction },
    effects: { broadcast: true, persist: true },
  };
};

/**
 * Reducer that processes any `Connection` actions.
 *
 * @param {AppState["objects"]} [state] - The current `objects` state.
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["objects"]} The updated state slice.
 */
export const reducer = (
  state: AppState["objects"] = {},
  action: Action
): AppState["objects"] => {
  switch (action.type) {
    case "connection/add":
      return { ...state, [action.data.id]: action.data };

    case "connection/set_direction":
    case "connection/grow":
    case "connection/set_to_id":
    case "connection/clear_to_id":
    case "connection/reshape":
    case "connection/start_reshaping":
    case "connection/finish_reshaping":
    case "connection/cancel_reshaping": {
      const object = state[action.data.id];
      assert(isConnection(object), "Object is not a Connection.");

      return { ...state, [object.id]: { ...state[object.id], ...action.data } };
    }

    default: {
      return state;
    }
  }
};

/**
 * Redux middleware that is used to intercept general actions to inject
 * `Connection` specific behavior.
 *
 * @param  store - The redux store object
 * @param  next - The next redux middleware in the middleware chain.
 * @param  action - The dispatched action we're intercepting
 * @returns The return value of the `next` middleware.
 */
export const middleware: Middleware<{}, AppState, Dispatch<any>> =
  ({ dispatch, getState }) =>
  (next) =>
  (action: Action) => {
    let additionalActions: Action[] = [];
    const state = getState();

    // Dispatch any additional behaviors
    switch (action.type) {
      /*
       * When a connection is finished, delete the connection if
       * it connects a sticky to itself or duplicates an existing
       * connection.
       */
      case "create/finish_creating": {
        const connection = getById(action.data.id)(state);

        if (connection && action.metadata?.to) {
          let existingConnections = getBetween(
            connection.from,
            action.metadata?.to
          )(state).filter((conn) => conn.id !== action.data.id);

          if (
            connection.from === action.metadata?.to ||
            existingConnections.length !== 0 ||
            connection.to === null
          ) {
            additionalActions.push(Delete.deleteObject(action.data.id)(state));
          }
        }

        break;
      }

      /*
       * Release connection whose `keepAlive` timer has expired.
       *
       * Unset the creating, grabbing, and editing properties.
       */
      case "alive/release_expired": {
        for (const id in action.data) {
          const connection = getById(id)(state);
          if (!connection || connection.deleted) continue;

          if (connection.grabbing)
            additionalActions.push(Grab.finishGrabbing(id)(state));

          if (connection.editing)
            additionalActions.push(Edit.finishEditing(id)(state));

          if (connection.creating)
            additionalActions.push(Delete.deleteObject(id)(state));
        }

        break;
      }
    }

    // Process the actual action first
    const result = next(action);

    // Dispatch additional actions
    additionalActions.forEach(dispatch);

    // Return result of original action
    return result;
  };

/**
 * Selector that returns a record of all connecitons in the board state, keyed
 * by their id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, Connection>} A record of all connections in the app
 * state.
 */
export const getConnections = createShallowEqualSelector(
  BaseObject.getAll,
  (objects) => filterObject(objects, isConnection)
);

/**
 * Selector creator for getting the connection with a given id.
 *
 * @param {string} id - The id of the connection we want to get
 * @returns Selector that returns the connection.
 */
export const getById = (id: string) =>
  createSelector(getConnections, (connections) => connections[id]);

/**
 * Selector that returns an array of all connections, removing the deleted ones.
 *
 * @param {AppState} state - The current app state.
 * @returns An array of (non-deleted) connections.
 */
export const getAll = createSelector(getConnections, (connections) =>
  Object.values(connections).filter((c) => !c.deleted)
);

/**
 * Selector that returns an array of all undeleted connections ordered by date last updated.
 * Locked connections are placed below unlocked ones
 *
 * @param {AppState} state - The current app state.
 * @returns An ordered array of (non-deleted) connections.
 */
// Even though we already use z-index on the board to place locked objects below
// we stille need to do it in the ordering because the SVG renderer can't use z-index
// and selected object all receive the same z-index so this is required to preserve the
// ordering within a selection.
export const getAllOrdered = createSelector(getAll, (connections) =>
  orderBy(connections, ["locked", "updatedAt"], ["desc", "asc"])
);

/**
 * Selector creator for getting all connections currently being created by the
 * provided user id.
 *
 * @param {string} userId - The user id creating the connections.
 * @returns Selector that returns the desired connections.
 */
export const getCreatingBy = (userId: string) =>
  createSelector(getAll, (connections) =>
    connections.filter(({ creating }) => creating === userId)
  );

/**
 * Selector creator for getting all connections currently being reshaped by the
 * provided user id.
 *
 * @param {string} userId - The user id reshaping the connections.
 * @returns Selector that returns the desired connections.
 */
export const getGrabbingBy = (userId: string) =>
  createSelector(getAll, (connections) =>
    connections.filter(({ grabbing }) => grabbing === userId)
  );

/**
 * Selector creator that returns all connections going to the sticky with the
 * provided id.
 *
 * @param {string} id - The sticky id we want to get all incoming connections
 * for.
 * @returns Selector that returns the incoming connections.
 */
export const getConnectionsTo = (id: string) =>
  createSelector(getAll, (connections) =>
    Object.values(connections).filter(
      ({ to, creating }) => to === id && !creating
    )
  );

/**
 * Selector creator that returns all connections coming from the sticky with the
 * provided id.
 *
 * @param {string} id - The sticky id we want to get all incoming connections
 * for.
 * @returns Selector that returns the incoming connections.
 */
export const getConnectionsFrom = (id: string) =>
  createSelector(getAll, (connections) =>
    Object.values(connections).filter(
      ({ from, creating }) => from === id && !creating
    )
  );

/**
 * Selector creator that returns all connections either coming from or going to
 * the sticky with the provided id.
 *
 * @param {string} id - The sticky id we want to get all incoming/outgoing
 * connections for.
 * @returns Selector that returns the connections.
 */
export const getRelatedConnections = (id: string) =>
  createSelector(getConnectionsTo(id), getConnectionsFrom(id), (to, from) => [
    ...to,
    ...from,
  ]);

/**
 * Get connections inside a selection area.
 *
 * Because connections have no defined shape (it's determined dynamically
 * by looking at the bounding stickies), we count a connection as lying in a
 * selection area when both `to`and `from` stickies lie within it.
 *
 * @param {Area} area - The area for which we wish to find the contained
 * connections.
 * @returns Selector returning the contained connections.
 */
export const getInsideArea =
  (area: {
    position: { x: number; y: number };
    width: number;
    height: number;
  }) =>
  (state: AppState): Connection[] => {
    const objectsInsideArea = Size.getInsideArea(area)(state).map(
      ({ id }) => id
    );

    return getAll(state).filter(({ to, from }) => {
      return (
        to && objectsInsideArea.includes(to) && objectsInsideArea.includes(from)
      );
    });
  };

/**
 */
/**
 * Selector that returns all connections between the provided `to` and `from`
 * stickies.
 *
 * @param {string} from - The id of the `from` sticky.
 * @param {string} to - The id of the `to` sticky.
 * @returns Selector that returns all connections between `to` and `from`.
 */
export const getBetween = (from: string, to: string) =>
  createSelector(getAll, (connections) =>
    connections
      .filter((conn) => conn.from === from)
      .filter((conn) => conn.to === to)
      .filter((conn) => !conn.deleted)
  );

/**
 * Selector creator that returns whether or not the sticky is a
 * valid target of connections being created by the user.
 *
 * @param {string} id - The sticky id we want to check.
 * @param {string} userId - The id of the user creating the connection.
 * @returns Selector that returns a boolean indicating whether or not the
 * provided sticky is a valid target of connections being created by the
 * given user.
 */
export const getIsCreationTarget = (id: string, userId: string) =>
  createSelector(
    getCreatingBy(userId),
    (connections) =>
      connections.length > 0 && !connections.find((conn) => conn.from === id)
  );

export const getBoundingRect =
  (connection: Connection) =>
  (state: AppState): Rectangle | undefined => {
    if (!connection.to || !connection.curvature || !connection.sense) return;

    const from = Size.getById(connection.from)(state);
    const to = Size.getById(connection.to)(state);
    if (!from || !to) return;

    const cFrom = Rect.getCenter(from);
    const cTo = Rect.getCenter(to);
    const circle = Circ.getFromCurvature(cFrom, cTo, connection.curvature);

    let { pi, pf } = getAttachmentPoints(from, to, circle, connection.sense);

    return Circ.getSegmentBoundingRect(pi, pf, circle, connection.sense);
  };
