import "./OpenComments.css";

import { CommentsButton } from "~/components/CommentsButton";
import { FixedScale } from "~/components/FixedScale";
import { classNames } from "~/util/classNames";

type Props = {
  id: string;
  commentCount: number;
  origin?: string;
};

export const OpenComments = ({ origin = "left bottom", ...props }: Props) => (
  <FixedScale min={0.5} max={3} origin={origin}>
    <CommentsButton
      {...props}
      className={classNames("open-comments")}
      rounded={true}
    />
  </FixedScale>
);
