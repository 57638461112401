import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { useBoardPath } from "~/util/hooks";

import { BoardModal } from "./BoardModal";
import { Box } from "./Box";
import { FeedbackBox } from "./FeedbackBox";
import { Send as FeedbackIcon } from "./Icons";
import { ModalDescription, ModalPanel, ModalProps, ModalTitle } from "./Modal";
import { TextWithLinks } from "./TextWithLinks";

let HelpLink = ({ children }: { children: ReactNode }) => (
  <Link to={`${useBoardPath()}/help`}>{children}</Link>
);

export let FeedbackModal = (props: ModalProps) => (
  <BoardModal {...props}>
    <ModalPanel className="feedback-modal" size="lg">
      <ModalTitle>
        <FeedbackIcon color="var(--grey-4)" /> Feedback
      </ModalTitle>
      <ModalDescription>
        <Box gap={4}>
          <TextWithLinks>
            Let us know how we could improve Sticky Studio!
          </TextWithLinks>
          <TextWithLinks>
            Want to speak to a person? <HelpLink>Contact support</HelpLink>{" "}
            instead.
          </TextWithLinks>
          <FeedbackBox />
        </Box>
      </ModalDescription>
    </ModalPanel>
  </BoardModal>
);
