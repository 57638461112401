import { createSelector } from "reselect";

import * as BoardObject from "~/store/bundles/BoardObject";
import * as Rect from "~/util/geometry/rectangle";
import * as Vec from "~/util/geometry/vector";
import { Action, AppState } from "~/store";
import { BaseAction, emptyAction, EmptyAction } from "~/store/shared";
import { BaseObject, Create, Size } from "~/store/traits";
import { Point, Rectangle, Vector, dist } from "~/util/geometry";
import { createShallowEqualSelector } from "~/util/selectors";

import * as Connection from "./Connection";
import * as Label from "./Label";
import * as Line from "./Line";
import { updateMany } from "../traits/BaseObject";
import { isImage } from "./Image";

const MIN_SCALE = 0.02;

/**
 * Collection of metadata associated with a user dragging the pointer
 */
type Drag = {
  /**
   * Position (in scene coordinates) where the drag was initiated
   */
  startPosition: Point;

  /**
   * Position (in scene coordinates) where the drag is currently at
   */

  currentPosition: Point;
  /**
   * Total delta (in scene coordinates) from the start of the drag to the current
   * cursor position.
   */
  delta: Vector;

  /**
   * Total distance (in scene coordinates) of the current drag
   */
  distance: number;

  /**
   * Total distance (in screen coordinates, i.e., in pixels) of the current drag
   */
  screenDistance: number;

  /**
   * Timestamp (unix time) the current drag was initiated
   */
  startTime: number;
};

/**
 * Collection of metadata associated with a MouseDown event
 */
type MouseDown = {
  /**
   * Position (in screen coordinates) where the mousedown event took place.
   */
  screenPosition: Point;

  /**
   * Position (in scene coordinates) where the mousedown event took place.
   */
  scenePosition: Point;

  /**
   * Time (unix epoch) at which the mousedown event took place
   */
  time: number;
};

/**
 * Interface that defines the `Client` state in the redux store.
 */
export interface Client {
  /**
   * The client's associated userId on the board.
   */
  userId: string;

  /**
   * The id of the board the client is currently viewing
   */
  boardId: string;

  /**
   *   Whether or not the client is currently interacting with an object
   *
   * @deprecated
   */
  isBusy: boolean;

  /**
   * Array of selected object ids.
   */
  selected: string[];

  /**
   * The current origin position that corresponds to the top-left corner of the
   * viewport.
   */
  origin: Point;

  /**
   * The current scale factor that sets the board zoom.
   */
  scale: number;

  /**
   * The dimensions (in screen pixels) of the viewport.
   */
  viewport?: { width: number; height: number };

  /**
   * Flag that describes whether we are in the middle of panning/scaling the
   * canvas.
   */
  transform: string | null;

  /**
   * Origin relative to which we want to measure panning actions.
   *
   * Mouse moves relative to this point will determine deltas by which to pan the
   * canvas.
   */
  transformOrigin: Point | null;

  /**
   * Object that stores the id of the object on which the context menu is opened,
   * as well as the cursor position at which it is opened.
   */
  contextMenu: { id: string; position: Point } | null;

  /**
   * The current selectionArea in *screen* coordinates.
   *
   * In case we want the selectionArea in *scene* coordinates,
   * @see getSceneSelectionArea
   */
  selectionArea: Rectangle | null;

  /**
   * The client's cursor state, both in screen and scene coordinates, as well as
   * the pointer state.
   */
  cursor: {
    screenPosition: Point;
    scenePosition: Point;
    isMouseDown: boolean;
    isDragging: boolean;
    mouseDown: MouseDown | null;
    drag: Drag | null;
  };

  /**
   * Flag that represents whether or not the client is currently online (i.e.,
   * has a working websocket connection).
   */
  online?: boolean;

  /**
   * The current (last created) sticky color.
   */
  currentStickyColor?: string;

  /**
   * The most recently-set label style.
   */
  currentLabelStyle?: Label.Style;

  /**
   * The id of the board object to show comments for.
   */
  commentObjectId?: string | null;

  /**
   * The id of the user object to showcase.
   */
  showcaseUserId?: string | null;

  /**
   * The ids of the board objects to showcase.
   */
  showcaseObjectIds?: string[] | null;

  /**
   * Stack of object snapshots the user can use to undo/redo any destructive actions
   */
  revisedObjects: {
    undo: BaseObject.BaseObject[][];
    redo: BaseObject.BaseObject[][];
  };
}

/**
 * Create a fresh client state.
 *
 * @param {string} userId - The userId associated with the client.
 * @returns {Client} A new client state.
 */
export const createState = (
  userId: string,
  selected: string[] = []
): Client => ({
  userId,
  boardId: "",
  isBusy: false,
  selected,
  origin: { x: 0, y: 0 },
  scale: 0.5,
  transform: null,
  transformOrigin: null,
  contextMenu: null,
  selectionArea: null,
  cursor: {
    screenPosition: { x: 0, y: 0 },
    scenePosition: { x: 0, y: 0 },
    isMouseDown: false,
    isDragging: false,
    mouseDown: null,
    drag: null,
  },
  online: true,
  showcaseUserId: null,
  showcaseObjectIds: null,
  revisedObjects: { undo: [], redo: [] },
});

/**
 * Swap out any IDs according to a provided lookup table.
 *
 * @param {Client} client - The client state to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Client} The modified client with ids replaced.
 */
const swapIds = (client: Client, lookup: (id: string) => string): Client => {
  return {
    ...client,
    userId: lookup(client.userId),
    selected: client.selected.map(lookup),
    contextMenu: client.contextMenu
      ? {
          ...client.contextMenu,
          id: lookup(client.contextMenu.id),
        }
      : null,
  };
};

/**
 * Remove any secondary information that we wouldn't want to appear, in a
 * template/fork.
 *
 * @param {Client} client - `Client` state to convert into a template
 * @returns {Client} Template created from the provided `Client` state.
 */
export const template = (client: Client): Client => {
  return {
    ...client,
    isBusy: false,
    selected: [],
    origin: { x: 0, y: 0 },
    scale: 0.5,
    transform: null,
    transformOrigin: null,
    contextMenu: null,
    selectionArea: null,
    commentObjectId: null,
    showcaseUserId: null,
    showcaseObjectIds: null,
  };
};

/**
 * Fork a client state by swapping out ids using a provided lookup table.
 *
 * @param {Client} client - The client state to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Client} The modified client state ids replaced.
 */
export const fork = (state: Client, lookup: (id: string) => string): Client => {
  return swapIds(state, lookup);
};

/**
 * Union of all possible `Client` actions.
 */
export type ClientAction =
  | SelectAction
  | SelectManyAction
  | SelectOnlyAction
  | UnselectAction
  | UnselectManyAction
  | UnselectAllAction
  | StartPanningAction
  | FinishPanningAction
  | PanAction
  | PanToAction
  | ScaleAction
  | CreateSelectionAreaAction
  | FinishSelectionAreaAction
  | UpdateSelectionAreaAction
  | MoveCursorAction
  | MouseDownAction
  | MouseUpAction
  | SetContextMenuAction
  | UnsetContextMenuAction
  | SetConnectionStatusAction
  | SetViewportAction
  | SetSceneParamsAction
  | SetCurrentStickyColorAction
  | SetCurrentLabelStyleAction
  | SetCommentObjectIdAction
  | UnsetCommentObjectIdAction
  | SetShowcaseUserIdAction
  | UnsetShowcaseUserIdAction
  | SetShowcaseObjectIdsAction
  | UnsetShowcaseObjectIdsAction
  | AddUndoSnapshotAction
  | UndoAction
  | RedoAction
  | EmptyAction;

export interface SelectAction extends BaseAction {
  type: "client/select";
  data: Pick<Client, "selected">;
}

export const select =
  /**
   * Action creator that adds an object to the selection.
   *
   * @param {string} id - The id of the object to add to the selection.
   * @returns The action thunk to dispatch to the store.
   */


    (id: string) =>
    (state: AppState): SelectAction => {
      let selected = getSelected(state);

      return {
        type: "client/select",
        data: { selected: [...selected, id] },
      };
    };

export interface SelectManyAction extends BaseAction {
  type: "client/select_many";
  data: Pick<Client, "selected">;
}

/**
 * Action creator that selects several objects at once.
 *
 * @param {string[]} ids - Array of object ids to select.
 * @returns {SelectManyAction} Action to dispatch to the store.
 */
export const selectMany = (ids: string[]): SelectManyAction => {
  return {
    type: "client/select_many",
    data: { selected: ids },
  };
};

export interface SelectOnlyAction extends BaseAction {
  type: "client/select_only";
  data: Pick<Client, "selected">;
}

/**
 * Action creator that clears the selection in favor of a single object.
 *
 * @param {string} id - The id of the object to select.
 * @returns Action to dispatch to the store.
 */
export const selectOnly = (id: string) => {
  return {
    type: "client/select_only",
    data: { selected: [id] },
  };
};

export interface UnselectMetadata {
  unselected?: string | null;
}

export interface UnselectAction extends BaseAction {
  type: "client/unselect";
  data: Pick<Client, "selected">;
  metadata?: UnselectMetadata | null;
}

/**
 * Action creator that, if present, removes an id from the selection.
 *
 * @param {string} id - The id of the object to remove from the selection.
 * @returns Action thunk to be dispatched to the store.
 */
export const unselect =
  (id: string) =>
  (state: AppState): UnselectAction => {
    let selected = getSelected(state);

    return {
      type: "client/unselect",
      data: { selected: selected.filter((selected) => selected !== id) },
      metadata: { unselected: id },
    };
  };

export interface UnselectManyAction extends BaseAction {
  type: "client/unselect_many";
  data: Pick<Client, "selected">;
}

/**
 * Action creator that removes all provided ids from the selection.
 *
 * @param {string[]} ids - Array of object ids to deselect.
 * @returns Action to dispatch to the store.
 */
export const unselectMany =
  (ids: string[]) =>
  (state: AppState): UnselectManyAction => {
    let selected = getSelected(state);

    return {
      type: "client/unselect_many",
      data: {
        selected: selected.filter((selected) => !ids.includes(selected)),
      },
    };
  };

export interface UnselectAllAction extends BaseAction {
  type: "client/unselect_all";
  data: Pick<Client, "selected">;
}

/**
 * Action creator that clears the selection.
 *
 * @returns {UnselectAllAction} Action to be dispatched to the store.
 */
export const unselectAll = (): UnselectAllAction => {
  return { type: "client/unselect_all", data: { selected: [] } };
};

/**
 * Action creator that toggles the selection state of an object by adding or
 * removing the object id to the array of selected objects.
 *
 * @param {string} id - The id of the object we wish to select/deselect.
 * @returns The action thunk to dispatch to the store.
 */
export const toggleSelect =
  (id: string) =>
  (state: AppState): SelectAction | UnselectAction => {
    let selected = getSelected(state);

    return !selected.includes(id)
      ? { type: "client/select", data: { selected: [...selected, id] } }
      : {
          type: "client/unselect",
          data: { selected: selected.filter((selected) => selected !== id) },
        };
  };

export interface StartPanningAction extends BaseAction {
  type: "client/start_panning";
  data: Pick<Client, "transform" | "transformOrigin">;
}

/**
 * Action creator that starts "panning" mode.
 *
 * @param {Point} position - The cursor position (in scene coordinates) relative
 * to which we want to move.
 * @returns {StartPanningAction} The action to be dispatched to the store.
 */
export const startPanning = (position: Point): StartPanningAction => {
  return {
    type: "client/start_panning",
    data: { transform: "translate", transformOrigin: position },
  };
};

export interface FinishPanningAction extends BaseAction {
  type: "client/finish_panning";
  data: Pick<Client, "transform" | "transformOrigin">;
}

/**
 * Action creator that ends "panning" mode.
 *
 * @returns {StartPanningAction} The action to be dispatched to the store.
 */
export const finishPanning = (): FinishPanningAction => {
  return {
    type: "client/finish_panning",
    data: { transform: null, transformOrigin: null },
  };
};

export interface PanAction extends BaseAction {
  type: "client/pan";
  data: Pick<Client, "transform" | "transformOrigin">;
}

/**
 * Action creator that pans to a given position, relative to the set origin.
 *
 * @param {Point} position - The position we want to pan the board to.
 * @returns The action thunk  to dispatch to the store.
 */
export const pan = (position: Point) => (state: AppState) => {
  let { transformOrigin = { x: 0, y: 0 }, origin } = getScene(state);

  let delta = {
    x: position.x - (transformOrigin?.x ?? 0),
    y: position.y - (transformOrigin?.y ?? 0),
  };

  return {
    type: "client/pan",
    data: {
      origin: { x: origin.x + delta.x, y: origin.y + delta.y },
      transformOrigin: { x: position.x, y: position.y },
    },
  };
};

export interface PanToAction extends BaseAction {
  type: "client/pan_to";
  data: Pick<Client, "origin">;
}

/**
 * Action creator that pans the board to a given position, regardless of the
 * panning origin.
 *
 * @param {Point} position - The position we want to pan the board to.
 * @returns The action thunk  to dispatch to the store.
 */
export const panTo = (position: Point): PanToAction => {
  return {
    type: "client/pan_to",
    data: {
      origin: position,
    },
  };
};

export interface ScaleAction extends BaseAction {
  type: "client/scale";
  data: Pick<Client, "scale" | "origin">;
}

/**
 * Action creator that scales the board around a given position by a
 * multiplicative factor.
 *
 * If no position is provided, we assume the canvas center.
 *
 * @param {number} delta - The relative factor by which to scale (1 is a no-op).
 * @param {Point} [position] - The point around which to scale the board.
 * @returns The action thunk to dispatch to the store.
 */
export const scale =
  (delta: number, position?: Point) =>
  (state: AppState): SetSceneParamsAction => {
    if (!position) position = getCanvasCenter(state);
    let { scale, origin } = getScene(state);

    let newScale = Math.max(delta * scale, MIN_SCALE);
    let newOrigin = {
      x: position.x + (newScale * (origin.x - position.x)) / scale,
      y: position.y + (newScale * (origin.y - position.y)) / scale,
    };

    return setSceneParams(newScale, newOrigin);
  };

export interface CreateSelectionAreaAction extends BaseAction {
  type: "client/create_selectionarea";
  data: Pick<Client, "selectionArea">;
}

/**
 * Action creator that starts creating a selection area.
 *
 * @param {Point} point - The position at which to start the selection area
 * (upper-left corner).
 * @returns {CreateSelectionAreaAction} The action to dispatch to the store.
 */
export const createSelectionArea = ({
  x,
  y,
}: Point): CreateSelectionAreaAction => {
  return {
    type: "client/create_selectionarea",
    data: { selectionArea: { position: { x, y }, width: 0, height: 0 } },
  };
};

export interface FinishSelectionAreaAction extends BaseAction {
  type: "client/finish_selectionarea";
  data: Pick<Client, "selectionArea" | "selected">;
}

/**
 * Action creator that finishes creating a selection area.
 *
 * @returns The action thunk to dispatch to the store.
 */
export const finishSelectionArea =
  () =>
  (state: AppState): FinishSelectionAreaAction | EmptyAction => {
    const area = getSceneSelectionArea(state);
    if (!area) return emptyAction();

    let selectionRect = {
      position: { x: area.x, y: area.y },
      width: area.width,
      height: area.height,
    };

    const sized = Size.getInsideArea(selectionRect)(state);
    const connections = Connection.getInsideArea(selectionRect)(state);
    const lines = Line.getInsideArea(selectionRect)(state);

    return {
      type: "client/finish_selectionarea",
      data: {
        selectionArea: null,
        selected: [
          ...sized.map(({ id }) => id),
          ...connections.map(({ id }) => id),
          ...lines.map(({ id }) => id),
        ],
      },
    };
  };

export interface UpdateSelectionAreaAction extends BaseAction {
  type: "client/update_selectionarea";
  data: Pick<Client, "selectionArea">;
}

/**
 * Action creator that updates the selection area that is currently being
 * created.
 *
 * @param {Point} position - The updated lower-right corner of the selection
 * area.
 * @returns The action thunk to dispatch to the store.
 */
export const updateSelectionArea =
  (position: { x: number; y: number }) =>
  (state: AppState): UpdateSelectionAreaAction => {
    let selectionArea = getSelectionArea(state) ?? {
      position: { x: 0, y: 0 },
      width: 0,
      height: 0,
    };

    let { x: width, y: height } = Vec.vec(selectionArea.position, position);

    return {
      type: "client/update_selectionarea",
      data: { selectionArea: { ...selectionArea, width, height } },
    };
  };

export interface MoveCursorAction extends BaseAction {
  type: "client/move_cursor";
  data: Pick<Client, "cursor">;
}

/**
 * Action creator that moves the client's cursor.
 *
 * @param {Point} screenPosition - The cursor's screen coordinates to update to.
 * @returns The action thunk to dispatch to the store.
 */
export const moveCursor = (screenPosition: Point) => (state: AppState) => {
  const cursor = getCursor(state);
  const scene = getScene(state);
  const scenePosition = toSceneCoordinates(scene, screenPosition);

  let isDragging =
    !!cursor.mouseDown &&
    dist(cursor.mouseDown.screenPosition, screenPosition) >= 30;

  if (isDragging) {
    let startPosition = cursor.mouseDown!.scenePosition;
    let currentPosition = scenePosition;
    let distance = dist(currentPosition, startPosition);
    let screenDistance = distance * scene.scale;

    let delta = {
      x: currentPosition.x - startPosition.x,
      y: currentPosition.y - startPosition.y,
    };

    return {
      type: "client/move_cursor",
      data: {
        cursor: {
          ...cursor,
          screenPosition,
          scenePosition,
          isDragging,
          drag: {
            startPosition,
            currentPosition,
            distance,
            screenDistance,
            delta,
            startTime: cursor.mouseDown!.time,
          },
        },
      },
    };
  } else {
    return {
      type: "client/move_cursor",
      data: { cursor: { ...cursor, screenPosition, scenePosition } },
    };
  }
};

export interface MouseDownAction extends BaseAction {
  type: "client/mouse_down";
  data: Pick<Client, "cursor">;
}

/**
 * Action creator that stores the pointer state when the user presses/clicks.
 *
 * @returns The action creator to dispatch to the store.
 */
export const mouseDown =
  () =>
  (state: AppState): MouseDownAction => {
    const cursor = getCursor(state);

    return {
      type: "client/mouse_down",
      data: {
        cursor: {
          ...cursor,
          isMouseDown: true,
          mouseDown: {
            screenPosition: cursor.screenPosition,
            scenePosition: cursor.scenePosition,
            time: Date.now(),
          },
        },
      },
    };
  };

export interface MouseUpAction extends BaseAction {
  type: "client/mouse_up";
  data: Pick<Client, "cursor">;
}

/**
 * Action creator that stores the pointer state when the user depresses the
 * pointer device.
 *
 * @returns The action creator to dispatch to the store.
 */
export const mouseUp =
  () =>
  (state: AppState): MouseUpAction => {
    const cursor = getCursor(state);

    return {
      type: "client/mouse_up",
      data: {
        cursor: {
          ...cursor,
          isMouseDown: false,
          isDragging: false,
          mouseDown: null,
          drag: null,
        },
      },
    };
  };

export interface SetContextMenuAction extends BaseAction {
  type: "client/set_context_menu";
  data: Pick<Client, "contextMenu">;
}

/**
 * Action creator that opens the context menu at a given position.
 *
 * @param {string} id - The id of the object to open the context menu for.
 * @param {Point} position - The pointer position to open the menu at.
 * @returns {SetContextMenuAction} The action to dispatch to the store.
 */
export const setContextMenu = (
  id: string,
  position: Point
): SetContextMenuAction => {
  return {
    type: "client/set_context_menu",
    data: { contextMenu: { id, position } },
  };
};

export interface UnsetContextMenuAction extends BaseAction {
  type: "client/unset_context_menu";
  data: Pick<Client, "contextMenu">;
}

/**
 * Action creator that closes the context menu.
 *
 * @returns {UnsetContextMenuAction} The action to dispatch to the store.
 */
export const unsetContextMenu = (): UnsetContextMenuAction => {
  return {
    type: "client/unset_context_menu",
    data: { contextMenu: null },
  };
};

export interface SetConnectionStatusAction extends BaseAction {
  type: "client/set_connection_status";
  data: Pick<Client, "online">;
}

/**
 * Action creator to set the client's websocket connection status.
 *
 * Mostly used to show a notification when the user's connection has dropped.
 *
 * @param {boolean} online - The status to set the `connection` flag to.
 * @returns {SetConnectionStatusAction} The action to dispatch to the store.
 */
export const setConnectionStatus = (
  online: boolean
): SetConnectionStatusAction => {
  return {
    type: "client/set_connection_status",
    data: { online },
  };
};

export interface SetViewportAction extends BaseAction {
  type: "client/set_viewport";
  data: Pick<Client, "viewport">;
}

/**
 * Set/update the current viewport dimensions.
 *
 * The viewport represents the actual canvas dimensions, in physical pixels,
 * and is used for scaling/centering content relative to the canvas dimensions.
 *
 * @param  {{width: number, height: number}} viewport -
 * @returns {SetViewportAction} [TODO:description]
 */
export const setViewport = ({
  width,
  height,
}: {
  width: number;
  height: number;
}): SetViewportAction => {
  return {
    type: "client/set_viewport",
    data: { viewport: { width, height } },
  };
};

export interface SetSceneParamsAction extends BaseAction {
  type: "client/set_scene_params";
  data: Pick<Client, "origin" | "scale">;
}

/**
 * Set the scale and origin coordinates of the client directly, without having
 * to go through panning/scaling.
 *
 * @param {number} scale - The scale parameter to set.
 * @param {Point} origin - The canvas origin to set.
 * @returns {SetSceneParamsAction} The action to dispatch to the store.
 */
export const setSceneParams = (
  scale: number,
  origin: Point
): SetSceneParamsAction => {
  return {
    type: "client/set_scene_params",
    data: { origin, scale },
  };
};

export interface SetCurrentStickyColorAction extends BaseAction {
  type: "client/set_current_sticky_color";
  data: Pick<Client, "currentStickyColor">;
}

/**
 * Set the current (last created) sticky color.
 *
 * @param {color} color - The (RGB) color string to set.
 * @returns {SetCurrentStickyColorAction} The action to dispatch to the store.
 */
export const setCurrentStickyColor = (
  color: string
): SetCurrentStickyColorAction => {
  return {
    type: "client/set_current_sticky_color" as const,
    data: { currentStickyColor: color },
  };
};

export interface SetCurrentLabelStyleAction extends BaseAction {
  type: "client/set_current_label_style";
  data: Pick<Client, "currentLabelStyle">;
}

/**
 * Set the style of the label that was last updated, so new labels can
 * be initialized to the same value.
 *
 * @param {number} scale - The scale parameter to set.
 * @param {Point} origin - The canvas origin to set.
 * @returns {SetCurrentLabelStyleAction} The action to dispatch to the store.
 */
export const setCurrentLabelStyle = (
  style: Label.Style
): SetCurrentLabelStyleAction => {
  return {
    type: "client/set_current_label_style",
    data: { currentLabelStyle: style },
  };
};

export interface SetCommentObjectIdAction extends BaseAction {
  type: "client/set_comment_object_id";
  data: Pick<Client, "commentObjectId">;
}

/**
 * Action creator that sets the id of the object to show comments for.
 *
 * @returns {SetCommentObjectIdAction} The action to dispatch to the store.
 */
export const setCommentObjectId = (
  commentObjectId: string
): SetCommentObjectIdAction => {
  return {
    type: "client/set_comment_object_id",
    data: { commentObjectId },
  };
};

export interface UnsetCommentObjectIdAction extends BaseAction {
  type: "client/unset_comment_object_id";
  data: Pick<Client, "commentObjectId">;
}

/**
 * Action creator that unsets the id of the object to show comments for.
 *
 * @returns {SetCommentObjectIdAction} The action to dispatch to the store.
 */
export const unsetCommentObjectId = (): UnsetCommentObjectIdAction => {
  return {
    type: "client/unset_comment_object_id",
    data: { commentObjectId: null },
  };
};

export interface SetShowcaseUserIdAction extends BaseAction {
  type: "client/set_showcase_user_id";
  data: Pick<Client, "showcaseUserId">;
}

/**
 * Action creator that sets the id of the user to highlight.
 *
 * @returns {SetShowcaseUserIdAction} The action to dispatch to the store.
 */
export const setShowcaseUserId = (
  showcaseUserId: string
): SetShowcaseUserIdAction => {
  return {
    type: "client/set_showcase_user_id",
    data: { showcaseUserId },
  };
};

export interface UnsetShowcaseUserIdAction extends BaseAction {
  type: "client/unset_showcase_user_id";
  data: Pick<Client, "showcaseUserId">;
}

/**
 * Action creator that unsets the id of the user to showcase.
 *
 * @returns {UnsetShowcaseUserIdAction} The action to dispatch to the store.
 */
export const unsetShowcaseUserId = (): UnsetShowcaseUserIdAction => {
  return {
    type: "client/unset_showcase_user_id",
    data: { showcaseUserId: null },
  };
};

export interface SetShowcaseObjectIdsAction extends BaseAction {
  type: "client/set_showcase_object_ids";
  data: Pick<Client, "showcaseObjectIds">;
}

/**
 * Action creator that sets the ids of the objects to showcase.
 *
 * @returns {SetShowcaseObjectIdsAction} The action to dispatch to the store.
 */
export const setShowcaseObjectIds = (
  showcaseObjectIds: string[]
): SetShowcaseObjectIdsAction => {
  return {
    type: "client/set_showcase_object_ids",
    data: { showcaseObjectIds },
  };
};

export interface UnsetShowcaseObjectIdsAction extends BaseAction {
  type: "client/unset_showcase_object_ids";
  data: Pick<Client, "showcaseObjectIds">;
}

/**
 * Action creator that unsets the ids of the objects to showcase.
 *
 * @returns {UnsetHighlightObjectIdAction} The action to dispatch to the store.
 */
export const unsetShowcaseObjectIds = (): UnsetShowcaseObjectIdsAction => {
  return {
    type: "client/unset_showcase_object_ids",
    data: { showcaseObjectIds: null },
  };
};

/**
 * Set client scale and origin such that the provided rectangle (given in *scene*
 * coordinates) fits and is centered.
 *
 * @param {Rectangle} rect - The rectangle to fit the screen to.
 * @returns The action thunk to dispatch to the store.
 */
export const zoomToFit =
  (rect: Rectangle) =>
  (state: AppState): SetSceneParamsAction | EmptyAction => {
    const viewport = getViewport(state);
    if (!viewport) return emptyAction();

    // Find the scale at which the given rectangle dimensions will match the
    // viewport dimensions.
    const scaleX = viewport.width / rect.width ?? 1;
    const scaleY = viewport.height / rect.height ?? 1;
    const scaleToFit = Math.min(scaleX, scaleY);

    const newScale = Math.max(scaleToFit, MIN_SCALE);

    // Pick the origin such that the viewport and rectangle centers coincide
    // Because the rectangle parameters are in scene coordinates, we multiply them
    // by the chosen scale to turn them into screen coordinates.
    const origin = {
      x:
        -newScale * rect.position.x +
        (viewport.width - scaleToFit * rect.width) / 2,
      y:
        -newScale * rect.position.y +
        (viewport.height - scaleToFit * rect.height) / 2,
    };

    return setSceneParams(newScale, origin);
  };

/**
 * Zoom/center the board to fit the entire board on screen.
 *
 * @returns The action thunk to dispatch to the store.
 */
export const zoomToFitBoard =
  () =>
  (state: AppState): ReturnType<typeof zoomToFit> | EmptyAction => {
    const objects = Object.values(BaseObject.getAll(state));
    const boundingRect = BoardObject.getBoundingRect(objects)(state);

    const viewport = getViewport(state);

    if (!viewport) return emptyAction();
    if (boundingRect.width === 0 || boundingRect.height === 0)
      return emptyAction();

    const paddedBoundingRect = getPaddedBoundingRect(boundingRect);

    return zoomToFit(paddedBoundingRect);
  };

/**
 * Zoom/center the board to fit the entire selection on screen.
 *
 * @returns The action thunk to dispatch to the store.
 */
export const zoomToFitSelection =
  () =>
  (state: AppState): ReturnType<typeof zoomToFit> | EmptyAction => {
    const selected = getSelectedObjects(state);
    const boundingRect = BoardObject.getBoundingRect(selected)(state);

    const viewport = getViewport(state);

    if (!viewport) return emptyAction();
    if (selected.length === 0) return emptyAction();
    if (boundingRect.width === 0 || boundingRect.height === 0)
      return emptyAction();

    const paddedBoundingRect = getPaddedBoundingRect(boundingRect);

    return zoomToFit(paddedBoundingRect);
  };

const getPaddedBoundingRect = (rect: Rectangle) => {
  // We pick a padding of 200px in *screen* coordinates, so it has a consistent
  // size regardless of the board bounds.
  const scenePadding = 200;

  return {
    position: {
      x: rect.position.x - scenePadding,
      y: rect.position.y - scenePadding,
    },
    width: rect.width + 2 * scenePadding,
    height: rect.height + 2 * scenePadding,
  };
};

/**
 * Given a position (in *scene* coordinates), center the canvas on this position,
 * maintaining the current scale.
 *
 * @param {Point} position - The position (in *screen* coordinates) to center
 * on.
 * @returns The action thunk to dispatch to the store.
 */
export const centerOn =
  (position: Point) =>
  (state: AppState): SetSceneParamsAction | EmptyAction => {
    const viewport = getViewport(state);
    if (!viewport) return emptyAction();
    const scene = getScene(state);

    const currentCenter = toSceneCoordinates(scene, {
      x: viewport.width / 2,
      y: viewport.height / 2,
    });

    // Vector that points from actual screen center to desired screen center
    const delta = {
      x: scene.scale * (position.x - currentCenter.x),
      y: scene.scale * (position.y - currentCenter.y),
    };

    const origin = {
      x: scene.origin.x - delta.x,
      y: scene.origin.y - delta.y,
    };

    return setSceneParams(scene.scale, origin);
  };

export interface AddUndoSnapshotAction extends BaseAction {
  type: "client/add_undo_snapshot";
  data: Pick<Client, "revisedObjects">;
}

/**
 * Push a collection of object snapshots to the undo stack
 */
export const addUndoSnapshot =
  (objects: BaseObject.BaseObject[]) =>
  (state: AppState): AddUndoSnapshotAction => {
    const undo = getUndos(state);

    return {
      type: "client/add_undo_snapshot",
      data: {
        revisedObjects: { undo: [...undo, objects], redo: [] },
      },
    };
  };

export interface UndoAction extends BaseAction {
  type: "client/undo";
  data: Pick<Client, "revisedObjects">;
}

/**
 * Pop a revision of object snapshots off the undo stack, apply them, and push
 * the overwritten state onto the redo stack.
 */
export const undo =
  () =>
  (state: AppState): UndoAction | EmptyAction => {
    const { undo, redo } = getClient(state).revisedObjects;
    // Fetch all the curent objects with this id, and store a redo snapshot
    const undoSnapshot = undo.pop();
    if (!undoSnapshot) return emptyAction();

    const redoSnapshot = undoSnapshot
      .map((obj) => BaseObject.getById(obj.id)(state))
      .filter((snapshot) => snapshot) as BaseObject.BaseObject[];

    return {
      type: "client/undo",
      data: {
        revisedObjects: { undo, redo: [...redo, redoSnapshot] },
      },
      effects: { dispatch: [updateMany(undoSnapshot)] },
    };
  };

export interface RedoAction extends BaseAction {
  type: "client/redo";
  data: Pick<Client, "revisedObjects">;
}

/**
 * Pop a revision of object snapshots off the redo stack, apply them, and push
 * the overwritten state onto the redo stack.
 */
export const redo =
  () =>
  (state: AppState): RedoAction | EmptyAction => {
    const { undo, redo } = getClient(state).revisedObjects;
    // Fetch all the curent objects with this id, and store a redo snapshot
    const redoSnapshot = redo.pop();
    if (!redoSnapshot) return emptyAction();

    const undoSnapshot = redoSnapshot
      .map((obj) => BaseObject.getById(obj.id)(state))
      .filter((snapshot) => snapshot) as BaseObject.BaseObject[];

    return {
      type: "client/redo",
      data: {
        revisedObjects: { undo: [...undo, undoSnapshot], redo },
      },
      effects: { dispatch: [updateMany(redoSnapshot)] },
    };
  };

/**
 * Reducer that processes any `Client` actions.
 *
 * @param {AppState["objects"]} [state] - The current `objects` state.
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["objects"]} The updated state slice.
 */
export const reducer = (
  state: AppState["client"] = {} as any,
  action: Action
): AppState["client"] => {
  switch (action.type) {
    case "client/select":
    case "client/select_many":
    case "client/select_only":
    case "client/unselect":
    case "client/unselect_many":
    case "client/unselect_all":
    case "client/start_panning":
    case "client/finish_panning":
    case "client/pan":
    case "client/pan_to":
    case "client/scale":
    case "client/set_scene_params":
    case "client/set_viewport":
    case "client/move_cursor":
    case "client/mouse_up":
    case "client/mouse_down":
    case "client/create_selectionarea":
    case "client/finish_selectionarea":
    case "client/update_selectionarea":
    case "client/set_context_menu":
    case "client/set_connection_status":
    case "client/unset_context_menu":
    case "client/set_current_sticky_color":
    case "client/set_current_label_style":
    case "client/set_comment_object_id":
    case "client/unset_comment_object_id":
    case "client/set_showcase_user_id":
    case "client/unset_showcase_user_id":
    case "client/set_showcase_object_ids":
    case "client/unset_showcase_object_ids":
    case "client/add_undo_snapshot":
    case "client/undo":
    case "client/redo": {
      return { ...state, ...action.data };
    }

    default:
      return state;
  }
};

/**
 * Selector that returns the `Client` part of the App state.
 *
 * @param {AppState} state - The current app state.
 * @returns {Client} The `Client` state.
 */
export const getClient = (state: AppState): Client => state.client;

/**
 * Selector that returns the value of the client's `transform` field.
 *
 * @param {AppState} state - The current app state.
 * @returns {string | null} The value of the `transform` field.
 */
export const getTransform = createSelector(
  getClient,
  (client) => client.transform
);

/**
 * Selector that returns the client's associated user id.
 *
 * @param {AppState} state - The current app state.
 * @returns {string} The client's user id.
 */
export const getUserId = createSelector(getClient, (client) => client.userId);

/**
 * Selector that returns the client's array of selected objects.
 *
 * @param {AppState} state - The current app state.
 * @returns {string[]} The array of ids of selected objects.
 */
export const getSelected = createSelector(
  getClient,
  (client) => client.selected
);

/*
 * Selector that returns the client's selection area.
 *
 * @param {AppState} - The current app state.
 * @returns The client's selection area.
 */
export const getSelectionArea = createSelector(
  getClient,
  (client) => client.selectionArea
);

/**
 * Selector that returns a normalized selection area.
 *
 * Normalizes a selection area to guarantee dimensions as positive.
 *
 * @param {AppState} state - The current app state.
 * @returns The normalized selection area
 */
export const getNormalizedSelectionArea = createSelector(
  getSelectionArea,
  (selectionArea): Rectangle | null => {
    if (selectionArea == null) {
      return null;
    }

    return Rect.normalized(selectionArea);
  }
);

/**
 * Selector that returns the clients "scene"-related parameters.
 *
 * * `scale`
 * * `transform`
 * * `origin`
 * * `transformOrigin`
 *
 * @param {AppState} state - The current app state.
 * @returns The client's scene parameters.
 */
export const getScene = createShallowEqualSelector(
  getClient,
  ({ scale, transform, transformOrigin, origin }) => {
    return { scale, transform, transformOrigin, origin };
  }
);

/**
 * Selector that returns the client's scale parameter.
 *
 * @param {AppState} state - The current app state.
 * @returns {number} The scale factor.
 */
export const getScale = createSelector(getClient, (client) => client.scale);

/**
 * Selector that returns the client's canvas origin.
 *
 * @param {AppState} state - The current app state.
 * @returns {number} The canvas origin.
 */
export const getOrigin = createSelector(getClient, (client) => client.origin);

/**
 * Selector that returs the client's cursor position.
 *
 * @param {AppState} state - The current app state.
 * @returns {Point} The cursor position (in *scene* coordinates)
 */
export const getCursor = createSelector(getClient, (client) => client.cursor);

/**
 * Selector that returns whether or not the client is "dragging" something
 * (i.e., moving the pointer while pressing down)
 *
 * @param {AppState} state - The current app state.
 * @returns {boolean} Whether or not the client is dragging/grabbing.
 */
export const getIsDragging = createSelector(
  getCursor,
  (cursor) => cursor.isDragging
);

/**
 * Get the client's selection area *in scene coordinates*.
 *
 * @param {AppState} state - The current app state.
 * @returns The selection area in scene coordinates.
 */
export const getSceneSelectionArea = createSelector(
  getScene,
  getNormalizedSelectionArea,
  (scene, selectionArea) => {
    if (!selectionArea) return;

    const {
      position: { x, y },
      width,
      height,
    } = selectionArea;
    const scenePos1 = toSceneCoordinates(scene, { x, y });
    const scenePos2 = toSceneCoordinates(scene, {
      x: x + width,
      y: y + height,
    });

    const sceneArea = {
      ...scenePos1,
      width: scenePos2.x - scenePos1.x,
      height: scenePos2.y - scenePos1.y,
    };
    return sceneArea;
  }
);

/**
 * Selector creator that returns whether an object id is in the array of selected
 * objects.
 *
 * @param {AppState} state - The current app state.
 * @returns Selector that checks whether or not the object is selected.
 */
export const getIsSelected = (id: string) =>
  createSelector(getSelected, (selected) => selected.includes(id));

/**
 * Selector that returns whether or not the client's `contextMenu` field is set.
 *
 * @param {AppState} state - The current app state.
 * @returns The client's `contextMenu` field.
 */
export const getContextMenu = createSelector(
  getClient,
  (client) => client.contextMenu
);

/**
 * Get the client's selection as objects.
 *
 * @param {AppState} state - The current app state.
 * @returns The currently selected objects.
 */
export const getSelectedObjects = createShallowEqualSelector(
  getSelected,
  BaseObject.getAll,
  (selected, objects) => {
    return selected
      .map((id) => objects[id])
      .filter((object) => object !== undefined);
  }
);

/**
 * Get the client's selection as objects including dependencies.
 *
 * @param {AppState} state - The current app state.
 * @returns The currently selected objects and dependencies of those objects.
 */
export const getSelectedWithDependencies = createSelector(
  getSelectedObjects,
  BaseObject.getAll,
  (selected, objects) => {
    const images = selected.filter(isImage);
    const uploads = [];
    for (let image of images) {
      uploads.push(objects[image.upload]);
    }
    return [...selected, ...uploads];
  }
);

/**
 * Selector that returns whether or not the user is busy
 * (dragging or creating something).
 *
 * @param {AppState} state - The current app state.
 * @returns {boolean} Whether or not the user is busy.
 */
export const getUserIsBusy = (userId: string) =>
  createSelector(
    getIsDragging,
    Create.getCreatingBy(userId),
    (isDragging, creating) => {
      return isDragging || creating.length > 0;
    }
  );

/**
 * Get the client's current viewport (in *screen* coordinates).
 *
 * @param {AppState} state - The current app state.
 * @returns {{width: number, height: number} | undefined } - The current
 * viewport.
 */
export const getViewport = createSelector(
  getClient,
  (client) => client.viewport
);

export const getVisibleArea = createSelector(
  getScale,
  getOrigin,
  getViewport,
  (scale, origin, viewport): Rectangle => {
    return {
      position: { x: -origin.x / scale, y: -origin.y / scale },
      width: (viewport?.width ?? 0) / scale,
      height: (viewport?.height ?? 0) / scale,
    };
  }
);

/**
 * Get the client's connection status.
 *
 * @param {AppState} state - The current app state.
 * @returns Whether or not the client is online.
 */
export const getConnectionStatus = createSelector(
  getClient,
  (client) => client.online
);

/**
 * Selector that returns the screen center
 *
 */
export const getCanvasCenter = createSelector(getViewport, (viewport) => {
  return viewport
    ? { x: viewport.width / 2, y: viewport.height / 2 }
    : { x: 0, y: 0 };
});

/**
 * Selector that returns the current (last created) sticky color.
 *
 * @returns {string} The current sticky color.
 */
export const getCurrentStickyColor = createSelector(
  getClient,
  (client) => client.currentStickyColor
);

/**
 * Get the client's current label style.
 *
 * @param {AppState} state - The current app state.
 * @returns The client's `currentLabelStyle` field.
 */
export const getCurrentLabelStyle = createSelector(
  getClient,
  (client) => client.currentLabelStyle ?? "heading"
);

/**
 * Get the client's current comment object id.
 *
 * @param {AppState} state - The current app state.
 * @returns {string} The client's `commentObjectId` field.
 */
export const getCommentObjectId = createSelector(
  getClient,
  (client) => client.commentObjectId
);

/**
 * Get the client's current highlight object id.
 *
 * @param {AppState} state - The current app state.
 * @returns {string} The client's `highlightObjectId` field.
 */
export const getShowcaseObjectIds = createSelector(
  getClient,
  (client) => client.showcaseObjectIds
);

/**
 * Get the client's current highlight user id.
 *
 * @param {AppState} state - The current app state.
 * @returns {string} The client's `highlightUserId` field.
 */
export const getShowcaseUserId = createSelector(
  getClient,
  (client) => client.showcaseUserId
);

/**
 * Get the current set of undo snapshots
 */
export const getUndos = createSelector(
  getClient,
  (client) => client.revisedObjects.undo
);

/**
 * Get the current set of redo snapshots
 */
export const getRedos = createSelector(
  getClient,
  (client) => client.revisedObjects.redo
);

/**
 * Helper that transforms a point from *screen* coordinates to *scene* coordinates.
 *
 * @param {{scale: number, origin: Point}} scene - The scene parameters that
 * define the coordinate transformation.
 * @param {Point} The point to transform, in *screen* coordinates.
 * @returns {Point} The transformed point in *scene* coordinates.
 */
export const toSceneCoordinates = (
  { scale, origin }: { scale: number; origin: Point },
  position: Point
): Point => {
  return {
    x: (position.x - origin.x) / scale,
    y: (position.y - origin.y) / scale,
  };
};

/**
 * Helper that transforms a point from *scene* coordinates to *screen* coordinates.
 *
 * @param {{scale: number, origin: Point}} scene - The scene parameters that
 * define the coordinate transformation.
 * @param {Point} The point to transform, in *scene* coordinates.
 * @returns {Point} The transformed point in *screen* coordinates.
 */
export const toScreenCoordinates = (
  { scale, origin }: { scale: number; origin: Point },
  position: Point
): Point => {
  return {
    x: position.x * scale + origin.x,
    y: position.y * scale + origin.y,
  };
};
