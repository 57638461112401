import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import * as Announcements from "~/util/AnnouncementClient";
import { useAccount } from "~/context/AuthContext";
import { BoardType, useConfig } from "~/context/ConfigContext";
import { getCreatedBy as getBoardCreatedBy } from "~/store/bundles/Board";
import {
  getAll as getStickers,
  recallAll as recallStickers,
} from "~/store/bundles/Sticker";

import * as Icons from "./Icons";
import { confirm } from "./AlertDialog";
import { IconButton } from "./Button";
import { Menu, MenuButton, MenuDivider, MenuItem, MenuItems } from "./Menu";

export let BoardMenu = ({ children }: { children: ReactNode }) => {
  let config = useConfig();
  let account = useAccount();
  let dispatch = useDispatch();
  let stickers = useSelector(getStickers);
  let createdBy = useSelector(getBoardCreatedBy);

  let isPro = account?.pro || false;
  let isBoard = config.type === BoardType.Board;
  // Use the `websocket` config option to distinguish betwween
  // viewing a template and editing one.
  let isTemplateEditor = config.type === BoardType.Template && config.websocket;
  let isTemplate = config.type === BoardType.Template;
  let isSandbox = config.type === BoardType.Sandbox;
  let hasStickers = stickers.length > 0;

  let recall = async () => {
    let ok = await confirm({
      title: "Recall Stickers",
      okButtonText: "Recall",
      description: (
        <>
          Permanently remove <strong>{stickers.length}</strong> stickers from
          the board?
        </>
      ),
    });

    if (ok) dispatch(recallStickers());
  };

  let isCreator = createdBy === account?.id;

  if (isTemplate) {
    // Basically none of the normal board links work inside the template viewer
    // and editor because of the way their URLs work. For now, we'll just skip
    // the whole menu and just render a back button instead.
    return (
      <Link to="/boards">
        <IconButton icon={<Icons.CornerLeftUp />} aria-label="Back to boards" />
      </Link>
    );
  }

  return (
    <Menu placement="bottom-start">
      <MenuButton>{children}</MenuButton>
      <MenuItems>
        {!isSandbox && (
          <>
            <MenuItem to="/boards">
              <Icons.CornerLeftUp /> Back to Boards
            </MenuItem>
            <MenuDivider />
          </>
        )}
        {(isBoard || isTemplateEditor) && isCreator && (
          <MenuItem to="settings">
            <Icons.Info /> Board Settings
          </MenuItem>
        )}
        {isBoard && (
          <MenuItem to="convert">
            <Icons.Package /> Create Template
          </MenuItem>
        )}
        {hasStickers && (
          <MenuItem onClick={recall}>
            <Icons.BarChart /> Recall Stickers
          </MenuItem>
        )}
        {isBoard && (
          <MenuItem to="export">
            <Icons.Download /> Export
          </MenuItem>
        )}
        <MenuDivider />
        <MenuItem to="help">
          <Icons.HelpCircle /> Help
        </MenuItem>
        <MenuItem to="feedback">
          <Icons.Send /> Feedback
        </MenuItem>
        <MenuItem to="tour">
          <Icons.Globe /> Tour
        </MenuItem>
        {Announcements.getAll().length > 0 && (
          <MenuItem to="changelog">
            <Icons.Gift /> What's New?
          </MenuItem>
        )}
        {!isPro && !isSandbox && (
          <>
            <MenuDivider />
            <MenuItem to="pro">
              <Icons.Logo />
              <strong>Sticky Studio Plus</strong>
            </MenuItem>
          </>
        )}
      </MenuItems>
    </Menu>
  );
};
