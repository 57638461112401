import { createSelector } from "reselect";

import * as Client from "~/store/bundles/Client";
import assert from "~/util/assert";
import { Rectangle, Vector, Point, vec, normalized } from "~/util/geometry";
import { createShallowEqualSelector } from "~/util/selectors";

import * as Alive from "./Alive";
import * as BaseObject from "./BaseObject";
import * as Lock from "./Lock";
import { AppState, State, Action as AppAction } from "../index";
import { BaseAction, emptyAction, EmptyAction } from "../shared";
import { Position } from "./Position";
import { Size } from "./Size";

export const MIN_DIMENSIONS: Record<string, { width: number; height: number }> =
  {
    image: {
      width: 50,
      height: 50,
    },
    label: {
      width: 50,
      height: 50,
    },
    sticky: {
      width: 50,
      height: 50,
    },
  };

export type Corner = "top-left" | "top-right" | "bottom-left" | "bottom-right";

/**
 * Interface that describes the metadata for a Resize event
 */
interface ResizeData {
  /**
   * The id of the user performing the resize
   */
  userId: string;

  /**
   * Cursor position the resize was initiated at
   */
  start: Point;

  /**
   * Current cursor position
   */
  current: Point;

  /**
   * Whether or not we are resizing from the center
   */
  fromCenter?: boolean;

  /**
   * Whether or not to keep the aspect ratio fixed during the resize
   */
  fixedAspectRatio?: boolean;

  /**
   * Timestamp when the resize event was initiated
   */
  initiatedAt: number;

  /**
   * The corner we're resizing
   */
  corner: Corner;

  /**
   * The initial object dimensions before initiating the resize
   */
  initialData: {
    width: number;
    height: number;
    position: Point;
    invertedX?: boolean;
    invertedY?: boolean;
  };
}

/**
 * Interface that describes things that can be resized.
 */
export interface Resize extends Size, Position {
  resize: ResizeData | null;
  invertedX?: boolean;
  invertedY?: boolean;
}

/**
 * Type guard that checks whether an object (BaseObject) implements the Resize
 * trait.
 */
export const isResize = <T extends BaseObject.BaseObject>(
  object: T
): object is T & Resize =>
  "resize" in object || // new format
  "resizing" in object; // old format

/**
 * Schema for actions returned by the `Resize.startResizing()` action creator.
 */
export interface StartResizingAction extends BaseAction {
  type: "resize/start_resizing";
  data: Pick<Resize, "id" | "resize">;
}

/**
 * Action creator that marks a given object as being resized by a user.
 */
export const startResizing =
  (id: string, userId: string, position: Point, corner: Corner) =>
  (state: AppState): StartResizingAction | EmptyAction => {
    const object = getById(id)(state);
    if (!object || !isResize(object)) return emptyAction();

    const scene = Client.getScene(state);
    const scenePosition = Client.toSceneCoordinates(scene, position);

    const resize = {
      userId,
      start: scenePosition,
      current: scenePosition,
      initialData: {
        width: object.width,
        height: object.height,
        position: object.position,
        invertedX: object.invertedX,
        invertedY: object.invertedY,
      },
      initiatedAt: Date.now(),
      corner,
    } as const;

    return {
      type: "resize/start_resizing" as const,
      data: { id, resize },
      effects: {
        broadcast: true,
        persist: true,
        dispatch: [Alive.touch(id)],
      },
    };
  };

/**
 * Schema for actions returned by the `Resize.startResizing()` action creator.
 */
export interface FinishResizingAction extends BaseAction {
  type: "resize/finish_resizing";
  data: Pick<
    Resize,
    | "id"
    | "resize"
    | "position"
    | "width"
    | "height"
    | "invertedX"
    | "invertedY"
    | "updatedAt"
    | "updatedBy"
  >;
}

/**
 * Action creator that clears a given object as being resized by a user.
 */
export const finishResizing =
  (id: string) =>
  (state: AppState): FinishResizingAction | EmptyAction => {
    let object = getById(id)(state);
    if (!object) return emptyAction();

    return {
      type: "resize/finish_resizing" as const,
      data: {
        id: object.id,
        position: object.position,
        width: object.width,
        height: object.height,
        invertedX: object.invertedX,
        invertedY: object.invertedY,
        resize: null,
        updatedAt: Date.now(),
        updatedBy: object.resize?.userId,
      },
      effects: {
        broadcast: true,
        persist: true,
      },
    };
  };

/**
 * Schema for actions returned by the `Resize.cancelResizing()` action creator.
 */
export interface CancelResizingAction extends BaseAction {
  type: "resize/cancel_resizing";
  data: Pick<
    Resize,
    | "id"
    | "resize"
    | "position"
    | "width"
    | "height"
    | "invertedX"
    | "invertedY"
    | "updatedAt"
    | "updatedBy"
  >;
}

/**
 * Action creator that cancels the resizing of an object.
 *
 * @returns The action thunk to dispatch to the store.
 */
export const cancelResizing =
  (id: string) =>
  (state: AppState): CancelResizingAction | EmptyAction => {
    let object = getById(id)(state);
    if (!object || !object.resize) return emptyAction();

    return {
      type: "resize/cancel_resizing" as const,
      data: {
        id: object.id,
        ...object.resize.initialData,
        resize: null,
      },
      effects: {
        broadcast: true,
      },
    };
  };

/**
 * Schema for actions returned by the `Resize.resize()` action creator.
 */
export interface ResizeAction extends BaseAction {
  type: "resize/resize";
  data: Pick<
    Resize,
    | "id"
    | "width"
    | "height"
    | "position"
    | "resize"
    | "invertedX"
    | "invertedY"
  >;
}

type ResizeOptions = {
  fromCenter?: boolean;
  lockRatio?: boolean;
};

/**
 * Action creator that resizes an object.
 */
export const resize =
  (id: string, position: Point, opts?: ResizeOptions) =>
  (state: AppState): ResizeAction | EmptyAction => {
    let object = getById(id)(state);
    if (!object || !object.resize) return emptyAction();

    // Don't resize the object if it's locked
    if (Lock.isLock(object) && object.locked) return emptyAction();

    let scene = Client.getScene(state);
    let scenePosition = Client.toSceneCoordinates(scene, position);

    let initialRect = {
      position: object.resize.initialData.position,
      width: object.resize.initialData.width,
      height: object.resize.initialData.height,
    };

    // Resize the rectangle according to the distance the cursor has moved
    let delta = vec(object.resize.start, scenePosition);
    let updatedRect = resizeCorner(initialRect, object.resize.corner, delta);

    // If we're resizing from the center, we effectively resize the opposite
    // corner in the opposite direction too
    if (opts?.fromCenter) {
      updatedRect = resizeCorner(updatedRect, opposite(object.resize.corner), {
        x: -delta.x,
        y: -delta.y,
      });
    }

    // If we're keeping the aspect ratio fixed, we set the height to the fixed
    // value determined by the new width
    if (opts?.lockRatio) {
      let ratio = updatedRect.width / object.width;
      updatedRect.height = ratio * object.height;
    }

    // If we're under the minimum width, don't update the x-position/width
    let MIN_WIDTH = MIN_DIMENSIONS[object.type]?.width;
    if (MIN_WIDTH && MIN_WIDTH > updatedRect.width) {
      updatedRect.position.x = object.position.x;
      updatedRect.width = object.width;
    }

    // If we're under the minimum height, don't update the y-position/height
    let MIN_HEIGHT = MIN_DIMENSIONS[object.type]?.height;
    if (MIN_HEIGHT && MIN_HEIGHT > updatedRect.height) {
      updatedRect.position.y = object.position.y;
      updatedRect.height = object.height;
    }

    const resize = {
      ...object.resize,
      current: scenePosition,
      fromCenter: opts?.fromCenter,
      fixedAspectRatio: opts?.lockRatio,
    };

    // We store a normalized version of the new rectangle, along with boolean
    // flags to indicate "inverted" sides (height or width < 0). This avoids
    // us having to take negative sides into account in all of our Rectangle
    // logic.
    return {
      type: "resize/resize",
      data: {
        id,
        resize,
        ...normalized(updatedRect),

        // Flip the original invertedX flag when the width goes negative
        invertedX:
          updatedRect.width < 0
            ? !resize.initialData.invertedX
            : resize.initialData.invertedX,

        // Flip the original invertedY flag when the height goes negative
        invertedY:
          updatedRect.height < 0
            ? !resize.initialData.invertedY
            : resize.initialData.invertedY,
      },
      effects: {
        broadcast: true,
        dispatch: [Alive.touch(id)],
      },
    };
  };

/**
 * Schema for actions returned by the `Resize.grow()` action creator.
 */
export interface GrowAction extends BaseAction {
  type: "resize/grow";
  data: Pick<Resize, "id" | "width" | "height">;
}

/**
 * Action creator that resizes an object by a given delta.
 */
export const grow =
  (id: string, delta: Point) =>
  (state: AppState): GrowAction | EmptyAction => {
    let object = getById(id)(state);
    if (!object) return emptyAction();

    let { width, height } = object;

    let MIN_WIDTH = MIN_DIMENSIONS[object.type]?.width ?? 50;
    let MIN_HEIGHT = MIN_DIMENSIONS[object.type]?.height ?? 50;

    let newWidth = Math.max(MIN_WIDTH, width + delta.x);
    let newHeight = Math.max(MIN_HEIGHT, height + delta.y);

    return {
      type: "resize/grow",
      data: { id, width: newWidth, height: newHeight },
      effects: {
        broadcast: true,
        persist: true,
        dispatch: [Alive.touch(id)],
      },
    };
  };

/**
 * Return a list of finishResizing actions for each object currently being
 * edited by a given user.
 */
export const releaseAllBy = (userId: string) => (state: AppState) => {
  const resizing = getResizingBy(userId)(state);
  return resizing.map((obj) => finishResizing(obj.id));
};

/**
 * Union type of all Resize-related actions.
 */
export type Action =
  | StartResizingAction
  | FinishResizingAction
  | CancelResizingAction
  | ResizeAction
  | GrowAction;

/**
 * Get all resizable objects from an app state.
 */
export const getAll = createShallowEqualSelector(BaseObject.getAll, (objects) =>
  Object.values(objects).filter(isResize)
);

/**
 * Get all objects from an app state being resized by given user.
 */
export const getResizingBy = (userId: string) =>
  createSelector(getAll, (objects) =>
    objects.filter(({ resize }) => resize?.userId === userId)
  );

/**
 * Get Resize object with given id.
 */
export const getById = (id: string) => (state: AppState) => {
  const object = BaseObject.getById(id)(state);
  if (object && isResize(object)) return object;
};

/**
 * Reducer that handles any Resize-related actions
 */
export const reducer = (state: State = {}, action: AppAction): State => {
  switch (action.type) {
    case "resize/start_resizing":
    case "resize/finish_resizing":
    case "resize/cancel_resizing":
    case "resize/resize":
    case "resize/grow": {
      const object = state[action.data.id];
      assert(isResize(object), "Object does not implement the Resize trait.");

      return {
        ...state,
        [action.data.id]: { ...object, ...action.data },
      };
    }
    default:
      return state;
  }
};

/**
 * Resize a given rectangle by moving a given corner/direction by a certain
 * delta.
 *
 * @param currentRect - The rectangle to resize
 * @param corner - The corner to move
 * @param delta - The amount by which to update the corner position
 *
 */
const resizeCorner = (
  currentRect: Rectangle,
  corner: Corner,
  delta: Vector
): Rectangle => {
  const {
    position: { x, y },
    width,
    height,
  } = currentRect;

  switch (corner) {
    case "top-right":
      return {
        position: { x, y: y + delta.y },
        width: width + delta.x,
        height: height - delta.y,
      };

    case "bottom-right":
      return {
        position: { x, y },
        width: width + delta.x,
        height: height + delta.y,
      };

    case "bottom-left":
      return {
        position: { x: x + delta.x, y },
        width: width - delta.x,
        height: height + delta.y,
      };

    case "top-left": {
      return {
        position: { x: x + delta.x, y: y + delta.y },
        width: width - delta.x,
        height: height - delta.y,
      };
    }
  }
};

/**
 * Helper that maps a corner to its opposite corner
 */
export const opposite = (dir: Corner): Corner => {
  switch (dir) {
    case "top-left":
      return "bottom-right";
    case "top-right":
      return "bottom-left";
    case "bottom-left":
      return "top-right";
    case "bottom-right":
      return "top-left";
  }
};
