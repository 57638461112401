import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "~/context/AuthContext";
import { postJson } from "~/util/ApiClient";

export const Logout = () => {
  const auth = useAuth();
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    postJson("/auth/logout").then((response) => {
      if (response.status === "success") setCompleted(true);
    });
    auth.deauthenticate();
  }, [auth]);

  return completed ? <Navigate to="/" /> : null;
};
