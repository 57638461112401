import "./Billing.css";

import { CardElement, CardElementProps } from "@stripe/react-stripe-js";
import {
  Stripe,
  StripeElementClasses,
  StripeElements,
  StripeElementStyle,
  Token,
} from "@stripe/stripe-js";
import { ReactNode } from "react";

import { STICKY_STUDIO_PLUS_PRICE } from "~/config";

import * as Icons from "./Icons";
import { Box } from "./Box";
import { ExternalLink } from "./Links";

export type BillingTier = "free" | "pro";
export type BillingPlan = "free" | "plus";

export interface BillingSelectTierProps {
  value: BillingTier;
  onChange(value: BillingTier): void;
}

export let LeafIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="var(--green-4)"
  >
    <path d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z" />
  </svg>
);

let Check = () => <Icons.Check color="var(--blue-3)" strokeWidth={3} />;

/**
 * Stripe Elements live inside iframes, so we can't style them with
 * regular CSS.
 */
let style: StripeElementStyle = {
  base: {
    fontFamily: "system-ui",
    fontSize: "16px",
  },
};

let classes: StripeElementClasses = {
  base: "billing-card-input",
  complete: "billing-card-input-complete",
  empty: "billing-card-input-empty",
  focus: "billing-card-input-focus",
  invalid: "billing-card-input-invalid",
};

export interface BillingCardInputProps extends CardElementProps {
  disabled?: boolean;
}

export let BillingCardInput = ({
  options,
  disabled,
  ...props
}: BillingCardInputProps) => (
  <CardElement
    {...props}
    options={{
      style,
      classes,
      disabled,
      ...options,
    }}
  />
);

export interface BillingCardPreviewProps {
  brand: string | undefined;
  expiryMonth: number | undefined;
  expiryYear: number | undefined;
  last4Digits: string | undefined;
}

export let BillingCardPreview = ({
  brand,
  expiryMonth,
  expiryYear,
  last4Digits,
}: BillingCardPreviewProps) => (
  <div className="billing-card-preview">
    <Box direction="row" align="center" gap={4}>
      <div className="billing-card-preview-brand">
        <Icons.CreditCard strokeWidth={3} /> {brand}
      </div>
      <div className="billing-card-preview-number">
        **** **** **** {last4Digits}
      </div>
      <div className="billing-card-preview-expiry">
        Exp. {expiryMonth} / {expiryYear}
      </div>
    </Box>
  </div>
);

export let BillingErrorMessage = ({ children }: { children: ReactNode }) => (
  <Box
    className="billing-error-message"
    direction="row"
    align="center"
    justify="center"
    gap={2}
  >
    <Icons.AlertTriangle /> {children}
  </Box>
);

/**
 * Create a Stripe token from a <CardInput /> component.
 *
 * @param stripe The current Stripe instance.
 * Can be retrieved with `useStripe`.
 *
 * @param elements The current Stripe Elements instance.
 * Can be retrieved with `useElements`.
 *
 * @param component The Stripe Elements component to create the token from.
 * If you're using `BillingCardInput` then you don't need to pass
 * this parameter.
 */
export async function createTokenFromElement(
  stripe: Stripe | null,
  elements: StripeElements | null,
  component = CardElement
): Promise<Token> {
  if (!stripe || !elements) {
    throw new Error("Couldn't find Stripe context!");
  }

  let cardElement = elements.getElement(component);

  if (!cardElement) {
    throw new Error("Couldn't find card input!");
  }

  let { token, error } = await stripe.createToken(cardElement);

  if (error) {
    throw new Error(error.message);
  }

  return token!;
}

export let BillingStatusMessage = ({ children }: { children: ReactNode }) => (
  <Box className="billing-status-message" gap={2}>
    {children}
  </Box>
);

export let BillingStatusMessageTitle = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Box
    className="billing-status-message-title"
    direction="row"
    gap={1}
    align="center"
  >
    {children}
  </Box>
);

export let BillingStatusMessageBody = ({
  children,
}: {
  children: ReactNode;
}) => <div className="billing-status-message-body">{children}</div>;

export let BillingStatusPastDue = () => (
  <BillingStatusMessage>
    <BillingStatusMessageTitle>
      <Icons.AlertTriangle /> Your payment is past due
    </BillingStatusMessageTitle>
    <BillingStatusMessageBody>
      Update your payment details to continue using Sticky Studio.
    </BillingStatusMessageBody>
  </BillingStatusMessage>
);

export let BillingStatusUnpaid = () => (
  <BillingStatusMessage>
    <BillingStatusMessageTitle>
      <Icons.AlertTriangle /> We weren't able to take a payment
    </BillingStatusMessageTitle>
    <BillingStatusMessageBody>
      Update your payment details to continue using Sticky Studio.
    </BillingStatusMessageBody>
  </BillingStatusMessage>
);

export let BillingStatusAlreadyPlus = () => (
  <BillingStatusMessage>
    <BillingStatusMessageTitle>
      <Icons.AlertTriangle /> We weren't able to take a payment
    </BillingStatusMessageTitle>
    <BillingStatusMessageBody>
      Update your payment details to continue using Sticky Studio.
    </BillingStatusMessageBody>
  </BillingStatusMessage>
);

export let StickyStudioPlusHeading = () => (
  <span>
    <Icons.Logo width={24} height={24} /> <strong>Sticky Studio</strong>{" "}
    <strong style={{ color: "var(--yellow-3)" }}>Plus</strong>
  </span>
);

export let StickyStudioPlusPricing = () => (
  <span>
    <strong>${STICKY_STUDIO_PLUS_PRICE}</strong> / month
  </span>
);

export let StickyStudioPlusAlready = () => (
  <Box className="sticky-studio-plus-already">
    You have Sticky Studio Plus. Hi-five 👏
  </Box>
);

export let StickyStudioPlusFeatures = () => (
  <Box gap={4}>
    <div>
      <strong>
        Unlimited boards <Check />
      </strong>
      <p>Create as many boards as you need</p>
    </div>
    <div>
      <strong>
        Invites & Permissions <Check />
      </strong>
      <p>Fine grained control over board access</p>
    </div>
    <div>
      <strong>
        Comments <Check />
      </strong>
      <p>Keep the conversation going with comment threads</p>
    </div>
    <div>
      <strong>
        Exports <Check />
      </strong>
      <p>Export boards to PDF, SVG, and Kumu</p>
    </div>
    <div>
      <strong>
        Templates <Check />
      </strong>
      <p>Skip the setup with template boards</p>
    </div>
    <div>
      <strong>
        Carbon Removal <LeafIcon />
      </strong>
      <p>
        5% of our revenue goes to{" "}
        <ExternalLink href="https://stripe.com/en-gb-us/climate">
          carbon removal programs
        </ExternalLink>
      </p>
    </div>
  </Box>
);
