import "./MouseEvents.css";

import { useGesture } from "@use-gesture/react";
import React, { useRef } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector, useStore } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Connection from "~/store/bundles/Connection";
import * as Sticker from "~/store/bundles/Sticker";
import * as Sticky from "~/store/bundles/Sticky";
import * as User from "~/store/bundles/User";
import * as Create from "~/store/traits/Create";
import * as Delete from "~/store/traits/Delete";
import * as Edit from "~/store/traits/Edit";
import * as Grab from "~/store/traits/Grab";
import * as Resize from "~/store/traits/Resize";
import * as Rectangle from "~/util/geometry";
import uid from "~/util/uid";
import { useTouchGestures } from "~/util/useGestures";

interface MouseEventsProps {
  children: React.ReactNode;
}

const MouseEvents = ({ children }: MouseEventsProps) => {
  let elementRef = useRef<HTMLDivElement>(null);
  let dispatch = useDispatch();
  let store = useStore();
  let userId = useSelector(Client.getUserId);
  let scene = useSelector(Client.getScene);
  let user = useSelector(User.getWithoutCursor(userId));
  let state = store.getState();

  let creating = useSelector(Create.getCreatingBy(userId));
  let creatingConnections = useSelector(Connection.getCreatingBy(userId));

  let grabbing = useSelector(Grab.getGrabbingBy(userId));

  let grabbedConnections = useSelector(Connection.getGrabbingBy(userId));
  let grabbedStickers = useSelector(Grab.getGrabbingBy(userId)).filter(
    Sticker.isSticker
  );

  let selectionArea = useSelector(Client.getSelectionArea);

  let resizing = useSelector(Resize.getResizingBy(userId));

  const { onTouchStart, onTouchEnd, onTouchMove, isPinching } =
    useTouchGestures({
      onPinch({ delta, x, y }) {
        let scaleBy = 1 - 0.03 * Math.sign(delta);
        dispatch(Client.scale(scaleBy, { x, y }));
      },
    });

  useGesture(
    {
      onPointerMove({ event }) {
        // Prevent any panning/movement whilst we are zooming to ensure that the
        // scene doesn't jump around.
        if (isPinching) return;

        let position = { x: event.clientX, y: event.clientY };

        let resizeFromCenter = event.altKey;
        let resizeLockAspectRatio = event.shiftKey;
        let positionSnap = event.shiftKey;

        dispatch(Client.moveCursor(position));

        if (scene.transform === "translate") {
          dispatch(Client.pan(position));
          return;
        }

        if (selectionArea) {
          dispatch(Client.updateSelectionArea(position));
        }

        if (user) {
          dispatch(User.moveCursor(user.id, position));
        }

        if (grabbing.length > 0)
          dispatch(
            Grab.moveMany(
              grabbing.map((obj) => obj.id),
              position,
              {
                snap: positionSnap,
              }
            )
          );

        resizing.forEach((object) => {
          dispatch(
            Resize.resize(object.id, position, {
              fromCenter: resizeFromCenter,
              lockRatio: resizeLockAspectRatio,
            })
          );
        });

        grabbedConnections.forEach((connection) => {
          dispatch(Connection.reshape(connection.id, position));
        });
      },
    },
    {
      target: elementRef,
    }
  );

  const onPointerUp = (event: React.PointerEvent) => {
    event.stopPropagation(); // Don't trigger document-level events

    dispatch(Client.finishPanning());

    dispatch(Grab.finishGrabbingMany(grabbing.map((obj) => obj.id)));

    for (const connection of grabbedConnections) {
      dispatch(Connection.finishReshaping(connection.id));
    }

    for (let object of creating) {
      if (!isValidTarget(event)) {
        dispatch(Create.finishCreating(object.id));
        dispatch(Delete.deleteObject(object.id));
      } else {
        dispatch(Create.finishCreating(object.id));
      }
    }

    resizing.forEach(({ id }) => {
      dispatch(Resize.finishResizing(id));
    });

    grabbedStickers.forEach(({ id, createdAt }) => {
      let underneath = Sticker.getUnderneath(id)(store.getState());

      if (underneath.length !== 0) {
        dispatch(Sticky.addSticker(underneath.slice(-1)[0].id, id));
        dispatch(Grab.finishGrabbing(id));
      } else {
        if (!createdAt) {
          toast.error("Oops! Try dropping that on a sticky");
        }

        dispatch(Delete.deleteObject(id)(state));
      }
    });

    creatingConnections.forEach(({ id }) => {
      let stickyId = uid();
      let position = { x: event.clientX, y: event.clientY };
      dispatch(Sticky.add({ id: stickyId, mousePosition: position }));
      dispatch(Connection.setToId(id, stickyId));
      dispatch(Create.finishCreating(id, { to: stickyId }));
      dispatch(Edit.startEditing(stickyId, userId));
    });

    dispatch(Client.mouseUp);
  };

  const onPointerDownCapture = () => {
    dispatch(Client.mouseDown());
  };

  return (
    <div
      ref={elementRef}
      className="mouse-events"
      onPointerDownCapture={onPointerDownCapture}
      onPointerUp={onPointerUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
    >
      {children}
    </div>
  );
};

function isValidTarget(event: React.PointerEvent) {
  const el = document.getElementById("board-dock");
  if (!el) return true;

  const bounds = el.getBoundingClientRect();
  const rect = Rectangle.getBoundingRectFromDOMRect(bounds);
  const position = { x: event.clientX, y: event.clientY };

  return !Rectangle.containsPoint(rect, position);
}

export default MouseEvents;
