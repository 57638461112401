export const COLORS = [
  "#EF4444", // red-50
  "#F97316", // orange-50
  "#F59E0B", // amber-50
  "#FACC15", // yellow-40
  "#22C55E", // green-50
  "#0EA5E9", // light-blue-50
  "#818CF8", // indigo-40
];

export const NAMES = [
  "Bruce Wayne",
  "Peter Parker",
  "Clark Kent",
  "Bruce Banner",
  "Tony Stark",
  "Natalia Romanova",
  "Diana Prince",
  "Benjamin Krupp",
];
