import Bugsnag from "@bugsnag/browser";

import * as _config from "~/config";

import uid from "./uid";

type Environment = "production" | "development" | "test";

export type Provider = "cloudinary";

const configs = {
  production: {
    name: "sticky-studio",
    endpoint: "https://api.cloudinary.com/v1_1/sticky-studio/upload",
    preset: "sticky-studio",
  },
  development: {
    name: "sticky-studio",
    endpoint: "https://api.cloudinary.com/v1_1/sticky-studio/upload",
    preset: "sticky-studio",
  },
  test: {
    name: "sticky-studio",
    endpoint: "https://api.cloudinary.com/v1_1/sticky-studio/upload",
    preset: "sticky-studio",
  },
};

const config = configs[(_config.NODE_ENV as Environment) ?? "development"];

const cloudinary = async (file: File, boardId: string) => {
  let publicId = `${boardId}/${uid()}`;

  try {
    return uploadFile(file, publicId);
  } catch (err: any) {
    Bugsnag.notify(err);
    return "";
  }
};

export const uploadFile = async (file: File, publicId: string = uid()) => {
  let data = new FormData();
  data.append("file", file);
  data.append("upload_preset", config.preset);
  data.append("public_id", publicId);

  let response = await fetch(config.endpoint, { method: "POST", body: data });

  if (response.status !== 200) throw new Error("Could not upload file.");

  return (await response.json()).secure_url;
};

export default cloudinary;
