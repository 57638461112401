import "./AlertDialog.css";

import { Dialog } from "@headlessui/react";
import { ReactNode } from "react";
import { render, unmountComponentAtNode } from "react-dom";

import { Box } from "./Box";
import { Button, ButtonProps } from "./Button";

export interface AlertDialogProps {
  open: boolean;
  onClose(value: boolean): void;
  children: ReactNode;
}

export let AlertDialog = ({ open, onClose, children }: AlertDialogProps) => (
  <Dialog open={open} onClose={onClose} className="alert-dialog-backdrop">
    <Dialog.Panel className="alert-dialog">{children}</Dialog.Panel>
  </Dialog>
);

export let AlertDialogTitle = ({ children }: { children: ReactNode }) => (
  <Dialog.Title className="alert-dialog-title">{children}</Dialog.Title>
);

export let AlertDialogDescription = ({ children }: { children: ReactNode }) => (
  <Dialog.Description className="alert-dialog-description" as="div">
    {children}
  </Dialog.Description>
);

export let AlertDialogActions = ({ children }: { children: ReactNode }) => (
  <Box className="alert-dialog-actions" justify="end" direction="row" gap={2}>
    {children}
  </Box>
);

interface ConfirmParams {
  title: ReactNode;
  description: ReactNode;
  okButtonText?: ReactNode;
  okButtonVariant?: ButtonProps["variant"];
  cancelButtonText?: ReactNode;
  cancelButtonVariant?: ButtonProps["variant"];
}

export let confirm = ({
  title = "Are you sure?",
  description = "",
  okButtonText = "Ok",
  cancelButtonText = "Cancel",
  okButtonVariant = "danger",
  cancelButtonVariant = "grey",
}: ConfirmParams): Promise<boolean> => {
  let root = document.createElement("div");
  root.className = "confirm";
  document.body.append(root);

  return new Promise((resolve) => {
    let ok = () => {
      resolve(true);
      unmount();
    };

    let cancel = () => {
      resolve(false);
      unmount();
    };

    let unmount = () => {
      unmountComponentAtNode(root);
      document.body.removeChild(root);
    };

    let dialog = (
      <AlertDialog open={true} onClose={cancel}>
        <AlertDialogTitle>{title}</AlertDialogTitle>
        <AlertDialogDescription>{description}</AlertDialogDescription>
        <AlertDialogActions>
          <Button variant={cancelButtonVariant} onClick={cancel}>
            {cancelButtonText}
          </Button>
          <Button variant={okButtonVariant} onClick={ok}>
            {okButtonText}
          </Button>
        </AlertDialogActions>
      </AlertDialog>
    );

    render(dialog, root);
  });
};
