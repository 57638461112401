import { orderBy } from "lodash";
import { Middleware, Dispatch } from "redux";
import { createSelector } from "reselect";

import * as Client from "~/store/bundles/Client";
import { Action, AppState } from "~/store";
import { BaseAction } from "~/store/shared";
import {
  BaseObject,
  Connect,
  Position,
  Alive,
  Lock,
  Delete,
  Create,
  Resize,
  Grab,
  Edit,
} from "~/store/traits";
import assert from "~/util/assert";
import { filterObject } from "~/util/filterObject";
import { Point } from "~/util/geometry";
import { createShallowEqualSelector } from "~/util/selectors";

export type Style = "title" | "heading" | "paragraph";

/**
 * Interface that defines a `Label` object.
 */
export interface Label
  extends BaseObject.BaseObject,
    Position.Position,
    Grab.Grab,
    Create.Create,
    Edit.Edit,
    Delete.Delete,
    Resize.Resize,
    Lock.Lock,
    Alive.Alive,
    Connect.Connect {
  type: "label";
  style: Style;
}

/**
 * Type guard for narrowing a `BaseObject` type to a `Label`.
 *
 * @param {BaseObject.BaseObject} object - The object whose type we want to
 * narrow.
 * @returns {boolean} Whether or not the object is of type `Label`.
 */
export const isLabel = (object: BaseObject.BaseObject): object is Label => {
  return object.type === "label";
};

/**
 * Swap out any IDs according to a provided lookup table.
 *
 * @param {Label} label - The label object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Label} The modified label with ids replaced.
 */
const swapIds = (label: Label, lookup: (id: string) => string): Label => {
  return {
    ...label,
    id: lookup(label.id),
    creating: label.creating ? lookup(label.creating) : null,
    grab: label.grab
      ? { ...label.grab, userId: lookup(label.grab.userId) }
      : null,
    editing: label.editing ? lookup(label.editing) : null,
    resize: label.resize
      ? { ...label.resize, userId: lookup(label.resize.userId) }
      : null,
  };
};

/**
 * Remove any secondary information that we wouldn't want to appear, in a
 * template/fork.
 *
 * @param {Label} label - Label to convert into a template
 * @returns {Label} Template created from the provided `Label` object.
 */
export const template = (label: Label): Label => ({
  ...label,
  creating: null,
  grab: null,
  resize: null,
  editing: null,
  keepAlive: null,
});

/**
 * Fork a label by swapping out ids using a provided lookup table.
 *
 * @param {Label} label - The label object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Label} The modified label with ids replaced.
 */
export const fork = swapIds;

/**
 * Union of all possible `Label` actions.
 */
export type LabelAction = AddAction | SetStyleAction;

export interface AddAction extends BaseAction {
  type: "label/add";
  data: Label;
}

/**
 * Type that describes the named arguments for adding a label.
 */
interface AddArgs {
  /**
   * The id for the to-be-added label.
   */
  id: string;
  /**
   * The mouse position to be used for placing
   */
  mousePosition: Point;
  /**
   * The width of the label.
   */
  width: number;
  /**
   * The height of the label.
   */
  height: number;
}

/**
 * Action creator for adding a label to the app state.
 *
 * @param {AddArgs} args - The parameters for the label.
 * @returns The action thunk to be dispatched to the store.
 */
export const add =
  ({ id, mousePosition, width, height }: AddArgs) =>
  (state: AppState): AddAction => {
    let scene = Client.getScene(state);
    let scenePosition = Client.toSceneCoordinates(scene, mousePosition);
    let currentLabelStyle = Client.getCurrentLabelStyle(state);

    let position: Point = {
      x: scenePosition.x - width / 2,
      y: scenePosition.y - height / 2,
    };

    return {
      type: "label/add" as const,
      data: {
        id,
        type: "label",
        style: currentLabelStyle,
        editing: null,
        creating: null,
        resize: null,
        position,
        width,
        height,
        content: "",
        draft: "",
        keepAlive: null,
        locked: false,
        deleted: false,
        grab: null,
      },
      effects: {
        broadcast: true,
        persist: true,
      },
    };
  };

/**
 * Reducer that processes any `Label` actions.
 *
 * @param {AppState["objects"]} [state] - The current `objects` state.
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["objects"]} The updated state slice.
 */
export const reducer = (
  state: AppState["objects"] = {},
  action: Action
): AppState["objects"] => {
  switch (action.type) {
    case "label/add":
      return { ...state, [action.data.id]: action.data };

    case "label/set_style": {
      const object = state[action.data.id];
      assert(isLabel(object), "Object is not a Label.");

      return { ...state, [object.id]: { ...state[object.id], ...action.data } };
    }

    default:
      return state;
  }
};

/**
 * Redux middleware that is used to intercept general actions to inject `Label`
 * specific behavior.
 *
 * @param  store - The redux store object
 * @param  next - The next redux middleware in the middleware chain.
 * @param  action - The dispatched action we're intercepting
 * @returns The return value of the `next` middleware.
 */
export const middleware: Middleware<{}, AppState, Dispatch<any>> =
  ({ dispatch, getState }) =>
  (next) =>
  (action: Action) => {
    let additionalActions: Action[] = [];
    const state = getState();

    // Dispatch any additional behaviors
    switch (action.type) {
      /*
       * When a label finishes being created, start editing it.
       */
      case "create/finish_creating": {
        const label = getById(action.data.id)(state);

        if (label?.creating) {
          additionalActions.push(
            Edit.startEditing(action.data.id, label.creating, "canvas")(state)
          );
        }

        break;
      }

      /*
       * Release label whose `keepAlive` timer has expired.
       *
       * Unset the creating, grabbing, editing, and resizing properties.
       */
      case "alive/release_expired": {
        for (const id in action.data) {
          const label = getById(id)(state);
          if (!label || label.deleted) continue;

          if (label.grab)
            additionalActions.push(Grab.finishGrabbing(id)(state));

          if (label.resize)
            additionalActions.push(Resize.finishResizing(id)(state));

          if (label.editing)
            additionalActions.push(Edit.finishEditing(id)(state));

          if (label.creating)
            additionalActions.push(Create.finishCreating(id)(state));
        }

        break;
      }
    }

    // Process the actual action first
    const result = next(action);

    // Dispatch additional actions
    additionalActions.forEach(dispatch);

    // Return result of original action
    return result;
  };

/**
 * Selector that returns a record of all labels in the board state, keyed by
 * their id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, Label>} A record of all labels in the app state.
 */
export const getLabels = createShallowEqualSelector(
  BaseObject.getAll,
  (objects) => filterObject(objects, isLabel)
);

/**
 * Selector that returns an array of all labels, removing the deleted ones.
 *
 * @param {AppState} state - The current app state.
 * @returns An array of (non-deleted) labels.
 */
export const getAll = createSelector(getLabels, (labels) =>
  Object.values(labels).filter((l) => !l.deleted)
);

/**
 * Selector that returns an array of all undeleted labels ordered by date last updated.
 * Locked labels are placed below unlocked ones
 *
 * @param {AppState} state - The current app state.
 * @returns An ordered array of (non-deleted) labels.
 */
// Even though we already use z-index on the board to place locked objects below
// we stille need to do it in the ordering because the SVG renderer can't use z-index
// and selected object all receive the same z-index so this is required to preserve the
// ordering within a selection.
export const getAllOrdered = createSelector(getAll, (labels) =>
  orderBy(labels, ["locked", "updatedAt"], ["desc", "asc"])
);

/**
 * Selector creator for getting the label with a given id.
 *
 * @param {string} id - The id of the label we want to get
 * @returns Selector that returns the label.
 */
export const getById = (id: string) =>
  createSelector(getLabels, (labels) => labels[id]);

/**
 * Selector creator that returns a label's `style` field.
 *
 * @param {string} id - The id of the label we're interested in.
 * @returns Selector that returns the value of the label's `style` field.
 */
export const getStyle = (id: string) =>
  createSelector(getById(id), (label) => label.style ?? "heading");

export interface SetStyleAction extends BaseAction {
  type: "label/set_style";
  data: Pick<Label, "id" | "style">;
}

/**
 * Action creator for setting the style of a label.
 *
 * @param {string} id - The id of the label whose style we wish to set.
 * @param {Style} style - The style parameter to set.
 * @returns {SetStyleAction} The action to dispatch to the store.
 */
export const setStyle = (id: string, style: Style): SetStyleAction => {
  return {
    type: "label/set_style",
    data: { id, style },
    effects: {
      broadcast: true,
      persist: true,
      dispatch: [Client.setCurrentLabelStyle(style)],
    },
  };
};
