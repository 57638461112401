import "./Label.css";

import React from "react";
import { useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Comments from "~/store/bundles/Comments";
import * as Labels from "~/store/bundles/Label";
import { BoardType, useConfig } from "~/context/ConfigContext";
import { classNames } from "~/util/classNames";
import usePermission from "~/util/usePermission";

import { ControlOverlay, Control } from "./ControlOverlay";
import { OpenComments } from "./OpenComments";
import { ConnectTarget } from "./shared/ConnectTarget";
import { ConnectionAnchors } from "./shared/ConnectionAnchors";
import { ContextMenuTarget } from "./shared/ContextMenuTarget";
import { EditTarget } from "./shared/EditTarget";
import { EditableText } from "./shared/EditableText";
import { GrabTarget } from "./shared/GrabTarget";
import { ObjectElements } from "./shared/ObjectElements";
import { Position } from "./shared/Position";
import ResizeHandle from "./shared/ResizeHandle";
import { SelectTarget } from "./shared/SelectTarget";
import { ShowcaseTarget } from "./shared/ShowcaseTarget";
import { useHoverTarget } from "./shared/useHoverTarget";

export const Label = React.memo(({ label }: { label: Labels.Label }) => {
  const userId = useSelector(Client.getUserId);
  let isSelected = useSelector(Client.getIsSelected(label.id));
  let labelStyle = useSelector(Labels.getStyle(label.id));
  let { position, width, height, locked } = label;
  const can = usePermission();
  const isGrabbing = userId === label.grab?.userId;
  const isResizing = !!label.resize;
  const isCreating = !!label.creating;
  const isTemplate = useConfig().type === BoardType.Template;
  const isEditing = !!label.editing;
  const comments = useSelector(Comments.getByParentId(label.id));
  const hasComments = comments.length > 0;
  const isUserBusy = useSelector(Client.getUserIsBusy(userId));
  const { hover, HoverTarget } = useHoverTarget(label.id);

  let className = classNames(
    "label__wrapper",
    `label--${labelStyle}`,
    isGrabbing ? "cursor-grabbing grabbing" : locked ? "locked" : "cursor-grab",
    isSelected && "selected rounded ring-8 ring-sky-400",
    !label.content ? "text-grey-400" : ""
  );

  const style = {
    transform: `translate(${position.x}px, ${position.y}px)`,
    width: `${width}px`,
    height: `${height}px`,
  };

  return (
    <div className={className} style={style}>
      <HoverTarget>
        <ConnectTarget id={label.id} style={{ width, height }}>
          <EditTarget id={label.id}>
            <SelectTarget id={label.id}>
              <GrabTarget id={label.id}>
                <ContextMenuTarget id={label.id}>
                  <ShowcaseTarget id={label.id} userId={label.createdBy}>
                    <EditableText
                      id={label.id}
                      className={`label__label`}
                      defaultValue="Type here"
                      height={height}
                      disabled={isUserBusy}
                    />
                  </ShowcaseTarget>
                </ContextMenuTarget>
              </GrabTarget>
            </SelectTarget>
          </EditTarget>

          <ObjectElements>
            {can("edit", label) &&
              !isResizing &&
              !isEditing &&
              hover &&
              !locked && <ConnectionAnchors objectId={label.id} />}

            {!isTemplate && !isCreating && (
              <ControlOverlay>
                <Control visible={hover || hasComments} style={style}>
                  <Position region="top-right">
                    <OpenComments
                      id={label.id}
                      commentCount={comments.length}
                    />
                  </Position>
                </Control>
              </ControlOverlay>
            )}

            {can("edit", label) && isSelected && !locked && (
              <>
                <Position region="top-right">
                  <ResizeHandle id={label.id} corner="top-right" />
                </Position>
                <Position region="top-left">
                  <ResizeHandle id={label.id} corner="top-left" />
                </Position>
                <Position region="bottom-right">
                  <ResizeHandle id={label.id} corner="bottom-right" />
                </Position>
                <Position region="bottom-left">
                  <ResizeHandle id={label.id} corner="bottom-left" />
                </Position>
              </>
            )}
          </ObjectElements>
        </ConnectTarget>
      </HoverTarget>
    </div>
  );
});

Label.displayName = "Label";
