import { ReactNode } from "react";
import ReactDOM from "react-dom";

type Props = {
  id: string;
  children: ReactNode;
};

/**
 * Expects an element with the provided id to be defined
 * in the DOM.
 */
export const Overlay = ({ id, children }: Props) => {
  let root = document.getElementById(id);
  if (!root) return null;
  return ReactDOM.createPortal(children, root);
};
