import "./Dashboard.css";

import { matchSorter } from "match-sorter";
import { useState, ReactNode } from "react";
import {
  Link,
  NavLink,
  NavLinkProps,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useAccount, useAuth } from "~/context/AuthContext";
import { useQuery } from "~/hooks/useQuery";
import {
  getBoardsByAccount,
  getTemplatesByAccount,
  BoardData,
  FeaturedTemplateData,
} from "~/util/BoardClient";
import { classNames } from "~/util/classNames";
import { useHasReachedBoardLimit } from "~/util/hooks";

import * as Icons from "./Icons";
import { BoardInfoEditor, BoardInfoEditorModal } from "./BoardInfoEditor";
import { Board, BoardList, BoardListItem } from "./BoardList";
import { Box } from "./Box";
import { Button, IconButton } from "./Button";
import { DashboardMenu, menu } from "./DashboardMenu";
import {
  FeaturedTemplateList,
  FeaturedTemplateListItem,
} from "./FeaturedTemplateList";
import { Panel, PanelStack } from "./Panel";
import { StickyStudioPlus } from "./StickyStudioPlus";
import { useIsPlus } from "./StickyStudioPlus";
import { TextInput, TextInputProps } from "./TextInput";

export let Dashboard = () => (
  <div className="dashboard">
    <Sidebar />
    <DashboardContent>
      <Outlet />
    </DashboardContent>
  </div>
);

export const DashboardSidebar = ({ children }: { children: ReactNode }) => (
  <div className="dashboard-sidebar">{children}</div>
);

interface DashboardSidebarSectionProps {
  grow?: boolean;
  children?: ReactNode;
}

export let DashboardSidebarSection = ({
  grow,
  children,
}: DashboardSidebarSectionProps) => (
  <div
    className={classNames(
      "dashboard-sidebar-section",
      grow && "dashboard-sidebar-section-grow"
    )}
  >
    {children}
  </div>
);

export let DashboardSidebarHeading = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Box
    className="dashboard-sidebar-heading"
    direction="row"
    align="center"
    gap={2}
  >
    {children}
  </Box>
);

interface DashboardSidebarLinkProps extends NavLinkProps {
  highlight?: boolean;
}

export let DashboardSidebarLink = ({
  highlight,
  ...props
}: DashboardSidebarLinkProps) => (
  <NavLink
    {...props}
    className={classNames(
      "dashboard-sidebar-navlink",
      highlight && "dashboard-sidebar-navlink-highlight"
    )}
  />
);

export let DashboardComingSoon = () => (
  <div className="dashboard-coming-soon dashboard-sidebar-navlink">
    <Icons.Clock /> Coming Soon
  </div>
);

export let DashboardContent = ({ children }: { children: ReactNode }) => (
  <div className="dashboard-content">{children}</div>
);

export let DashboardPage = ({ children }: { children: ReactNode }) => (
  <Box gap={2}>
    <DashboardHeader />
    <div className="dashboard-page">{children}</div>
  </Box>
);

export let DashboardSidebarTitle = () => {
  let account = useAccount();

  return (
    <>
      <Icons.Logo width={24} height={24} /> Sticky Studio
      {account?.plan === "plus" && (
        <span style={{ color: "var(--yellow-3)" }}> Plus</span>
      )}
    </>
  );
};

export let DashboardBoardsView = () => {
  let [search, setSearch] = useState("");
  let location = useLocation();
  let auth = useAuth();
  let filterAllBoards = (board: Board) => true;
  let filterMyBoards = (board: Board) => board.createdBy === auth.account?.id;
  let filter =
    location.pathname === "/boards/own" ? filterMyBoards : filterAllBoards;
  let { data: response, refetch } = useQuery(() =>
    getBoardsByAccount(auth.account!.id)
  );

  let boards = response?.status === "success" ? response.data : [];
  let hasReachedBoardLimit = useHasReachedBoardLimit();

  if (boards.length === 0 && response) {
    return (
      <DashboardPage>
        <DashboardBlankSlate />
      </DashboardPage>
    );
  }

  let results = boards.filter(filter).sort((a, b) => {
    // Invited boards should always show up first
    if (a.invite) return -Infinity;
    if (b.invite) return +Infinity;

    // Otherwise, sort them by most recently visited
    return Date.parse(b.lastVisitedAt!) - Date.parse(a.lastVisitedAt!);
  });

  if (search) {
    results = matchSorter(boards, search, {
      keys: [
        "name",
        "description",
        // Add a resolved value of "template" for templates
        // so searches for the term "template" include them.
        (item) => (item.template ? "template" : ""),
        // Add a resolved value of "invited" for invited boards
        // so searches for the term "invited" include them.
        (item) => (item.invite ? "invited" : ""),
      ],
    });
  }

  return (
    <Box grow gap={3}>
      <DashboardHeader>
        <Box gap={2} direction="row" justify="between" grow>
          <DashboardSearchInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Link className="dashboard-new-link" to="/boards/new">
            <Button
              variant="primary"
              disabled={hasReachedBoardLimit}
              data-cy="new-board-button"
            >
              <Icons.Plus strokeWidth={4} /> New Board
            </Button>
          </Link>
        </Box>
      </DashboardHeader>

      {hasReachedBoardLimit && <DashboardBoardLimitReached />}

      {results.length === 0 ? (
        <DashboardNoSearchResults search={search} />
      ) : (
        <BoardList>
          {results.map((board) => (
            <BoardListItem
              key={board.id}
              board={board}
              onDelete={refetch}
              onUpdate={refetch}
            />
          ))}
        </BoardList>
      )}
    </Box>
  );
};

export let DashboardTemplatesView = () => {
  let auth = useAuth();
  let { data: response, refetch } = useQuery(() =>
    getTemplatesByAccount(auth.account!.id)
  );

  let { templates, featured } =
    response?.status === "success"
      ? response.data
      : {
          templates: [],
          featured: [],
        };

  return (
    <Box grow gap={3}>
      <DashboardHeader />
      <DashboardFeaturedTemplates templates={featured} />
      {useIsPlus() ? (
        <DashboardUserTemplatesView templates={templates} onRefetch={refetch} />
      ) : (
        <Box direction="column" align="center" justify="center" gap={4} grow>
          <Box align="center" justify="center">
            Upgrade to <StickyStudioPlus /> to create your own templates!
          </Box>
        </Box>
      )}
    </Box>
  );
};

export let DashboardFeaturedTemplates = ({
  templates,
}: {
  templates: FeaturedTemplateData[];
}) => {
  // Sort by most recently created
  let results = templates.sort(
    (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
  );

  if (!results.length) return null;

  return (
    <FeaturedTemplateList>
      {results.map((template) => (
        <FeaturedTemplateListItem key={template.id} template={template} />
      ))}
    </FeaturedTemplateList>
  );
};

export let DashboardUserTemplatesView = ({
  templates,
  onRefetch,
}: {
  templates: BoardData[];
  onRefetch: () => void;
}) => {
  // Sort by most recently visited
  let results = templates.sort(
    (a, b) => Date.parse(b.lastVisitedAt!) - Date.parse(a.lastVisitedAt!)
  );

  if (results.length === 0) {
    return <DashboardNoTemplates />;
  }

  return (
    <BoardList>
      {results.map((board) => (
        <BoardListItem
          key={board.id}
          board={board}
          onDelete={onRefetch}
          onUpdate={onRefetch}
        />
      ))}
    </BoardList>
  );
};

export let DashboardBlankSlate = () => {
  let navigate = useNavigate();

  return (
    <PanelStack group>
      <Panel className="dashboard-blank-slate-message">
        <Icons.Logo className="dashboard-blank-slate-logo" />
        <div className="dashboard-blank-slate-heading">Sticky Studio</div>
        <div className="dashboard-blank-slate-details">
          Get started by creating your first board.
        </div>
      </Panel>
      <Panel>
        <BoardInfoEditor onCreate={(boardId) => navigate(`/${boardId}`)} />
      </Panel>
    </PanelStack>
  );
};

export let DashboardSearchInput = (props: TextInputProps) => (
  <Box className="dashboard-search-input" direction="row">
    <Icons.Search />
    <TextInput
      variant="white"
      placeholder="Search boards"
      {...props}
      data-cy={"board-search-input"}
    />
  </Box>
);

export let DashboardNoSearchResults = ({ search }: { search?: string }) => {
  let hasReachedBoardLimit = useHasReachedBoardLimit();

  return (
    <Box
      className="dashboard-no-search-results"
      direction="column"
      align="center"
      justify="center"
      gap={4}
      grow
    >
      <div className="dashboard-no-results-heading">
        No results{" "}
        {search && (
          <>
            for "<strong>{search}</strong>"
          </>
        )}
      </div>
      {!hasReachedBoardLimit && (
        <Link to={`/boards/new?name=${encodeURIComponent(search!)}`}>
          <Button variant="primary" data-cy="create-board-button">
            Create Board
          </Button>
        </Link>
      )}
    </Box>
  );
};

export let DashboardNewBoardView = () => {
  let navigate = useNavigate();
  let backToBoards = () => navigate("/boards");
  let openBoard = (boardId: string) => navigate(`/${boardId}`);

  return (
    <BoardInfoEditorModal open onCreate={openBoard} onClose={backToBoards} />
  );
};

const DashboardBoardLimitReached = () => (
  <Panel variant="primary">
    <Box gap={4} direction="row" align="center" justify="between">
      <div>
        You'll need <StickyStudioPlus /> if you want to create more boards
      </div>
      <Link to="/account/billing">
        <Button variant="primary">Upgrade</Button>
      </Link>
    </Box>
  </Panel>
);

const DashboardNoTemplates = () => (
  <Box
    className="dashboard-no-search-results"
    direction="column"
    align="center"
    justify="center"
    gap={4}
    grow
  >
    <div className="dashboard-no-results-heading">No templates.</div>
    To create a template, create a board and then convert it to a template.
  </Box>
);

const Sidebar = () => {
  let account = useAccount();

  return (
    <div className="dashboard-sidebar">
      <DashboardSidebarSection>
        <Link to="/product" className="dashboard-sidebar-logo">
          <DashboardSidebarTitle />
        </Link>
      </DashboardSidebarSection>
      {menu.map((section, sectionIndex) => (
        <DashboardSidebarSection
          key={`section-${sectionIndex}`}
          grow={section.grow}
        >
          {section.title && (
            <DashboardSidebarHeading>{section.title}</DashboardSidebarHeading>
          )}
          {section.items?.map((item, itemIndex) => (
            <DashboardSidebarLink
              key={`section-${sectionIndex}-${itemIndex}`}
              className={item.className}
              to={item.to}
              data-cy={item.dataCy}
              end={item.end}
            >
              {item.before && item.before}
              {item.title}
              {item.after && item.after}
            </DashboardSidebarLink>
          ))}
        </DashboardSidebarSection>
      ))}

      {!account?.pro && (
        <DashboardSidebarSection>
          <Box as={Link} to="/account/billing">
            <Button variant="primary">
              <Icons.Logo /> Upgrade to Plus
            </Button>
          </Box>
        </DashboardSidebarSection>
      )}
    </div>
  );
};

export const DashboardHeader = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <Box className="dashboard-header" gap={2} direction="row">
      <DashboardMenu>
        <IconButton icon={<Icons.Menu />} aria-label="Menu" />
      </DashboardMenu>
      {children}
    </Box>
  );
};
