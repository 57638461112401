import "./ChangeLogList.css";

import { ReactNode } from "react";

import { Announcement } from "~/util/AnnouncementClient";

import { Box } from "./Box";

export let ChangeLogList = ({ children }: { children: ReactNode }) => (
  <div className="changelog-list">{children}</div>
);

export interface ChangeLogListItemProps {
  announcement: Announcement;
}

export let ChangeLogListItem = ({ announcement }: ChangeLogListItemProps) => {
  const { published, title } = announcement;

  const publishDate = published.toLocaleDateString("default", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <div className="changelog-list-item">
      <Box gap={1}>
        <div className="changelog-list-item-title">{publishDate}</div>
        <div className="changelog-list-item-subtitle">{title}</div>
      </Box>
      <div className="changelog-list-item-content">{announcement.content}</div>
    </div>
  );
};
