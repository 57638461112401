import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { DiscordInviteLink } from "~/components/DiscordInviteLink";
import { useBoardPath } from "~/util/hooks";

import { BoardModal } from "./BoardModal";
import { ShareLink } from "./BoardSharingOptions";
import { Box } from "./Box";
import {
  HelpCircle as HelpIcon,
  Mail as EmailIcon,
  MessageCircle as ChatIcon,
  Send,
} from "./Icons";
import { ModalDescription, ModalPanel, ModalProps, ModalTitle } from "./Modal";
import { TextWithLinks } from "./TextWithLinks";

let SupportEmail = () => (
  <a href="mailto:support@sticky.studio">support@sticky.studio</a>
);

let FeedbackLink = ({ children }: { children: ReactNode }) => (
  <Link to={`${useBoardPath()}/feedback`}>{children}</Link>
);

export let HelpModal = (props: ModalProps) => (
  <BoardModal {...props}>
    <ModalPanel className="help-modal" size="lg">
      <ModalTitle>
        <HelpIcon color="var(--grey-4)" /> Help
      </ModalTitle>
      <ModalDescription>
        <Box gap={4}>
          <TextWithLinks>
            <EmailIcon /> Email us at <SupportEmail /> and include a copy of
            your board link if you want us to take a look.
          </TextWithLinks>
          <TextWithLinks>
            <ChatIcon /> Prefer to chat in realtime? Join our{" "}
            <DiscordInviteLink>Discord server</DiscordInviteLink>!
          </TextWithLinks>
          <TextWithLinks>
            <Send /> Found something we could improve? Leave us some{" "}
            <FeedbackLink>feedback</FeedbackLink>!
          </TextWithLinks>
          <ShareLink />
        </Box>
      </ModalDescription>
    </ModalPanel>
  </BoardModal>
);
