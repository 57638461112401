import * as Circ from "~/util/geometry/circle";
import * as Rect from "~/util/geometry/rectangle";
import * as Vec from "~/util/geometry/vector";
import type { Connection } from "~/store/bundles/Connection";
import type { Point, Rectangle, Circle } from "~/util/geometry";

/**
 * Given two rectangles and a connection, return an svg path and any additional
 * data.
 */
export const getConnectionPath = (
  from: Rectangle,
  to: Rectangle,
  connection: Connection
) => {
  let c1 = Rect.getCenter(from);
  let c2 = Rect.getCenter(to);

  // Default to the new parameters, but fall back on the controlPoint if no
  // parameters are set.
  const curvature =
    connection.curvature ??
    (connection.controlPoint
      ? Circ.getCurvature(c1, connection.controlPoint, c2)
      : 1);

  const sense =
    connection.sense ??
    (connection.controlPoint
      ? Circ.getSense(c1, connection.controlPoint, c2)
      : 1);

  let circle = Circ.getFromCurvature(c1, c2, curvature);

  // Generate the parameters we need for svg path
  let { pi, pf } = getAttachmentPoints(from, to, circle, sense);

  // Find the direction of the arrow-head. We need to flip the tangent direction
  // depending on the handedness of the arc.
  let startDir = Vec.scale(Circ.getTangent(circle, pi), -sense);
  let endDir = Vec.scale(Circ.getTangent(circle, pf), sense);

  // Find the midway point along the connection (for placing a potential label)
  const midpoint = Circ.getSegmentMiddle(pi, pf, circle, sense);

  return {
    path: generatePath(pi, pf, circle, sense),
    start: pi,
    startAngle: Vec.angleFromDir(startDir),
    end: pf,
    endAngle: Vec.angleFromDir(endDir),
    midpoint,
  };
};

/**
 * Given two rectangles, a circle that passes through both centers, and a
 * traversal sense, return the attachment points where the circle intersects the
 * rectangles.
 */
export const getAttachmentPoints = (
  rect1: Rectangle,
  rect2: Rectangle,
  circle: Circle,
  sense: -1 | 1
) => {
  let { x: x1, y: y1 } = rect1.position;
  let { x: x2, y: y2 } = rect2.position;
  let c1 = { x: x1 + rect1.width / 2, y: y1 + rect1.height / 2 };
  let c2 = { x: x2 + rect2.width / 2, y: y2 + rect2.height / 2 };

  const orderPoints = (p1: Point, p2: Point) => {
    let phi1 = Vec.angle(Vec.vec(circle.c, c1), Vec.vec(circle.c, p1));
    let phi2 = Vec.angle(Vec.vec(circle.c, c1), Vec.vec(circle.c, p2));
    return -sense * (phi2 - phi1);
  };

  let intersections = [
    ...Circ.getRectIntersections(circle, rect1),
    ...Circ.getRectIntersections(circle, rect2),
  ];

  // We order the intersection points as we move along the circle from c1 to c2
  // (in the right chirality). The first two points we come across are the
  // intersection points we care about.
  let [p1, p2] = intersections.sort(orderPoints);
  return { pi: p1 ?? c1, pf: p2 ?? c2 };
};

/**
 * Given a circle, two limiting points and a traversal sense, return whether or
 * not the traversed segment is the larger circle segment.
 */
export const getLargeArc = (
  pi: Point,
  pf: Point,
  { c }: Circle,
  sense: -1 | 1
): 0 | 1 => {
  const chirality = Vec.handedness(Vec.vec(c, pi), Vec.vec(c, pf));
  return sense * chirality === 1 ? 0 : 1;
};

/**
 * Given a circle, two limiting points and a traversal sense, return the SVG path
 * string for the segment.
 */
export const generatePath = (
  pi: Point,
  pf: Point,
  circle: Circle,
  sense: -1 | 1
) => {
  const sweep = sense === 1 ? 1 : 0;
  const largeArc = getLargeArc(pi, pf, circle, sense);

  return `M ${pi.x} ${pi.y} A ${circle.r} ${circle.r} 0 ${largeArc} ${sweep} ${pf.x} ${pf.y}`;
};
