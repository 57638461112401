export * from "./vector";
export * from "./rectangle";
export * from "./circle";

/**
 * Clamp a given value between a min and max value
 */
export const clamp = (min: number, max: number, value: number): number => {
  return value <= min ? min : value >= max ? max : value;
};
