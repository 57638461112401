import "./Sticker.css";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Stickers from "~/store/bundles/Sticker";
import * as Sticky from "~/store/bundles/Sticky";
import * as User from "~/store/bundles/User";
import { Avatar } from "~/components/Avatar";
import { Emoji } from "~/components/Emoji";
import { classNames } from "~/util/classNames";
import { vec } from "~/util/geometry";

import { Box } from "./shared/Box";
import { ContextMenuTarget } from "./shared/ContextMenuTarget";
import { GrabTarget } from "./shared/GrabTarget";
import { ShowcaseTarget } from "./shared/ShowcaseTarget";

export const Sticker = React.memo(
  ({ sticker }: { sticker: Stickers.Sticker }) => {
    const dispatch = useDispatch();
    const { width, height } = sticker;
    const userId = useSelector(Client.getUserId);
    const recipient = useSelector(Stickers.getRecipient(sticker.id));

    const isOwner = userId === sticker.user;
    const isGrabbing = userId === sticker.grab?.userId;

    const onGrab = () => {
      if (recipient) {
        dispatch(Stickers.removeRecipient(sticker.id));
        dispatch(Sticky.removeSticker(recipient.id, sticker.id));
      }
    };

    // When placed on an object, the sticker position is taken to be relative
    // to that object.
    const position = recipient
      ? vec(recipient.position, sticker.position)
      : sticker.position;

    return (
      <Box
        className={`sticker absolute ${
          isGrabbing
            ? "grabbing cursor-grabbing"
            : isOwner
            ? "cursor-grab"
            : "cursor-default"
        }`}
        {...{ position, width, height }}
      >
        <MaybeGrabTarget when={isOwner} id={sticker.id} onGrab={onGrab}>
          <ContextMenuTarget id={sticker.id}>
            <ShowcaseTarget id={sticker.id} userId={sticker.user}>
              {Stickers.isReaction(sticker) ? (
                <ReactionSticker
                  shortcode={sticker.shortcode}
                  isOwner={isOwner}
                  isGrabbing={isGrabbing}
                />
              ) : Stickers.isAvatar(sticker) ? (
                <AvatarSticker
                  userId={sticker.avatarUserId}
                  isOwner={isOwner}
                  isGrabbing={isGrabbing}
                />
              ) : (
                <VoteSticker
                  color={sticker.color}
                  isOwner={isOwner}
                  isGrabbing={isGrabbing}
                />
              )}
            </ShowcaseTarget>
          </ContextMenuTarget>
        </MaybeGrabTarget>
      </Box>
    );
  }
);

interface StickerProps {
  isOwner: boolean;
  isGrabbing: boolean;
}

interface VoteStickerProps extends StickerProps {
  color: string;
}

export const VoteSticker = ({ isOwner }: VoteStickerProps) => (
  <div
    className={`
  vote absolute rounded-full
  min-w-full min-h-full
  bg-purple-700 ${isOwner ? "drop-shadow-md" : "cursor-default"}
  `}
  />
);

interface ReactionStickerProps extends StickerProps {
  shortcode: Stickers.Shortcode;
}

export const ReactionSticker = ({
  shortcode,
  isOwner,
}: ReactionStickerProps) => (
  <div
    className={`
  reaction absolute rounded-full
  flex items-center justify-center
  text-3xl
  min-w-full min-h-full
  bg-white ${isOwner ? "drop-shadow-md" : "cursor-default"}
  `}
  >
    <Emoji shortcode={shortcode} />
  </div>
);

interface AvatarStickerProps extends StickerProps {
  userId: string;
}

export const AvatarSticker = ({ userId, isOwner }: AvatarStickerProps) => {
  const user = useSelector(User.getById(userId));

  return (
    <Avatar
      className={classNames(
        "avatar absolute min-w-full min-h-full",
        isOwner && "drop-shadow-md"
      )}
      name={user.name}
      color={user.color}
      picture={user.picture}
    />
  );
};

// We only want to wrap the vote in a grab target if the user is the
// owner, otherwise, we just render the vote directly.
const MaybeGrabTarget = ({
  when,
  children,
  ...props
}: Parameters<typeof GrabTarget>[0] & { when: boolean }) => {
  return when ? (
    <GrabTarget {...props}>{children}</GrabTarget>
  ) : (
    <>{children}</>
  );
};

Sticker.displayName = "Sticker";
