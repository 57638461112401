import "./Anchor.css";

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Connection from "~/store/bundles/Connection";
import * as Create from "~/store/traits/Create";
import { ArrowUp, ArrowRight, ArrowDown, ArrowLeft } from "~/assets/icons";
import { FixedScale } from "~/components/FixedScale";
import { ShowAtScale } from "~/components/ShowAtScale";
import uid from "~/util/uid";

import { RoundButton } from "./RoundButton";

interface AnchorProps {
  className?: string;
  side: "top" | "right" | "bottom" | "left";
  objectId: string;
  showAtScale?: number;
}

export const Anchor = React.memo(
  ({ className, side, objectId, showAtScale }: AnchorProps) => {
    let dispatch = useDispatch();
    let userId = useSelector(Client.getUserId);

    const [status, setStatus] = useState("idle");

    const onPointerDown = (event: React.PointerEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (!userId) return;

      setStatus("ready");

      let connectionId = uid();

      dispatch(
        Connection.add({ id: connectionId, from: objectId, to: null, userId })
      );
      dispatch(Create.startCreating(connectionId, userId));
    };

    const onPointerLeave = () => {
      switch (status) {
        case "ready":
          setStatus("connecting");
          break;
        case "idle":
          // Do nothing
          break;
      }
    };

    const onPointerUp = (event: React.PointerEvent) => {
      event.stopPropagation();
      event.preventDefault();
    };

    const arrow = toArrow({ side });

    return (
      <div
        onPointerLeave={onPointerLeave}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        className={`
        ${className}
        object__anchor
        p-1 rounded-full ring-2 leading-none transition
        transition-opacity
        ${status === "ready" ? "ring-gray-500" : "ring-transparent"}
      `}
      >
        <ShowAtScale to={showAtScale}>
          <FixedScale min={0.5} max={1}>
            <RoundButton>{arrow}</RoundButton>
          </FixedScale>
        </ShowAtScale>
      </div>
    );
  }
);

Anchor.displayName = "Anchor";

function toArrow({ side }: { side: AnchorProps["side"] }) {
  switch (side) {
    case "top":
      return <ArrowUp className="h-5 w-5" />;
    case "right":
      return <ArrowRight className="h-5 w-5" />;
    case "bottom":
      return <ArrowDown className="h-5 w-5" />;
    case "left":
      return <ArrowLeft className="h-5 w-5" />;
  }
}

Anchor.displayName = "Anchor";
