import { ReactNode } from "react";

import { Point } from "~/util/geometry";

export const Box = ({
  position,
  width,
  height,
  className,
  children,
}: {
  position: Point;
  width: number;
  height: number;
  className?: string;
  children?: ReactNode;
}) => {
  return (
    <div
      className={className}
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        width: width,
        height: height,
        minWidth: width,
        minHeight: height,
      }}
    >
      {children}
    </div>
  );
};
