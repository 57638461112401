import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import * as Client from "../store/bundles/Client";

/**
 * Updates the url hash to a comma-delimited list
 * of selected object ids as the selection changes.
 */
export const useSelectionHash = () => {
  const selected = useSelector(Client.getSelected);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hash = getSelectionHash(selected);
    if (location.hash.slice(1) !== hash) {
      navigate(`${location.pathname}#${hash}`, { replace: true });
    }
  }, [location.pathname, location.hash, navigate, selected]);
};

/**
 * Parses the url hash into an array of strings,
 * removing the leading #.
 */
export const parseSelectionHash = (hash: string) => {
  return hash
    .replace(/^#/, "")
    .split(",")
    .filter((s) => s);
};

const getSelectionHash = (selection: string[]) => selection.sort().join(",");
