import { createSelector } from "reselect";

import type { Action, AppState } from "~/store";
import { Provider } from "~/store/middleware/upload";
import { BaseAction, BaseEffects } from "~/store/shared";
import { BaseObject } from "~/store/traits";
import assert from "~/util/assert";
import { filterObject } from "~/util/filterObject";
import { createShallowEqualSelector } from "~/util/selectors";

import * as Board from "./Board";

/**
 * Union type of all possible `Upload` states.
 */
export type Upload = UploadLoading | UploadSuccess | UploadFailed;

/**
 * Interface describing fields common to all `Upload` states.
 */
export interface BaseUpload extends BaseObject.BaseObject {
  type: "upload";
}

/**
 * Interface describing an `Upload` that is being processed.
 */
export interface UploadLoading extends BaseUpload {
  status: "loading";
  previewUrl: string;
}

/**
 * Interface describing an `Upload` that was successfully uploaded.
 */
export interface UploadSuccess extends BaseUpload {
  status: "success";
  url: string;
}

/**
 * Interface describing an `Upload` that failed to upload.
 */
export interface UploadFailed extends BaseUpload {
  status: "failed";
}

/**
 * Type guard for narrowing a `BaseObject`'s type to `Upload`.
 *
 * @param {BaseObject} object - The object whose type we  want to narrow.
 * @returns {boolean} Whether or not the object is an `Upload`.
 */
export const isUpload = (object: BaseObject.BaseObject): object is Upload => {
  return object.type === "upload";
};

/**
 * Swap out any IDs according to a provided lookup table
 */
/**
 * Swap out any IDs according to a provided lookup table.
 *
 * @param {Upload} upload - The upload object to be modified
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Upload} The modified vote with ids replaced.
 */
const swapIds = (upload: Upload, lookup: (id: string) => string): Upload => {
  return {
    ...upload,
    id: lookup(upload.id),
  };
};

/**
 * Remove any secondary information that we wouldn't want to appear, in a
 * template/fork.
 *
 * In the case of `Upload`, this is a no-op.
 *
 * @param {Upload} upload - The `Upload` to convert into a template.
 * @returns {Upload} Template created from the provided `Upload` object.
 */
export const template = (upload: Upload): Upload => upload;

/**
 * Fork an upload by swapping out ids using a provided lookup table.
 *
 * @param {Upload} upload - The upload object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with an `id` string and returns the string to replace it.
 * @returns {Upload} The modified upload with ids replaced.
 */
export const fork = swapIds;

/**
 * Union type of all possible `Upload` actions.
 */
export type UploadAction = AddAction | SucceedAction | FailAction;

/**
 * Effects specific to `Upload` related actions.
 *
 * Apart from the more conventional effects (`persist`, `broadcast`, etc...),
 * UploadEffects deal with uploading files to cloudinary.
 */
export interface UploadEffects extends BaseEffects {
  upload: {
    provider: Provider;
    file: File;
    boardId: string;
  };
}

export interface AddAction extends BaseAction<UploadEffects> {
  type: "upload/add";
  data: Upload;
}

/**
 * Type that describes the named arguments for adding an upload.
 */
interface AddParams {
  /**
   * The id for the to-be-added upload.
   */
  id: string;
  /**
   * The storage provider to be used.
   */
  provider: Provider;
  /**
   * The file to be uploaded.
   */
  file: File;
}

/**
 * Action creator for adding an `Upload` to the app state.
 *
 * @param {AddArgs} args - The parameters for the upload.
 * @returns The action thunk to be dispatched to the store.
 */
export const add =
  ({ id, provider, file }: AddParams) =>
  (state: AppState): AddAction => {
    let boardId = Board.getBoardId(state);
    let previewUrl = URL.createObjectURL(file);
    return {
      type: "upload/add",
      data: {
        id,
        type: "upload",
        status: "loading",
        previewUrl,
      },
      effects: {
        persist: true,
        broadcast: true,
        upload: {
          provider,
          file,
          boardId,
        },
      },
    };
  };

export interface SucceedAction extends BaseAction {
  type: "upload/succeed";
  data: UploadSuccess;
}

/**
 * Action creator for registering the success of an upload.
 *
 * Sets the `status` and `url` fields accordingly.
 *
 * @param {string} id - The id of the upload that succeeded.
 * @param {string} url - The url where the uploaded file can be found.
 * @returns {SucceedAction} The action to be dispatched to the store.
 */
export const succeed = (id: string, url: string): SucceedAction => {
  return {
    type: "upload/succeed",
    data: { type: "upload", id, status: "success", url },
    effects: { broadcast: true, persist: true },
  };
};

export interface FailAction extends BaseAction {
  type: "upload/fail";
  data: UploadFailed;
}

/**
 * Action creator for registering the failure of an upload.
 *
 * Sets the `status` field accordingly.
 *
 * @param {string} id - The id of the upload that succeeded.
 * @returns {FailAction} The action to be dispatched to the store.
 */
export const fail = (id: string): FailAction => {
  return {
    type: "upload/fail",
    data: { type: "upload", id, status: "failed" },
    effects: { broadcast: true, persist: true },
  };
};

/**
 * Reducer that processes any `Upload` actions.
 *
 * @param {AppState["objects"]} [state] - The current `objects` state.
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["objects"]} The updated state slice.
 */
export const reducer = (
  state: AppState["objects"] = {},
  action: Action
): AppState["objects"] => {
  switch (action.type) {
    case "upload/add":
      return { ...state, [action.data.id]: action.data };

    case "upload/succeed":
    case "upload/fail": {
      const object = state[action.data.id];
      assert(isUpload(object), "Object is not a Sticky.");

      return { ...state, [object.id]: { ...state[object.id], ...action.data } };
    }

    default:
      return state;
  }
};

/**
 * Selector that returns a record of all uploads in the board state, keyed by
 * their id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, Upload>} A record of all uploads in the app state.
 */
export const getUploads = createShallowEqualSelector(
  BaseObject.getAll,
  (objects) => filterObject(objects, isUpload)
);

/**
 * Selector that returns an array of all uploads in the app state
 *
 * @param {AppState} state - The current app state.
 * @returns {Upload[]} An array of uploads.
 */
export const getAll = createSelector(getUploads, (uploads) =>
  Object.values(uploads)
);

/**
 * Selector creator that returns the upload with the given id.
 *
 * @param {string} id - The id of the upload we want.
 * @returns A selector that returns the upload.
 */
export const getById = (id: string) =>
  createSelector(getUploads, (uploads) => uploads[id]);
