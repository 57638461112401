import "./index.css";

import React from "react";
import { createRoot } from "react-dom/client";

import * as FeatureFlags from "~/util/FeatureFlags";
import * as Clock from "~/util/clock";
import { ErrorBoundary } from "~/util/bugsnag";

import App from "./components/App";

FeatureFlags.parseFromQuery(window.location.search);
FeatureFlags.addBodyFlagAttribute();

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

Clock.init();
