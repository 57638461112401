import orderBy from "lodash/orderBy";
import { ReactNode } from "react";

import * as Announcements from "~/announcements";

/**
 * The most recent eligible featured announcement will be shown
 * in the AnnouncementModal to registered users upon visiting a
 * board. Both featured and non-featured announcements (change
 * log items) will be shown in the Dashboard change log.
 *
 * Featured announcments are considered eligible for display if
 * the current date falls before the announcement's expiration
 * date (date it was published + showForDays setting).
 *
 * To add an announcement, create a component in the `~/announcements`
 * dir that has a default export implementing the `Announcement`
 * interface below, and export it from the index.
 */

/**
 * Interface that defines an Announcement.
 */
export interface BaseAnnouncement<Featured extends boolean> {
  /* The date the announcement was published */
  published: Date;
  /* The slug for the announcement */
  slug: string;
  /* The title of the announcement */
  title: string;
  /* The content of the announcement */
  content: ReactNode;
  /* Whether or not the announcement is featured */
  featured: Featured;
}

export interface FeaturedAnnouncement extends BaseAnnouncement<true> {
  /* How many days after the publish date we want to show the announcement for */
  showForDays: number;
  /* An optional image for the announcement */
  image?: ReactNode;
}

export interface ChangeLog extends BaseAnnouncement<false> {}

export type Announcement = FeaturedAnnouncement | ChangeLog;

export const getAll = (): Announcement[] => {
  let announcements: Announcement[] = Object.values(Announcements).filter(
    (announcement) => !isScheduled(announcement)
  );
  return orderBy(announcements, "published");
};

export const getBySlug = (slug: string): Announcement | undefined => {
  return getAll().find((announcement) => announcement.slug === slug);
};

export const getLastUnread = (): FeaturedAnnouncement | undefined => {
  return getUnread()
    .filter(isFeatured)
    .filter((announcement) => !isExpired(announcement))
    .pop();
};

export const getFeaturedBySlug = (
  slug: string
): FeaturedAnnouncement | undefined => {
  return getAll()
    .filter(isFeatured)
    .find((announcement) => announcement.slug === slug);
};

export const getUnread = () => {
  let announcements = getAll();

  const receipt = getLastReadReceipt();

  if (receipt) {
    announcements = announcements.filter((announcement) => {
      return announcement.published.getTime() > receipt;
    });
  }

  return announcements;
};

export const getHasUnreadChangeLog = () => {
  return getUnread().filter(isChangeLog).length > 0;
};

export const updateLastReadTime = () => {
  return setLastReadReceipt();
};

export let setLastReadReceipt = (): void => {
  return localStorage.setItem(
    "announcements.lastRead",
    JSON.stringify(Date.now())
  );
};

export let getLastReadReceipt = (): number | null => {
  try {
    const receipt = localStorage.getItem("announcements.lastRead");
    return receipt ? JSON.parse(receipt) : null;
  } catch (e) {
    return null;
  }
};

export function isFeatured(
  announcement: Announcement
): announcement is FeaturedAnnouncement {
  return announcement.featured === true;
}

export function isChangeLog(
  announcement: Announcement
): announcement is ChangeLog {
  return announcement.featured === false;
}

export function isScheduled(announcement: Announcement): boolean {
  return announcement.published > new Date();
}

export function isExpired(announcement: FeaturedAnnouncement): boolean {
  let showUntil = new Date(announcement.published.getTime());
  showUntil.setDate(showUntil.getDate() + announcement.showForDays);
  return Date.now() > showUntil.getTime();
}
