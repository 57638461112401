import * as Client from "~/store/bundles/Client";
import assert from "~/util/assert";
import { Point, Vector } from "~/util/geometry";
import { UpdateMany } from "~/util/types";

import * as BaseObject from "./BaseObject";
import * as Grab from "./Grab";
import * as Lock from "./Lock";
import { AppState, State, Action as AppAction } from "../index";
import { BaseAction, BaseEffects, emptyAction, EmptyAction } from "../shared";
import { AliveEffects } from "./Alive";
import { Trait } from "./Trait";

/**
 * Interface that describes objects with a position.
 */
export interface Position extends Trait {
  position: Point;
}

/**
 * Type guard that checks whether an object (BaseObject) implements the Position
 * trait.
 */
export const isPosition = <T extends BaseObject.BaseObject>(
  object: T
): object is T & Position => "position" in object;

/**
 * Effects that can be present on any Position action.
 */
export interface PositionEffects extends BaseEffects, AliveEffects {}

/**
 * The action type for Position actions.
 */
export interface BasePositionAction extends BaseAction<PositionEffects> {}

/**
 * Schema for actions returned by the `Position.position()` action creator.
 */
export interface PositionAction extends BasePositionAction {
  type: "position/position";
  data: Pick<Position, "id" | "position">;
}

/**
 * Move an object to a given position
 *
 * @param id - The id of the object to move
 * @param position - The (screen) position to move the object to
 */
export const position =
  (id: string, position: Point) =>
  (state: AppState): PositionAction | EmptyAction => {
    let object = getById(id)(state);
    if (!object || !Grab.isGrab(object)) return emptyAction();

    // Don't reposition the object if it's locked
    if (Lock.isLock(object) && object.locked) return emptyAction();

    let scene = Client.getScene(state);
    let scenePosition = Client.toSceneCoordinates(scene, position);

    return {
      type: "position/position",
      data: { id, position: scenePosition },
      effects: {
        broadcast: true,
        persist: true,
        keepAlive: true,
      },
    };
  };

/**
 * Schema for actions returned by the `Position.positionBy()` action creator.
 */
export interface PositionByAction extends BasePositionAction {
  type: "position/position_by";
  data: Pick<Position, "id" | "position">;
}

/**
 * Move an object by a given delta (in _scene_ coordinates)
 *
 * @param id - The id of the object to move
 * @param delta - The (scene) delta to move the object by
 */
export const positionBy =
  (id: string, delta: Point) =>
  (state: AppState): PositionByAction | EmptyAction => {
    let object = getById(id)(state);
    if (!object || !Grab.isGrab(object)) return emptyAction();

    // Don't reposition the object if it's locked
    if (Lock.isLock(object) && object.locked) return emptyAction();

    let newPosition = {
      x: object.position.x + delta.x,
      y: object.position.y + delta.y,
    };

    return {
      type: "position/position_by",
      data: { id, position: newPosition },
      effects: {
        persist: true,
        broadcast: true,
        keepAlive: true,
      },
    };
  };

/**
 * Schema for actions returned by the `Position.displaceManyBy()` action creator.
 */
export interface DisplaceManyByAction extends BasePositionAction {
  type: "position/displace_many_by";
  data: UpdateMany<Position, "position">;
}

/**
 * Move several objects by a given delta (in _scene_ coordinates)
 *
 * @param ids - The ids of the objects to move
 * @param delta - The (scene) delta to move the object by
 */
export const displaceManyBy =
  (ids: string[], delta: Vector) =>
  (state: AppState): DisplaceManyByAction => {
    const data: UpdateMany<Position, "position"> = {};

    for (const id of ids) {
      let object = getById(id)(state);
      if (!object || !Grab.isGrab(object)) continue;

      // Don't reposition the object if it's locked
      if (Lock.isLock(object) && object.locked) continue;

      let newPosition = {
        x: object.position.x + delta.x,
        y: object.position.y + delta.y,
      };

      data[id] = { position: newPosition };
    }

    return {
      type: "position/displace_many_by",
      data,
      effects: {
        persist: true,
        broadcast: true,
        keepAlive: true,
      },
    };
  };

/**
 * Union type of all Position-related actions.
 */
export type Action = PositionAction | PositionByAction | DisplaceManyByAction;

/**
 * Get all positionable objects from an app state.
 */
export const getAll = (state: AppState) =>
  Object.values(state.objects).filter(isPosition);

/**
 * Get Position object with given id.
 */
export const getById = (id: string) => (state: AppState) => {
  const object = BaseObject.getById(id)(state);
  if (object && isPosition(object)) return object;
};

/**
 * Reducer that handles any Position-related actions
 */
export const reducer = (state: State = {}, action: AppAction): State => {
  switch (action.type) {
    case "position/position":
    case "position/position_by": {
      const object = state[action.data.id];
      assert(
        isPosition(object),
        "Object does not implement the Position trait."
      );

      return {
        ...state,
        [action.data.id]: { ...object, ...action.data },
      };
    }

    case "position/displace_many_by": {
      let newState = { ...state };

      for (const id in action.data) {
        const object = newState[id];
        assert(
          isPosition(object),
          "Object does not implement the Position trait."
        );

        newState[id] = { ...object, ...action.data[id] };
      }

      return newState;
    }

    default:
      return state;
  }
};
