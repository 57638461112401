import "./Connection.css";

import React from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Comments from "~/store/bundles/Comments";
import * as Connections from "~/store/bundles/Connection";
import * as User from "~/store/bundles/User";
import * as Rect from "~/util/geometry/rectangle";
import { BoardType, useConfig } from "~/context/ConfigContext";
import { Grab } from "~/store/traits";
import { Size } from "~/store/traits";
import { classNames } from "~/util/classNames";
import {
  normalize,
  padded,
  Point,
  Rectangle,
  scale,
  sub,
  vec,
} from "~/util/geometry";

import { ControlOverlay, Control } from "./ControlOverlay";
import { OpenComments } from "./OpenComments";
import { getConnectionPath } from "./circular";
import { Box } from "./shared/Box";
import { ContextMenuTarget } from "./shared/ContextMenuTarget";
import { EditTarget } from "./shared/EditTarget";
import { EditableText } from "./shared/EditableText";
import { SelectTarget } from "./shared/SelectTarget";
import { ShowcaseTarget } from "./shared/ShowcaseTarget";
import { useHoverTarget } from "./shared/useHoverTarget";

/**
 * Render a connection.
 *
 * We branch on whether or not the connection is directed at an object or the
 * cursor position as a performance optimization. We don't want _every_
 * connection to re-render on every cursor move when it's only the occasional
 * connection being created that actually depends on the cursor position.
 */
export const Connection = React.memo(
  ({ connection }: { connection: Connections.Connection }) => {
    return connection.to ? (
      <ObjectConnection to={connection.to} connection={connection} />
    ) : connection.creating ? (
      <CursorConnection to={connection.creating} connection={connection} />
    ) : null;
  }
);

Connection.displayName = "Connection";

/**
 * Render a connection between two objects.
 *
 * @param connection - The connection object to render
 *  @param to - The id of the object to connect to
 */
export const ObjectConnection = ({
  connection,
  to,
}: {
  connection: Connections.Connection;
  to: string;
}) => {
  const fromRect = useSelector(Size.getById(connection.from));
  const toRect = useSelector(Size.getById(to));

  if (!fromRect || !toRect || connection.to === connection.from) return null;

  return (
    <GenericConnection connection={connection} from={fromRect} to={toRect} />
  );
};

/**
 * Render a connection between an object and the cursor position.
 *
 * Because the connection curve logic expects an origin and target `Rectangle`,
 * we wrap the mouse cursor in an infinitesimal rectangle.
 *
 * @param connection - The connection object to render
 *  @param to - The id of the user whose cursor we want to connect to.
 */
export const CursorConnection = ({
  connection,
  to,
}: {
  connection: Connections.Connection;
  to: string;
}) => {
  const cursor = useSelector(User.getCursor(to));
  const fromRect = useSelector(Size.getById(connection.from));

  if (!fromRect) return null;

  let center = Rect.getCenter(fromRect);

  let toRect = {
    width: 0.001,
    height: 0.001,

    // Hack to keep the connection from obstructing pointer events: subtract a
    // couple of pixels in the radial direction.
    position: shorten(center, cursor, 10),
  };

  // Hide cursor connections if they're being drawn to 0,0. Usually this means
  // the connection is being created on mobile, where there is no hover event
  // with which to update the cursor position.
  //
  // TODO: look into nullable cursors and/or a better way to handle this
  if (cursor.x === 0 && cursor.y === 0) {
    return null;
  }

  return (
    <GenericConnection connection={connection} from={fromRect} to={toRect} />
  );
};

/**
 * Render a connection generically between two provided rectangles.
 *
 * This component is agnostic to whether the connection is between two objects
 * or between an object and the cursor (mocked as an infinitesimal rectangle).
 */
export const GenericConnection = ({
  connection,
  from,
  to,
}: {
  connection: Connections.Connection;
  from: Rectangle;
  to: Rectangle;
}) => {
  const dispatch = useDispatch();
  const { getState } = useStore();
  const userId = useSelector(Client.getUserId);
  const isSelected = useSelector(Client.getIsSelected(connection.id));
  const isTemplate = useConfig().type === BoardType.Template;
  const isCreating = !!connection.creating;
  const comments = useSelector(Comments.getByParentId(connection.id));
  const hasComments = comments.length > 0;
  const isUserBusy = useSelector(Client.getUserIsBusy(userId));
  const { hover, HoverTarget } = useHoverTarget(connection.id);

  const onPointerDown: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    let position = { x: event.clientX, y: event.clientY };
    let selectionCount = Client.getSelected(getState()).length;

    if (connection.locked) {
      dispatch(Client.startPanning(position));
    } else if (isSelected && selectionCount > 1) {
      dispatch(Grab.startGrabbingSelection(userId, position));
    } else {
      dispatch(Connections.startReshaping(connection.id, userId));
    }
  };

  let className = classNames(
    "connection absolute overflow-visible stroke-current group",
    isSelected ? "connection--selected" : "",
    connection.grabbing
      ? "cursor-grabbing"
      : connection.locked
      ? ""
      : "cursor-grab"
  );

  let { path, end, endAngle, start, startAngle, midpoint } = getConnectionPath(
    padded(from, 10),
    padded(to, 10),
    connection
  );

  const labelPosition = {
    x: midpoint.x - connection.labelWidth / 2,
    y: midpoint.y - connection.labelHeight / 2,
  };

  const hasLabel = connection.content.trim() || connection.editing;

  // Place the comment button at the connection's midpoint if there is no label
  // and is not editing, otherwise place in the top right corner of label
  const commentPosition = {
    x: hasLabel ? labelPosition.x + connection.labelWidth - 20 : midpoint.x,
    y: hasLabel ? labelPosition.y : midpoint.y,
  };

  return (
    <div
      className={classNames(
        "connection__wrapper absolute pointer-events-none",
        connection.locked ? "locked " : " ",
        isSelected && "selected "
      )}
    >
      <HoverTarget onPointerDown={onPointerDown}>
        <EditTarget id={connection.id}>
          <SelectTarget id={connection.id}>
            <ContextMenuTarget id={connection.id}>
              <ShowcaseTarget id={connection.id} userId={connection.createdBy}>
                <svg className={className} key={connection.id}>
                  <path
                    data-id={connection.id}
                    className={`
                    pointer-events-auto
                    connection__margin
                    stroke-[30px] text-transparent stroke-current
                  `}
                    fill="none"
                    d={path}
                  />

                  <path
                    data-id={connection.id}
                    className={`
                  connection__curve 
                  pointer-events-auto stroke-[6px]
                  `}
                    fill="none"
                    d={path}
                  />

                  <ConnectionHeads
                    direction={connection.direction}
                    start={start}
                    end={end}
                    startAngle={startAngle}
                    endAngle={endAngle}
                  />
                </svg>

                <Box
                  position={labelPosition}
                  width={connection.labelWidth}
                  height={connection.labelHeight}
                  className="absolute font-display font-2xl"
                >
                  <EditableText
                    id={connection.id}
                    className="connection__label flex justify-center text-center"
                    disabled={isUserBusy}
                  />
                </Box>
                {!isTemplate && !isCreating && (hover || hasComments) && (
                  <ControlOverlay>
                    <Control
                      style={{
                        left: commentPosition.x,
                        top: commentPosition.y,
                        width: 50,
                        height: 30,
                      }}
                    >
                      <div className="connection__comments flex justify-center -translate-x-1/2 -translate-y-1/2">
                        <OpenComments
                          id={connection.id}
                          commentCount={comments.length}
                          origin="center"
                        />
                      </div>
                    </Control>
                  </ControlOverlay>
                )}
              </ShowcaseTarget>
            </ContextMenuTarget>
          </SelectTarget>
        </EditTarget>
      </HoverTarget>
    </div>
  );
};

/**
 * Draws the connection arrowheads, according to the connection's "direction"
 * type and the calculated connection curve parameters.
 */
const ConnectionHeads = ({
  direction,
  start,
  end,
  startAngle,
  endAngle,
}: {
  direction: Connections.Connection["direction"];
  start: Point;
  end: Point;
  startAngle: number;
  endAngle: number;
}) => {
  switch (direction) {
    case "to":
      return <ConnectionHead position={end} angle={endAngle} />;
    case "reverse":
      return <ConnectionHead position={start} angle={startAngle} />;
    case "both":
      return (
        <>
          <ConnectionHead position={start} angle={startAngle} />
          <ConnectionHead position={end} angle={endAngle} />
        </>
      );
    default:
      return null;
  }
};

/**
 * Draws a single connection head at a given position angled in a given direction
 */
const ConnectionHead = ({
  position,
  angle,
}: {
  position: Point;
  angle: number;
}) => {
  let { x, y } = position;
  let points = `${x + 6}, ${y} ${x - 25}, ${y - 15} ${x - 25} ${y + 15}`;

  return (
    <polygon
      className="connection__head stroke-0 fill-current"
      points={points}
      transform={`rotate(${angle}, ${x}, ${y})`}
    />
  );
};

/**
 * Helper that, given two points and an offset, returns a third point that
 * approximates the second point, but moved towards the initial point by the
 * offset distance.
 *
 * @param p1 - The initial point
 * @param p2 - The point we want to offset towards `p1`
 * @param offset - The offset by which to shift `p2` in scene distance.
 *
 * @example shorten({x: 0, y: 0}, {x: 0, y: 5}, 0.1) -> {x: 0, y: 4.5 }
 */
const shorten = (p1: Point, p2: Point, offset: number) => {
  let delta = scale(normalize(vec(p1, p2)), offset);
  return sub(p2, delta);
};
