import {
  Route,
  RouterProvider,
  Navigate,
  useLocation,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { BoardEditorWrapper } from "~/components/BoardEditor";
import { CaseStudyEngagingInquiry } from "~/components/CaseStudyEngagingInquiry";
import { ConfirmLogin } from "~/components/ConfirmLogin";
import { CreateBoardAndRedirect } from "~/components/CreateBoardAndRedirect";
import {
  Dashboard,
  DashboardNewBoardView,
  DashboardPage,
  DashboardBoardsView,
  DashboardTemplatesView,
} from "~/components/Dashboard";
import { Landing as Product } from "~/components/Landing";
import { Login } from "~/components/Login";
import { Logout } from "~/components/Logout";
import Sandbox from "~/components/Sandbox";
import { Signup } from "~/components/Signup";
import { TemplateEditorWrapper } from "~/components/TemplateEditor";
import { TemplateViewerWrapper } from "~/components/TemplateViewer";
import { Verify } from "~/components/Verify";
import { useAuth } from "~/context/AuthContext";

import { AccountBilling } from "./AccountBilling";
import { AccountDetails } from "./AccountDetails";
import { AuthLayout } from "./AuthLayout";
import Privacy from "./Privacy";
import Terms from "./Terms";
import { VerifyNewEmail } from "./VerifyNewEmail";

const Landing = () => {
  let auth = useAuth();
  return auth.authenticated ? <Navigate to="/boards" /> : <Product />;
};

const router = createBrowserRouter(
  createRoutesFromElements([
    AuthRoutes(),

    <Route path="/" element={<Landing />} />,

    <Route path="/product" element={<Product />} />,

    <Route path="/pricing" element={<Navigate to="/product#pricing" />} />,

    <Route path="/try/*" element={<Sandbox />} />,

    <Route path="/terms" element={<Terms />} />,

    <Route path="/privacy" element={<Privacy />} />,

    <Route
      path="/studies/engaging-inquiry"
      element={<CaseStudyEngagingInquiry />}
    />,

    <Route path="/verify/email" element={<VerifyNewEmail />} />,

    <Route
      path="/boards/*"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    >
      <Route
        path="new"
        element={
          <>
            <DashboardBoardsView />
            <DashboardNewBoardView />
          </>
        }
      />
      <Route path="own" element={<DashboardBoardsView />} />
      <Route path="templates" element={<DashboardTemplatesView />} />
      <Route index element={<DashboardBoardsView />} />
    </Route>,

    <Route
      path="/account/*"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    >
      <Route
        path=""
        element={
          <DashboardPage>
            <AccountDetails />
          </DashboardPage>
        }
      />
      <Route
        path="billing"
        element={
          <DashboardPage>
            <AccountBilling />
          </DashboardPage>
        }
      />
    </Route>,

    <Route
      path="/new"
      element={
        <PrivateRoute>
          <CreateBoardAndRedirect />
        </PrivateRoute>
      }
    />,

    <Route path="/template/:boardId/*" element={<TemplateViewerWrapper />} />,

    <Route
      path="/template/:boardId/edit/*"
      element={<TemplateEditorWrapper />}
    />,

    <Route path="/:boardId/*" element={<BoardEditorWrapper />} />,
  ])
);

function PrivateRoute({ children }: { children: React.ReactElement }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.authenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

// Routes related to sign-in, sign-out and sign-up
function AuthRoutes() {
  return (
    <Route>
      <Route element={<AuthLayout />}>
        <Route key="/login" path="/login" element={<Login />} />

        <Route key="/signup" path="/signup" element={<Signup />} />

        <Route key="/code" path="/code" element={<ConfirmLogin />} />
        <Route key="/verify" path="/verify" element={<Verify />} />
      </Route>

      <Route key="/logout" path="/logout" element={<Logout />} />
    </Route>
  );
}

export let Routes = () => {
  return <RouterProvider router={router} />;
};
