import { createSelector } from "reselect";

import * as Client from "./Client";
import { Action, AppState } from "..";
import { BaseAction } from "../shared";

type CommentType = "board" | "object";

export interface BaseComment {
  /**
   * The id of the comment
   */
  id: string;

  /**
   * The id of the comment this comment is a reply to.
   *
   * If it's a top-level
   * comment, not a reply, then the `parentId` is the `objectId`.
   */
  parentId: string;

  /**
   * The userId corresponding to the user that created the comment.
   */
  authorId: string;

  /**
   * The actual body of the comment, as an HTML string
   */
  body: string;

  /**
   * Flag that tracks whether the comment has been deleted or not.
   */
  deleted: boolean;

  /**
   * Timestamp of when the comment was created.
   */
  createdAt: string;

  /**
   * The type of the comment.
   */
  type: CommentType;
}

export interface BoardComment extends BaseComment {
  /**
   * The id of the board the comment is associated with.
   */
  boardId: string;
  type: "board";
}

export interface ObjectComment extends BaseComment {
  /**
   * The id of the object the comment is associated with.
   */
  objectId: string;
  type: "object";
}

export type Comment = BoardComment | ObjectComment;

export interface CreateBoardCommentAttributes {
  id: BoardComment["id"];
  parentId: BoardComment["parentId"];
  authorId: BoardComment["authorId"];
  body: BoardComment["body"];
}

export interface CreateObjectCommentAttributes {
  id: ObjectComment["id"];
  objectId: ObjectComment["objectId"];
  parentId: ObjectComment["parentId"];
  authorId: ObjectComment["authorId"];
  body: ObjectComment["body"];
}

export type CreateCommentAttributes =
  | CreateBoardCommentAttributes
  | CreateObjectCommentAttributes;

export interface CreateCommentWSAction {
  type: "websocket/create_comment";
  data: CreateCommentAttributes;
}

/**
 * Type that describes the named options for adding a board comment.
 *
 * @typedef {CreateBoardCommentOptions} The options object
 * @property {string} id - The id of the comment to be added
 * @property {string} parentId - The id of the object/comment this comment
 * is a reply to.
 * @property {string} body - The comment body, as an HTML string
 */
type CreateBoardCommentOptions = {
  id: string;
  parentId: string;
  body: string;
  type: "board";
};

/**
 * Type that describes the named options for adding a board comment.
 *
 * @typedef {CreateBoardCommentOptions} The options object
 * @property {string} id - The id of the comment to be added
 * @property {string} objectId - The id of the object the comment belongs to
 * @property {string} parentId - The id of the object/comment this comment
 * is a reply to.
 * @property {string} body - The comment body, as an HTML string
 */
type CreateObjectCommentOptions = {
  id: string;
  objectId: string;
  parentId: string;
  body: string;
  type: "object";
};

/**
 * Create a comment on the server
 *
 * Because comments require some data to be generated on the server, we generate
 * a websocket event that creates the comment on the server. The server then
 * broadcasts a redux action that will actually add the comment to the store.
 *
 * @typedef {CreateBoardCommentOptions|CreateObjectCommentOptions} - The options
 * object
 *
 * @returns The action thunk to be dispatched to the store
 */
export const create =
  (params: CreateBoardCommentOptions | CreateObjectCommentOptions) =>
  (state: AppState): CreateCommentWSAction => {
    let authorId = Client.getUserId(state);

    return {
      type: "websocket/create_comment",
      data: { ...params, authorId },
    };
  };

export interface CreateCommentAction {
  type: "comment/create";
  data: Comment;
}

export interface UpdateCommentAction extends BaseAction {
  type: "comment/update";
  data: Pick<Comment, "id" | "body">;
}

/**
 * Action creator for updating a comment's body
 *
 * @param id - The id of the comment to update
 * @param body - The updated body string
 *
 * @returns The action to dispatch to the store
 */
export const update = (id: string, body: string): UpdateCommentAction => {
  return {
    type: "comment/update",
    data: { id, body },
    effects: { broadcast: true },
  };
};

export interface RemoveCommentAction extends BaseAction {
  type: "comment/remove";
  data: Pick<Comment, "id" | "deleted">;
}

/**
 * Action creator for removing a comment
 *
 * @param id - The id of the comment to remove
 *
 * @returns The action to dispatch to the store
 */
export const remove = (id: string): RemoveCommentAction => {
  return {
    type: "comment/remove",
    data: { id, deleted: true },
    effects: { broadcast: true },
  };
};

/*
 * Type guard for narrowing a `Comment`'s type to BoardComment
 *
 * @param {Comment} object - The comment whose type we want to narrow.
 * @returns {boolean} Whether or not the object is of type `BoardComment`.
 */
export const isBoardComment = (object: Comment): object is BoardComment =>
  "boardId" in object && object.type === "board";

/*
 * Type guard for narrowing a `Comment`'s type to ObjectComment
 *
 * @param {Comment} object - The comment whose type we want to narrow.
 * @returns {boolean} Whether or not the object is of type `ObjectComment`.
 */
export const isObjectComment = (object: Comment): object is ObjectComment =>
  "objectId" in object && object.type === "object";

export type CommentAction =
  | CreateCommentWSAction
  | CreateCommentAction
  | UpdateCommentAction
  | RemoveCommentAction;

/**
 * Reducer that processes any `Comments` actions.
 *
 * @param {AppState["comments"]} [state] - The current `objects` state.
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["comments"]} The updated state slice.
 */
export const reducer = (
  state: AppState["comments"] = {} as any,
  action: Action
): AppState["comments"] => {
  switch (action.type) {
    case "comment/create": {
      return { ...state, [action.data.id]: action.data };
    }

    case "comment/update":
    case "comment/remove": {
      const comment = state[action.data.id];

      return { ...state, [comment.id]: { ...comment, ...action.data } };
    }

    default:
      return state;
  }
};

/**
 * Selector that returns all comments on the board as a record keyed by id
 *
 * @param state - The board state
 *
 * @returns A record of all comments
 */
export const getComments = (state: AppState) => state.comments;

/**
 * Selector that returns all comments associated with a particular board.
 *
 * @param boardId - The id of the board whose comments we're interested in
 *
 * @returns An array of comments
 */
export const getByBoardId = (boardId: string) => {
  return createSelector(getComments, (comments) => {
    return Object.values(comments)
      .filter(isBoardComment)
      .filter((comment) => comment.boardId === boardId)
      .filter((comment) => !comment.deleted)
      .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));
  });
};

/**
 * Selector that returns all comments associated with a particular object.
 *
 * @param objectId - The id of the object whose comments we're interested in
 *
 * @returns An array of comments
 */
export const getByObjectId = (objectId: string) => (state: AppState) => {
  return Object.values(getComments(state))
    .filter(isObjectComment)
    .filter((comment) => comment.objectId === objectId)
    .filter((comment) => !comment.deleted);
};

/**
 * Selector that returns all comments associated with a particular parent.
 *
 * @param parentId - The id of the comment/object whose replies we're interested in
 *
 * @returns An array of comments
 */
export const getByParentId = (parentId: string) =>
  createSelector(getComments, (comments) => {
    return Object.values(comments)
      .filter((comment) => comment.parentId === parentId)
      .filter((comment) => !comment.deleted);
  });

/**
 * Selector that returns all comments created by a particular user.
 *
 * @param authorId - The id of the user whose comments we're interested in
 *
 * @returns An array of comments
 */
export const getByAuthorId = (authorId: string) => (state: AppState) => {
  return Object.values(getComments(state))
    .filter((comment) => comment.authorId === authorId)
    .filter((comment) => !comment.deleted);
};
