import { ReactNode } from "react";

export let DiscordInviteLink = ({ children }: { children: ReactNode }) => (
  <a
    target="_blank"
    rel="noreferrer"
    href="https://discord.gg/2KdhpSvPne"
    className="underline"
  >
    {children}
  </a>
);
