import "./AnnouncementModal.css";

import { Dialog } from "@headlessui/react";
import { useEffect, CSSProperties, ReactNode } from "react";
import { useParams } from "react-router-dom";

import lineSrc from "~/assets/background-line.svg";
import {
  getBySlug,
  isFeatured as isFeaturedAnnouncement,
  updateLastReadTime,
} from "~/util/AnnouncementClient";

import * as Icons from "./Icons";
import { Box } from "./Box";
import { Button } from "./Button";
import {
  Modal,
  ModalPanel,
  ModalProps,
  ModalTitle,
  ModalDescription,
} from "./Modal";
import { Tag } from "./Tag";

const modalStyle: CSSProperties = {
  backgroundImage: `url(${lineSrc})`,
  backgroundSize: "70vh",
  backgroundPositionX: 0,
  backgroundPositionY: 0,
};

export let AnnouncementModal = (props: ModalProps) => {
  const { slug = "" } = useParams();
  const announcement = getBySlug(slug);

  useEffect(() => {
    updateLastReadTime();
  }, []);

  if (!announcement) return null;

  const { title, content, published } = announcement;

  const isFeatured = isFeaturedAnnouncement(announcement);
  const hasImage = isFeatured && announcement.image;

  const subtitle = published.toLocaleDateString("default", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <Modal className="announcement-modal" {...props}>
      <ModalPanel size="lg" style={modalStyle}>
        {hasImage && <ModalImage>{announcement.image}</ModalImage>}
        <Box gap={2} p={6}>
          <ModalHeader>
            <Box direction="row">
              <Tag color="blue">
                <Icons.Gift /> New
              </Tag>
            </Box>
            <ModalTitle>{title}</ModalTitle>
            <ModalSubtitle>{subtitle}</ModalSubtitle>
          </ModalHeader>
          <ModalDescription>{content}</ModalDescription>
          <Box align="end">
            <Button variant="primary" onClick={props.onClose}>
              Got it
            </Button>
          </Box>
        </Box>
      </ModalPanel>
    </Modal>
  );
};

const ModalHeader = ({ children }: { children: ReactNode }) => (
  <Box>{children}</Box>
);

const ModalSubtitle = ({ children }: { children: ReactNode }) => (
  <Dialog.Title className="announcement-modal-subtitle">
    {children}
  </Dialog.Title>
);

const ModalImage = ({ children }: { children: ReactNode }) => (
  <div className="announcement-modal-image">
    <Box p={6}>{children}</Box>
  </div>
);
