import { useState, useEffect } from "react";

/**
 * Quick and dirty approximation of react-query.
 */
export function useQuery<T>(query: () => Promise<T>) {
  let [data, setData] = useState<T | undefined>();
  let [isLoading, setIsLoading] = useState(false);
  let [error, setError] = useState<string | undefined>();

  function refetch() {
    query().then(
      (response) => {
        setIsLoading(false);
        setData(response);
      },
      (error) => {
        setIsLoading(false);
        setError(error);
      }
    );
  }

  /* eslint-disable */
  useEffect(() => {
    setIsLoading(true);
    refetch();
  }, []);
  /* eslint-enable */

  return { data, isLoading: isLoading, error, refetch };
}
