import "./BoardDock.css";

import {
  PointerEvent,
  useCallback,
  ButtonHTMLAttributes,
  useState,
} from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Image from "~/store/bundles/Image";
import * as Label from "~/store/bundles/Label";
import * as Line from "~/store/bundles/Line";
import * as Shape from "~/store/bundles/Shape";
import * as Sticker from "~/store/bundles/Sticker";
import * as Sticky from "~/store/bundles/Sticky";
import * as Upload from "~/store/bundles/Upload";
import * as Vertex from "~/store/bundles/Vertex";
import * as Create from "~/store/traits/Create";
import * as Grab from "~/store/traits/Grab";
import * as Vec from "~/util/geometry/vector";
import { isEnabled } from "~/util/FeatureFlags";
import { Point } from "~/util/geometry";
import { useHandleClickDrag, useWindowSize } from "~/util/hooks";
import { useOrientation } from "~/util/hooks";
import uid from "~/util/uid";
import usePermission from "~/util/usePermission";

import * as Icons from "./Icons";
import { ButtonGroup, IconButton } from "./Button";
import { Disabled } from "./Disabled";
import { Emoji, emojis as allEmojis, getEmoji } from "./Emoji";
import { ImageUploadButton } from "./ImageUploadButton";
import {
  ToolbarContextMenu,
  ToolbarContextMenuButton,
  ToolbarContextMenuItem,
  ToolbarContextMenuItems,
} from "./ToolbarContextMenu";

type ShapeType = Shape.Shape["shape"];

type CreateStickerOptions =
  | { type: "vote"; color: string }
  | { type: "reaction"; shortcode: Shortcode };

type CreateObjectHandlers = {
  onPointerDown: (event: PointerEvent<Element>) => void;
  onPointerUp: (event: PointerEvent<Element>) => void;
};

type StickyColor = "sticky1" | "sticky2" | "sticky3" | "sticky4";

const stickyColors: StickyColor[] = [
  "sticky1",
  "sticky2",
  "sticky3",
  "sticky4",
];

const StickyLabels: Record<StickyColor, string> = {
  sticky1: "Green Sticky",
  sticky2: "Red Sticky",
  sticky3: "Yellow Sticky",
  sticky4: "Blue Sticky",
};

export let BoardDock = () => {
  let orientation = useOrientation();
  let dispatch = useDispatch();
  let userId = useSelector(Client.getUserId);
  let { getState } = useStore();
  let can = usePermission();
  let creating = useSelector(Create.getCreatingBy(userId));
  let isCreating = creating.length > 0;

  function handleAddImage(file: File) {
    let imageId = uid();
    let uploadId = uid();
    let cursor = Client.getCursor(getState());
    let position = cursor.screenPosition;

    dispatch(Upload.add({ id: uploadId, provider: "cloudinary", file }));
    dispatch(
      Image.add({
        id: imageId,
        mousePosition: position,
        width: 300,
        height: 300,
        uploadId,
      })
    );
    dispatch(Create.startCreating(imageId, userId));
    dispatch(Grab.startGrabbing(imageId, userId, position));
  }

  const useCreateStickyHandler = (color: string) => {
    const handler = useCreateObjectHandler({ type: "sticky", color });
    return useHandleClickDrag(handler);
  };

  const useCreateShapeHandler = (shape: ShapeType) => {
    const handler = useCreateObjectHandler({ type: "shape", shape });
    return useHandleClickDrag(handler);
  };

  const useCreateLineHandler = () => {
    const handler = useCreateObjectHandler({ type: "line" });
    return useHandleClickDrag(handler);
  };

  const useCreateTextHandler = () => {
    const handler = useCreateObjectHandler({ type: "text" });
    return useHandleClickDrag(handler);
  };

  const useCreateStickerHandler = (options: AddStickerOptions) => {
    const handler = useCreateObjectHandler(options);
    return useHandleClickDrag(handler);
  };

  const stickyHandlers: Record<StickyColor, CreateObjectHandlers> = {
    sticky1: useCreateStickyHandler("sticky1"),
    sticky2: useCreateStickyHandler("sticky2"),
    sticky3: useCreateStickyHandler("sticky3"),
    sticky4: useCreateStickyHandler("sticky4"),
  };

  const shapeHandlers: Record<ShapeType, CreateObjectHandlers> = {
    rect: useCreateShapeHandler("rect"),
    ellipse: useCreateShapeHandler("ellipse"),
  };

  const lineHandler = useCreateLineHandler();

  const disabled = isCreating;
  const collapseMenuItems = useCollapseMenuItems();

  return (
    <Disabled disabled={!can("edit")} msg="You can't edit the board">
      <ButtonGroup
        id="board-dock"
        className="board-dock"
        size={orientation === "landscape" ? "xl" : "lg"}
      >
        {collapseMenuItems ? (
          <StickyButton
            disabled={disabled}
            createStickyHandler={useCreateStickyHandler}
          />
        ) : (
          <>
            {stickyColors.map((color) => (
              <IconButton
                key={color}
                icon={<Icons.Sticky color={`var(--${color})`} />}
                aria-label={StickyLabels[color]}
                disabled={disabled}
                {...stickyHandlers[color]}
              />
            ))}
          </>
        )}
        {collapseMenuItems ? (
          <GeometryButton
            disabled={disabled}
            createShapeHandler={useCreateShapeHandler}
            createLineHandler={useCreateLineHandler}
          />
        ) : (
          <>
            <IconButton
              icon={<Icons.Square />}
              aria-label="Rectangle"
              disabled={disabled}
              {...shapeHandlers["rect"]}
            />
            <IconButton
              icon={<Icons.Circle />}
              aria-label="Circle"
              disabled={disabled}
              {...shapeHandlers["ellipse"]}
            />
            {isEnabled("lines") && (
              <IconButton
                icon={<Icons.Line />}
                aria-label="Line"
                disabled={disabled}
                {...lineHandler}
              />
            )}
          </>
        )}
        <ImageUploadButton onChange={handleAddImage}>
          <IconButton
            disabled={disabled}
            icon={<Icons.Image />}
            aria-label="Image"
          />
        </ImageUploadButton>
        <IconButton
          icon={<Icons.Type />}
          aria-label="Text"
          disabled={disabled}
          {...useCreateTextHandler()}
        />
        <StickerButton
          disabled={disabled}
          createHandler={useCreateStickerHandler}
        />
      </ButtonGroup>
    </Disabled>
  );
};

type CreateObjectOptions =
  | { type: "sticky"; color: string }
  | { type: "shape"; shape: ShapeType }
  | { type: "text" }
  | { type: "line" }
  | CreateStickerOptions;

const labelSizes: Record<Label.Style, { width: number; height: number }> = {
  title: { width: 400, height: 100 },
  heading: { width: 300, height: 80 },
  paragraph: { width: 150, height: 50 },
};

/**
 * Hook that handles creating objects in the center of the
 * board by single-clicking or by dragging.
 */
function useCreateObjectHandler(options: CreateObjectOptions) {
  const dispatch = useDispatch();
  const viewport = useSelector(Client.getViewport);
  const userId = useSelector(Client.getUserId);
  const scale = useSelector(Client.getScale);
  const labelStyle = useSelector(Client.getCurrentLabelStyle);
  const collapseMenuItems = useCollapseMenuItems();

  const createAt = useCallback(
    (position: Point) => {
      let id = uid();

      if (options.type === "shape") {
        const shape = options.shape;
        dispatch(
          Shape.add({
            id,
            shape,
            mousePosition: position,
            width: 300,
            height: 300,
            color: "#cccccc",
          })
        );
      } else if (options.type === "line") {
        const from = uid();
        const to = uid();
        dispatch(
          Vertex.add({
            id: from,
            parent: id,
            position: Vec.add(position, { x: -50, y: 50 }),
          })
        );
        dispatch(
          Vertex.add({
            id: to,
            parent: id,
            position: Vec.add(position, { x: 50, y: -50 }),
          })
        );
        dispatch(Line.add({ id, from, to, color: "#cccccc" }));
      } else if (options.type === "text") {
        const { width, height } = labelSizes[labelStyle];
        dispatch(Label.add({ id, mousePosition: position, width, height }));
      } else if (options.type === "reaction" || options.type === "vote") {
        dispatch(Sticker.add({ id, mousePosition: position, userId, options }));
      } else {
        const color = options.color;
        dispatch(
          Sticky.add({
            id,
            mousePosition: position,
            width: 200,
            height: 200,
            color,
          })
        );
      }

      return id;
    },
    [dispatch, options, labelStyle, userId]
  );

  return useCallback(
    (event: PointerEvent, isDrag: boolean) => {
      if (!viewport) return;

      let collapsedTypes = ["reaction"];

      if (collapseMenuItems) {
        collapsedTypes = [...collapsedTypes, "shape", "sticky"];
        if (isEnabled("lines")) {
          collapsedTypes.push("line");
        }
      }

      if (isDrag) {
        const position = { x: event.clientX, y: event.clientY };
        const id = createAt(position);

        dispatch(Create.startCreating(id, userId));
        dispatch(Grab.startGrabbing(id, userId, position));
      }
      // Otherwise, create object in the center of the viewport with a
      // random offset (if not a reaction).
      else if (
        !["reaction", "vote"].includes(options.type) &&
        !collapsedTypes.includes(options.type)
      ) {
        const offset = {
          x: (-25 + Math.random() * 50) * scale,
          y: (-25 + Math.random() * 50) * scale,
        };
        const position = Vec.add(
          {
            x: viewport.width / 2,
            y: viewport.height / 2,
          },
          offset
        );

        const id = createAt(position);

        dispatch(Create.startCreating(id, userId));
        dispatch(Create.finishCreating(id));
      }
    },
    [createAt, dispatch, scale, userId, viewport, options, collapseMenuItems]
  );
}

type Shortcode = Sticker.Shortcode;
type NativeButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
type AddStickerOptions = Exclude<
  Sticker.AddStickerOptions,
  Sticker.AddAvatarOptions
>;

interface StickerButtonProps extends NativeButtonProps {
  createHandler: (options: AddStickerOptions) => CreateObjectHandlers;
}

let StickerButton = ({ createHandler, ...props }: StickerButtonProps) => {
  const shortcodes = Object.keys(allEmojis) as Array<keyof typeof allEmojis>;
  const [sticker, setSticker] = useState<AddStickerOptions>({
    type: "reaction",
    shortcode: ":thumbs-up:",
  });

  let onChange = (options: AddStickerOptions) => {
    setSticker(options);
  };

  return (
    <ToolbarContextMenu<AddStickerOptions | null>
      value={null}
      onChange={onChange}
      position="above"
    >
      <ToolbarContextMenuButton>
        {Sticker.isAddVote(sticker) ? (
          <IconButton
            {...props}
            icon={<Icons.Vote color={sticker.color} />}
            aria-label="Vote"
            {...createHandler({ type: "vote", color: sticker.color })}
          />
        ) : (
          <IconButton
            {...props}
            icon={<Emoji shortcode={sticker.shortcode} />}
            aria-label="Reaction"
            {...createHandler({
              type: "reaction",
              shortcode: sticker.shortcode,
            })}
          />
        )}
      </ToolbarContextMenuButton>
      <ToolbarContextMenuItems>
        <ToolbarContextMenuItem<Sticker.AddVoteOptions>
          value={{ type: "vote", color: "#9E87DD" }}
        >
          <IconButton
            icon={<Icons.Vote color="#9E87DD" />}
            aria-label="Vote"
            {...createHandler({ type: "vote", color: "#9E87DD" })}
          />
        </ToolbarContextMenuItem>
        {shortcodes.map((shortcode) => (
          <ToolbarContextMenuItem<Sticker.AddReactionOptions>
            key={shortcode}
            value={{ shortcode, type: "reaction" }}
          >
            <IconButton
              icon={getEmoji(shortcode)}
              aria-label={shortcode}
              {...createHandler({ type: "reaction", shortcode })}
            />
          </ToolbarContextMenuItem>
        ))}
      </ToolbarContextMenuItems>
    </ToolbarContextMenu>
  );
};

type Geometry = ShapeType | "line";

const GeometryIcons: Record<Geometry, React.FunctionComponent> = {
  rect: Icons.Square,
  ellipse: Icons.Circle,
  line: Icons.Line,
};

const GeometryLabels: Record<Geometry, string> = {
  rect: "Rectangle",
  ellipse: "Circle",
  line: "Line",
};

interface GeometryButtonProps extends NativeButtonProps {
  createShapeHandler: (type: ShapeType) => CreateObjectHandlers;
  createLineHandler: () => CreateObjectHandlers;
}

let GeometryButton = ({
  createShapeHandler,
  createLineHandler,
  ...props
}: GeometryButtonProps) => {
  const [currentGeometry, setCurrentGeometry] = useState<Geometry>("rect");

  const handlers: Record<Geometry, CreateObjectHandlers> = {
    rect: createShapeHandler("rect"),
    ellipse: createShapeHandler("ellipse"),
    line: createLineHandler(),
  };

  let onChange = (geometry: Geometry) => {
    setCurrentGeometry(geometry);
  };

  const SelectedIcon = GeometryIcons[currentGeometry];
  const geometries: Geometry[] = isEnabled("lines")
    ? ["rect", "ellipse", "line"]
    : ["rect", "ellipse"];

  return (
    <ToolbarContextMenu value={null} onChange={onChange} position="above">
      <ToolbarContextMenuButton>
        <IconButton
          {...props}
          icon={<SelectedIcon />}
          aria-label={GeometryLabels[currentGeometry]}
          {...handlers[currentGeometry]}
        />
      </ToolbarContextMenuButton>
      <ToolbarContextMenuItems>
        {geometries.map((geometry) => {
          const Icon = GeometryIcons[geometry];
          return (
            <ToolbarContextMenuItem key={geometry} value={geometry}>
              <IconButton
                icon={<Icon />}
                aria-label={geometry}
                {...handlers[geometry]}
              />
            </ToolbarContextMenuItem>
          );
        })}
      </ToolbarContextMenuItems>
    </ToolbarContextMenu>
  );
};

interface StickyButtonProps extends NativeButtonProps {
  createStickyHandler: (color: StickyColor) => CreateObjectHandlers;
}

let StickyButton = ({ createStickyHandler, ...props }: StickyButtonProps) => {
  const dispatch = useDispatch();
  const currentStickyColor =
    (useSelector(Client.getCurrentStickyColor) as StickyColor) ?? "sticky1";

  let onChange = (color: string) => {
    dispatch(Client.setCurrentStickyColor(color));
  };

  return (
    <ToolbarContextMenu value={null} onChange={onChange} position="above">
      <ToolbarContextMenuButton>
        <IconButton
          {...props}
          icon={<Icons.Sticky color={`var(--${currentStickyColor})`} />}
          aria-label={StickyLabels[currentStickyColor]}
          {...createStickyHandler(currentStickyColor)}
        />
      </ToolbarContextMenuButton>
      <ToolbarContextMenuItems>
        {stickyColors.map((color) => (
          <ToolbarContextMenuItem key={color} value={color}>
            <IconButton
              icon={<Icons.Sticky color={`var(--${color})`} />}
              aria-label={StickyLabels[currentStickyColor]}
              {...createStickyHandler(color)}
            />
          </ToolbarContextMenuItem>
        ))}
      </ToolbarContextMenuItems>
    </ToolbarContextMenu>
  );
};

const useCollapseMenuItems = () => {
  let [windowWidth] = useWindowSize();
  return windowWidth <= 666;
};
