import { orderBy } from "lodash";
import { isDesktop } from "react-device-detect";
import { Middleware, Dispatch } from "redux";
import { createSelector } from "reselect";

import { Action, AppState } from "~/store";
import { BaseAction, BaseEffects, KeepAliveEffect } from "~/store/shared";
import assert from "~/util/assert";
import { filterObject } from "~/util/filterObject";
import { Point } from "~/util/geometry";
import { createShallowEqualSelector } from "~/util/selectors";

import * as Client from "./Client";
import * as Sticker from "./Sticker";
import {
  Alive,
  BaseObject,
  Color,
  Connect,
  Create,
  Delete,
  Edit,
  Grab,
  Lock,
  Resize,
  Position,
} from "../traits";

const DEFAULT_WIDTH = 200;
const DEFAULT_HEIGHT = 200;
const DEFAULT_COLOR = "sticky3";

/**
 * Interface that defines a `Sticky` object.
 */
export interface Sticky
  extends BaseObject.BaseObject,
    Create.Create,
    Delete.Delete,
    Alive.Alive,
    Edit.Edit,
    Color.Color,
    Resize.Resize,
    Lock.Lock,
    Position.Position,
    Grab.Grab,
    Connect.Connect {
  type: "sticky";
  editMode: "canvas" | "profile" | null;
  collaborators: string[];
  attachments: Record<string, Attachment>;
  /**
   * For legacy reasons, `votes` store all types of
   * stickers (votes, reactions and avatars).
   */
  votes: string[];
}

/**
 * Interface that defines a Sticky comment.
 */
export interface Comment {
  id: string;
  stickyId: string;
  parent: string | null;
  body: string;
  author: string;
  timestamp: number;
  deleted: boolean;
}

/**
 * Interface that defines a Sticky attachment.
 */
export interface Attachment {
  id: string;
  stickyId: string;
  userId: string;
  filename: string;
  filetype: string;
  status: "pending" | "success" | "failed";
  url: string | null;
  deleted: boolean;
}

/**
 * Type guard for narrowing a `BaseObject` type to a `Sticky`.
 *
 * @param {BaseObject.BaseObject} object - The object whose type we want to
 * narrow.
 * @returns {boolean} Whether or not the object is of type `Sticky`.
 */
export const isSticky = (object: BaseObject.BaseObject): object is Sticky => {
  return object.type === "sticky";
};

/**
 * Swap out any IDs according to a provided lookup table.
 *
 * @param {Sticky} sticky - The sticky object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Sticky} The modified sticky with ids replaced.
 */
const swapIds = (sticky: Sticky, lookup: (id: string) => string): Sticky => {
  return {
    ...sticky,
    id: lookup(sticky.id),
    creating: sticky.creating ? lookup(sticky.creating) : null,
    grab: sticky.grab
      ? { ...sticky.grab, userId: lookup(sticky.grab.userId) }
      : null,
    editing: sticky.editing ? lookup(sticky.editing) : null,
    resize: sticky.resize
      ? { ...sticky.resize, userId: lookup(sticky.resize.userId) }
      : null,
  };
};

/**
 * Remove any secondary information that we wouldn't want to appear, in a
 * template/fork.
 *
 * @param {Sticky} sticky - Sticky to convert into a template
 * @returns {Sticky} Template created from the provided `Sticky` object.
 */
export const template = (sticky: Sticky): Sticky => ({
  ...sticky,
  votes: [],
  collaborators: [],
  creating: null,
  grab: null,
  resize: null,
  editing: null,
  editMode: null,
  attachments: {},
  keepAlive: null,
});

/**
 * Fork a label by swapping out ids using a provided lookup table.
 *
 * @param {Sticky} sticky - The sticky object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Sticky} The modified sticky with ids replaced.
 */
export const fork = (
  sticky: Sticky,
  lookup: (id: string) => string
): Sticky => {
  return {
    ...swapIds(sticky, lookup),
    votes: [], // Wipe votes/reactions, don't think we ever want these copied over.
  };
};

/**
 * Union of all possible `Sticky` actions.
 */
export type StickyAction =
  | AddAction
  | AddAttachmentAction
  | UpdateAttachmentStatusAction
  | RemoveAttachmentAction
  | AddStickerAction
  | RemoveStickerAction
  | SetColorAction
  | SetCollaboratorsAction;

export interface AddAction extends BaseAction {
  type: "sticky/add";
  data: Sticky;
}

/**
 * Action creator for adding a sticky to the app state.
 *
 * @typedef {Object} AddArgs
 * @property {string} id - The id for the to-be-added sticky.
 * @property {Point} mousePosition - The mouse position to be used for placing the sticky.
 * @property {number} width - The width of the sticky.
 * @property {number} height - The height of the sticky.
 *
 * @param {AddArgs} - The parameters for the sticky.
 * @returns The action thunk to be dispatched to the store.
 */
export const add =
  ({
    id,
    mousePosition,
    width = DEFAULT_WIDTH,
    height = DEFAULT_HEIGHT,
    color = null,
    content = "",
  }: {
    id: string;
    mousePosition: Point;
    width?: number;
    height?: number;
    color?: string | null;
    content?: string;
  }) =>
  (state: AppState): AddAction => {
    let scene = Client.getScene(state);
    let scenePosition = Client.toSceneCoordinates(scene, mousePosition);

    if (!color) {
      color = Client.getCurrentStickyColor(state) ?? DEFAULT_COLOR;
    }

    let position = {
      x: scenePosition.x - width / 2,
      y: scenePosition.y - height / 2,
    };

    return {
      type: "sticky/add",
      data: {
        id,
        type: "sticky",
        editing: null,
        creating: null,
        resize: null,
        position,
        width,
        height,
        color,
        content,
        draft: "",
        collaborators: [],
        attachments: {},
        locked: false,
        deleted: false,
        votes: [],
        keepAlive: null,
        grab: null,
        editMode: null,
      },
      effects: {
        broadcast: true,
        persist: true,
        dispatch: [Client.setCurrentStickyColor(color)],
      },
    };
  };

export interface AddAttachmentAction extends BaseAction {
  type: "sticky/add_attachment";
  data: Pick<Sticky, "id" | "attachments">;
}

/**
 * Action creator that adds an attachment to a sticky.
 *
 * @param {string} id - The id of the attachment to be added.
 * @param {string} stickyId - The id of the sticky to add the attachment to.
 * @param {string} userId - The id of the user adding the attachment.
 * @param {string} filename - The filename of the attached file.
 * @param {string} filetype - The filetype of the attached file.
 * @returns The action thunk to be dispatched to the store.
 */
export const addAttachment =
  (
    id: string,
    stickyId: string,
    userId: string,
    filename: string,
    filetype: string
  ) =>
  (state: AppState): AddAttachmentAction => {
    let { attachments } = getById(stickyId)(state);
    let newAttachment: Attachment = {
      id,
      stickyId,
      userId,
      filename,
      filetype,
      status: "pending",
      url: null,
      deleted: false,
    };

    return {
      type: "sticky/add_attachment",
      data: {
        id: stickyId,
        attachments: { ...attachments, [id]: newAttachment },
      },
      effects: { broadcast: true, persist: true },
    };
  };

export interface UpdateAttachmentStatusAction extends BaseAction {
  type: "sticky/update_attachment_status";
  data: Pick<Sticky, "id" | "attachments">;
}

/**
 * Action creator for updating an attachment's upload status.
 *
 * @param {string} stickyId - The id of the sticky the attachment belongs to.
 * @param {string} id - The id of the attachment
 * @param {"pending" | "success" | "failed"} status - The status of the
 * attachment we want to update.
 * @param {string} url - The url where the attached file is located.
 * @returns The action thunk to be dispatched to the store.
 */
export const updateAttachmentStatus =
  (
    stickyId: string,
    id: string,
    status: "pending" | "success" | "failed",
    url: string
  ) =>
  (state: AppState): UpdateAttachmentStatusAction => {
    let { attachments } = getById(stickyId)(state);
    let newAttachment: Attachment = { ...attachments[id], status };

    if (status === "success") newAttachment.url = url;

    return {
      type: "sticky/update_attachment_status",
      data: {
        id: stickyId,
        attachments: { ...attachments, [id]: newAttachment },
      },
      effects: { broadcast: true, persist: true },
    };
  };

export interface RemoveAttachmentAction extends BaseAction {
  type: "sticky/remove_attachment";
  data: Pick<Sticky, "id" | "attachments">;
}

/**
 * Action creator that removes an attachment from a sticky.
 *
 * @param {string} stickyId - The id of the sticky the attachment belongs to.
 * @param {string} id - The id of the attachment.
 * @returns The action thunk to be dispatched to the store.
 */
export const removeAttachment =
  (stickyId: string, id: string) =>
  (state: AppState): RemoveAttachmentAction => {
    let { attachments } = getById(stickyId)(state);
    let newAttachment = { ...attachments[id], deleted: true };
    return {
      type: "sticky/remove_attachment",
      data: {
        id: stickyId,
        attachments: { ...attachments, [id]: newAttachment },
      },
      effects: { broadcast: true, persist: true },
    };
  };

export interface AddStickerAction extends BaseAction {
  type: "sticky/add_sticker";
  data: Pick<Sticky, "id" | "votes">;
}

export const addSticker =
  /**
   * Action creator that adds a sticker to a sticky.
   *
   * @param {string} id - The id of the sticky we want to attach the sticker to.
   * @param {string} stickerId - The id of the sticker we wish to place.
   * @returns The action thunk to be dispatched to the store.
   */


    (id: string, stickerId: string) =>
    (state: AppState): AddStickerAction => {
      let { votes: stickers } = getById(id)(state);
      return {
        type: "sticky/add_sticker",
        data: { id, votes: [...stickers, stickerId] },
        effects: {
          broadcast: true,
          persist: true,
          dispatch: [Sticker.assignRecipient(stickerId, id)],
        },
      };
    };

export interface RemoveStickerAction extends BaseAction {
  type: "sticky/remove_sticker";
  data: Pick<Sticky, "id" | "votes">;
}

/**
 * Action creator that removes a sticker from a sticky.
 *
 * @param {string} id - The id of the sticky we wish to remove the sticker from.
 * @param {string} stickerId - The id of the sticker we wish to remove.
 * @returns The action thunk to be dispatched to the store.
 */
export const removeSticker =
  (id: string, stickerId: string) =>
  (state: AppState): RemoveStickerAction => {
    let { votes: stickers } = getById(id)(state);
    return {
      type: "sticky/remove_sticker",
      data: { id, votes: stickers.filter((v) => v !== stickerId) },
      effects: {
        broadcast: true,
        persist: true,
        dispatch: [Sticker.removeRecipient(stickerId)],
      },
    };
  };

export interface SetColorAction extends BaseAction {
  type: "sticky/set_color";
  data: Pick<Sticky, "id" | "color">;
}

/**
 * Action creator for setting the color of a sticky.
 *
 * @param {string} id - The id of the sticky whose color we wish to set.
 * @param {string} color - The (RGB) color string to set.
 * @returns {SetColorAction} The action to dispatch to the store.
 */
export const setColor = (id: string, color: string): SetColorAction => {
  return {
    type: "sticky/set_color" as const,
    data: { id, color },
    effects: { broadcast: true, persist: true },
  };
};

export interface SetCollaboratorsAction
  extends BaseAction<BaseEffects & KeepAliveEffect> {
  type: "sticky/set_collaborators";
  data: Pick<Sticky, "id" | "collaborators">;
}

/**
 * Action creator that updates the array of collaborators of a sticky.
 *
 * @param {string} id - The id of the sticky we wish to update.
 * @param {string[]} collaborators - The updated array of collaborator user ids.
 * @returns {SetCollaboratorsAction} The action to dispatch to the store.
 */
export const setCollaborators = (
  id: string,
  collaborators: string[]
): SetCollaboratorsAction => {
  return {
    type: "sticky/set_collaborators",
    data: { id, collaborators },
    effects: {
      broadcast: true,
      persist: true,
      keepAlive: true,
    },
  };
};

/**
 * Reducer that processes any `Sticky` actions.
 *
 * @param {AppState["objects"]} [state] - The current `objects` state.
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["objects"]} The updated state slice.
 */
export const reducer = (
  state: AppState["objects"] = {},
  action: Action
): AppState["objects"] => {
  switch (action?.type) {
    case "sticky/add":
      return { ...state, [action.data.id]: action.data };

    case "sticky/add_attachment":
    case "sticky/update_attachment_status":
    case "sticky/remove_attachment":
    case "sticky/add_sticker":
    case "sticky/remove_sticker":
    case "sticky/set_color":
    case "sticky/set_collaborators": {
      const object = state[action.data.id];
      assert(isSticky(object), "Object is not a Sticky.");

      return { ...state, [object.id]: { ...state[object.id], ...action.data } };
    }

    default:
      return state;
  }
};

/**
 * Redux middleware that is used to intercept general actions to inject `Sticky`
 * specific behavior.
 *
 * @param  store - The redux store object
 * @param  next - The next redux middleware in the middleware chain.
 * @param  action - The dispatched action we're intercepting
 * @returns The return value of the `next` middleware.
 */
export const middleware: Middleware<{}, AppState, Dispatch<Action>> =
  ({ dispatch, getState }) =>
  (next) =>
  (action: Action) => {
    let additionalActions: Action[] = [];
    const state = getState();

    // Dispatch any additional behaviors
    switch (action.type) {
      /*
       * When a sticky finishes being created, start editing it.
       */
      case "create/finish_creating": {
        const sticky = getById(action.data.id)(state);

        if (sticky?.creating) {
          additionalActions = [
            ...additionalActions,
            setCollaborators(action.data.id, [sticky.creating]),
          ];

          if (isDesktop) {
            additionalActions.push(
              Edit.startEditing(
                action.data.id,
                sticky.creating,
                "canvas"
              )(state)
            );
          }
        }

        break;
      }

      /*
       * When a grabbed sticky is released, release all associated votes/reactions
       * as well.
       */
      case "grab/finish_grabbing": {
        const stickers = getStickers(action.data.id)(state);

        /*
         * Dispatch an extra "Grab.finish" action for all the sticky's votes/reactions
         * to force a database persist after the moving is done.
         */
        for (const stickerId of stickers) {
          additionalActions.push(Grab.finishGrabbing(stickerId)(state));
        }

        break;
      }

      case "resize/resize": {
        /*
         * Votes/reactions should move when the sticky is resized to avoid them dropping
         * off the edge (and also just not to look dumb).
         */
        const sticky = getById(action.data.id)(state);

        if (sticky) {
          const stickers = getStickers(action.data.id)(state);
          const scene = Client.getScene(state);

          const {
            position: newPosition,
            width: newWidth,
            height: newHeight,
          } = action.data;

          const stickerActions = stickers.map((stickerId) => {
            let sticker = Sticker.getById(stickerId)(state);

            let relativePos = {
              x: (sticker.position.x - sticky.position.x) / sticky.width,
              y: (sticker.position.y - sticky.position.y) / sticky.height,
            };

            let pseudoPosition = Client.toScreenCoordinates(scene, {
              x: newPosition.x + relativePos.x * newWidth,
              y: newPosition.y + relativePos.y * newHeight,
            });

            return Position.position(stickerId, pseudoPosition)(state);
          });

          additionalActions = [...additionalActions, ...stickerActions];
        }

        break;
      }

      case "grab/move":
      case "position/position_by": {
        const sticky = getById(action.data.id)(state);

        if (sticky) {
          const stickers = getStickers(action.data.id)(state);

          const delta = {
            x: action.data.position.x - sticky.position.x,
            y: action.data.position.y - sticky.position.y,
          };

          additionalActions = [
            ...additionalActions,
            Position.displaceManyBy(stickers, delta)(state),
          ];
        }

        break;
      }

      /*
       * When editing a sticky, attach the editMode to the action payload
       */
      case "edit/start_editing": {
        return next({
          ...action,
          data: { ...action.data, editMode: action.metadata.editMode },
        });
      }

      /*
       * When finished editing, add the editing userId to the collaborators array.
       */
      case "edit/finish_editing": {
        const sticky = getById(action.data.id)(state);
        if (!sticky || !sticky.editing) return next(action);

        const newCollaborators = sticky.collaborators.includes(sticky.editing)
          ? sticky.collaborators
          : [...sticky.collaborators, sticky.editing];

        return next({
          ...action,
          data: {
            ...action.data,
            collaborators: newCollaborators,
          },
        });
      }

      /*
       * When a sticky's color is updated, set the current sticky color.
       */
      case "color/set_color": {
        const sticky = getById(action.data.id)(state);
        if (!sticky) return next(action);

        additionalActions.push(Client.setCurrentStickyColor(action.data.color));

        break;
      }

      case "grab/move_many": {
        for (const id in action.data) {
          const sticky = getById(id)(state);

          if (sticky) {
            const stickers = getStickers(id)(state);

            const delta = {
              x: action.data[id].position.x - sticky.position.x,
              y: action.data[id].position.y - sticky.position.y,
            };

            additionalActions = [
              ...additionalActions,
              Position.displaceManyBy(stickers, delta)(state),
            ];
          }
        }

        break;
      }

      /*
       * Release sticky whose `keepAlive` timer has expired.
       *
       * Unset the creating, grabbing, editing and resizing properties.
       */
      case "alive/release_expired": {
        for (const id in action.data) {
          const sticky = getById(id)(state);
          if (!sticky || sticky.deleted) continue;

          if (sticky.grab)
            additionalActions.push(Grab.finishGrabbing(id)(state));

          if (sticky.resize)
            additionalActions.push(Resize.finishResizing(id)(state));

          if (sticky.editing)
            additionalActions.push(Edit.finishEditing(id)(state));

          if (sticky.creating)
            additionalActions.push(Create.finishCreating(id)(state));
        }

        break;
      }

      /**
       * When a sticky is removed, remove any associated stickers.
       * Note that cnnections are removed in the connect trait.
       */
      case "delete/delete": {
        const object = getById(action.data.id)(state);
        if (!object) break;

        const stickers = getStickers(action.data.id)(state);
        const additionalIds = [...stickers];

        return dispatch(
          Delete.deleteMany([object.id, ...additionalIds])(state)
        );
      }

      /**
       * When a sticky is removed, remove any associated stickers.
       * Note that cnnections are removed in the connect trait.
       */
      case "delete/delete_many": {
        const ids = Object.keys(action.data);

        const additionalIds = ids.flatMap((id) => {
          const sticky = getById(id)(state);
          if (!sticky) return [];

          const stickerIds = getStickers(sticky.id)(state);
          return [...stickerIds];
        });

        // NOTE: We pass this new action on to the next middleware instead
        // of running it through the dispatch chain again so we don't end up
        // in an infinite loop
        return next(Delete.deleteMany([...ids, ...additionalIds])(state));
      }
    }

    // Process the actual action first
    const result = next(action);

    // Dispatch additional actions
    additionalActions.forEach(dispatch);

    // Return result of original action
    return result;
  };

/**
 * Selector that returns a record of all stickies in the board state, keyed by
 * their id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, Sticky>} A record of all stickies in the app state.
 */
export const getStickies = createShallowEqualSelector(
  BaseObject.getAll,
  (objects) => filterObject(objects, isSticky)
);

/**
 * Selector that returns an array of all stickies, removing the deleted ones.
 *
 * @param {AppState} state - The current app state.
 * @returns An array of (non-deleted) stickies.
 */
export const getAll = createSelector(getStickies, (stickies) =>
  Object.values(stickies).filter((st) => !st.deleted)
);

/**
 * Selector that returns an array of all undeleted stickies ordered by date last updated.
 * Locked stickies are placed below unlocked ones
 *
 * @param {AppState} state - The current app state.
 * @returns An ordered array of (non-deleted) stickies.
 */
// Even though we already use z-index on the board to place locked objects below
// we stille need to do it in the ordering because the SVG renderer can't use z-index
// and selected object all receive the same z-index so this is required to preserve the
// ordering within a selection.
export const getAllOrdered = createSelector(getAll, (stickies) =>
  orderBy(stickies, ["locked", "updatedAt"], ["desc", "asc"])
);

/**
 * Selector creator for getting the sticky with a given id.
 *
 * @param {string} id - The id of the sticky we want to get
 * @returns Selector that returns the sticky.
 */
export const getById = (id: string) =>
  createSelector(getStickies, (stickies) => stickies[id]);

/**
 * Selector creator that returns the stickers associated with a sticky.
 *
 * For legacy reasons, `votes` store all types of stickers (votes,
 * reactions and avatars).
 *
 * @param {string} id - The id of the sticky we're interested in.
 * @returns Selector that returns an array of sticker ids.
 */
export const getStickers = (id: string) => {
  return createSelector(getById(id), (sticky) => sticky?.votes ?? []);
};

/**
 * Selector creator for getting all stickies created by the provided user id.
 *
 * @param {string} userId - The id of the user who created the stickies.
 * @returns Selector that returns the desired stickies.
 */
export const getCreatedBy = (userId: string) =>
  createSelector(getAll, (stickies) => {
    return stickies
      .filter((s) => s.collaborators[0] === userId)
      .map((s) => s.id);
  });
