let faviconLinkElement = document.querySelector("link[rel=icon]")!;
let originalFaviconHref = faviconLinkElement.getAttribute("href")!;

export function resetFavicon() {
  setFavicon(originalFaviconHref);
}

export function setFavicon(href: string) {
  faviconLinkElement.setAttribute("href", href);
}
