import "./CommentEditor.css";

import { Suspense, useReducer, useState } from "react";
import React from "react";

import usePermission from "~/util/usePermission";

import * as Icons from "./Icons";
import { Box } from "./Box";
import { Disabled } from "./Disabled";
import { Spinner } from "./Spinner";
import { Tooltip } from "./Tooltip";

const RichTextEditor = React.lazy(() => import("./RichTextEditor"));

interface CommentEditorProps {
  defaultValue?: string;
  onSubmit(content: string): void;
  onCancel?(): void;
  autoFocus?: boolean;
  placeholder?: string;
}

export const CommentEditor = ({
  defaultValue,
  autoFocus,
  onSubmit,
  onCancel,
  placeholder,
}: CommentEditorProps) => {
  let [draft, setDraft] = useState(defaultValue || "");
  let can = usePermission();
  let disabled = !can("comment");

  let [showFormattingControls, toggleFormattingControls] = useReducer(
    (on) => !on,
    false
  );

  function submit() {
    let commentIsEmpty =
      draft
        .replaceAll("<p>", "")
        .replaceAll("</p>", "")
        .replaceAll("<br>", "")
        .trim() === "";
    if (!commentIsEmpty) onSubmit(draft);
    setDraft("");
  }

  function handleKeydown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      return onCancel?.();
    }

    // Prevent keypresses from triggering board shortcuts
    event.stopPropagation();
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      return submit();
    }
  }

  return (
    <Disabled disabled={!can("comment")} msg="Comments are disabled">
      <Suspense fallback={<LoadingSpinner />}>
        <div className="comment-editor">
          <RichTextEditor
            value={draft}
            onChange={setDraft}
            autoFocus={autoFocus && !disabled}
            placeholder={placeholder}
            defaultValue={defaultValue}
            className="comment-editor-input"
            showToolbar={showFormattingControls}
            onKeyDown={handleKeydown}
          />
          <div className="comment-editor-actions">
            <Tooltip
              label={
                showFormattingControls ? "Hide formatting" : "Show formatting"
              }
              position="right"
            >
              <button
                disabled={disabled}
                className="comment-editor-action"
                onClick={toggleFormattingControls}
                aria-pressed={showFormattingControls}
              >
                Aa
              </button>
            </Tooltip>
            <Tooltip label="Send" position="left">
              <button
                disabled={disabled}
                className="comment-editor-action"
                aria-label="Send"
                onClick={submit}
              >
                <Icons.Send />
              </button>
            </Tooltip>
          </div>
        </div>
      </Suspense>
    </Disabled>
  );
};

let LoadingSpinner = () => (
  <Box align="center" justify="center" id="rich-text-editor-loading-spinner">
    <Spinner />
  </Box>
);
