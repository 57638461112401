import { ReactNode, useState } from "react";

import { disable, enable, Flag, isEnabled } from "~/util/FeatureFlags";

import { BoardModal } from "./BoardModal";
import { Box, HStack, VStack } from "./Box";
import { ModalDescription, ModalPanel, ModalProps, ModalTitle } from "./Modal";

interface FlagDetails {
  name: ReactNode;
  description: ReactNode;
  type: "preview" | "experiment";
}

let flagDetails: { [K in Flag]: FlagDetails | null } = {
  // These feature flags are hidden from users
  alpha: null,
  stickyfan: null,

  lines: {
    name: "Lines",
    description: "Adds a line object to the dock",
    type: "preview",
  },
};

export let FeatureFlagsModal = (props: ModalProps) => {
  let flags = Object.keys(flagDetails) as Flag[];
  let [, setState] = useState<[]>();
  let forceUpdate = () => setState([]);

  return (
    <BoardModal {...props}>
      <ModalPanel>
        <ModalTitle>⛳️ Feature Flags</ModalTitle>
        <ModalDescription>
          <Box gap={4}>
            <Box gap={4}>
              <p>
                These are features we're not ready to share with everyone yet.
                Enabling them turns them on in this browser. Not just in this
                board, or this account.
              </p>
              <p>
                We don't know how well they work and{" "}
                <strong>they might break your boards</strong>!
              </p>
            </Box>
            <VStack gap={2}>
              {flags.map((flag) => {
                let details = flagDetails[flag];
                return (
                  details && (
                    <FeatureFlagToggle
                      key={flag}
                      flag={flag}
                      details={details}
                      enabled={isEnabled(flag)}
                      onToggle={() => {
                        isEnabled(flag) ? disable(flag) : enable(flag);
                        forceUpdate();
                      }}
                    />
                  )
                );
              })}
            </VStack>
          </Box>
        </ModalDescription>
      </ModalPanel>
    </BoardModal>
  );
};

export let FeatureFlagToggle = ({
  flag,
  details,
  enabled,
  onToggle,
}: {
  flag: Flag;
  details: FlagDetails;
  enabled: boolean;
  onToggle: () => void;
}) => {
  let id = `flag-${flag}`;
  return (
    <Box
      as={"label"}
      htmlFor={id}
      p={2}
      className="rounded"
      style={{
        border: "solid 2px",
        borderColor: enabled ? "var(--blue-2)" : "var(--grey-1)",
      }}
    >
      <HStack gap={2}>
        <input
          type="checkbox"
          defaultChecked={enabled}
          id={id}
          onChange={onToggle}
        />
        <strong>{details.name}</strong>
        <FeatureFlagType type={details.type} />
      </HStack>
      <Box className="text-sm">{details.description}</Box>
    </Box>
  );
};

export let FeatureFlagType = ({ type }: { type: FlagDetails["type"] }) => {
  return (
    <span
      className="text-xs font-bold rounded px-2 py-1"
      style={{
        color: type === "experiment" ? "var(--green-4)" : "var(--yellow-4)",
      }}
    >
      {type === "experiment" ? "🧪 Experiment" : "🔭 Preview"}
    </span>
  );
};
