import "./Page.css";

import React, { CSSProperties, ReactNode } from "react";
import {
  HashLink as Link,
  HashLinkProps as LinkProps,
} from "react-router-hash-link";

import background from "~/assets/background.svg";
import kumuLogo from "~/assets/kumu.png";
import { useAuth } from "~/context/AuthContext";
import { classNames } from "~/util/classNames";

import * as Icons from "./Icons";
import { Box } from "./Box";
import { Button } from "./Button";

const defaultStyles: CSSProperties = {
  backgroundImage: `url(${background})`,
};

type Props = {
  style?: CSSProperties;
  className?: string;
  children: React.ReactNode;
};

export const Page = ({
  style = defaultStyles,
  className = "",
  children,
}: Props) => (
  <Box className={classNames("page2", className)} style={style}>
    {children}
  </Box>
);

export const Section = ({
  wide = false,
  dim = false,
  tight = false,
  children,
  className = "",
  containerClassName = "",
}: {
  wide?: boolean;
  dim?: boolean;
  tight?: boolean;
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
}) => {
  return (
    <section
      className={classNames(
        "section",
        dim && "section-dim",
        tight && "section-tight",
        className
      )}
    >
      <Box
        className={classNames(
          "section-container",
          wide && "section-container-wide",
          containerClassName
        )}
      >
        {children}
      </Box>
    </section>
  );
};

export const Header = ({
  children = <DefaultHeaderContents />,
}: {
  children?: ReactNode;
}) => {
  return (
    <Box
      className="header"
      as="header"
      direction="row"
      align="center"
      justify="between"
    >
      {children}
    </Box>
  );
};

export const DefaultHeaderContents = () => (
  <>
    <HeaderLogo />
    <HeaderNavigation />
  </>
);

export const Footer = ({ children = <DefaultFooterContents /> }) => {
  return (
    <footer className="footer">
      <Box className="footer-container" direction="row" justify="between">
        {children}
      </Box>
    </footer>
  );
};

export const DefaultFooterContents = () => (
  <>
    <a
      href="https://kumu.io"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-2 whitespace-nowrap"
    >
      <img src={kumuLogo} alt="Kumu Logo" width={32} height={32} />
      <span className="footer-tagline">
        Tools by <strong>Kumu</strong>
      </span>
    </a>
    <Box direction="row" align="center" gap={5}>
      <Link to="/privacy">Privacy</Link>
      <Link to="/terms">Terms</Link>
    </Box>
  </>
);

export const HeaderLogo = () => {
  return (
    <Link to="/product" className="header-logo">
      <Box direction="row" gap={1} align="center">
        <Icons.Logo width={24} height={24} />{" "}
        <span className="text">Sticky Studio</span>
      </Box>
    </Link>
  );
};

export const HeaderNavigation = ({
  children = <HeaderDefaultNavigationContents />,
}) => (
  <Box className="nav" as="nav" direction="row" align="center" gap={7}>
    {children}
  </Box>
);

export const HeaderDefaultNavigationContents = () => {
  const auth = useAuth();

  return (
    <>
      <HeaderLink className="header-link-pricing" to="/product#pricing">
        Pricing
      </HeaderLink>
      {auth.authenticated ? (
        <HeaderLink to="/">
          <Button flat>Dashboard</Button>
        </HeaderLink>
      ) : (
        <>
          <HeaderLink to="/login">Login</HeaderLink>
          <HeaderLink to="/signup">
            <Button flat>Sign Up</Button>
          </HeaderLink>
        </>
      )}
    </>
  );
};

export const HeaderLink = ({ children, ...props }: LinkProps) => (
  <Link {...props} className={classNames("header-link", props.className)}>
    {children}
  </Link>
);

export const Divider = () => <hr className="divider" />;
