import React, { CSSProperties, useMemo } from "react";
import { useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import { isSafari } from "~/util/browser";

/**
 * Gives its children a fixed size, regardless of the scene's zoom level.
 * Useful for objects on the canvas that should be visible at any size.
 *
 * @param min Minimum scale factor to apply to its contents.
 * @param max Maximum scale factor to apply to its contents.
 * @param children The children to render inside this component.
 */
export const FixedScale = ({
  min = 0,
  max = Infinity,
  origin,
  children,
}: {
  min?: number;
  max?: number;
  origin?: string;
  children: React.ReactNode;
}) => {
  let style = useFixedScaleStyle({ min, max, origin });
  return <div style={style}>{children}</div>;
};

/**
 * Convenience hook for getting fixed scale styles. Useful for times when it
 * wouldn't be appropriate to wrap the contents in a `div` to apply the fixed
 * scale.
 *
 * @see FixedScale
 */
export const useFixedScaleStyle = ({
  min = 0,
  max = Infinity,
  origin = "center",
}: {
  min: number;
  max: number;
  origin?: string;
}) => {
  let scale = useSelector(Client.getScale);
  scale = 1 / scale;
  scale = Math.max(scale, min);
  scale = Math.min(scale, max);

  // Fixed scaling moves the children to the wrong positions on Safari.
  // https://github.com/kumu/sticky-studio/pull/261#issuecomment-987711311
  return useMemo<CSSProperties>(() => {
    return isSafari
      ? {}
      : {
          transform: `scale(${scale})`,
          transformOrigin: origin,
        };
  }, [scale, origin]);
};
