import "./ContextMenu.css";

import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Link } from "react-router-dom";

import * as BoardObject from "~/store/bundles/BoardObject";
import * as Client from "~/store/bundles/Client";
import { BoardType, useConfig } from "~/context/ConfigContext";
import { BaseObject, Delete, Edit, Lock } from "~/store/traits";
import {
  insertFromBlob,
  objectsToBlobs,
  readBlobFromClipboard,
  writeBlobsToClipboard,
} from "~/util/clipboard";
import { useBoardPath, useWindowClickEvent } from "~/util/hooks";

import * as Icons from "./Icons";
import { useFloat } from "./FloatProvider";
import { Menu, MenuItem, MenuItems } from "./Menu";
import { Shortcut } from "./Shortcut";

type Selection = BaseObject.BaseObject[];

const canLockSelection = (objects: Selection) => objects.some(Lock.isLock);

const canDeleteSelection = (objects: Selection) =>
  objects.some(Delete.isDelete);

const canOpenComments = (objects: Selection) => objects.length === 1;

const canEditText = (objects: Selection) =>
  objects.length === 1 && objects.every(Edit.isEdit);

export const ContextMenu = React.memo(() => {
  const config = useConfig();
  const { getState } = useStore();
  const isSandbox = config.type === BoardType.Sandbox;
  const selectionIds = useSelector(Client.getSelected);
  const contextMenu = useSelector(Client.getContextMenu);
  useCloseHandler();
  const undos = useSelector(Client.getUndos);
  const redos = useSelector(Client.getRedos);
  let [canPaste, setCanPaste] = useState(false);

  useEffect(() => {
    const checkIfCanPaste = async () => {
      const blob = await readBlobFromClipboard();
      setCanPaste(!!blob);
    };
    checkIfCanPaste();
  }, []);

  if (!contextMenu) return null;

  // Manually fetch the selected objects so the hooks don't trigger too many
  // re-renders.
  const selection = selectionIds
    .map((id) => BoardObject.getAll(getState())[id])
    .filter((object) => object !== undefined);

  const canComment = !isSandbox && canOpenComments(selection);
  const canLock = canLockSelection(selection);
  const canDelete = canDeleteSelection(selection);
  const canEdit = canEditText(selection);
  const canUndo = undos.length > 0;
  const canRedo = redos.length > 0;

  return (
    <div className="canvas-context-menu">
      <Menu placement="right-start">
        <ContextMenuItems>
          {canEdit && <EditOption selection={selection} />}
          {canComment && <CommentsOption selection={selection} />}
          {canDelete && <DeleteOption selection={selection} />}
          {canLock && <LockOption selection={selection} />}
          {selection.length > 0 && <CopyOption />}
          {<PasteOption disabled={!canPaste} />}
          {<UndoOption disabled={!canUndo} />}
          {<RedoOption disabled={!canRedo} />}
        </ContextMenuItems>
      </Menu>
    </div>
  );
});

const ContextMenuItems = ({ children }: { children: ReactNode }) => {
  const { setReference } = useFloat();
  const { position } = useSelector(Client.getContextMenu)!;

  useEffect(() => {
    setReference({
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x: position.x,
          y: position.y,
          top: position.y,
          right: position.x,
          bottom: position.y,
          left: position.x,
        };
      },
    });
  }, [position, setReference]);

  return <MenuItems static={true}>{children}</MenuItems>;
};

ContextMenu.displayName = "ContextMenu";

const DeleteOption = ({
  selection,
}: {
  selection: BaseObject.BaseObject[];
}) => {
  const dispatch = useDispatch();
  const objects = selection
    .filter(Delete.isDelete)
    .filter((object) => !Lock.isLock(object) || !object.locked);

  const onDelete: React.MouseEventHandler = () => {
    const ids = objects.map((object) => object.id);
    dispatch(Delete.deleteMany(ids));
  };

  return (
    <Item onClick={onDelete} shortcutName="delete">
      <Icons.Trash /> Delete
    </Item>
  );
};

const LockOption = ({ selection }: { selection: BaseObject.BaseObject[] }) => {
  const dispatch = useDispatch();
  const objects = selection.filter(Lock.isLock);
  const allLocked = objects
    .filter(Lock.isLock)
    .every((object) => object.locked);

  const onLock: React.MouseEventHandler = () => {
    for (const object of objects) {
      if (allLocked) {
        dispatch(Lock.unlock(object.id));
      } else {
        dispatch(Lock.lock(object.id));
      }
    }
  };

  return (
    <Item onClick={onLock} shortcutName="lock">
      {allLocked ? (
        <>
          <Icons.Unlock /> Unlock
        </>
      ) : (
        <>
          <Icons.Lock /> Lock
        </>
      )}
    </Item>
  );
};

const EditOption = ({ selection }: { selection: BaseObject.BaseObject[] }) => {
  const dispatch = useDispatch();
  const userId = useSelector(Client.getUserId);
  const object = selection[0];

  const onEdit: React.MouseEventHandler = () => {
    dispatch(Edit.startEditing(object.id, userId));
  };

  return (
    <Item onClick={onEdit}>
      <Icons.Edit /> Edit
    </Item>
  );
};

const CopyOption = () => {
  const store = useStore();
  const onCopy = async () => {
    let selection = Client.getSelectedWithDependencies(store.getState());
    let blobs = await objectsToBlobs(selection);
    await writeBlobsToClipboard(blobs);
  };

  return (
    <Item onClick={onCopy} shortcutName="copy">
      <Icons.Copy /> Copy
    </Item>
  );
};

const PasteOption = ({ disabled }: { disabled: boolean }) => {
  const store = useStore();
  const onPaste = async () => {
    let blob = await readBlobFromClipboard();
    if (blob) await insertFromBlob(blob, store);
  };

  return (
    <Item disabled={disabled} onClick={onPaste} shortcutName="paste">
      <Icons.Copy /> Paste
    </Item>
  );
};

const UndoOption = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useDispatch();
  const onUndo = () => dispatch(Client.undo());

  return (
    <Item disabled={disabled} onClick={onUndo} shortcutName="undo">
      <Icons.Undo /> Undo
    </Item>
  );
};

const RedoOption = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useDispatch();
  const onRedo = () => dispatch(Client.redo());

  return (
    <Item disabled={disabled} onClick={onRedo} shortcutName="redo">
      <Icons.Redo /> Redo
    </Item>
  );
};

const CommentsOption = ({
  selection,
}: {
  selection: BaseObject.BaseObject[];
}) => {
  const boardPath = useBoardPath();
  const object = selection[0];
  const href = `${boardPath}/${object.type}/${object.id}`;

  return (
    <Link to={href}>
      <Item>
        <Icons.MessageCircle /> Comments
      </Item>
    </Link>
  );
};

const Item = ({
  onClick,
  children,
  shortcutName,
  disabled,
}: {
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
  shortcutName?: string;
  children: ReactNode;
}) => {
  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <div className="context-menu-item">
        <div className="context-menu-item-label">{children}</div>
        <div>{shortcutName && <Shortcut name={shortcutName} />}</div>
      </div>
    </MenuItem>
  );
};

const useCloseHandler = () => {
  const dispatch = useDispatch();

  useWindowClickEvent(() => {
    dispatch(Client.unsetContextMenu());
  }, false);
};
