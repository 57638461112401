import "./Button.css";

import {
  ButtonHTMLAttributes,
  HTMLAttributes,
  forwardRef,
  ReactNode,
} from "react";

import { classNames } from "~/util/classNames";

import * as Icons from "./Icons";

type NativeButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export interface ButtonProps extends NativeButtonProps {
  variant?: "white" | "grey" | "primary" | "secondary" | "danger";
  flat?: boolean;
  size?: "sm" | "md" | "lg";
  loading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, size = "md", disabled, flat, loading, ...props }, ref) => (
    <button
      {...props}
      ref={ref}
      disabled={disabled || loading}
      className={classNames(
        "button",
        `button-${size}`,
        flat && `button-flat`,
        variant && `button-${variant}`,
        props.className
      )}
    >
      {loading && <Icons.Loader className="button-spinner" />}
      {props.children}
    </button>
  )
);

Button.displayName = "Button";

export interface IconButtonProps extends ButtonProps {
  icon: ReactNode;
  toggled?: boolean;
  "aria-label": string;
}

export const IconButton = ({
  icon,
  toggled = false,
  ...props
}: IconButtonProps) => (
  <Button
    {...props}
    className={classNames(
      toggled ? "toggled" : "",
      "icon-button",
      props.className
    )}
  >
    {icon}
  </Button>
);

export interface ButtonGroupProps extends HTMLAttributes<HTMLDivElement> {
  direction?: "row" | "column";
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
  children: ReactNode;
}

export const ButtonGroup = ({
  direction = "row",
  size = "md",
  children,
  className,
}: ButtonGroupProps) => (
  <div
    className={classNames(
      `button-group button-group-${size} button-group-${direction}`,
      className
    )}
  >
    {children}
  </div>
);

export const ButtonGroupDivider = () => (
  <div className="button-group-divider" />
);
