import saveTheStickiesImage from "~/assets/marketing/save_the_stickies.png";
import { Box } from "~/components/Box";
import { Smile } from "~/components/Icons";
import { ExternalLink } from "~/components/Links";
import { Announcement } from "~/util/AnnouncementClient";

import commentsImage from "./comments.gif";
import copyPasteImage from "./copypaste.gif";
import exportImage from "./export.gif";
import permalinksImage from "./permalinks.gif";
import reactionsImage from "./reactions.gif";

/**
 * The most recent eligible featured announcement exported from this
 * index will be shown to qualifiying users upon visiting a board.
 * Both featured and non-featured announcements will be shown on the
 * Dashboard change log.
 *
 * Announcements must have a default export that implements the
 * `Announcement` interface defined in `~/util/AnnouncementClient`.
 *
 * export { default as ExampleAnnouncement } from "./000-example";
 */

let DiscordServerMessage = () => (
  <p>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://discord.gg/2KdhpSvPne"
      className="underline font-bold"
    >
      🎁 Join the Sticky Studio Discord server
    </a>{" "}
    if you want to ask questions, request features, or find out what we're up
    to.
  </p>
);

export let StickersAndDiscord: Announcement = {
  title: "Stickers 🎉",
  featured: true,
  published: new Date("2022-10-26"),
  slug: "stickers",
  image: <img src={reactionsImage} alt="Example of stickers" />,
  showForDays: 7,
  content: (
    <>
      <p>
        You'll notice a new <Smile /> icon on the dock, where you can find a set
        of stickers that you can drag onto stickies as reactions.
      </p>
      <DiscordServerMessage />
    </>
  ),
};

export let CommentsImprovements: Announcement = {
  title: "Comments 💬",
  featured: true,
  published: new Date("2022-11-21"),
  slug: "comments",
  image: <img src={commentsImage} alt="Example of connection comments" />,
  showForDays: 7,
  content: (
    <>
      <p>
        Find yourself wanting to have a general conversation about the board?
        Look out for the new <strong>board comments</strong> button in the
        bottom right.
      </p>
      <p>
        Stickies are important, but so are connections! We've also made it
        easier to jump straight into a comment thread for{" "}
        <strong>connections, shapes, and labels!</strong>
      </p>
      <DiscordServerMessage />
    </>
  ),
};

export let PermalinksAndShortcuts: Announcement = {
  title: "Permalinks & Shortcuts 🏃‍♀️",
  featured: true,
  published: new Date("2023-03-06"),
  slug: "permalinks-and-shortcuts",
  image: <img src={permalinksImage} alt="Example of permalinks" />,
  showForDays: 7,
  content: (
    <Box gap={4}>
      <p>Want to share a link to a specific sticky? Now you can!</p>
      <p>
        Now every object on your boards has its own URL. Select something, copy
        the URL and when someone else opens the board, they'll see that
        selection.
      </p>
      <p>
        <em>Also</em> we've added a guide to Sticky Studio's keyboard shortcuts!
        Press <kbd>?</kbd> at any time to see them!
      </p>
    </Box>
  ),
};

export let AprilFools: Announcement = {
  title: "Save the Stickies!",
  featured: true,
  published: new Date("2023-04-01"),
  slug: "save-the-stickies",
  image: <img src={saveTheStickiesImage} alt="Save the Stickies!" />,
  showForDays: 1,
  content: (
    <Box gap={4}>
      <p>
        We're trying to save the planet from single-use sticky notes and we need
        your help!
      </p>
      <p>
        Learn more about our climate pledges and our campaign to{" "}
        <ExternalLink href="https://savethestickies.org">
          save the stickies
        </ExternalLink>
        !
      </p>
    </Box>
  ),
};

export let Export: Announcement = {
  title: "Export to PDF",
  featured: true,
  published: new Date("2023-05-22"),
  slug: "export-to-pdf",
  image: <img src={exportImage} alt="Example of export to PDF" />,
  showForDays: 7,
  content: (
    <Box gap={4}>
      <p>
        We've added two new ways to export your boards! You can now export to
        PDF and SVG, as well to Kumu's blueprint format, which allows you to
        easily import your boards into a Kumu map.
      </p>
    </Box>
  ),
};

export let CopyAndPaste: Announcement = {
  title: "Copy & Paste",
  featured: true,
  published: new Date("2023-06-08"),
  slug: "copy-and-paste",
  image: <img src={copyPasteImage} alt="Example of copy & paste" />,
  showForDays: 7,
  content: (
    <Box gap={4}>
      <p>Getting your ideas out just got a whole lot easier!</p>
      <p>
        You can now paste text and images you've copied outside of Sticky Studio
        into your boards, and copy content between your boards as well. Give it
        a shot and{" "}
        <a className="underline" rel="noopener noreferrer" href={`../feedback`}>
          let us know what you think!
        </a>
      </p>
    </Box>
  ),
};
