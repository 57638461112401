import "./FormField.css";

import { useField } from "formik";
import { LabelHTMLAttributes, ReactNode } from "react";

import { classNames } from "~/util/classNames";

import * as Icons from "./Icons";
import { Box } from "./Box";

export let ValidIcon = () => (
  <Icons.Check color="var(--blue-3)" strokeWidth={3} />
);

export let InvalidIcon = () => <Icons.X color="var(--red-3)" strokeWidth={3} />;

export let FormField = ({ children }: { children: ReactNode }) => (
  <Box className="form-field">{children}</Box>
);

export let FormFieldContext = ({ children }: { children: ReactNode }) => (
  <Box className="form-field-context" direction="row" justify="between">
    {children}
  </Box>
);

export let FormFieldMessage = ({ children }: { children: ReactNode }) => (
  <Box className="form-field-message" direction="row" align="center" gap={2}>
    {children}
  </Box>
);

export let FormFieldHeader = ({ children }: { children: ReactNode }) => (
  <Box
    as="header"
    className="form-field-header"
    direction="row"
    align="center"
    justify="between"
    gap={2}
  >
    {children}
  </Box>
);

export let FormFieldDescription = ({ children }: { children: ReactNode }) => (
  <div className="form-field-description">{children}</div>
);

interface FormFieldLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {}

export let FormFieldLabel = (props: FormFieldLabelProps) => (
  <label
    {...props}
    className={classNames("form-field-label", props.className)}
  />
);

export let FormFieldValidMessage = ({ children }: { children: ReactNode }) => (
  <>
    {children} <ValidIcon />
  </>
);

export let FormFieldInvalidMessage = ({
  children,
}: {
  children: ReactNode;
}) => (
  <>
    {children} <InvalidIcon />
  </>
);

export let FormFieldValidationMessage = ({ name }: { name: string }) => {
  let [, meta] = useField(name);
  if (!meta.touched) return null;

  return meta.error ? (
    <FormFieldInvalidMessage>{meta.error}</FormFieldInvalidMessage>
  ) : (
    <FormFieldValidMessage>Looks good</FormFieldValidMessage>
  );
};
