import "./BoardSettingsModal.css";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setInfo as setBoardInfo } from "~/store/bundles/Board";
import { getBoard, BoardData } from "~/util/BoardClient";

import * as Icons from "./Icons";
import { BoardInfoEditor } from "./BoardInfoEditor";
import { BoardModal } from "./BoardModal";
import { Box } from "./Box";
import { ModalError, ModalPanel, ModalProps, ModalTitle } from "./Modal";

interface BoardSettingsModalProps extends ModalProps {
  boardId: string;
}

export let BoardSettingsModal = (props: BoardSettingsModalProps) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | undefined>();
  const [board, setBoard] = useState<BoardData>();

  const fetchBoard = async (): Promise<BoardData> => {
    const response = await getBoard(props.boardId);

    if (response.status === "success") {
      return response.data;
    } else if (response.status === "failed") {
      throw new Error(response.msg);
    } else {
      throw new Error("Something went wrong");
    }
  };

  const onUpdate = async () => {
    let board: BoardData | undefined;

    try {
      setError(undefined);
      board = await fetchBoard();
    } catch (err: any) {
      setError(err.message);
    }

    if (board) {
      // Update the local board state and broadcast to
      // connected clients.
      dispatch(setBoardInfo(board));
      props.onClose();
    }
  };

  useEffect(() => {
    let cancelled = false;

    getBoard(props.boardId).then((res) => {
      if (!cancelled && res.status === "success") {
        setBoard(res.data);
      }
    });

    return () => {
      cancelled = true;
    };
  }, [props.boardId]);

  if (!board) return null;

  return (
    <BoardModal className="board-settings-modal" {...props}>
      <ModalPanel size="sm">
        <Box gap={6} p={4}>
          <ModalTitle>
            <Box direction="row" justify="center" align="center" gap={2}>
              <Icons.Info /> Board Settings
            </Box>
          </ModalTitle>
          <BoardInfoEditor board={board} onUpdate={onUpdate} />
          <ModalError>{error}</ModalError>
        </Box>
      </ModalPanel>
    </BoardModal>
  );
};
