import "./Carousel.css";

import React, { useEffect, useMemo, useRef, useState } from "react";

import { classNames } from "~/util/classNames";
import { useWindowEventListener } from "~/util/hooks";

import * as Icons from "./Icons";
import { Box } from "./Box";
import { IconButton } from "./Button";

type Props = {
  itemWidth?: number;
  children?: React.ReactNode;
  gap?: number;
  className?: string;
  itemClassName?: string;
};

export let Carousel = ({
  className,
  itemClassName,
  itemWidth = 300,
  gap = 10,
  ...props
}: Props) => {
  let [currentIndex, setCurrentIndex] = useState(0);
  // Number of items that the available width can fully accommodate
  let [pageSize, setPageSize] = useState(0);

  const el = useRef<HTMLDivElement>(null);
  const itemContainer = useRef<HTMLDivElement>(null);

  const children = useMemo(
    () => React.Children.toArray(props.children),
    [props.children]
  );

  const onPrev = () => setCurrentIndex(Math.max(0, currentIndex - pageSize));
  const onNext = () =>
    setCurrentIndex(Math.min(currentIndex + pageSize, children.length - 1));

  const onResize = () => {
    const availableWidth = el.current?.offsetWidth ?? 0;
    setPageSize(
      Math.max(1, Math.floor((availableWidth + gap) / (itemWidth + gap)))
    );
  };

  useEffect(() => {
    if (itemContainer.current) {
      itemContainer.current.style.left = `${-(
        currentIndex *
        (itemWidth + gap)
      )}px`;
    }
  }, [currentIndex, itemWidth, gap]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onResize(), []);
  useWindowEventListener("resize", onResize, false);

  const hasPrev = useMemo(() => currentIndex > 0, [currentIndex]);
  const hasNext = useMemo(
    () => currentIndex + pageSize < children.length,
    [currentIndex, children.length, pageSize]
  );
  const showNavigation = useMemo(
    () => pageSize < children.length,
    [pageSize, children]
  );

  return (
    <div className={classNames("carousel", className)} ref={el}>
      <Box direction="column">
        <div className="carousel-container">
          <div className="carousel-items" ref={itemContainer} style={{ gap }}>
            {children.map((child, index) => (
              <CarouselItem
                key={`item-${index}`}
                className={itemClassName}
                width={itemWidth}
              >
                {child}
              </CarouselItem>
            ))}
          </div>
        </div>
      </Box>
      {showNavigation && (
        <Box direction="row" justify="between">
          <IconButton
            className="carousel-button"
            icon={<Icons.ArrowLeft />}
            flat
            disabled={!hasPrev}
            onClick={onPrev}
            aria-label="Prev"
          />
          <IconButton
            className="carousel-button"
            icon={<Icons.ArrowRight />}
            flat
            disabled={!hasNext}
            onClick={onNext}
            aria-label="Next"
          />
        </Box>
      )}
    </div>
  );
};

type CarouselItemProps = {
  className?: string;
  width?: number;
  children?: React.ReactNode;
};

export let CarouselItem = ({
  children,
  className,
  width,
}: CarouselItemProps) => {
  return (
    <div className={classNames("carousel-item", className)} style={{ width }}>
      {children}
    </div>
  );
};
