import "./BoardControlRegions.css";

import { Box, BoxProps } from "./Box";

export let TopLeft = (props: BoxProps) => (
  <Box
    className="board-control-region board-control-region-top-left"
    {...props}
  />
);

export let Top = (props: BoxProps) => (
  <Box className="board-control-region board-control-region-top" {...props} />
);

export let TopRight = (props: BoxProps) => (
  <Box
    className="board-control-region board-control-region-top-right"
    {...props}
  />
);

export let Left = (props: BoxProps) => (
  <Box className="board-control-region board-control-region-left" {...props} />
);

export let Right = (props: BoxProps) => (
  <Box className="board-control-region board-control-region-right" {...props} />
);

export let BottomLeft = (props: BoxProps) => (
  <Box
    className="board-control-region board-control-region-bottom-left"
    {...props}
  />
);

export let Bottom = (props: BoxProps) => (
  <Box
    className="board-control-region board-control-region-bottom"
    {...props}
  />
);

export let BottomRight = (props: BoxProps) => (
  <Box
    className="board-control-region board-control-region-bottom-right"
    {...props}
  />
);
