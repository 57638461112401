import { Selector, useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import { createSelectorCreator, defaultMemoize } from "reselect";

/**
 * Create a selector that does a deep comparison on the result before triggering
 * downstream selectors.
 *
 * This is well-suited for selectors that create objects or arrays on the fly
 * and are a dependency for a large number of other selectors.
 */
export const createShallowEqualSelector = createSelectorCreator(
  defaultMemoize,
  { resultEqualityCheck: shallowEqual }
);

/**
 * Works the same as the standard `useSelector` hook, but does a shallow
 * equality check (rather than the default reference equality) to see if the
 * selector output has changed and we should re-render the component.
 */
export const useShallowEqualSelector = <S, T>(selector: Selector<S, T>) => {
  return useSelector(selector, shallowEqual);
};
