/**
 * Asserts that a condition is truthy and throws an error if not.
 * @param condition The condition to check
 * @param message The error message
 */
export function assert(
  condition: any,
  message: string = "Assertion failed"
): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}

export default assert;
