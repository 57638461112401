import * as Rect from "~/util/geometry/rectangle";
import { Rectangle } from "~/util/geometry";

import * as BaseObject from "./BaseObject";
import * as Delete from "./Delete";
import { AppState } from "../index";
import { Position } from "./Position";
import { Trait } from "./Trait";

/**
 * Interface that describes objects that have a size (width and height).
 */
export interface Size extends Trait, Position {
  width: number;
  height: number;
}

/**
 * Type guard that checks whether an object (BaseObject) implements the Size
 * trait.
 */
export const isSize = <T extends BaseObject.BaseObject>(
  object: T
): object is T & Size => "width" in object && "height" in object;

/**
 * Get all sized objects from an app state.
 */
export const getAll = (state: AppState) =>
  Object.values(state.objects).filter(isSize);

/**
 * Get Color object with given id.
 */
export const getById = (id: string) => (state: AppState) => {
  const object = BaseObject.getById(id)(state);
  if (object && isSize(object)) return object;
};

/**
 * Return a bounding rectangle of the object
 */
export const getArea =
  (id: string) =>
  (state: AppState): Rectangle | undefined => {
    const object = getById(id)(state);
    if (!object) return undefined;

    return {
      position: object.position,
      width: object.width,
      height: object.height,
    };
  };

/**
 * Return all sized objects that lie within a given area.
 */
export const getInsideArea =
  (area: Rectangle) =>
  (state: AppState): Size[] => {
    return (
      getAll(state)
        // If any of the objects are Delete, check that they aren't deleted.
        .filter(
          (object) =>
            !Delete.isDelete(object) ||
            (Delete.isDelete(object) && !object.deleted)
        )
        .filter((object) => Rect.overlaps(object, area))
    );
  };

/**
 * Get the bouning box of all `Size` objects.
 */
export const getBoundingRect = (state: AppState): Rectangle => {
  const objects = getAll(state)
    // If any of the objects are Delete, check that they aren't deleted.
    .filter(
      (object) =>
        !Delete.isDelete(object) || (Delete.isDelete(object) && !object.deleted)
    );

  return Rect.getBoundingRect(objects);
};
