export const NODE_ENV = import.meta.env.NODE_ENV;
export const LOG_LEVEL = import.meta.env.REACT_APP_LOG_LEVEL!;
export const API_URL = import.meta.env.REACT_APP_API_URL!;
export const WEB_URL = import.meta.env.REACT_APP_WEB_URL!;
export const WS_URL = import.meta.env.REACT_APP_WS_URL!;
export const STRIPE_PUBLISHABLE_KEY = import.meta.env
  .REACT_APP_STRIPE_PUBLISHABLE_KEY!;
export const BUGSNAG_API_KEY = import.meta.env.REACT_APP_BUGSNAG_API_KEY!;
export const VERSION = import.meta.env.REACT_APP_VERSION!;
export const STICKY_STUDIO_PLUS_PRICE = 5;
