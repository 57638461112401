import "./ControlOverlay.css";

import { CSSProperties, ReactNode } from "react";
import { useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import { classNames } from "~/util/classNames";

import { Overlay } from "./Overlay";

export const ControlOverlay = ({ children }: { children: ReactNode }) => {
  const origin = useSelector(Client.getOrigin);
  const scale = useSelector(Client.getScale);

  let style = {
    transform: `translate(${origin.x}px, ${origin.y}px) scale(${scale})`,
    transformOrigin: "top left",
  };

  return (
    <Overlay id="control-overlay">
      <div style={style}>{children}</div>
    </Overlay>
  );
};

type ControlProps = {
  visible?: boolean;
  style?: CSSProperties;
  children: ReactNode;
};

export const Control = ({ children, visible = true, style }: ControlProps) => {
  return (
    <div className={classNames("absolute", "overlay-control")} style={style}>
      {visible && children}
    </div>
  );
};
