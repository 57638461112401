import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useDispatch } from "react-redux";

import * as Board from "~/store/bundles/Board";
import * as Billing from "~/util/BillingClient";
import { withStripeElements } from "~/context/StripeElementsContext";

import * as Icons from "./Icons";
import {
  BillingCardInput,
  createTokenFromElement,
  StickyStudioPlusFeatures,
  StickyStudioPlusHeading,
  StickyStudioPlusPricing,
} from "./Billing";
import { BoardModal } from "./BoardModal";
import { Box } from "./Box";
import { Button } from "./Button";
import { ModalPanel, ModalProps, ModalTitle } from "./Modal";

interface PlanUpgradeModalProps extends ModalProps {
  onUpgrade?(): void;
}

let PlanUpgradeModalBody = ({ onUpgrade, ...props }: PlanUpgradeModalProps) => {
  let [error, setError] = useState<string | undefined>();
  let [busy, setBusy] = useState(false);
  let dispatch = useDispatch();
  let stripe = useStripe();
  let elements = useElements();

  async function submit() {
    try {
      setBusy(true);
      let token = await createTokenFromElement(stripe, elements);
      await Billing.updateCard(token.id);
      await Billing.updatePlan("plus");
      dispatch(Board.setPlan("plus"));
      onUpgrade?.();
    } catch (err: any) {
      setError(err.message);
    } finally {
      setBusy(false);
    }
  }

  return (
    <BoardModal {...props}>
      <ModalPanel size="sm">
        <Box gap={6} p={4}>
          <ModalTitle>
            <Box direction="row" justify="center" align="center" gap={2}>
              <Icons.Logo /> Sticky Studio Plus
            </Box>
          </ModalTitle>
          <StickyStudioPlusFeatures />
          <hr />
          <Box gap={2}>
            <Box direction="row" justify="between">
              <StickyStudioPlusHeading />
              <StickyStudioPlusPricing />
            </Box>
            <BillingCardInput
              disabled={busy}
              onChange={() => setError(undefined)}
            />
            <Button variant="primary" onClick={submit} loading={busy}>
              Upgrade 🚀
            </Button>
          </Box>
          {error && (
            <Box
              direction="row"
              justify="center"
              style={{ color: "var(--red-3)" }}
            >
              {error}
            </Box>
          )}
        </Box>
      </ModalPanel>
    </BoardModal>
  );
};

export let PlanUpgradeModal = withStripeElements(PlanUpgradeModalBody);
