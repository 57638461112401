import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { useAuth } from "~/context/AuthContext";
import { FetchResponse, postJson } from "~/util/ApiClient";
import { useQuery } from "~/util/hooks";

import { Box } from "./Box";
import { Button } from "./Button";
import { Divider } from "./Page";
import { Panel } from "./Panel";
import { Text } from "./Typography";

const VERIFY_ENDPOINT = "/auth/verify";

export const Verify = () => {
  const [response, setResponse] = useState<FetchResponse>();
  const query = useQuery();
  const auth = useAuth();

  useEffect(() => {
    postJson(VERIFY_ENDPOINT, {
      code: query.get("code"),
      email: query.get("email"),
    }).then(setResponse);
  }, [query]);

  useEffect(() => {
    if (response?.status === "success") auth.authenticate();
  });

  return (
    <Box align="center" gap={8}>
      <Panel className="confirm-login-panel">
        {!response ? (
          <Text size={1}>Please wait while we log you in...</Text>
        ) : response.status === "success" ? (
          <Navigate to="/" />
        ) : (
          <VerificationError errorMessage={response.msg} />
        )}
      </Panel>
    </Box>
  );
};

type VerificationErrorProps = {
  errorMessage: string | undefined;
};

const VerificationError = ({ errorMessage }: VerificationErrorProps) => {
  const query = useQuery();
  const navigate = useNavigate();

  const onRetry = () => {
    const email = query.get("email");
    navigate("/login", { state: { email } });
  };

  return (
    <>
      <Text size={1} data-cy="verify-error-message">
        {errorMessage}
      </Text>
      <Divider />
      <Box>
        <Button variant="primary" onClick={onRetry}>
          Try Again
        </Button>
      </Box>
    </>
  );
};
