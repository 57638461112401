import "./Tag.css";

import { ReactNode } from "react";

import { classNames } from "~/util/classNames";

export interface TagProps {
  color?: "purple" | "grey" | "blue" | "yellow" | "red" | "green";
  children: ReactNode;
}

export let Tag = ({ color = "grey", children }: TagProps) => (
  <span className={classNames("tag", `tag-${color}`)}>{children}</span>
);
