import { ReactNode } from "react";

import { Gift } from "./Icons";

export let CouponName = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className="font-bold rounded inline-block px-2 py-1"
      style={{ background: "var(--blue-1)", color: "var(--blue-4)" }}
    >
      <Gift /> {children}
    </div>
  );
};

export const FreeForever = () => <CouponName>Free Forever</CouponName>;
