/* eslint-disable no-console */
import * as config from "../config";

const levels: Record<string, number> = {
  error: 1,
  warning: 2,
  info: 3,
  debug: 4,
};

let LOG_LEVEL: number =
  config.NODE_ENV !== "production" ? levels[config.LOG_LEVEL ?? "info"] : 2; // Production mode runs in "warning" mode

const log = {
  debug(...msg: string[]) {
    if (LOG_LEVEL >= 4) console.debug(...msg);
  },

  info(...msg: string[]) {
    if (LOG_LEVEL >= 3) console.log(...msg);
  },

  warn(...msg: string[]) {
    if (LOG_LEVEL >= 2) console.warn(...msg);
  },
  error(...msg: string[]) {
    if (LOG_LEVEL >= 1) console.error(...msg);
  },
};

(window as any).setLogLevel = (level: string) => {
  LOG_LEVEL = levels[level] ?? "info";
};

export default log;
