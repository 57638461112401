import "./Cursor.css";

import React, { ReactNode } from "react";
import { useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Users from "~/store/bundles/User";
import { Pointer as MousePointer } from "~/assets/icons";
import { FixedScale } from "~/components/FixedScale";
import { useConfig } from "~/context/ConfigContext";
import {
  angle,
  clampToRounded,
  containsPoint,
  padded,
  padRounded,
  vec,
  Vector,
} from "~/util/geometry";

export const Cursor = React.memo(({ user }: { user: Users.User }) => {
  const viewport = useSelector(Client.getVisibleArea)!;
  const userId = useSelector(Client.getUserId);
  const config = useConfig();

  if (user.id === userId) return null;
  if (config.type === "template") return null;

  return containsPoint(viewport, user.cursor) ? (
    <OnscreenCursor user={user} />
  ) : (
    <OffscreenCursor user={user} />
  );
});

const OnscreenCursor = ({ user }: { user: Users.User }) => {
  return (
    <Translate {...user.cursor} className="cursor cursor-onscreen">
      <FixedScale origin="top left">
        <MousePointer height={15} width={15} fill={user.color} />
        <Label name={user.name} color={user.color} />
      </FixedScale>
    </Translate>
  );
};

const Label = ({ name, color }: { name: string; color: string }) => (
  <div className="cursor-label" style={{ backgroundColor: color }}>
    {name}
  </div>
);

/**
 * For off-screen cursors, we render a little arrowhead on the edge of the
 * viewport in the direction of the actual cursor position.
 */
const OffscreenCursor = ({ user }: { user: Users.User }) => {
  const scale = useSelector(Client.getScale);
  const viewport = useSelector(Client.getVisibleArea)!;

  // Add some internal padding to the viewport so the indicators lie within
  // the viewport (and add some rounding to make it look nicer)
  const paddedViewport = {
    ...padded(viewport, -25 / scale),
    radius: 4 / scale,
  };

  // Find the point on the padded viewport that is _closest_ to the cursor
  const arrowPos = clampToRounded(
    padRounded(paddedViewport, 15 / scale),
    user.cursor
  );

  // Find the _direction_ from the edge of the viewport to the actual cursor
  const arrowDir = vec(arrowPos, user.cursor);

  return (
    <Translate {...arrowPos} className={"cursor-offscreen-arrow"}>
      <FixedScale origin="center">
        <Arrow direction={arrowDir} color={user.color} />
      </FixedScale>
    </Translate>
  );
};

const Arrow = ({ color, direction }: { color: string; direction: Vector }) => {
  const base = 8;
  const phi = (angle({ x: 1, y: 0 }, direction) * 360) / 2 / Math.PI;
  const height = (Math.sqrt(3) * base) / 2;
  const points = `${-height / 2} ${base / 2} ${height / 2} 0 ${-height / 2} ${
    -base / 2
  }`;

  return (
    <svg>
      <polygon fill={color} points={points} transform={`rotate(${phi})`} />
    </svg>
  );
};

const Translate = ({
  x,
  y,
  className,
  children,
}: {
  x: number;
  y: number;
  className?: string;
  children: ReactNode;
}) => (
  <div className={className} style={{ transform: `translate(${x}px, ${y}px)` }}>
    {children}
  </div>
);

Cursor.displayName = "Cursor";
