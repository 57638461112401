/* Dispatch additional actions (side-effect)
 *
 * A dispatched action may specify an additional action or an array of
 * additional actions to be dispatched.
 *
 * These should be passed in in action.effects under the `dispatch` key.
 * Example:
 * { type: "SOME_ACTION",
 *   data: { a: "string a", b: 42 },
 *   effects: { dispatch: { type: "ANOTHER_ACTION", data: { c: "string c" } } }
 * }
 *
 * The additional actions are dispatched _after_ the original action has hit the
 * reducer.
 **/
import { Middleware } from "redux";

import { Action } from "~/util/types";

const dispatch: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action: Action) => {
    let result = next(action);
    if (!action?.effects?.dispatch) {
      return result;
    }

    if (action?.effects?.dispatch) {
      if (Array.isArray(action.effects.dispatch)) {
        action.effects.dispatch.forEach((action: Action) => dispatch(action));
      } else {
        dispatch(action.effects.dispatch);
      }
    }

    return result;
  };

export default dispatch;
