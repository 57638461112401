import "./App.css";

import React from "react";

import { AuthProvider } from "~/context/AuthContext";

import { Routes } from "./Routes";

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Routes />
      </div>
    </AuthProvider>
  );
}

export default App;
