import { HTMLAttributes } from "react";

import { classNames } from "~/util/classNames";

interface Props extends HTMLAttributes<HTMLDivElement> {
  cursor?: string;
  width?: number;
  height?: number;
}

export let Handle = ({ cursor = "grab", className, ...props }: Props) => (
  <div
    {...props}
    className={classNames(
      "bg-white border-2 border-black w-[20px] h-[20px] z-10",
      className
    )}
    style={{ cursor }}
  />
);
