import { ReactNode } from "react";

type Region =
  | "top-left"
  | "top-center"
  | "top-right"
  | "middle-left"
  | "middle-right"
  | "bottom-left"
  | "bottom-center"
  | "bottom-right";

const POSITION_STYLES: Record<Region, string> = {
  "top-left": "absolute -translate-x-1/2 -translate-y-1/2",
  "top-center": "absolute left-1/2 -translate-x-1/2 -translate-y-1/2",
  "top-right": "absolute left-full -translate-x-1/2 -translate-y-1/2",
  "middle-left": "absolute top-1/2 -translate-x-1/2 -translate-y-1/2",
  "middle-right":
    "absolute top-1/2 left-full -translate-x-1/2 -translate-y-1/2",
  "bottom-left": "absolute top-full -translate-x-1/2 -translate-y-1/2",
  "bottom-center":
    "absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2",
  "bottom-right":
    "absolute top-full left-full -translate-x-1/2 -translate-y-1/2",
};

export const Position = ({
  region,
  children,
}: {
  region: Region;
  children: ReactNode;
}) => {
  return <div className={POSITION_STYLES[region]}>{children}</div>;
};
