import { Provider as ReduxProvider } from "react-redux";
import { Store } from "redux";

import { ConfigProvider } from "~/context/ConfigContext";
import { Config } from "~/context/ConfigContext";

import Canvas from "./Canvas";

type Props = {
  store: Store;
  config: Config;
};

const CanvasRenderer = ({ store, config }: Props) => {
  return (
    <ConfigProvider config={config}>
      <ReduxProvider store={store}>
        <Canvas />
      </ReduxProvider>
    </ConfigProvider>
  );
};

export default CanvasRenderer;
