import assert from "~/util/assert";
import { clock } from "~/util/clock";
import { UpdateMany } from "~/util/types";

import * as BaseObject from "./BaseObject";
import { AppState, State, Action as AppAction } from "../index";
import { BaseAction } from "../shared";
import { Trait } from "./Trait";

const TIMEOUT = 30_000; // 30 seconds

/**
 * Interface that describes objects with a keepAlive field.
 *
 * Objects that are Alive come with a `keepAlive` timer. If the timer expires,
 * all actions that are being performed on the object are cancelled.
 */
export interface Alive extends Trait {
  keepAlive: number | null;
}

/**
 * Type guard that checks whether an object (BaseObject) implements the Alive
 * trait.
 */
export const isAlive = <T extends BaseObject.BaseObject>(
  object: T
): object is T & Alive => "keepAlive" in object;

/**
 * Effects that can be present on any Alive action.
 */
export interface AliveEffects {
  keepAlive?: boolean;
}

/**
 * The action type for Alive actions.
 */
export interface AliveAction extends BaseAction {}

/**
 * Schema for actions returned by the `Alive.touch()` action creator.
 */
export interface TouchAction extends BaseAction {
  type: "alive/touch";
  data: Pick<Alive, "id" | "keepAlive">;
}

/**
 * Action creator that updates the keepAlive timestamp of a given object.
 *
 * Sets the `keepAlive` timestamp to 30s into the future, relative to
 * the server clock.
 */
export const touch = (id: string): TouchAction => {
  return {
    type: "alive/touch",
    data: { id, keepAlive: clock.now() + TIMEOUT },
    effects: { broadcast: true, persist: true },
  };
};

/**
 * Schema for actions returned by the `Alive.touchMany()` action creator.
 */
export interface TouchManyAction extends BaseAction {
  type: "alive/touch_many";
  data: UpdateMany<Alive, "keepAlive">;
}

/**
 * Action creator that updates the keepAlive timestamp of a given set of objects.
 *
 * Sets the `keepAlive` timestamp to 30s into the future, relative to
 * the server clock.
 */
export const touchMany = (ids: string[]): TouchManyAction => {
  const data: UpdateMany<Alive, "keepAlive"> = {};
  const keepAlive = clock.now() + TIMEOUT;

  for (const id of ids) {
    data[id] = { keepAlive };
  }

  return {
    type: "alive/touch_many",
    data,
    effects: { broadcast: true, persist: true },
  };
};

/**
 * Schema for actions returned by the `Alive.releaseExpired()` action creator.
 */
export interface ReleaseExpiredAction extends BaseAction {
  type: "alive/release_expired";
  data: UpdateMany<Alive, "keepAlive">;
}

/**
 * Release any objects whose keepAlive timer has expired
 *
 * Dummy action that objects can hook into in their middleware to do whatever
 * cleanup they need to do (finish editing, finish grabbing, etc...)
 */
export const releaseExpired =
  () =>
  (state: AppState): ReleaseExpiredAction => {
    const expiredIds = getExpired(state).map(({ id }) => id);
    const data: UpdateMany<Alive, "keepAlive"> = {};

    for (const id of expiredIds) {
      data[id] = { keepAlive: null };
    }

    return {
      type: "alive/release_expired",
      data,
    };
  };

/**
 * Union type of all Alive related actions.
 */
export type Action = TouchAction | TouchManyAction | ReleaseExpiredAction;

/**
 * Get all objects from an app state that implement the Alive trait.
 */
export const getAll = (state: AppState) =>
  Object.values(state.objects).filter(isAlive);

/**
 * Get all objects that have expired.
 */
export const getExpired = (state: AppState) =>
  Object.values(state.objects)
    .filter(isAlive)
    .filter(({ keepAlive }) => keepAlive && keepAlive <= clock.now());

/**
 * Get Alive object with given id.
 */
export const getById = (id: string) => (state: AppState) => {
  const object = BaseObject.getById(id)(state);
  if (object && isAlive(object)) return object;
};

/**
 * Reducer that handles any Alive-related actions
 */
export const reducer = (state: State = {}, action: AppAction): State => {
  switch (action.type) {
    case "alive/touch": {
      const object = state[action.data.id];
      assert(isAlive(object), "Object does not implement the Alive trait.");

      return {
        ...state,
        [action.data.id]: { ...object, ...action.data },
      };
    }

    case "alive/touch_many":
    case "alive/release_expired": {
      let newState = { ...state };

      for (const id in action.data) {
        const object = newState[id];
        assert(isAlive(object), "Object does not implement the Alive trait.");

        newState[id] = { ...newState[id], ...action.data[id] };
      }

      return newState;
    }

    default:
      return state;
  }
};
