import type { Action } from "redux";
import { Socket } from "socket.io-client";

type Listener = (socket: Socket, action: Action) => void;

/**
 * Simple interface for adding handlers that emit messages over a websocket
 * according to a passed-in action.
 */
export const createEmitter = (socket: Socket) => {
  const listeners: Listener[] = [];

  const add = (listener: Listener) => {
    listeners.push(listener);
  };

  const process = (action: Action) => {
    for (let listener of listeners) {
      listener(socket, action);
    }
  };

  return { add, process };
};
