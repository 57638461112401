import "./Signup.css";

import { Link } from "react-router-dom";

import * as Icons from "./Icons";
import { Box } from ".//Box";
import { Divider } from "./Page";
import { Panel } from "./Panel";
import { SignupForm } from "./SignupForm";
import { Heading } from "./Typography";
import { Text } from "./Typography";

export const Signup = () => (
  <Box align="center" gap={8}>
    <Panel className="signup-panel" size="lg">
      <SignupHeader />
      <Divider />
      <SignupForm />
    </Panel>
    <Text className="signup-disclaimer" size={2} variant="muted">
      By creating an account, you agree to the{" "}
      <Link className="underline" to="/terms">
        Terms of Service
      </Link>
      . For more information about Sticky Studio's privacy practices, please
      read our{" "}
      <Link className="underline" to="/privacy">
        Privacy Statement
      </Link>
      .
    </Text>
  </Box>
);

const SignupHeader = () => (
  <Heading level={3}>
    <Box direction="row" justify="center" align="center" gap={2}>
      <Icons.Logo />
      Sign Up
    </Box>
  </Heading>
);
