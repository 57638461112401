import "./TextWithLinks.css";

import { ReactNode } from "react";

/**
 * Renders children with correctly styled anchor tags.
 */
export let TextWithLinks = ({ children }: { children: ReactNode }) => (
  <div className="text-with-links">{children}</div>
);
