import "./Toaster.css";

import { ElementType } from "react";
import {
  Toast as HotToast,
  ToastType,
  Toaster as HotToaster,
  resolveValue,
} from "react-hot-toast";

import { classNames } from "~/util/classNames";

import { Box } from "./Box";
import { AlertTriangle, CheckCircle } from "./Icons";
import { Spinner } from "./Spinner";

const Icons: Record<ToastType, ElementType | null> = {
  error: AlertTriangle,
  success: CheckCircle,
  loading: Spinner,
  blank: null,
  custom: null,
};

export const Toaster = () => (
  <HotToaster position="bottom-left">{renderToast}</HotToaster>
);

const renderToast = (t: HotToast) => {
  const Icon = Icons[t.type];

  return (
    <div
      className={classNames(
        "toast",
        t.visible && "toast__visible",
        `toast__${t.type}`
      )}
    >
      <Box align="center" direction="row" gap={2}>
        {Icon && <Icon />}
        {resolveValue(t.message, t)}
      </Box>
    </div>
  );
};
