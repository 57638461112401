import React, { useContext } from "react";

export enum Permission {
  NoAccess,
  ReadOnly,
  Comment,
  Write,
  EditOwned,
}

export enum BoardType {
  Board = "board",
  Sandbox = "sandbox",
  Template = "template",
}

export type Config = {
  type: BoardType;
  permission: Permission;
  websocket: boolean;
  boardId: string;
  userId: string;
};

interface ConfigProviderProps {
  children: React.ReactNode;
  config: Config;
}

export const ConfigContext = React.createContext<Config | null>(null);

export const ConfigProvider = ({ children, config }: ConfigProviderProps) => {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export const useConfig = () => {
  let config = useContext(ConfigContext);
  if (!config) {
    throw new Error("Can't use config unless inside a context provider");
  }

  return config;
};
