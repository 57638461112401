import { Rectangle } from "~/util/geometry";

import { Box } from "./shared/Box";

export const SelectionArea = ({ area }: { area: Rectangle }) => {
  return (
    <Box
      {...area}
      className="bg-blue-100 rounded border-1 border-blue-200 opacity-60"
    />
  );
};
