import countBy from "lodash/countBy";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import { Middleware, Dispatch } from "redux";
import { createSelector } from "reselect";

import * as Rectangle from "~/util/geometry/rectangle";
import { Action, AppState } from "~/store";
import { BaseAction } from "~/store/shared";
import {
  BaseObject,
  Create,
  Grab,
  Color,
  Size,
  Delete,
  Position,
  Alive,
} from "~/store/traits";
import assert from "~/util/assert";
import { filterObject } from "~/util/filterObject";
import { Point } from "~/util/geometry/vector";
import { createShallowEqualSelector } from "~/util/selectors";

import * as Client from "./Client";
import * as Sticky from "./Sticky";

const WIDTH = 50;
const HEIGHT = 50;

// The size at which a group of like votes/reactions/avatars are collapsed
// into a single sticker group.
const COLLAPSE_THREHSHOLDS: Record<"vote" | "reaction" | "avatar", number> = {
  vote: 6,
  reaction: 6,
  avatar: 6,
};

/**
 * Stickers encompass votes (colored stickers), reactions (solid white
 * stickers with an emoji), and avatars.
 */
interface BaseSticker
  extends BaseObject.BaseObject,
    Delete.Delete,
    Size.Size,
    Position.Position,
    Grab.Grab,
    Create.Create,
    Alive.Alive {
  sticky: string | null;
  user: string;
  recipient: string | null;
}

export type Shortcode =
  | ":eyes:"
  | ":smile:"
  | ":frown:"
  | ":thinking-face:"
  | ":heart:"
  | ":thumbs-up:"
  | ":thumbs-down:"
  | ":check-mark:"
  | ":fire:"
  | ":party-popper:"
  | ":raising-hands:";

/**
 * Interface that defines a Vote object.
 */
export interface Vote extends BaseSticker, Color.Color {
  type: "vote";
}

/**
 * Interface that defines a Reaction object.
 */
export interface Reaction extends BaseSticker {
  type: "vote";
  shortcode: Shortcode;
}

/**
 * Interface that defines an Avatar object.
 */
export interface Avatar extends BaseSticker {
  type: "vote";
  avatarUserId: string;
}

/**
 * Interface that defines a VoteGroup object.
 */
export interface VoteGroup extends Vote {
  stickers: string[];
}

/**
 * Interface that defines a ReactionGroup object.
 */
export interface ReactionGroup extends Reaction {
  stickers: string[];
}

/**
 * Interface that defines an AvatarGroup object.
 */
export interface AvatarGroup extends Avatar {
  stickers: string[];
}

/**
 * Union type that defines sticker types
 */
export type Sticker = Vote | Reaction | Avatar;

/**
 * Union type that defines sticker group types
 */
export type StickerGroup = VoteGroup | ReactionGroup | AvatarGroup;

/**
 * Type guard for narrowing a `BaseObject`'s type to Sticker
 *
 * @param {BaseObject.BaseObject} object - The object whose type we want to narrow.
 * @returns {boolean} Whether or not the object is of type `Sticker`.
 */
export const isSticker = (object: BaseObject.BaseObject): object is Sticker => {
  return object.type === "vote";
};

/**
 * Type guard for narrowing a `BaseObject`'s type to Vote
 *
 * @param {BaseObject.BaseObject} object - The object whose type we want to narrow.
 * @returns {boolean} Whether or not the object is of type `Vote`.
 */
export const isVote = (object: BaseObject.BaseObject): object is Vote => {
  return isSticker(object) && "color" in object;
};

/**
 * Type guard for narrowing a `BaseObject`'s type to Avatar
 *
 * @param {BaseObject.BaseObject} object - The object whose type we want to narrow.
 * @returns {boolean} Whether or not the object is of type `Avatar`.
 */
export const isAvatar = (object: BaseObject.BaseObject): object is Avatar => {
  return isSticker(object) && "avatarUserId" in object;
};

/**
 * Type guard for narrowing a `BaseObject`'s type to Reaction
 *
 * @param {BaseObject.BaseObject} object - The object whose type we want to narrow.
 * @returns {boolean} Whether or not the object is of type `Reaction`.
 */
export const isReaction = (
  object: BaseObject.BaseObject
): object is Reaction => {
  return isSticker(object) && "shortcode" in object;
};

/**
 * Type guard for narrowing a `BaseObject`'s type to StickerGroup
 *
 * @param {BaseObject.BaseObject} object - The object whose type we want to narrow.
 * @returns {boolean} Whether or not the object is of type `StickerGroup`.
 */
export const isStickerGroup = (
  object: BaseObject.BaseObject
): object is StickerGroup => {
  return isSticker(object) && "stickers" in object;
};

/**
 * Type guard for narrowing a `BaseObject`'s type to ReactionGroup
 *
 * @param {BaseObject.BaseObject} object - The object whose type we want to narrow.
 * @returns {boolean} Whether or not the object is of type `Reaction`.
 */
export const isReactionGroup = (
  object: BaseObject.BaseObject
): object is ReactionGroup => {
  return isStickerGroup(object) && isReaction(object);
};

/**
 * Type guard for narrowing a `BaseObject`'s type to AvatarGroup
 *
 * @param {BaseObject.BaseObject} object - The object whose type we want to narrow.
 * @returns {boolean} Whether or not the object is of type `Avatr`.
 */
export const isAvatarGroup = (
  object: BaseObject.BaseObject
): object is AvatarGroup => {
  return isStickerGroup(object) && isAvatar(object);
};

/**
 * Swap out any IDs according to a provided lookup table.
 *
 * @param {Sticker} sticker - The sticker object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with an `id` string and returns the string to replace it.
 * @returns {Sticker} The modified sticker with ids replaced.
 */
const swapIds = (sticker: Sticker, lookup: (id: string) => string): Sticker => {
  return {
    ...sticker,
    id: lookup(sticker.id),
    user: lookup(sticker.user),
    creating: sticker.creating ? lookup(sticker.creating) : null,
    grab: sticker.grab
      ? { ...sticker.grab, userId: lookup(sticker.grab.userId) }
      : null,
    recipient: sticker.recipient ? lookup(sticker.recipient) : null,
    sticky: sticker.sticky ? lookup(sticker.sticky) : null,
  };
};

/**
 * Remove any secondary information that we wouldn't want to appear, in a
 * template/fork.
 *
 * @param {Sticker} sticker - Sticker to convert into a template
 * @returns {Sticker} Template created from the provided `Sticker` object.
 */
export const template = (sticker: Sticker): Sticker => ({
  ...sticker,
  creating: null,
  grab: null,
});

/**
 * Fork a sticker by swapping out ids using a provided lookup table.
 *
 * @param {Sticker} sticker - The sticker object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with an `id` string and returns the string to replace it.
 * @returns {Sticker} The modified sticker with ids replaced.
 */
export const fork = swapIds;

/**
 * Union of all possible `Sticker` action types.
 */
export type StickerAction =
  | AddAction
  | AssignRecipientAction
  | RemoveRecipientAction
  | RecallAllAction;

export interface AddAction extends BaseAction {
  type: "sticker/add";
  data: Sticker;
}

/**
 * Type that describes the named options for adding a vote.
 *
 * @typedef {AddVoteOptions} The options object
 * @property {'vote'} type - The type of sticker
 * @property {string} color - The color of the vote
 */
export interface AddVoteOptions {
  type: "vote";
  color: string;
}

/**
 * Type that describes the named options for adding a reaction.
 *
 * @typedef {AddReactionOptions} The options object
 * @property {'reaction'} type - The type of sticker
 * @property {Shortcode} reaction - The shortcode representing
 * the desired emoji
 */
export interface AddReactionOptions {
  type: "reaction";
  shortcode: Shortcode;
}

/**
 * Type that describes the named options for adding an avatar.
 *
 * @typedef {AddAvatarOptions} The options object
 * @property {'avatar'} type - The type of sticker
 * @property {User} user - The user object.
 */
export interface AddAvatarOptions {
  type: "avatar";
  userId: string;
}

/**
 * Type that describes the named options for adding a sticker.
 *
 * @typedef {AddVoteOptions|AddReactionOptions|AddAvatarOptions}
 */
export type AddStickerOptions =
  | AddVoteOptions
  | AddReactionOptions
  | AddAvatarOptions;

/**
 * Type that describes the named arguments for adding a sticker.
 */
interface AddArgs {
  /**
   * The id for the to-be-added sticker.
   */
  id: string;
  /**
   * The mouse position, used for setting the sticker position.
   */
  mousePosition: Point;
  /**
   * The id of the user who created the sticker.
   */
  userId: string;
  /**
   * The options for the sticker.
   */
  options: AddStickerOptions;
}

/**
 * Action creator for adding a sticker to the app state.
 *
 * @param {AddArgs} params - The parameters for the sticker to be added.
 * @returns The action thunk to be dispatched to the store.
 */
export const add =
  ({ id, mousePosition, userId, options }: AddArgs) =>
  (state: AppState): AddAction => {
    let scene = Client.getScene(state);
    let scenePosition = Client.toSceneCoordinates(scene, mousePosition);

    let position = {
      x: scenePosition.x - WIDTH / 2,
      y: scenePosition.y - HEIGHT / 2,
    };

    return {
      type: "sticker/add",
      data: isAddReaction(options)
        ? {
            id,
            type: "vote",
            sticky: null,
            user: userId,
            position,
            width: WIDTH,
            height: HEIGHT,
            grab: null,
            creating: null,
            deleted: false,
            recipient: null,
            keepAlive: null,
            shortcode: options.shortcode,
          }
        : isAddVote(options)
        ? {
            id,
            type: "vote",
            sticky: null,
            user: userId,
            position,
            width: WIDTH,
            height: HEIGHT,
            grab: null,
            creating: null,
            deleted: false,
            recipient: null,
            keepAlive: null,
            color: options.color,
          }
        : {
            id,
            type: "vote",
            sticky: null,
            user: userId,
            position,
            width: WIDTH,
            height: HEIGHT,
            grab: null,
            creating: null,
            deleted: false,
            recipient: null,
            keepAlive: null,
            avatarUserId: options.userId,
          },
      effects: {
        broadcast: true,
        persist: true,
      },
    };
  };

export interface AssignRecipientAction extends BaseAction {
  type: "sticker/assign_recipient";
  data: Pick<Sticker, "id" | "recipient">;
}

/**
 * Action creator for assigning a sticky (recipient) to the sticker.
 *
 * @param {string} id - The id of the sticker we want to assign to a sticky.
 * @param {string} recipient - The id of the sticky we want to assign the sticker to.
 * @returns {AssignRecipientAction} The action to be dispatched to the store.
 */
export const assignRecipient = (
  id: string,
  recipient: string
): AssignRecipientAction => {
  return {
    type: "sticker/assign_recipient",
    data: { id, recipient },
    effects: { broadcast: true, persist: true },
  };
};

export interface RemoveRecipientAction extends BaseAction {
  type: "sticker/remove_recipient";
  data: Pick<Sticker, "id" | "recipient">;
}

/**
 * Action creator for dissociating a sticker from its sticky.
 *
 * NOTE: this only sets the recipient (sticky) id on the sticker, it is not
 * responsible for removing the sticker's id from the sticky's list of associated
 * stickers.
 *
 * @param {string} id - The id of the sticker whose `recipient` we want to clear.
 * @returns The action thunk to be dispatched to the store.
 */
export const removeRecipient = (id: string) => {
  return {
    type: "sticker/remove_recipient" as const,
    data: { id, recipient: null },
    effects: { broadcast: true, persist: true },
  };
};

export interface RecallAllAction extends BaseAction {
  type: "sticker/recall_all";
}

/**
 * Action creator for removing all the stickers from the board.
 *
 * This action both deletes the sticker objects (sets their `deleted` field to
 * `true`) and dispatches actions to remove the stickers from the stickies they
 * were associated with.
 *
 * @returns The action thunk to be dispatched to the store.
 */
export const recallAll =
  () =>
  (state: AppState): RecallAllAction => {
    let stickerIds = getAll(state).map((sticker) => sticker.id);
    let stickerAction = Delete.deleteMany(stickerIds);
    let stickyActions = Sticky.getAll(state).flatMap((sticky) => {
      const stickers = sticky.votes
        .map((id) => getById(id)(state))
        .filter((vote) => isVote(vote));
      return stickers.map((sticker) =>
        Sticky.removeSticker(sticky.id, sticker.id)
      );
    });

    return {
      type: "sticker/recall_all",
      effects: {
        dispatch: [stickerAction, ...stickyActions],
      },
    };
  };

/**
 * Reducer that processes any `Sticker` actions.
 *
 * @param {AppState["objects"]} [state] - The current `objects` state.
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["objects"]} The updated state slice.
 */
export const reducer = (
  state: AppState["objects"] = {},
  action: Action
): AppState["objects"] => {
  switch (action.type) {
    case "sticker/add":
      return { ...state, [action.data.id]: action.data };

    case "sticker/assign_recipient":
    case "sticker/remove_recipient": {
      const object = state[action.data.id];
      assert(isSticker(object), "Object is not a Sticker.");

      return { ...state, [object.id]: { ...state[object.id], ...action.data } };
    }

    default:
      return state;
  }
};

/**
 * Redux middleware that is used to intercept general actions to inject sticker
 * specific behavior.
 *
 * @param  store - The redux store object
 * @param  next - The next redux middleware in the middleware chain.
 * @param  action - The dispatched action we're intercepting
 * @returns The return value of the `next` middleware.
 */
export const middleware: Middleware<{}, AppState, Dispatch<any>> =
  ({ dispatch, getState }) =>
  (next) =>
  (action: Action) => {
    let additionalActions: Action[] = [];
    const state = getState();

    // Dispatch any additional behaviors
    switch (action.type) {
      /*
       * Release sticker whose `keepAlive` timer has expired.
       * Unset the creating, grabbing, and resizing properties.
       */
      case "alive/release_expired": {
        for (const id in action.data) {
          const sticker = getById(id)(state);
          if (!sticker || sticker.deleted) continue;

          if (sticker.grab)
            additionalActions.push(Grab.finishGrabbing(id)(state));

          if (sticker.creating)
            additionalActions.push(Create.finishCreating(id)(state));
        }

        break;
      }

      /*
       * While we remove stickers from the containing sticky when
       * grabbed, we don't do so for sticker groups, otherwise they
       * would break apart. So when a sticker that's part of a group
       * is removed, update the containing sticky.
       */
      case "delete/delete": {
        const sticker = getById(action.data.id)(state);
        const counts = getStickerGroupCounts(state);

        if (sticker?.recipient && isCollapsed(sticker, counts)) {
          additionalActions.push(
            Sticky.removeSticker(sticker.recipient, sticker.id)(state)
          );
        }
      }
    }

    // Process the actual action first
    const result = next(action);

    // Dispatch additional actions
    additionalActions.forEach(dispatch);

    // Return result of original action
    return result;
  };

/**
 * Selector for getting a record of all stickers in the board state, keyed by their
 * id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, Sticker>} A record of all stickers in the app state.
 */
export const getStickers = createShallowEqualSelector(
  BaseObject.getAll,
  (objects) => filterObject(objects, isSticker)
);

/**
 * Selector for getting a record of all votes in the board state, keyed by their
 * id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, Vote>} A record of all votes in the app state.
 */
export const getVotes = createSelector(getStickers, (objects) =>
  filterObject(objects, isVote)
);

/**
 * Selector for getting a record of all reactions in the board state, keyed by their
 * id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, Sticker>} A record of all reactions in the app state.
 */
export const getReactions = createSelector(getStickers, (objects) =>
  filterObject(objects, isReaction)
);

/**
 * Selector for getting an array of all stickers, removing the deleted stickers.
 *
 * @param {AppState} state - The current app state.
 * @returns {Sticker[]} An array of (non-deleted) stickers.
 */
export const getAll = createSelector(getStickers, (stickers) =>
  Object.values(stickers ?? {}).filter((v) => !v.deleted)
);

/**
 * Selector that returns an array of all undeleted stickers ordered by date last updated.
 *
 * @param {AppState} state - The current app state.
 * @returns An ordered array of (non-deleted) stickers.
 */
export const getAllOrdered = createSelector(getAll, (stickers) =>
  orderBy(stickers, "updatedAt")
);

/**
 * Selector creator for getting a lookup table of the number of stickers that
 * match each collapse-by key.
 *
 * @returns Selector that returns a lookup table of the number of stickers for
 * each collapse-by key.
 */
export const getStickerGroupCounts = createSelector(getAll, (stickers) =>
  countBy(stickers, (sticker) => getCollapseByKey(sticker))
);

/**
 * Selector for getting an array of all stickers with like stickers grouped into
 * StickerGroups, removing the deleted stickers,
 *
 * @param {string} userId - The id of the current user.
 * @returns {(Sticker|StickerGroup)[]} A mixed array of (non-deleted) stickers and
 * sticker groups.
 */
export const getAllCollapsed = createShallowEqualSelector(
  getAll,
  (state: AppState) => Client.getUserId(state),
  (stickers, userId) => collapseStickers(stickers, userId)
);

/**
 * Selector for getting an array of all votes, removing the deleted votes.
 *
 * @param {AppState} state - The current app state.
 * @returns {Reaction[]} An array of (non-deleted) votes.
 */
export const getAllVotes = createSelector(getVotes, (votes) =>
  Object.values(votes ?? {}).filter((v) => !v.deleted)
);

/**
 * Selector for getting an array of all reactions, removing the deleted reactions.
 *
 * @param {AppState} state - The current app state.
 * @returns {Reaction[]} An array of (non-deleted) reactions.
 */
export const getAllReactions = createSelector(getReactions, (reactions) =>
  Object.values(reactions ?? {}).filter((r) => !r.deleted)
);

/**
 * Selector creator for getting the sticker with a given id.
 *
 * @param {string} id - The id of the sticker we want to get
 * @returns Selector that returns the sticker.
 */
export const getById = (id: string) =>
  createSelector(getStickers, (stickers) => stickers[id]);

/**
 * Selector creator for getting many votes by their id.
 *
 * @param {string[]} ids - The array of ids of the stickers to retrieve.
 * @returns Selector that returns the desired stickers.
 */
export const getManyById = (ids: string[]) =>
  createShallowEqualSelector(getAll, (stickers) =>
    stickers.filter((sticker) => ids.includes(sticker.id))
  );

/**
 * Selector creator for getting many stickers by their id, collapsing
 * like stickers.
 *
 * @param {string[]} ids - The array of ids of the stickers to retrieve.
 * @param {string} userId - The id of the current user.
 * @returns Selector that returns the desired stickers.
 */
export const getManyByIdCollapsed = (ids: string[]) =>
  createSelector(getManyById(ids), Client.getUserId, (stickers, userId) =>
    collapseStickers(stickers, userId)
  );

/**
 * Selector creator for getting the `deleted` field of a sticker.
 *
 * @param {string} id - The id of the sticker whose `deleted` field we want.
 * @returns Selector that returns the desired `deleted` field.
 */
export const getDeleted = (id: string) =>
  createSelector(getById(id), (sticker) => sticker.deleted ?? false);

/**
 * Selector creator for determining whether a provided user id is currently in
 * the process of voting or reacting.
 *
 * This corresponds to the user either being in the middle of creating or
 * moving a sticker.
 * @param {string} userId - The id of the user.
 * @returns Selector that returns whether or not the user is stickering.
 */
export const isStickering = (userId: string) =>
  createSelector(getAll, (stickers) =>
    stickers.some((sticker) => {
      return sticker.creating === userId || sticker.grab?.userId === userId;
    })
  );

/**
 * Selector creator for getting the `recipient` field of a given sticker.
 *
 * @param {string} id - The id of the sticker we're interested in.
 * @returns Selector that returns the desired sticker's `recipient` field.
 */
export const getRecipient = (id: string) =>
  createSelector(
    Sticky.getAll,
    (stickies) => stickies.filter(({ votes }) => votes.includes(id))?.[0]
  );

/**
 * Selector creator for getting all stickies that are currently positioned
 * underneath the desired sticker.
 *
 * @param {string} id - The id of the sticker we're interested in.
 * @returns Selector that returns the stickies underneath the sticker.
 */
export const getUnderneath = (id: string) =>
  createSelector(Sticky.getAll, Size.getArea(id), (stickies, stickerArea) => {
    if (!stickerArea) return [];

    return stickies.filter((sticky) => {
      return Rectangle.containsRect(Rectangle.padded(sticky, 40), stickerArea);
    });
  });

/**
 * Utility for creating a `StickerGroup`` from an array of like stickers.
 *
 * @param {Sticker[]} stickers - The array of stickers to group.
 * @param {string} userId - The id of the current user.
 * @returns {StickerGroup} The sticker group.
 */
function createStickerGroup(stickers: Sticker[], userId: string): StickerGroup {
  const recent = orderBy(stickers, "updatedAt", "desc");
  const owned = recent.filter((sticker) => userId === sticker.user);

  // Base the group on the most-recently updated sticker owned
  // by the current user.
  const base = owned?.[0] ?? recent[0];

  return {
    ...base,
    // Position the group consistently using the most recent
    // position for the group.
    position: recent[0].position,
    stickers: stickers.map((sticker) => sticker.id),
  };
}

/**
 * Utility for determining whether or not a sticker should be
 * collapsed.
 *
 * @param {Sticker} sticker - The target sticker to check.
 * @param {Record<string, number>} counts - An object containing
 * the counts for each sticker group.
 * @returns Whether or not the sticker is collapsed.
 */
function isCollapsed(sticker: Sticker, counts: Record<string, number>) {
  const key = getCollapseByKey(sticker);
  return counts[key] >= COLLAPSE_THREHSHOLDS[sticker.type];
}

/**
 * Utility for getting the key for grouping like stickers.
 *
 * For reactions, we use the id of the sticky the reaction
 * belongs to and the shortcode. For votes, we use the id of
 * the sticky the reaction belongs to and the vote color.
 *
 * @param {Sticker} sticker - The target sticker to check.
 * @returns The computed key.
 */
export function getCollapseByKey(sticker: Sticker) {
  const { recipient } = sticker;

  if (isReaction(sticker)) {
    return `${recipient}_${sticker.shortcode}`;
  } else if (isAvatar(sticker)) {
    return `${recipient}_${sticker.avatarUserId}`;
  } else {
    return `${recipient}_${sticker.color}`;
  }
}

/**
 * Utility that checks whether a vote is dangling (has no recipient)
 */
export const isDangling = (sticker: Sticker) => !sticker.recipient;

/**
 * Collapse a collection of stickers into StickerGroups
 */
const collapseStickers = (stickers: Sticker[], userId: string) => {
  const groupCounts = countStickerGroups(stickers);

  const groups: Record<string, Sticker[]> = groupBy(
    stickers.filter(
      (sticker) =>
        // Handle stickers that may not have a recipient
        sticker.recipient && isCollapsed(sticker, groupCounts)
    ),
    getCollapseByKey
  );

  const stickerGroups = Object.keys(groups).map<StickerGroup>((key) =>
    createStickerGroup(groups[key], userId)
  );
  const stickerGroupIds = stickerGroups.flatMap(({ stickers }) => stickers);

  stickers = stickers.filter(({ id }) => !stickerGroupIds.includes(id));

  return [...stickerGroups, ...stickers];
};

/**
 * Compile a lookup table of occurence counts for every sticker type in the
 * collection.
 *
 * For reactions, we use the id of the sticky the reaction belongs to and the
 * shortcode. For votes, we use the id of the sticky the reaction belongs to
 * and the vote color.
 */
const countStickerGroups = (stickers: Sticker[]) =>
  countBy(stickers, (sticker) => getCollapseByKey(sticker));

/**
 * Return the collapse threshold for the provided sticker.
 */
export const collapseThreshold = (sticker: Sticker) =>
  COLLAPSE_THREHSHOLDS[sticker.type];

export const isAddReaction = (
  object: AddStickerOptions
): object is AddReactionOptions => {
  return object.type === "reaction";
};

export const isAddVote = (
  object: AddStickerOptions
): object is AddVoteOptions => {
  return object.type === "vote";
};
