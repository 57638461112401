/**
 * Report additional context to bugsnag if anything in the redux pipeline errors.
 */
import Bugsnag from "@bugsnag/browser";
import { Middleware } from "redux";

import { Config } from "~/context/ConfigContext";
import { Action as StoreAction } from "~/store";
import { Action } from "~/util/types";

import { BaseObject } from "../traits";

// Set of actions that should be tracked in bugsnag through breadcrumbs.
const BREADCRUMB_ACTIONS: Set<StoreAction["type"]> = new Set([
  "client/select",
  "client/start_panning",
  "client/scale",
  "comment/create",
  "comment/update",
  "connection/add",
  "image/add",
  "label/add",
  "shape/add",
  "sticky/add",
  "upload/add",
  "user/add",
  "sticker/add",
  "create/start_creating",
  "edit/start_editing",
  "grab/start_grabbing",
  "resize/start_resizing",
  "delete/delete",
  "base_object/insert",
] as const);

export const bugsnag =
  (config: Config): Middleware =>
  ({ getState }) => {
    Bugsnag.addMetadata("Board Data", {
      ...config,
    });

    return (next) => (action: StoreAction) => {
      if (BREADCRUMB_ACTIONS.has(action.type)) {
        Bugsnag.leaveBreadcrumb(`Performed action: ${action.type}`, action);
      }

      try {
        return next(action);
      } catch (err: any) {
        let object = (action as Action).data?.id
          ? BaseObject.getById((action as Action).data.id)(getState())
          : undefined;

        Bugsnag.addMetadata("action", { action, object });

        // Pass on error to be caught by top-level bugsnag boundary.
        throw err;
      }
    };
  };
