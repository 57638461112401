import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Vertex from "~/store/bundles/Vertex";
import { Duplicate, Create, Grab, Lock } from "~/store/traits";
import usePermission from "~/util/usePermission";

/**
 * Component that encapsulates the grabbing-related mouse event logic that is
 * shared between all Grab objects on the canvas.
 */
export const GrabTarget = ({
  id,
  onGrab,
  grabTest,
  onBeforeGrab,
  children,
}: {
  id: string;
  grabTest?: (userId: string) => boolean;
  onGrab?: () => void;
  onBeforeGrab?: () => void;
  children: ReactNode;
}) => {
  const data = useSelector(Grab.getById(id));
  const isSelected = useSelector(Client.getIsSelected(id));
  const isVertex = !!useSelector(Vertex.getById(id));
  const userId = useSelector(Client.getUserId);
  const dispatch = useDispatch();
  const can = usePermission();
  const object = useSelector(Create.getById(id));

  const onPointerDown = (event: React.PointerEvent) => {
    event.preventDefault();
    if (!userId) return;
    if (!can("edit", object)) return;
    if (grabTest && !grabTest(userId)) return;
    if (event.button !== 0) return;

    const position = { x: event.clientX, y: event.clientY };

    if (data && Create.isCreate(data) && data.creating === userId) return;
    if (data && Lock.isLock(data) && data.locked) {
      dispatch(Client.startPanning(position));
      return;
    }
    if (onBeforeGrab) onBeforeGrab();

    if (event.altKey) {
      dispatch(
        isSelected
          ? Duplicate.duplicateSelection(userId, position)
          : Duplicate.duplicate(id, position)
      );
    } else if (isSelected && !isVertex) {
      dispatch(Client.unsetShowcaseUserId());
      dispatch(Grab.startGrabbingSelection(userId, position));
    } else {
      dispatch(Client.unsetShowcaseUserId());
      dispatch(Grab.startGrabbing(id, userId, position));
    }

    if (onGrab) onGrab();
  };

  return <div onPointerDown={onPointerDown}>{children}</div>;
};
