import { Anchor } from "../Anchor";
import { Position } from "../shared/Position";

export let ConnectionAnchors = ({ objectId }: { objectId: string }) => (
  <>
    <Position region="top-center">
      <Anchor objectId={objectId} side="top" />
    </Position>
    <Position region="bottom-center">
      <Anchor objectId={objectId} side="bottom" />
    </Position>
    <Position region="middle-left">
      <Anchor objectId={objectId} side="left" />
    </Position>
    <Position region="middle-right">
      <Anchor objectId={objectId} side="right" />
    </Position>
  </>
);
