import { Middleware } from "redux";

import * as Upload from "~/store/bundles/Upload";
import cloudinary from "~/util/CloudinaryClient";
import { pdfFileToImage } from "~/util/pdfToImage";

/**
 * Union type of allowed storage providers.
 */
export type Provider = "cloudinary";

/**
 * Type that specifies the API for upload provider clients.
 */
type ProviderHandler = (
  file: File,
  boardId: string
) => Promise<string | undefined>;

const providers: Record<Provider, ProviderHandler> = {
  cloudinary,
};

export const middleware: Middleware = (store) => (next) => (action) => {
  if (action?.effects?.upload) {
    let { provider, file, boardId } = action.effects
      .upload as Upload.UploadEffects["upload"];
    delete action.effects.upload;

    Promise.resolve(file)
      // If its a pdf, convert to image
      .then((file) =>
        file.type === "application/pdf" ? pdfFileToImage(file) : file
      )
      .then((file) => providers[provider](file, boardId))
      .then((url) => {
        if (url) {
          store.dispatch(Upload.succeed(action.data.id, url));
        } else {
          store.dispatch(Upload.fail(action.data.id));
        }
      });
  }
  return next(action);
};

export default middleware;
