import { orderBy } from "lodash";
import { Middleware, Dispatch } from "redux";
import { createSelector } from "reselect";

import { Action, AppState } from "~/store";
import { BaseAction } from "~/store/shared";
import {
  Alive,
  Create,
  Delete,
  Grab,
  Lock,
  Select,
  Resize,
  Position,
  Connect,
  BaseObject,
} from "~/store/traits";
import { filterObject } from "~/util/filterObject";
import { Point } from "~/util/geometry";
import { createShallowEqualSelector } from "~/util/selectors";

import * as Client from "./Client";

/**
 * Interface that defines an `Image` object.
 */
export interface Image
  extends BaseObject.BaseObject,
    Alive.Alive,
    Create.Create,
    Delete.Delete,
    Grab.Grab,
    Lock.Lock,
    Position.Position,
    Select.Select,
    Resize.Resize,
    Connect.Connect {
  type: "image";
  upload: string;
}

/**
 * Type guard for narrowing a `BaseObject` type to an `Image`.
 *
 * @param {BaseObject} object - The object whose type we want to narrow.
 * @returns {boolean} Whether or not the object is of type `Image`.
 */
export const isImage = (object: BaseObject.BaseObject): object is Image => {
  return object.type === "image";
};

/**
 * Swap out any IDs according to a provided lookup table.
 *
 * @param {Image} image - The image object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with an `id` string and returns the string to replace it.
 * @returns {Image} The modified image with ids replaced.
 */
const swapIds = (image: Image, lookup: (id: string) => string): Image => {
  return {
    ...image,
    id: lookup(image.id),
    upload: lookup(image.upload),
    creating: image.creating ? lookup(image.creating) : null,
    grab: image.grab
      ? { ...image.grab, userId: lookup(image.grab.userId) }
      : null,
    resize: image.resize
      ? { ...image.resize, userId: lookup(image.resize.userId) }
      : null,
  };
};

/**
 * Remove any secondary information that we wouldn't want to appear, in a
 * template/fork.
 *
 * @param {Image} image - Image to convert into a template
 * @returns {Image} Template created from the provided `Image` object.
 */
export const template = (image: Image): Image => ({
  ...image,
  creating: null,
  grab: null,
  resize: null,
});

/**
 * Fork an image by swapping out ids using a provided lookup table.
 *
 * @param {Image} image - The image object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with an `id` string and returns the string to replace it.
 * @returns {Image} The modified image with ids replaced.
 */
export const fork = swapIds;

/**
 * Union of all possible `Image` action types.
 */
export type ImageAction = AddAction;

export interface AddAction extends BaseAction {
  type: "image/add";
  data: Image;
}

/**
 * Type that describes the named arguments for adding an image.
 */
interface AddArgs {
  /**
   * The id for the to-be-added image.
   */
  id: string;
  /**
   * The mouse position to be used for placing.
   */
  mousePosition: Point;
  /**
   * The width of the image.
   */
  width: number;
  /**
   * The height of the image.
   */
  height: number;
  /**
   * The id of the associated upload.
   */
  uploadId: string;
}

/**
 * Action creator for adding an image to the app state.
 *
 * @param {AddArgs} args - The parameters for the image.
 * @returns The action thunk to be dispatched to the store.
 */
export const add =
  ({ id, mousePosition, width, height, uploadId }: AddArgs) =>
  (state: AppState): AddAction => {
    let scene = Client.getScene(state);
    let scenePosition = Client.toSceneCoordinates(scene, mousePosition);

    let position: Point = {
      x: scenePosition.x - width / 2,
      y: scenePosition.y - height / 2,
    };

    return {
      type: "image/add",
      data: {
        type: "image",
        id,
        position,
        width,
        height,
        locked: false,
        deleted: false,
        upload: uploadId,
        grab: null,
        resize: null,
        creating: null,
        keepAlive: null,
      },
      effects: {
        broadcast: true,
        persist: true,
      },
    };
  };

/**
 * Reducer that processes any `Image` actions.
 *
 * @param {AppState["objects"]} [state] - The current `objects` state.
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["objects"]} The updated state slice.
 */
export const reducer = (
  state: AppState["objects"] = {},
  action: Action
): AppState["objects"] => {
  switch (action.type) {
    case "image/add":
      return { ...state, [action.data.id]: action.data };

    default:
      return state;
  }
};

/**
 * Redux middleware that is used to intercept general actions to inject `Image`
 * specific behavior.
 *
 * @param  store - The redux store object
 * @param  next - The next redux middleware in the middleware chain.
 * @param  action - The dispatched action we're intercepting
 * @returns The return value of the `next` middleware.
 */
export const middleware: Middleware<{}, AppState, Dispatch<any>> =
  ({ dispatch, getState }) =>
  (next) =>
  (action: Action) => {
    let additionalActions: Action[] = [];
    const state = getState();

    // Dispatch any additional behaviors
    switch (action.type) {
      /*
       * Release image whose `keepAlive` timer has expired.
       *
       * Unset the creating, grabbing, and resizing properties.
       */
      case "alive/release_expired": {
        for (const id in action.data) {
          const image = getById(id)(state);
          if (!image || image.deleted) continue;

          if (image.grab)
            additionalActions.push(Grab.finishGrabbing(id)(state));

          if (image.resize)
            additionalActions.push(Resize.finishResizing(id)(state));

          if (image.creating)
            additionalActions.push(Create.finishCreating(id)(state));
        }

        break;
      }
    }

    // Process the actual action first
    const result = next(action);

    // Dispatch additional actions
    additionalActions.forEach(dispatch);

    // Return result of original action
    return result;
  };

/**
 * Selector that returns a record of all images in the board state, keyed by
 * their id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, Image>} A record of all images in the app state.
 */
export const getImages = createShallowEqualSelector(
  BaseObject.getAll,
  (objects) => filterObject(objects, isImage)
);

/**
 * Selector that returns an array of all images, removing the deleted ones.
 *
 * @param {AppState} state - The current app state.
 * @returns An array of (non-deleted) images.
 */
export const getAll = createSelector(getImages, (images) =>
  Object.values(images).filter((img) => !img.deleted)
);

/**
 * Selector that returns an array of all undeleted images ordered by date last updated.
 * Locked images are placed below unlocked ones
 *
 * @param {AppState} state - The current app state.
 * @returns An ordered array of (non-deleted) images.
 */
// Even though we already use z-index on the board to place locked objects below
// we stille need to do it in the ordering because the SVG renderer can't use z-index
// and selected object all receive the same z-index so this is required to preserve the
// ordering within a selection.
export const getAllOrdered = createSelector(getAll, (images) =>
  orderBy(images, ["locked", "updatedAt"], ["desc", "asc"])
);

/**
 * Selector creator for getting the image with a given id.
 *
 * @param {string} id - The id of the vote we want to get
 * @returns Selector that returns the vote.
 */
export const getById = (id: string) =>
  createSelector(getImages, (images) => images[id]);
