import * as Vec from "./vector";
import { getBoundingRectFromPoints, Rectangle } from "./rectangle";
import { isPoint, Point } from "./vector";

export interface Circle {
  c: Point;
  r: number;
}

export const isCircle = (object: any): object is Circle => {
  return (
    object.hasOwnProperty("c") &&
    isPoint(object.c) &&
    object.hasOwnProperty("y")
  );
};

/**
 * Get the circle parameters for a circle passing through three given points.
 */
export const getCircle = (p1: Point, p2: Point, p3: Point): Circle => {
  let { x: x1, y: y1 } = p1;
  let { x: x2, y: y2 } = p2;
  let { x: x3, y: y3 } = p3;

  let a21 = (y2 - y1) / (x2 - x1);
  let a31 = (y3 - y1) / (x3 - x1);
  let b21 = 1 / a21;
  let b31 = 1 / a31;
  let xb21 = (x1 + x2) / 2;
  let yb21 = (y1 + y2) / 2;
  let xb31 = (x1 + x3) / 2;
  let yb31 = (y1 + y3) / 2;

  let x0 = (b31 * xb31 - b21 * xb21 + yb31 - yb21) / (b31 - b21);
  let y0 = (a31 * yb31 - a21 * yb21 + xb31 - xb21) / (a31 - a21);
  let r = Math.sqrt((x3 - x0) ** 2 + (y3 - y0) ** 2);

  return { c: { x: x0, y: y0 }, r };
};

/**
 * Find the tangent direction to a given circle at a given point
 */
export const getTangent = ({ c }: Circle, { x, y }: Point): Point => {
  return Vec.normalize({ x: -(y - c.y), y: x - c.x });
};

/**
 * Get intersections between circle and vertical line (fixed x)
 */
export const getXIntersections = (circle: Circle, x: number): Point[] => {
  let { c, r } = circle;
  let D = r ** 2 - (x - c.x) ** 2;
  return D >= 0
    ? [
        { x, y: c.y + Math.sqrt(D) },
        { x, y: c.y - Math.sqrt(D) },
      ]
    : [];
};

/**
 * Get intersections between circle and horizontal line (fixed y)
 */
export const getYIntersections = (circle: Circle, y: number): Point[] => {
  let { c, r } = circle;
  let D = r ** 2 - (y - c.y) ** 2;
  return D >= 0
    ? [
        { x: c.x + Math.sqrt(D), y },
        { x: c.x - Math.sqrt(D), y },
      ]
    : [];
};

/**
 * Get the intersections between a circle and a rectangle:
 * Look for all intersections with the lines delimiting the rectangle, then
 * return only the intersections that have both coordinates within the
 * boundaries of the rectangle.
 */
export const getRectIntersections = (circle: Circle, rect: Rectangle) => {
  let {
    position: { x, y },
    width,
    height,
  } = rect;

  let intersections = [
    ...getXIntersections(circle, x),
    ...getXIntersections(circle, x + width),
    ...getYIntersections(circle, y),
    ...getYIntersections(circle, y + height),
  ];

  return intersections.filter((point) => {
    return (
      point.x >= x &&
      point.x <= x + width &&
      point.y >= y &&
      point.y <= y + height
    );
  });
};

/**
 * Get the curvature of a circle passing through three points as the inverse
 * radius (in units relative to the distance between the initial and final point)
 * with the sign representing whether the circle is positioned left or right
 * relative to the initial and final points.
 */
export const getCurvature = (p1: Point, p2: Point, p3: Point) => {
  const { c, r } = getCircle(p1, p2, p3);
  return (
    (Vec.dist(p1, p3) / r) * Vec.handedness(Vec.vec(p1, p3), Vec.vec(p1, c))
  );
};

/**
 * Given three points, figure out the sense (1 for clockwise, -1 for counter-
 * clockwise) we would traverse the circle arc connecting the points from the
 * initial to the final point.
 */
export const getSense = (p1: Point, p2: Point, p3: Point) => {
  return Vec.handedness(Vec.vec(p1, p2), Vec.vec(p2, p3));
};

/**
 * Given two points and a (signed) curvature, return the unique circle that
 * satisfies the constraints.
 */
export const getFromCurvature = (
  pi: Point,
  pf: Point,
  curvature: number
): Circle => {
  const L = Vec.dist(pi, pf);
  const R = L / Math.abs(curvature); // Circle radius

  // Outward radial unit vector
  const u: Vec.Vector = Vec.scale(
    Vec.normal(Vec.vec(pi, pf)),
    Math.sign(curvature)
  );

  const midpoint: Point = {
    x: pi.x + Vec.scale(Vec.vec(pi, pf), 0.5).x,
    y: pi.y + Vec.scale(Vec.vec(pi, pf), 0.5).y,
  };

  const center: Point = {
    x: midpoint.x + Math.sqrt(R * R - (L * L) / 4) * u.x,
    y: midpoint.y + Math.sqrt(R * R - (L * L) / 4) * u.y,
  };

  return { c: center, r: R };
};

/**
 * Given a circle, two points on the circle, and a sense, figure out the midpoint
 * on the segment between the initial and final points.
 */
export const getSegmentMiddle = (
  pi: Point,
  pf: Point,
  circle: Circle,
  sense: -1 | 1
): Point => {
  let theta =
    sense === -1
      ? Vec.angle(Vec.vec(circle.c, pf), Vec.vec(circle.c, pi))
      : -Vec.angle(Vec.vec(circle.c, pi), Vec.vec(circle.c, pf));

  let bisector = Vec.rotate(Vec.vec(circle.c, pf), theta / 2);

  return { x: circle.c.x + bisector.x, y: circle.c.y + bisector.y };
};

/**
 * Given a circle segment, find the bounding box of the segment
 *
 * @param pi - The initial point of the segment
 * @param pf - The final point of the segment
 * @param circle - The circle in question
 * @param sense - The sense along which we're moving, to identfy which segment
 *                we want to consider (the small or large arc)
 */
export const getSegmentBoundingRect = (
  pi: Point,
  pf: Point,
  circle: Circle,
  sense: -1 | 1
): Rectangle => {
  // Idea: The bounding box needs to know what points on the segment will be
  // "most up/left/right/bottom". That is, it's determined by the segment
  // endpoints, and any of the top/bottom/leftmost/rightmost points of the
  // circle, _if_ they lie on the circle segment.

  // Top, bottom, left and right of a unit circle
  const tblr = [
    { x: 0, y: 1 },
    { x: 0, y: -1 },
    { x: -1, y: 0 },
    { x: 1, y: 0 },
  ];

  // Top, bottom, left and right of our actual circle by translating and
  // scaling the unit circle
  const extremes = tblr.map((point) => {
    return Vec.add(Vec.scale(point, circle.r), circle.c);
  });

  // Vec.angle returns the _right-handed_ angle between two vectors. So,
  // depending on the handedness of our circle segment, we need to swap the
  // start and end to make sure the subtended angle is righthanded.
  const [start, finish] = sense === 1 ? [pi, pf] : [pf, pi];

  // Find the ones that lie on our circle segment, i.e., lie within the
  // subtended angle
  const subtended = Vec.angle(
    Vec.vec(circle.c, start),
    Vec.vec(circle.c, finish)
  );

  const included = extremes.filter((extreme) => {
    return (
      Vec.angle(Vec.vec(circle.c, start), Vec.vec(circle.c, extreme)) <
      subtended
    );
  });

  return getBoundingRectFromPoints([pi, pf, ...included]);
};

export const clampToCircle = (circle: Circle, point: Point) => {
  const dir = Vec.normalize(Vec.vec(circle.c, point));

  return {
    x: circle.c.x + circle.r * dir.x,
    y: circle.c.y + circle.r * dir.y,
  };
};
