import { orderBy } from "lodash";
import { Middleware, Dispatch } from "redux";
import { createSelector } from "reselect";

import * as Client from "~/store/bundles/Client";
import { Action, AppState } from "~/store";
import { BaseAction } from "~/store/shared";
import {
  BaseObject,
  Connect,
  Create,
  Position,
  Color,
  Resize,
  Grab,
  Lock,
  Delete,
  Alive,
} from "~/store/traits";
import { filterObject } from "~/util/filterObject";
import { Point } from "~/util/geometry";
import { createShallowEqualSelector } from "~/util/selectors";

/**
 * Interface that defines a `Shape` object.
 */
export interface Shape
  extends BaseObject.BaseObject,
    Create.Create,
    Position.Position,
    Color.Color,
    Resize.Resize,
    Grab.Grab,
    Lock.Lock,
    Delete.Delete,
    Alive.Alive,
    Connect.Connect {
  type: "shape";
  shape: "rect" | "ellipse";
}

/**
 * Type guard for narrowing a `BaseObject` type to a `Shape`.
 *
 * @param {BaseObject.BaseObject} object - The object whose type we want to
 * narrow.
 * @returns {boolean} Whether or not the object is of type `Shape`
 */
export const isShape = (object: BaseObject.BaseObject): object is Shape => {
  return object.type === "shape";
};

/**
 * Swap out any IDs according to a provided lookup table.
 *
 * @param {Shape} shape - The shape object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Shape} The modified shape with ids replaced.
 */
const swapIds = (shape: Shape, lookup: (id: string) => string): Shape => {
  return {
    ...shape,
    id: lookup(shape.id),
    creating: shape.creating ? lookup(shape.creating) : null,
    grab: shape.grab
      ? { ...shape.grab, userId: lookup(shape.grab.userId) }
      : null,
    resize: shape.resize
      ? { ...shape.resize, userId: lookup(shape.resize.userId) }
      : null,
  };
};

/**
 * Remove any secondary information that we wouldn't want to appear, in a
 * template/fork.
 *
 * @param {Shape} shape - Shape to convert into a template
 * @returns {Shape} Template created from the provided `Shape` object.
 */
export const template = (shape: Shape): Shape => ({
  ...shape,
  creating: null,
  grab: null,
  resize: null,
});

/**
 * Fork a shape by swapping out ids using a provided lookup table.
 *
 * @param {Shape} shape - The shape object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Shape} The modified shape with ids replaced.
 */
export const fork = swapIds;

/**
 * Union of all possible `Shape` actions.
 */
export type ShapeAction = AddAction;

export interface AddAction extends BaseAction {
  type: "shape/add";
  data: Shape;
}

/**
 * Type that describes the named arguments for adding a shape.
 */
interface AddArgs {
  /**
   * The id for the to-be-added shape.
   */
  id: string;
  /**
   * The type of shape to create.
   */
  shape: "rect" | "ellipse";
  /**
   * The mouse position to be used for placing the shape.
   */
  mousePosition: Point;
  /**
   * The width of the shape.
   */
  width: number;
  /**
   * The height of the shape.
   */
  height: number;
  /**
   * The color of the shape.
   */
  color: string;
}

/**
 * Action creator for adding a shape to the app state.
 *
 * @param {AddArgs} args - The parameters for the shape.
 * @returns The action thunk to be dispatched to the store.
 */
export const add =
  ({ id, shape, mousePosition, width, height, color }: AddArgs) =>
  (state: AppState): AddAction => {
    let scene = Client.getScene(state);
    let scenePosition = Client.toSceneCoordinates(scene, mousePosition);

    let position = {
      x: scenePosition.x - width / 2,
      y: scenePosition.y - height / 2,
    };

    return {
      type: "shape/add",
      data: {
        type: "shape",
        id,
        shape,
        position,
        width,
        height,
        color,
        locked: false,
        deleted: false,
        grab: null,
        resize: null,
        creating: null,
        keepAlive: null,
      },
      effects: {
        broadcast: true,
        persist: true,
      },
    };
  };

/**
 * Reducer that processes any `Shape` actions.
 *
 * @param {AppState["objects"]} [state] - The current `objects` state.
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["objects"]} The updated state slice.
 */
export const reducer = (
  state: AppState["objects"] = {},
  action: Action
): AppState["objects"] => {
  switch (action.type) {
    case "shape/add":
      return { ...state, [action.data.id]: action.data };

    default:
      return state;
  }
};

/**
 * Redux middleware that is used to intercept general actions to inject `Shape`
 * specific behavior.
 *
 * @param  store - The redux store object
 * @param  next - The next redux middleware in the middleware chain.
 * @param  action - The dispatched action we're intercepting
 * @returns The return value of the `next` middleware.
 */
export const middleware: Middleware<{}, AppState, Dispatch<any>> =
  ({ dispatch, getState }) =>
  (next) =>
  (action: Action) => {
    let additionalActions: Action[] = [];
    const state = getState();

    // Dispatch any additional behaviors
    switch (action.type) {
      /*
       * Release shape whose `keepAlive` timer has expired.
       *
       * Unset the creating, grabbing, and resizing properties.
       */
      case "alive/release_expired": {
        for (const id in action.data) {
          const shape = getById(id)(state);
          if (!shape || shape.deleted) continue;

          if (shape.grab)
            additionalActions.push(Grab.finishGrabbing(id)(state));

          if (shape.resize)
            additionalActions.push(Resize.finishResizing(id)(state));

          if (shape.creating)
            additionalActions.push(Create.finishCreating(id)(state));
        }

        break;
      }
    }

    // Process the actual action first
    const result = next(action);

    // Dispatch additional actions
    additionalActions.forEach(dispatch);

    // Return result of original action
    return result;
  };

/**
 * Selector that returns a record of all shapes in the board state, keyed by
 * their id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, Shape>} A record of all shapes in the app state.
 */
export const getShapes = createShallowEqualSelector(
  BaseObject.getAll,
  (objects) => filterObject(objects, isShape)
);

/**
 * Selector that returns an array of all shapes, removing the deleted ones.
 *
 * @param {AppState} state - The current app state.
 * @returns An array of (non-deleted) shapes.
 */
export const getAll = createSelector(getShapes, (shapes) =>
  Object.values(shapes).filter((sh) => !sh.deleted)
);

/**
 * Selector that returns an array of all undeleted shapes ordered by date last updated.
 * Locked shapes are placed below unlocked ones
 *
 * @param {AppState} state - The current app state.
 * @returns An ordered array of (non-deleted) shapes.
 */
// Even though we already use z-index on the board to place locked objects below
// we stille need to do it in the ordering because the SVG renderer can't use z-index
// and selected object all receive the same z-index so this is required to preserve the
// ordering within a selection.
export const getAllOrdered = createSelector(getAll, (shapes) =>
  orderBy(shapes, ["locked", "updatedAt"], ["desc", "asc"])
);

/**
 * Selector creator for getting the shape with a given id.
 *
 * @param {string} id - The id of the shape we want to get
 * @returns Selector that returns the shape.
 */
export const getById = (id: string) =>
  createSelector(getShapes, (shapes) => shapes[id]);
