import React, { useCallback, useRef, useState } from "react";

interface PinchEvent {
  x: number;
  y: number;
  delta: number;
}

interface GestureHandlers {
  onPinch(event: PinchEvent): any;
}

export const useTouchGestures = (handlers: GestureHandlers) => {
  const touchesRef = useRef<React.TouchList | undefined>();
  const [isPinching, setIsPinching] = useState(false);
  const { onPinch } = handlers;

  const onTouchStart = useCallback(
    (event: React.TouchEvent) => {
      touchesRef.current = event.touches;

      if (event.touches.length === 2) {
        setIsPinching(true);
      }
    },
    [setIsPinching]
  );

  const onTouchEnd = useCallback(
    (event: React.TouchEvent) => {
      touchesRef.current = undefined;
      setIsPinching(false);
    },
    [setIsPinching]
  );

  const onTouchMove = useCallback(
    (event: React.TouchEvent) => {
      const currTouches = event.touches;
      const prevTouches = touchesRef.current;
      touchesRef.current = currTouches;

      if (currTouches.length === 2 && prevTouches?.length === 2) {
        const t0 = prevTouches[0];
        const t1 = prevTouches[1];
        const t2 = currTouches[0];
        const t3 = currTouches[1];

        const prevDistance = Math.hypot(
          t1.clientX - t0.clientX,
          t1.clientY - t0.clientY
        );

        const currDistance = Math.hypot(
          t3.clientX - t2.clientX,
          t3.clientY - t2.clientY
        );

        const delta = prevDistance - currDistance;

        // Find the pinch midpoint
        const x = t2.clientX + (t3.clientX - t2.clientX) / 2;
        const y = t2.clientY + (t3.clientY - t2.clientY) / 2;

        const event: PinchEvent = { delta, x, y };

        onPinch(event);
      }
    },
    [onPinch]
  );

  return {
    onTouchStart,
    onTouchEnd,
    onTouchMove,
    isPinching,
  };
};
