import "./Panel.css";

import { ReactNode } from "react";

import { classNames } from "~/util/classNames";

interface PanelProps {
  className?: string;
  size?: "sm" | "md" | "lg";
  variant?: "default" | "primary" | "danger" | "info";
  children: ReactNode;
  dataCy?: string;
}

export let Panel = ({
  size = "md",
  variant = "default",
  className,
  children,
  dataCy,
}: PanelProps) => (
  <div
    className={classNames(
      "panel",
      `panel-${size}`,
      `panel-${variant}`,
      className
    )}
    data-cy={dataCy}
  >
    {children}
  </div>
);

interface PanelStackProps {
  className?: string;
  size?: PanelProps["size"];
  group?: boolean;
  children: ReactNode;
}

export let PanelStack = ({
  size = "md",
  group = false,
  className,
  children,
}: PanelStackProps) => (
  <div
    className={classNames(
      `panel-stack`,
      `panel-stack-${size}`,
      group && `panel-stack-grouped`,
      className
    )}
  >
    {children}
  </div>
);
