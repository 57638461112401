import { ReactNode } from "react";
import { useDispatch } from "react-redux";

import * as Client from "~/store/bundles/Client";

/**
 * Component that encapsulates the context-menu related mouse event logic shared
 * between all canvas objects.
 */
export const ContextMenuTarget = ({
  id,
  children,
}: {
  id: string;
  children: ReactNode;
}) => {
  const dispatch = useDispatch();

  const onContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();

    let position = { x: event.clientX, y: event.clientY };
    dispatch(Client.setContextMenu(id, position));
  };

  return <div onContextMenu={onContextMenu}>{children}</div>;
};
