import "./EditTarget.css";

import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDoubleTap } from "use-double-tap";

import * as Client from "~/store/bundles/Client";
import { Edit, Create } from "~/store/traits";
import usePermission from "~/util/usePermission";

/**
 * Component that encapsulates the edit-related mouse event behavior of all Edit
 * objects on the canvas.
 */
export const EditTarget = ({
  id,
  children,
}: {
  id: string;
  children: ReactNode;
}) => {
  const dispatch = useDispatch();
  const userId = useSelector(Client.getUserId);
  const can = usePermission();
  const object = useSelector(Create.getById(id));

  const handleDoubleClick = useDoubleTap((event: React.MouseEvent) => {
    event.stopPropagation(); // Don't trigger document-level events

    if (!userId) return;
    if (!can("edit", object)) return;

    dispatch(Edit.startEditing(id, userId));
  });

  return (
    <div className="edit-target" {...handleDoubleClick}>
      {children}
    </div>
  );
};
