import "./Shape.css";

import React from "react";
import { useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Comments from "~/store/bundles/Comments";
import * as Shapes from "~/store/bundles/Shape";
import * as Rectangle from "~/util/geometry/rectangle";
import { BoardType, useConfig } from "~/context/ConfigContext";
import { classNames } from "~/util/classNames";
import usePermission from "~/util/usePermission";

import { ControlOverlay, Control } from "./ControlOverlay";
import { OpenComments } from "./OpenComments";
import { ConnectTarget } from "./shared/ConnectTarget";
import { ConnectionAnchors } from "./shared/ConnectionAnchors";
import { ContextMenuTarget } from "./shared/ContextMenuTarget";
import { GrabTarget } from "./shared/GrabTarget";
import { ObjectElements } from "./shared/ObjectElements";
import { Position } from "./shared/Position";
import ResizeHandle from "./shared/ResizeHandle";
import { SelectTarget } from "./shared/SelectTarget";
import { ShowcaseTarget } from "./shared/ShowcaseTarget";
import { useHoverTarget } from "./shared/useHoverTarget";

export const Shape = React.memo(({ shape }: { shape: Shapes.Shape }) => {
  const userId = useSelector(Client.getUserId);
  const isSelected = useSelector(Client.getIsSelected(shape.id));
  const isGrabbing = userId === shape.grab?.userId;
  const isTemplate = useConfig().type === BoardType.Template;
  const isResizing = !!shape.resize;
  const isCreating = !!shape.creating;
  const comments = useSelector(Comments.getByParentId(shape.id));
  const hasComments = comments.length > 0;
  const { hover, HoverTarget } = useHoverTarget(shape.id);

  const { position, width, height } = Rectangle.normalized(shape);
  const { locked } = shape;
  const can = usePermission();

  const className = classNames(
    "shape__wrapper",
    "group pointer-events-none",
    isSelected && shape.shape === "ellipse"
      ? "rounded ring-8 ring-sky-400"
      : "",
    isGrabbing ? "grabbing cursor-grabbing" : locked ? "locked" : "cursor-grab",
    isSelected && "selected"
  );

  const highlighted = isSelected;

  const style = {
    transform: `translate(${position.x}px, ${position.y}px)`,
    width: `${width}px`,
    height: `${height}px`,
  };

  return (
    <HoverTarget>
      <ConnectTarget id={shape.id} className={className} style={style}>
        <SelectTarget id={shape.id}>
          <ContextMenuTarget id={shape.id}>
            <GrabTarget id={shape.id}>
              <ShowcaseTarget id={shape.id} userId={shape.createdBy}>
                {shape.shape === "rect" ? (
                  <Rect {...{ width, height, highlighted }} />
                ) : (
                  <Ellipse {...{ width, height, highlighted }} />
                )}
              </ShowcaseTarget>
            </GrabTarget>
          </ContextMenuTarget>
        </SelectTarget>

        <ObjectElements>
          {can("edit", shape) && !isResizing && hover && !locked && (
            <ConnectionAnchors objectId={shape.id} />
          )}

          {!isTemplate && !isCreating && (
            <ControlOverlay>
              <Control visible={hover || hasComments} style={style}>
                <Position region="top-right">
                  <OpenComments id={shape.id} commentCount={comments.length} />
                </Position>
              </Control>
            </ControlOverlay>
          )}

          {can("edit", shape) && isSelected && !locked && (
            <>
              <Position region="top-right">
                <ResizeHandle id={shape.id} corner="top-right" />
              </Position>
              <Position region="top-left">
                <ResizeHandle id={shape.id} corner="top-left" />
              </Position>
              <Position region="bottom-right">
                <ResizeHandle id={shape.id} corner="bottom-right" />
              </Position>
              <Position region="bottom-left">
                <ResizeHandle id={shape.id} corner="bottom-left" />
              </Position>
            </>
          )}
        </ObjectElements>
      </ConnectTarget>
    </HoverTarget>
  );
});

Shape.displayName = "Shape";

const Rect = ({
  width,
  height,
  highlighted,
}: {
  width: number;
  height: number;
  highlighted: boolean;
}) => {
  return (
    <svg className="rounded overflow-visible" width={width} height={height}>
      <rect
        className="stroke-[50px] text-transparent fill-current"
        x={0}
        y={0}
        width={width}
        height={height}
        rx={4}
        pointerEvents="stroke"
      />
      <rect
        stroke={highlighted ? "var(--blue-3)" : "var(--grey-3)"}
        className={`
            rounded
            group-hover:text-sky-400
            stroke-[10px]
        `}
        x={0}
        y={0}
        width={width}
        height={height}
        rx={4}
        fill="none"
      />
    </svg>
  );
};

const Ellipse = ({
  width,
  height,
  highlighted,
}: {
  width: number;
  height: number;
  highlighted: boolean;
}) => {
  return (
    <svg className="overflow-visible" width={width} height={height}>
      <ellipse
        className="stroke-[50px] text-transparent fill-current"
        cx={width / 2}
        cy={height / 2}
        rx={"50%"}
        ry={"50%"}
        pointerEvents="stroke"
      />
      <rect
        className="stroke-[50px] text-transparent fill-current"
        x={0}
        y={0}
        width={width}
        height={height}
        rx={4}
        pointerEvents="stroke"
        stroke={"transparent"}
      />
      <ellipse
        stroke={highlighted ? "var(--blue-3)" : "var(--grey-3)"}
        className={`
        group-hover:text-sky-400
        stroke-[10px]
        rounded
        `}
        cx={width / 2}
        cy={height / 2}
        rx={"50%"}
        ry={"50%"}
        fill="none"
      />
    </svg>
  );
};
