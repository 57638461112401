import "./KeybindingsModal.css";

import {
  Keybinding,
  useKeybindingDomain,
  useKeybindings,
} from "~/context/KeybindingsContext";
import { isApple } from "~/util/isApple";

import * as Icons from "./Icons";
import { Box } from "./Box";
import {
  Modal,
  ModalPanel,
  ModalProps,
  ModalTitle,
  ModalDescription,
} from "./Modal";

export const KeybindingsModal = (props: ModalProps) => {
  const { bindings } = useKeybindings();
  useKeybindingDomain("modal");

  return (
    <Modal className="keybindings-modal" {...props}>
      <ModalPanel>
        <ModalTitle>
          <Box direction="row" justify="center" align="center" gap={2}>
            <Icons.HelpCircle /> Keyboard shortcuts
          </Box>
        </ModalTitle>

        <ModalDescription>
          Use these keyboard shortcuts to get your ideas into your board even
          faster!
        </ModalDescription>

        <Box
          className="keybindings-shortcuts"
          gap={1}
          p={2}
          dir="col"
          wrap={true}
        >
          {Object.values(bindings["default"]).map((binding) => {
            return <Shortcut key={binding.description} {...binding} />;
          })}
        </Box>
      </ModalPanel>
    </Modal>
  );
};

export const Shortcut = ({
  description,
  combinations,
}: {
  description: string;
  combinations: Keybinding["combinations"];
}) => {
  return (
    <Box className="keybindings-shortcut" direction="row" justify="between">
      <span>{description}</span>
      <Box direction="row" gap={2}>
        {combinations.map((keys) => (
          <ShortcutCombination {...keys} name={keys.key} />
        ))}
      </Box>
    </Box>
  );
};

export const ShortcutCombination = ({
  name,
  ctrl,
  shift,
  alt,
}: {
  name: string;
  ctrl?: boolean;
  shift?: boolean;
  alt?: boolean;
}) => {
  return (
    <Box className="keybindings-combination" direction="row" gap={2}>
      {ctrl && <Key name="Ctrl" />}
      {shift && <Key name="Shift" />}
      {alt && <Key name="Alt" />}
      <Key name={name} />
    </Box>
  );
};

export const Key = ({ name }: { name: string }) => {
  return (
    <kbd>
      <span className="keybindings-key">{SYMBOLS[name] ?? name}</span>
    </kbd>
  );
};

const SYMBOLS: Record<string, string> = {
  Ctrl: isApple ? "⌘" : "Ctrl",
  Alt: isApple ? "⌥" : "Alt",
  Delete: "Del",
  ArrowLeft: "←",
  ArrowUp: "↑",
  ArrowRight: "→",
  ArrowDown: "↓",
};
