import * as Comment from "~/store/bundles/Comments";
import * as BaseObject from "~/store/traits/BaseObject";
import { Account } from "~/context/AuthContext";
import { Plan } from "~/types/billing";

import { postJson } from "./ApiClient";

export type UpdateAccountParams = Pick<
  Account,
  "firstName" | "lastName" | "email" | "cursorColor" | "profilePicture"
>;

export async function updateAccount(id: string, values: UpdateAccountParams) {
  let response = await postJson(`/account/${id}`, values, "put");

  if (response.status === "failed") {
    throw new Error(response.msg);
  }

  return response;
}

/**
 * Interface that defines the params for creating an account,
 * including an optional board to create and associate with
 * the newly-created user.
 */
export interface CreateAccountParams extends UpdateAccountParams {
  plan: Plan;
  stripeSource?: string;
  promo?: string;
  referralToken?: string;
  inviteToken?: string;
  board?: {
    userId: string;
    name: string;
    description: string;
    objects: BaseObject.BaseObject[];
    comments: Comment.Comment[];
  };
}

export async function createAccount(values: CreateAccountParams) {
  let response = await postJson(`/account/create`, values);

  if (response.status === "failed") {
    throw new Error(response.msg);
  }

  return response;
}

export type LoginParams = Pick<Account, "email">;

export async function login(values: LoginParams) {
  let response = await postJson("/auth/login", values);

  if (response.status === "failed") {
    throw new Error(response.msg);
  }

  return response;
}

export interface ConfirmLoginParams extends Pick<Account, "email"> {
  code: string;
}

export async function confirmLogin(values: ConfirmLoginParams) {
  let response = await postJson("/auth/verify", values);

  if (response.status === "failed") {
    throw new Error(response.msg);
  }

  return response;
}

export interface VerifyNewEmailParams {
  token: string;
}

export async function verifyNewEmail(values: VerifyNewEmailParams) {
  let response = await postJson("/account/email", values);

  if (response.status === "failed") {
    throw new Error(response.msg);
  }

  return response;
}
