import "./Message.css";

import { ReactNode } from "react";

import { AlertTriangle, Check, Info } from "~/components/Icons";
import { classNames } from "~/util/classNames";

import { Box } from "./Box";

type Variant = "default" | "info" | "warning" | "danger" | "success";

interface MessageProps {
  variant?: Variant;
  icon?: ReactNode;
  children: ReactNode;
}

let icons: { [K in Variant]: ReactNode } = {
  default: <Info />,
  info: <Info />,
  success: <Check />,
  warning: <AlertTriangle />,
  danger: <AlertTriangle />,
};

export const Message = ({
  variant = "default",
  icon = icons[variant],
  children,
}: MessageProps) => (
  <Box
    className={classNames("message", `message-${variant}`)}
    direction="row"
    align="center"
    gap={2}
  >
    {icon}
    <div>{children}</div>
  </Box>
);
