import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "~/context/AuthContext";
import { verifyNewEmail } from "~/util/AccountClient";

import { Box } from "./Box";
import { Logo } from "./Icons";
import { Message } from "./Message";
import { Panel } from "./Panel";
import { Spinner } from "./Spinner";

type VerificationState =
  | { type: "pending" }
  | { type: "success" }
  | { type: "failed"; error: string };

export const EmailVerificationStatus = ({
  state,
}: {
  state: VerificationState;
}) => (
  <Box gap={2} align="center">
    <Box direction="row" gap={2} align="center">
      <Logo /> <strong>Sticky Studio</strong>
    </Box>
    <Box direction="row" gap={2} align="center">
      {state.type === "pending" ? (
        <Message variant="info" icon={<Spinner width="1em" height="1em" />}>
          Verifying new email address
        </Message>
      ) : state.type === "success" ? (
        <Message variant="success">
          Email address verified. Returning to dashboard...
        </Message>
      ) : (
        <Message variant="danger">{state.error}</Message>
      )}
    </Box>
  </Box>
);

export const VerifyNewEmail = () => {
  let navigate = useNavigate();
  let auth = useAuth();
  let [state, update] = useState<VerificationState>({ type: "pending" });

  useEffect(() => {
    let cancelled = false;
    let query = new URLSearchParams(window.location.search);
    let token = query.get("token") || "";

    let onSuccess = () => {
      if (cancelled) return;
      update({ type: "success" });
      auth.authenticate();
      setTimeout(() => navigate("/account", { replace: true }), 2000);
    };

    let onFailure = (err: any) => {
      if (cancelled) return;
      update({ type: "failed", error: err.message });
    };

    verifyNewEmail({ token }).then(onSuccess, onFailure);

    return () => {
      cancelled = true;
    };

    // This effect triggers the network request which verifies the new email
    // address, so it's important that it only runs once. The `auth` value
    // is not memoized inside `AuthContext` and adding it as a dependency
    // causes the effect to re-run every time we call `auth.authenticate`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box align="center" p={8}>
      <Panel>
        <EmailVerificationStatus state={state} />
      </Panel>
    </Box>
  );
};
