import { NAMES, COLORS } from "./constants";
import { pick } from "./random";

export const generateRandomUser = () => {
  const name = pick(NAMES);
  const color = pick(COLORS);

  return { name, color };
};

export const getVisitData = () => {
  const lastVisit = localStorage.getItem(`first-visit`);
  if (!lastVisit) {
    localStorage.setItem(`first-visit`, JSON.stringify(Date.now()));
  }

  const secondsSinceLastVisit = lastVisit
    ? (Date.now() - JSON.parse(lastVisit)) / 1000
    : 0;

  return {
    isFirstVisit: lastVisit == null,
    minutesSinceLastVisit: secondsSinceLastVisit / 60,
  };
};

export function getFirstLetter(name: string): string {
  // Ensures that emojis in names are extracted correctly, as using [0] will
  // only get the first half of some emojis that are represented with a pair
  // of characters.
  let codePoint = name.trim().codePointAt(0);
  return codePoint ? String.fromCodePoint(codePoint) : "?";
}
