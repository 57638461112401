import "./Tooltip.css";

import {
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  FloatingArrow,
  FloatingPortal,
  Placement,
  arrow,
  flip,
  offset,
} from "@floating-ui/react";
import { ReactNode, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

interface TooltipProps {
  label: string;
  position?: Placement;
  children: ReactNode;
}

export let Tooltip = ({ label, position = "top", children }: TooltipProps) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef<SVGSVGElement>(null);

  const { x, y, strategy, refs, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: position,
    middleware: [
      offset(10),
      flip(),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  // Hide tooltips on mobile, since they only appear
  // on click and obscure the popover if there is one.
  if (isMobile) {
    return <>{children}</>;
  }

  return (
    <>
      <div className="flex" ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {open && (
        <FloatingPortal>
          <div
            className="tooltip pointer-events-none"
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y || 0,
              left: x || 0,
              width: "max-content",
            }}
            {...getFloatingProps()}
          >
            {label}
            {(context.x || context.y) && (
              <FloatingArrow ref={arrowRef} context={context} />
            )}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
