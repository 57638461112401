import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Alive } from "~/store/traits";

/**
 * Start a periodic loop that releases any objects that have been idle for too
 * long.
 */
export const useCleanupLoop = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const releaseExpired = () => {
      dispatch(Alive.releaseExpired());
    };

    // Clean-up loop (runs every 30s)
    const handle = setInterval(releaseExpired, 30_000);

    return () => clearInterval(handle);
  }, [dispatch]);
};
