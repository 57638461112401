import React, { ReactNode } from "react";

import { ErrorBoundary as BugsnagErrorBoundary } from "~/util/bugsnag";

export const ErrorBoundary = React.memo(
  ({ children }: { children: ReactNode }) => {
    return (
      <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>
        {children}
      </BugsnagErrorBoundary>
    );
  }
);

ErrorBoundary.displayName = "ErrorBoundary";

const FallbackComponent = () => null;
