import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { ReactNode, ComponentType } from "react";

import { STRIPE_PUBLISHABLE_KEY } from "../config";

let stripePromise: Promise<Stripe | null> | null = null;

/*
 * Context helper to prevent reduce the number of places we need to manually
 * load Stripe and import the publishable key etc.
 */
export const StripeElementsContext = ({
  children,
}: {
  children: ReactNode;
}) => {
  // Don't load stripe until this component is rendered.
  if (stripePromise == null) {
    stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  }

  return <Elements stripe={stripePromise}>{children}</Elements>;
};

/**
 * Use this to wrap a component to automatically create the context so you can
 * use Stripe/Elements hooks inside it.
 */
export const withStripeElements =
  <Props extends {}>(Component: ComponentType<Props>) =>
  (props: Props) =>
    (
      <StripeElementsContext>
        <Component {...props} />
      </StripeElementsContext>
    );
