import {
  computePosition,
  FloatingElement,
  ReferenceElement,
  Strategy,
  UseFloatingProps,
  flip,
} from "@floating-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";

type Position = { x: number; y: number };

interface Context {
  setReference: (ref: ReferenceElement | null) => void;
  setFloating: (ref: FloatingElement | null) => void;
  position: Position | null;
  isPositioned: boolean;
  strategy: Strategy;
}

const FloatContext = React.createContext<Context | null>(null);

export interface Props extends Partial<Pick<UseFloatingProps, "placement">> {
  children?: React.ReactNode;
}

export const FloatProvider = ({ children, placement = "bottom" }: Props) => {
  const [reference, setReference] = useState<ReferenceElement | null>(null);
  const [floating, setFloating] = useState<FloatingElement | null>(null);
  const [position, setPosition] = useState<Position | null>(null);

  const strategy: Strategy = "absolute";

  const calculatePosition = useCallback(async () => {
    if (!reference || !floating) return;

    const { x, y } = await computePosition(reference, floating, {
      placement,
      strategy,
      middleware: [flip()],
    });

    setPosition({ x, y });
  }, [placement, reference, floating]);

  useEffect(() => {
    calculatePosition();
  }, [calculatePosition]);

  const isPositioned = position !== null;

  const context = {
    setReference,
    setFloating,
    position,
    strategy,
    isPositioned,
  };

  return (
    <FloatContext.Provider value={context}>{children}</FloatContext.Provider>
  );
};

export const useFloat = () => {
  const context = useContext(FloatContext);

  if (!context) {
    throw new Error("Can't use float unless within context provider");
  }

  return context;
};
