import "./ChangeLogModal.css";

import orderBy from "lodash/orderBy";
import { useEffect } from "react";

import {
  getAll as getAllAnnouncements,
  updateLastReadTime,
} from "~/util/AnnouncementClient";

import { Box } from "./Box";
import { Button } from "./Button";
import { ChangeLogList, ChangeLogListItem } from "./ChangeLogList";
import { Gift as GiftIcon } from "./Icons";
import {
  Modal,
  ModalPanel,
  ModalProps,
  ModalTitle,
  ModalDescription,
} from "./Modal";
import { Text } from "./Typography";

interface ChangeLogModalModalProps extends ModalProps {
  onUpdate?: () => void;
}

export let ChangeLogModal = ({
  onUpdate,
  ...props
}: ChangeLogModalModalProps) => {
  useEffect(() => {
    updateLastReadTime();

    if (onUpdate) {
      onUpdate();
    }
  }, [onUpdate]);

  return (
    <Modal className="changelog-modal" {...props}>
      <ModalPanel size="lg">
        <ModalTitle>
          <Box direction="row" justify="center" align="center" gap={2}>
            <GiftIcon /> What's New
          </Box>
        </ModalTitle>
        <ModalDescription>
          <ChangeLog />
        </ModalDescription>
        <Box align="end">
          <Button variant="primary" onClick={props.onClose}>
            Close
          </Button>
        </Box>
      </ModalPanel>
    </Modal>
  );
};

let ChangeLog = () => {
  let announcements = getAllAnnouncements();
  announcements = orderBy(announcements, "published", "desc");

  return (
    <ChangeLogList>
      {announcements.length > 0 ? (
        announcements.map((announcement) => (
          <ChangeLogListItem
            key={announcement.slug}
            announcement={announcement}
          />
        ))
      ) : (
        <Text variant="muted">
          There aren't any updates to show at the moment.
        </Text>
      )}
    </ChangeLogList>
  );
};
