import "./Legal.css";

import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { Box } from "./Box";
import { ArrowLeft, Logo } from "./Icons";
import { Panel } from "./Panel";
import { Heading } from "./Typography";

const Legal = ({
  title,
  children,
}: {
  title: ReactNode;
  children: ReactNode;
}) => (
  <Box p={8} className="legal" gap={6}>
    <Link to="/">
      <ArrowLeft /> <strong>Back</strong>
    </Link>
    <Panel size="lg">
      <Box align="center">
        <Logo width={64} height={64} />
        <Heading level={3}>{title}</Heading>
      </Box>
      <div className="legal-content">{children}</div>
    </Panel>
  </Box>
);

export default Legal;
