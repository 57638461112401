import { useKeybindingDomain } from "~/context/KeybindingsContext";

import { Modal, ModalProps } from "./Modal";

/**
 * An extension of Modal that handles updating key
 * bindings when the modal is opened, and restoring
 * them when closed. Should be used instead of Modal
 * for any in-board modals.
 */
export let BoardModal = (props: ModalProps) => {
  useKeybindingDomain("modal");

  return <Modal {...props} />;
};
