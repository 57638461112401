import "./SignupForm.css";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Formik,
  FormikErrors,
  FormikHelpers,
  useField,
  useFormikContext,
} from "formik";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { withStripeElements } from "~/context/StripeElementsContext";
import { useBoardStash } from "~/hooks/useBoardStash";
import { Plan } from "~/types/billing";
import { CreateAccountParams, createAccount } from "~/util/AccountClient";
import { FetchResponse, useFetch } from "~/util/ApiClient";
import { FathomEvents, trackAnalyticsEvent } from "~/util/analytics";
import { useQuery } from "~/util/hooks";

import { Box } from "./Box";
import { Button } from "./Button";
import {
  FormField,
  FormFieldHeader,
  FormFieldMessage,
  FormFieldValidationMessage,
} from "./FormField";
import { FormStatus } from "./FormStatus";
// TODO: remove?
import { TextInput } from "./TextInput";

type Values = Pick<CreateAccountParams, "firstName" | "lastName" | "email">;

enum Status {
  Entry,
  Submitting,
  Submitted,
}

export let SignupForm = withStripeElements(() => {
  const location = useLocation();
  const query = useQuery();
  const initialPlan = (query.get("plan") as Plan) ?? Plan.Free;
  const promo = query.get("promo");
  const referral = query.get("referral");
  const email = query.get("email");
  const invite = query.get("invite");

  const initialValues = {
    firstName: "",
    lastName: "",
    email: email || "",
  };

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { load: loadStash } = useBoardStash();

  const [status, setFormStatus] = useState(Status.Entry);
  const [plan] = useState<Plan>(initialPlan);

  const onSubmit = async (
    values: Values,
    { setStatus, setSubmitting }: FormikHelpers<Values>
  ) => {
    // Check if form is already submitting to prevent the user creating
    // multiple accounts.
    if (status !== Status.Entry) {
      return;
    }

    setFormStatus(Status.Submitting);

    const params: CreateAccountParams = { ...values, plan };

    // Add coupon (promo code) to request if present
    if (promo) {
      params.promo = promo;
    }

    // Add referral token, if present
    if (referral) {
      params.referralToken = referral;
    }

    // Attach the invite token, if present
    if (invite) {
      params.inviteToken = invite;
    }

    // Prevent submissions before stripe/elements have loaded
    if (!elements || !stripe) return;

    if (plan !== Plan.Free) {
      const cardElement = elements.getElement(CardElement)!;
      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        setFormStatus(Status.Entry);
        setStatus({
          status: "failed",
          msg: error.message ?? "Something went wrong.",
        });
        return;
      }

      if (token) {
        params.stripeSource = token.id;
      }
    }

    let stash = loadStash();

    if (stash) {
      params.board = {
        name: "Sandbox",
        description: "The board you created when trying Sticky Studio.",
        objects: Object.values(stash.objects),
        comments: Object.values(stash.comments),
        userId: stash.userId,
      };
    }

    let response: FetchResponse | undefined;

    try {
      response = await createAccount(params);
      setStatus(response);
      setFormStatus(Status.Submitted);
      // Redirect to the login code page.
      navigate("/code", {
        replace: true,
        state: {
          email: values.email,
          // Preserve the `from` state so that we can redirect to wherever the
          // user was trying to go, after they enter the code.
          from: location.state?.from,
        },
      });
      trackAnalyticsEvent(FathomEvents.SignUp);
    } catch (error: any) {
      setStatus({
        status: "failed",
        msg: error.message ?? "Something went wrong.",
      });
    } finally {
      setSubmitting(false);
      setFormStatus(Status.Entry);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit }) => (
        <Box as="form" onSubmit={handleSubmit} gap={3}>
          <SignUpFormNameField
            label="First name"
            property="firstName"
            autoFocus={true}
          />
          <SignUpFormNameField label="Last name" property="lastName" />
          <SignUpFormEmailField />
          {plan !== Plan.Free && (
            <CardElement className="w-full" onChange={console.log} />
          )}
          <SignupFormSubmitButton />
          <FormStatus />
          {promo && <CouponStatus promoCode={promo} />}
        </Box>
      )}
    </Formik>
  );
});

type SignupFormNameFieldProps = {
  label: string;
  property: string;
  autoFocus?: boolean;
};

let SignUpFormNameField = ({
  property,
  label,
  autoFocus,
}: SignupFormNameFieldProps) => {
  let [field] = useField(property);

  return (
    <FormField>
      <FormFieldHeader>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldHeader>
      <TextInput {...field} autoFocus={autoFocus} placeholder={label} />
    </FormField>
  );
};

let SignUpFormEmailField = () => {
  let [field] = useField("email");

  return (
    <FormField>
      <FormFieldHeader>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldHeader>
      <TextInput {...field} placeholder="Email" />
    </FormField>
  );
};

let SignupFormSubmitButton = () => {
  let { isSubmitting } = useFormikContext();

  return (
    <Button type="submit" variant="primary" loading={isSubmitting}>
      Create Account
    </Button>
  );
};

type PromotionCodeData = {
  id: string;
  active: boolean;
  percentOff?: number;
  amountOff?: number;
};

const CouponStatus = ({ promoCode }: { promoCode: string }) => {
  let response = useFetch<PromotionCodeData>(`/signup/promo?code=${promoCode}`);

  let promotion = response.status === "success" && response.data;

  if (!promotion) {
    return (
      <div className="flex justify-between w-full text-md opacity-50">
        <div>{response.status === "failed" && "Invalid promo code"}</div>
      </div>
    );
  }

  return (
    <div className="flex justify-between w-full text-md opacity-50">
      <div>{promotion.active ? "Coupon" : "This coupon is not active"}</div>
      {promotion.active && (
        <div>
          {promotion.amountOff && `$${promotion.amountOff / 100} off`}
          {promotion.percentOff && `${promotion.percentOff}% off`}
        </div>
      )}
    </div>
  );
};

function validate(values: Values) {
  let errors: FormikErrors<Values> = {};

  if (!values.firstName || values.firstName === "")
    errors.firstName = "First name is required.";
  if (!values.lastName || values.lastName === "")
    errors.lastName = "Last name is required.";

  if (!values.email || values.email === "") errors.email = "Email is required.";
  if (!/\S+@\S+\.\S{2,}/.test(values.email))
    errors.email = "Email must be a valid email address.";

  return errors;
}
