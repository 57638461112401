import React from "react";
import { useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Stickers from "~/store/bundles/Sticker";
import * as Rect from "~/util/geometry/rectangle";
import { ErrorBoundary } from "~/components/ErrorBoundary";
import { isConnection } from "~/store/bundles/Connection";
import { isImage } from "~/store/bundles/Image";
import { isLabel } from "~/store/bundles/Label";
import { isLine } from "~/store/bundles/Line";
import { isShape } from "~/store/bundles/Shape";
import { isSticker, isStickerGroup } from "~/store/bundles/Sticker";
import { isSticky } from "~/store/bundles/Sticky";
import { isUser } from "~/store/bundles/User";
import { isVertex } from "~/store/bundles/Vertex";
import { BaseObject } from "~/store/traits/BaseObject";
import { isSize } from "~/store/traits/Size";

import { Connection } from "./Connection";
import { Cursor } from "./Cursor";
import { Image } from "./Image";
import { Label } from "./Label";
import { Line } from "./Line";
import { Shape } from "./Shape";
import { Sticker } from "./Sticker";
import { StickerGroup } from "./StickerGroup";
import { Sticky } from "./Sticky";
import { Vertex } from "./Vertex";

export const BoardObject = React.memo(({ data }: { data: BaseObject }) => {
  const visibleArea = useSelector(Client.getVisibleArea);

  return isVisible(data, visibleArea) ? (
    <ErrorBoundary>{getComponent(data)}</ErrorBoundary>
  ) : null;
});

BoardObject.displayName = "BoardObject";

const getComponent = (object: BaseObject) => {
  if (isSticky(object)) return <Sticky sticky={object} />;
  if (isLabel(object)) return <Label label={object} />;
  if (isConnection(object)) return <Connection connection={object} />;
  if (isImage(object)) return <Image image={object} />;
  if (isShape(object)) return <Shape shape={object} />;
  if (isVertex(object)) return <Vertex vertex={object} />;
  if (isLine(object)) return <Line line={object} />;
  if (isUser(object)) return <Cursor user={object} />;
  if (isSticker(object))
    return isStickerGroup(object) ? (
      <StickerGroup
        key={Stickers.getCollapseByKey(object)}
        stickerGroup={object}
      />
    ) : (
      <Sticker sticker={object} />
    );
  return null;
};

const isVisible = (obj: BaseObject, visibleArea: Rect.Rectangle) =>
  isSize(obj) ? Rect.overlaps(obj, visibleArea) : true;
