import "./FeedbackBox.css";

import { ReactNode, useEffect, useState } from "react";

import { postJson } from "~/util/ApiClient";

import { Box } from "./Box";
import { Button } from "./Button";
import { Send as SendIcon } from "./Icons";

const DEFAULT_EMOJIS = ["🙂", "🤨", "😭", "😍"];

interface FeedbackBoxProps {
  emojis?: string[];
  onSent?(): void;
}

export let FeedbackBox = ({
  emojis = DEFAULT_EMOJIS,
  onSent = () => {},
}: FeedbackBoxProps) => {
  let [activeEmoji, setEmoji] = useState(emojis[0]);
  let [message, setMessage] = useState("");
  let [sending, setSending] = useState(false);
  let [info, setInfo] = useState<ReactNode>("");
  let isEmpty = message.trim().length === 0;

  // Clear info message after 2 seconds.
  useEffect(() => {
    if (!info) return;
    let handle = setTimeout(() => setInfo(""), 2000);
    return () => {
      clearTimeout(handle);
    };
  }, [info]);

  async function send() {
    let content = message.trim();
    if (content === "") return;

    setSending(true);

    let response = await postJson("/feedback", {
      message: content,
      emoji: activeEmoji,
    });

    if (response.status === "success") {
      setSending(false);
      setMessage("");
      setInfo("Thanks!");
      setTimeout(() => onSent(), 2000);
    } else {
      setSending(false);
      setInfo("Couldn't send");
    }
  }

  return (
    <Box className="feedback-box" gap={2}>
      <textarea
        autoFocus
        className="feedback-box-textarea"
        value={message}
        disabled={sending}
        placeholder="How can we make Sticky Studio better?"
        onChange={(e) => setMessage(e.target.value)}
      />
      <Box direction="row" justify="between" align="center">
        <Box direction="row" className="feedback-box-emojis" gap={2}>
          {emojis.map((emoji) => (
            <button
              key={emoji}
              className="feedback-box-emoji"
              aria-pressed={emoji === activeEmoji}
              onClick={() => setEmoji(emoji)}
            >
              {emoji}
            </button>
          ))}
        </Box>
        {info ? (
          <Box className="feedback-box-info" p={2}>
            {info}
          </Box>
        ) : (
          <Button
            variant="primary"
            loading={sending}
            onClick={send}
            disabled={isEmpty}
          >
            {sending ? (
              <>Sending</>
            ) : (
              <>
                <SendIcon /> Send
              </>
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
};
