import { ChangeEventHandler, ReactNode, useRef } from "react";

export interface ImageUploadButtonProps {
  onChange(file: File): void;
  children: ReactNode;
}

export let ImageUploadButton = ({
  onChange,
  children,
}: ImageUploadButtonProps) => {
  let inputRef = useRef<HTMLInputElement>(null);

  let handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    let input = event.target as HTMLInputElement;
    let file = input.files?.[0];
    if (file) {
      // Clear the input so onChange event will fire again,
      // even if same image is re-selected.
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      onChange(file);
    }
  };

  let handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <div role="button" onClick={handleClick}>
      <input
        ref={inputRef}
        type="file"
        accept="image/*,.pdf"
        onChange={handleChange}
        className="hidden"
      />
      {children}
    </div>
  );
};
