import "./BoardSharingOptions.css";

import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { Link } from "react-router-dom";

import * as Board from "~/store/bundles/Board";
import * as User from "~/store/bundles/User";
import { WEB_URL } from "~/config";
import { useAccount, useAuth } from "~/context/AuthContext";
import {
  AccessMode,
  Permission,
  getBoard,
  updateBoard,
} from "~/util/BoardClient";
import { Invite, useInvites } from "~/util/InviteClient";
import { FathomEvents, trackAnalyticsEvent } from "~/util/analytics";
import { EMAIL_REGEX, isValidEmail } from "~/util/email";
import { useBoardPath } from "~/util/hooks";
import usePermission from "~/util/usePermission";

import * as Icons from "./Icons";
import { Avatar } from "./Avatar";
import { Box } from "./Box";
import { Button } from "./Button";
import { Panel, PanelStack } from "./Panel";
import { Select, SelectButton, SelectOption, SelectOptions } from "./Select";
import { Spinner } from "./Spinner";
import { TextInput } from "./TextInput";
import { Tooltip } from "./Tooltip";

export let BoardSharingOptions = () => {
  let [accessMode, setAccessMode] = useAccessMode();
  let [permission, setPermission] = useBoardPermission();
  let auth = useAuth();
  let boardPath = useBoardPath();
  let can = usePermission();
  let isPro = !!auth.account?.pro;
  let canEditSettings = can("change-permission") || can("change-access");

  return (
    <PanelStack group className="board-sharing" size="sm">
      {
        // Show upsell to non-pro members
        !isPro && (
          <Panel className="board-sharing-section">
            <Box direction="row" justify="between">
              <div>
                Upgrade to{" "}
                <strong>
                  <Icons.Logo /> Plus
                </strong>{" "}
                to change the access permissions of your boards.
              </div>
              <Link to={`${boardPath}/pro`}>
                <Button variant="primary">Upgrade</Button>
              </Link>
            </Box>
          </Panel>
        )
      }

      {canEditSettings && (
        <Panel className="board-sharing-section">
          <header className="board-sharing-header">
            <div className="board-sharing-title">
              <Icons.Users /> Collaboration
            </div>
            <small className="board-sharing-info">
              Choose how others can collaborate
            </small>
          </header>

          {permission !== undefined && (
            <BoardPermissionSettings
              permission={permission}
              setPermission={setPermission}
              disabled={!can("change-permission")}
            />
          )}

          {
            // It would be confusing to show access options for NoAccess board
            permission !== Permission.NoAccess && accessMode && (
              <AccessModeSettings
                accessMode={accessMode}
                setAccessMode={setAccessMode}
                disabled={!can("change-access")}
              />
            )
          }
        </Panel>
      )}

      {permission === Permission.NoAccess ? null : accessMode ===
        "share-link" ? (
        <ShareLinkPanel />
      ) : accessMode === "invite-only" ? (
        <InvitePanel />
      ) : null}
    </PanelStack>
  );
};

export const ShareLinkPanel = () => {
  return (
    <Panel className="board-sharing-section">
      <header className="board-sharing-header">
        <div className="board-sharing-title">
          <Icons.UserPlus /> Share
        </div>
        <small className="board-sharing-info">
          Share this link to start collaborating
        </small>
      </header>
      <ShareLink />
    </Panel>
  );
};

export let ShareLink = () => {
  let boardId = useSelector(Board.getBoardId);
  let [copied, setCopied] = useState(false);
  let link = `${WEB_URL}/${boardId}`;

  function copyToClipboard() {
    trackAnalyticsEvent(FathomEvents.CopyShareLink);
    navigator.clipboard.writeText(link).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  }

  return (
    <Box direction="row" align="center" gap={2}>
      <TextInput
        onFocus={(event) => event.target.select()}
        value={link}
        readOnly
      />
      <Button variant="primary" onClick={copyToClipboard} disabled={copied}>
        {copied ? (
          <>
            <Icons.Check /> Copied
          </>
        ) : (
          <>
            <Icons.Copy /> Copy
          </>
        )}
      </Button>
    </Box>
  );
};

const PermissionOptions: PickerOption<Permission>[] = [
  {
    value: Permission.NoAccess,
    icon: <Icons.UserCheck />,
    name: "No Access",
    description: <>Only you have access to the board.</>,
  },
  {
    value: Permission.ReadOnly,
    icon: <Icons.Eye />,
    name: "View",
    description: (
      <>
        All members can <em>view</em> the board.
      </>
    ),
  },
  {
    value: Permission.Comment,
    icon: <Icons.MessageCircle />,
    name: "Comment",
    description: (
      <>
        All members can <em>comment</em>.
      </>
    ),
  },
  {
    value: Permission.Write,
    icon: <Icons.Edit />,
    name: "Edit",
    description: (
      <>
        All members can <em>edit</em> this board.
      </>
    ),
  },
];

type BoardPermissionSettingsProps = {
  permission: Permission;
  setPermission: (permission: Permission) => void;
  disabled: boolean;
};

export let BoardPermissionSettings = ({
  permission,
  setPermission,
  disabled,
}: BoardPermissionSettingsProps) => {
  let boardId = useSelector(Board.getBoardId);

  async function updatePermission(value: Permission) {
    let result = await updateBoard(boardId, { permissions: value });

    // Restore previous value
    if (result.status === "failed") {
      setPermission(permission);
    } else {
      setPermission(value);
      trackAnalyticsEvent(FathomEvents.ChangeBoardPermissions);
    }
  }

  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      gap={4}
      className="board-sharing-permissions"
    >
      <SettingPicker
        options={PermissionOptions}
        value={permission}
        onChange={updatePermission}
        disabled={disabled}
      />
    </Box>
  );
};

const AccessModeOptions: PickerOption<AccessMode>[] = [
  {
    value: "invite-only",
    icon: <Icons.UserPlus />,
    name: "Invite",
    description: <>Only people you invite have access.</>,
  },
  {
    value: "share-link",
    icon: <Icons.Link />,
    name: "Link",
    description: <>Only people with the link have access.</>,
  },
];

export const AccessModeSettings: FC<{
  accessMode: AccessMode;
  setAccessMode: (accessMode: AccessMode) => void;
  disabled: boolean;
}> = ({ accessMode, setAccessMode, disabled }) => {
  const boardId = useSelector(Board.getBoardId);

  async function updateAccessMode(value: AccessMode) {
    let result = await updateBoard(boardId, { accessMode: value });

    // Restore previous value
    if (result.status === "failed") {
      setAccessMode(accessMode);
    } else {
      setAccessMode(value);
    }
  }

  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      gap={4}
      className="board-sharing-mode"
    >
      {
        <SettingPicker
          options={AccessModeOptions}
          value={accessMode}
          onChange={updateAccessMode}
          disabled={disabled}
        />
      }
    </Box>
  );
};

type PickerOption<T extends React.Key> = {
  value: T;
  icon: ReactNode;
  name: ReactNode;
  description: ReactNode;
};

type SettingPickerProps<T extends React.Key> = {
  options: PickerOption<T>[];
  value: T;
  onChange: (value: T) => void | Promise<void>;
  disabled?: boolean;
};

export const SettingPicker = <T extends React.Key>(
  props: SettingPickerProps<T>
) => {
  const [busy, setBusy] = useState(false);
  const active = props.options.find((option) => option.value === props.value)!;

  const onChange = async (value: T) => {
    if (busy || value === active.value) return;

    setBusy(true);
    await props.onChange(value);
    setBusy(false);
  };

  return (
    <Box
      direction="row"
      gap={3}
      align="center"
      justify="between"
      className="board-sharing-picker"
    >
      <div className="board-sharing-picker-description">
        {active.description}
      </div>
      <Select
        value={props.value}
        onChange={onChange}
        disabled={busy || props.disabled}
      >
        <SelectButton>
          <div className="board-sharing-picker-active">
            {active.icon} {active.name}
          </div>
        </SelectButton>
        <SelectOptions>
          {props.options.map((option) => (
            <SelectOption key={option.value} value={option.value}>
              {option.name}
            </SelectOption>
          ))}
        </SelectOptions>
      </Select>
    </Box>
  );
};

export type InviteEntry = {
  id: string;
  to: string | User.User;
  createdAt: string;
};

export const InvitePanel = () => {
  const { getState } = useStore();
  const [input, setInput] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { valid, msg } = useValidation(input);

  const { isLoading, invites, add, remove, refresh } = useInvites();
  const can = usePermission();

  const emails = input
    .split(",")
    .map((str) => str.trim())
    .filter((str) => str !== "");

  /**
   * Helper to paper over the inconsistency beteen display name (email vs full
   * name vs avatar) for different invites.
   */
  const toEntry = useCallback(
    (invite: Invite): InviteEntry => {
      let { id, userId, firstName, lastName, email, createdAt } = invite;

      const to = userId
        ? User.getById(userId)(getState()) ?? email
        : firstName && lastName
        ? `${firstName} ${lastName}`
        : email;

      return { id, to, createdAt };
    },
    [getState]
  );

  const onSubmit = async () => {
    setIsSubmitting(true);
    await Promise.all(emails.map(add));
    setIsSubmitting(false);
    refresh();

    setInput("");
  };

  const onChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    setInput(ev.target.value);
  };

  return (
    <>
      <Panel className="board-sharing-section">
        {can("invite") ? (
          <Box direction="column" gap={2}>
            <header className="board-sharing-header">
              <div className="board-sharing-title">
                <Icons.UserPlus /> Invited members
              </div>
              <small className="board-sharing-info">
                {input === "" ? (
                  "Invite by email to start collaborating"
                ) : (
                  <ValidationMessage valid={valid} msg={msg} />
                )}
              </small>
            </header>
            <Box direction="row" gap={3}>
              <EmailInputField
                onSubmit={onSubmit}
                value={input}
                onChange={onChangeEmail}
                submitting={isSubmitting}
              />
              <InviteButton
                onSubmit={onSubmit}
                disabled={!valid}
                submitting={isSubmitting}
              />
            </Box>
          </Box>
        ) : (
          <Box direction="column" gap={2}>
            <header className="board-sharing-header">
              <div className="board-sharing-title">
                <Icons.UserPlus /> Invited members
              </div>
              <small className="board-sharing-info">
                Only the board owner can invite people
              </small>
            </header>
          </Box>
        )}

        {isLoading ? (
          <Spinner />
        ) : (
          invites.map((invite) => {
            return (
              <Invitation
                key={invite.id}
                invite={toEntry(invite)}
                remove={() => remove(invite.id)}
              />
            );
          })
        )}
      </Panel>
    </>
  );
};

const ValidationMessage: FC<{
  valid?: boolean;
  msg: string;
}> = ({ valid, msg }) => (
  <span>
    {valid !== undefined && valid ? (
      <Icons.Check color="var(--blue-3)" strokeWidth={3} />
    ) : (
      <Icons.X color="var(--red-3)" strokeWidth={3} />
    )}
    {msg}
  </span>
);

const EmailInputField: FC<{
  value: string;
  onChange: React.ChangeEventHandler;
  onSubmit: () => void;
  submitting: boolean;
}> = ({ value, onSubmit, onChange, submitting }) => {
  const onKeyPress: React.KeyboardEventHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <TextInput
      value={value}
      onKeyPress={onKeyPress}
      onChange={onChange}
      placeholder="Email"
      disabled={submitting}
      type="text"
      pattern={EMAIL_REGEX.source}
      multiple
    />
  );
};

const InviteButton: FC<{
  onSubmit: () => Promise<unknown>;
  disabled: boolean;
  submitting: boolean;
}> = ({ onSubmit, disabled, submitting }) => {
  return (
    <Button
      variant="primary"
      onClick={onSubmit}
      loading={submitting}
      disabled={disabled}
    >
      <Icons.UserPlus /> Invite
    </Button>
  );
};

export const Invitation: FC<{
  invite: InviteEntry;
  remove: () => Promise<void>;
}> = ({ invite, remove }) => {
  const [removing, setRemoving] = useState(false);
  const can = usePermission();

  const onRemove = async () => {
    setRemoving(true);
    await remove();
    setRemoving(false);
  };

  return (
    <Box direction="row" m={1} justify="between">
      <Box direction="row" align="center" gap={3}>
        {typeof invite.to === "string" ? (
          <>
            <Icons.Mail className="board-sharing-icon" />
            <span>{invite.to}</span>
          </>
        ) : (
          <>
            <Avatar
              name={invite.to.name}
              color={invite.to.color}
              className="board-sharing-icon"
            />
            <span>{invite.to.name}</span>
          </>
        )}
      </Box>

      {can("invite") ? (
        !removing ? (
          <Tooltip label="Revoke access" position="left">
            <button onClick={onRemove}>
              <Icons.X className="board-sharing-remove" />
            </button>
          </Tooltip>
        ) : (
          <Spinner />
        )
      ) : null}
    </Box>
  );
};

const useBoardPermission = () => {
  let [permission, setPermission] = useState<Permission>();

  let boardId = useSelector(Board.getBoardId);

  useEffect(() => {
    let cancelled = false;

    getBoard(boardId).then((res) => {
      if (!cancelled && res.status === "success") {
        setPermission(res.data.permissions);
      }
    });

    return () => {
      cancelled = true;
    };
  }, [boardId]);

  return [permission, setPermission] as const;
};

const useAccessMode = () => {
  const boardId = useSelector(Board.getBoardId);
  const [accessMode, setAccessMode] = useState<AccessMode>();

  useEffect(() => {
    let cancelled = false;

    getBoard(boardId).then((res) => {
      if (!cancelled && res.status === "success") {
        setAccessMode(res.data.accessMode);
      }
    });

    return () => {
      cancelled = true;
    };
  }, [boardId]);

  return [accessMode, setAccessMode] as const;
};

const useValidation = (input: string): { valid?: boolean; msg: string } => {
  const account = useAccount();

  if (input === "") return { msg: "Invite by email to start collaborating" };

  if (!isValidEmail(input)) {
    return { valid: false, msg: "Invalid email address" };
  }

  if (input === account?.email) {
    return { valid: false, msg: "You can't send an invite to yourself." };
  }

  return { valid: true, msg: "Looks good!" };
};
