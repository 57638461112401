import "./RichText.css";

import React from "react";

type Props = {
  value: string;
  className?: string;
};

const RichText = ({ value, className }: Props) => {
  return (
    <div
      className={`ql-snow richtext ${className}`}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

export default RichText;
