import "./Disabled.css";

import { ReactNode } from "react";

import { Box } from "./Box";
import { Tooltip } from "./Tooltip";

export const Disabled = ({
  children,
  disabled,
  msg,
}: {
  children: ReactNode;
  disabled?: boolean;
  msg: string;
}) => {
  return disabled ? (
    <Box>
      <Tooltip label={msg} position="top">
        {children}
        <Box className="disabled-wrapper" justify="center" align="center">
          <Box className="disabled-wrapper-dimmer" />
        </Box>
      </Tooltip>
    </Box>
  ) : (
    <>{children}</>
  );
};
