import "./ToolbarContextMenu.css";

import { Listbox } from "@headlessui/react";
import { ReactNode } from "react";

import { classNames } from "~/util/classNames";

interface ToolbarSelectProps<T> {
  value: T;
  onChange(value: T): void;
  children?: ReactNode;
  position?: "above" | "below";
  horizontal?: boolean;
  disabled?: boolean;
}

export function ToolbarContextMenu<T>({
  position = "below",
  horizontal = false,
  ...props
}: ToolbarSelectProps<T>) {
  return (
    <Listbox
      {...props}
      as="div"
      horizontal={horizontal}
      className={classNames(
        "toolbar-context-menu",
        `toolbar-context-menu-${position}`,
        horizontal && "toolbar-context-menu-horizontal"
      )}
    />
  );
}

export const ToolbarContextMenuButton = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Listbox.Button as="div" className="toolbar-context-menu-button">
    {children}
  </Listbox.Button>
);

export const ToolbarContextMenuItems = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Listbox.Options className="toolbar-context-menu-items">
    {children}
  </Listbox.Options>
);

interface ToolbarContextMenuItemProps<T> {
  value: T;
  disabled?: boolean;
  children: ReactNode;
}

export const ToolbarContextMenuItem = <T extends any = any>({
  children,
  ...props
}: ToolbarContextMenuItemProps<T>) => (
  <Listbox.Option {...props}>
    {({ active, disabled, selected }) => (
      <div
        className={classNames(
          "toolbar-context-menu-item",
          active && "toolbar-context-menu-item-active",
          disabled && "toolbar-context-menu-item-disabled",
          selected && "toolbar-context-menu-item-selected"
        )}
      >
        {children}
      </div>
    )}
  </Listbox.Option>
);
