import "./AccountDetails.css";

import { Formik, FormikHelpers, useField } from "formik";
import { ChangeEvent, ReactNode, useRef, useState } from "react";
import toast from "react-hot-toast";

import { useAccount, useAuth } from "~/context/AuthContext";
import { updateAccount, UpdateAccountParams } from "~/util/AccountClient";
import { uploadFile } from "~/util/CloudinaryClient";
import { classNames } from "~/util/classNames";
import { COLORS } from "~/util/constants";

import { Avatar } from "./Avatar";
import { Box } from "./Box";
import { Button } from "./Button";
import { ColorPicker } from "./ColorPicker";
import {
  FormField,
  FormFieldContext,
  FormFieldLabel,
  FormFieldMessage,
  FormFieldValidationMessage,
} from "./FormField";
import { FormStatus } from "./FormStatus";
import { Logo } from "./Icons";
import { Message } from "./Message";
import { Panel } from "./Panel";
import { Spinner } from "./Spinner";
import { useIsPlus } from "./StickyStudioPlus";
import { TextInput } from "./TextInput";

type Values = UpdateAccountParams;
type Errors = Partial<Record<keyof Values, string>>;

function validate(values: Values): Errors {
  let errors: Errors = {};

  if (values.firstName === "") errors.firstName = "First name is required";
  if (values.firstName.length > 20) errors.firstName = "Too long";

  if (values.lastName === "") errors.lastName = "Last name is required";
  if (values.lastName.length > 20) errors.lastName = "Too long";

  if (values.email === "") errors.email = "Email is required";
  if (values.email.length > 200) errors.email = "Too long";
  if (!/\w+@\w+/.test(values.email)) errors.email = "No can do";

  return errors;
}

export let AccountDetails = () => {
  let account = useAccount();
  let [message, setMessage] = useState<ReactNode>();
  let { authenticate } = useAuth();

  let initialValues: Values = {
    firstName: account?.firstName || "",
    lastName: account?.lastName || "",
    email: account?.email || "",
    cursorColor: account?.cursorColor ?? COLORS[0],
    profilePicture: account?.profilePicture,
  };

  let onSubmit = async (values: Values, helpers: FormikHelpers<Values>) => {
    if (account) {
      try {
        const response = await updateAccount(account.id, { ...values });

        if (values.email !== account.email) {
          setMessage(
            <Message variant="info">
              Follow the link we sent to {values.email} to verify this email
              address.
            </Message>
          );
        }

        // re-fetch account data
        if (response.status === "success") {
          authenticate();
        }

        helpers.setStatus(response);
        helpers.setSubmitting(false);
      } catch (error: any) {
        helpers.setStatus({
          status: "failed",
          msg: error.message ?? "Something went wrong.",
        });
      }
    }
  };

  return (
    <Panel>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Box gap={6}>
              <PicturePickerField />
              <FirstNameField />
              <LastNameField />
              <EmailField />
              <ColorPickerField />
              <Button
                type="submit"
                variant="primary"
                loading={isSubmitting}
                disabled={!dirty}
                data-cy="update-button"
              >
                Update
              </Button>
              {message}
              <FormStatus />
            </Box>
          </form>
        )}
      </Formik>
    </Panel>
  );
};

export let FirstNameField = () => {
  let [field] = useField("firstName");

  return (
    <FormField>
      <FormFieldContext>
        <FormFieldLabel htmlFor="first-name">First Name</FormFieldLabel>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldContext>
      <TextInput {...field} id="first-name" placeholder="Your first name" />
    </FormField>
  );
};

export let LastNameField = () => {
  let [field] = useField("lastName");

  return (
    <FormField>
      <FormFieldContext>
        <FormFieldLabel htmlFor="last-name">Last Name</FormFieldLabel>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldContext>
      <TextInput {...field} id="last-name" placeholder="Your last name" />
    </FormField>
  );
};

export let EmailField = () => {
  let [field] = useField("email");

  return (
    <FormField>
      <FormFieldContext>
        <FormFieldLabel htmlFor="email">Email Address</FormFieldLabel>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldContext>
      <TextInput
        {...field}
        id="email"
        type="email"
        placeholder="Your email address"
        data-cy="email-input"
      />
    </FormField>
  );
};

const ColorPickerField = () => {
  const [field, , helpers] = useField("cursorColor");
  const onClick = (color: string) => helpers.setValue(color);

  return (
    <FormField>
      <FormFieldContext>
        <FormFieldLabel>Preferred cursor color</FormFieldLabel>
      </FormFieldContext>

      <Box align="start" justify="start" m={1}>
        <ColorPicker selectedColor={field.value} onClick={onClick} />
      </Box>
    </FormField>
  );
};

const PicturePickerField = () => {
  const [profilePicture, , helpers] = useField("profilePicture");
  const [firstNameField] = useField("firstName");
  const [colorField] = useField("cursorColor");

  const onChange = (pictureUrl: string) => helpers.setValue(pictureUrl);
  const plus = useIsPlus();

  return (
    <PicturePicker
      disabled={!plus}
      name={firstNameField.value}
      color={colorField.value}
      message={
        plus ? (
          "Change Picture"
        ) : (
          <div className="p-3 text-center">
            Upgrade to
            <div className="font-bold">
              <Logo /> Plus
            </div>
          </div>
        )
      }
      current={profilePicture.value}
      onChange={onChange}
    />
  );
};

type PicturePickerProps = {
  current?: string;
  disabled?: boolean;
  name?: string;
  color?: string;
  message?: ReactNode;
  onChange: (url: string) => void;
};

export const PicturePicker = ({
  current,
  disabled,
  message,
  name,
  color,
  onChange,
}: PicturePickerProps) => {
  const [tempImage, setTempImage] = useState<File>();
  const inputRef = useRef<HTMLInputElement>(null);

  const onAttach = async (event: ChangeEvent<HTMLInputElement>) => {
    let [file] = Array.from(event.currentTarget.files ?? []);
    if (!file) return;

    // Set as temporary image so we can show a preview
    setTempImage(file);

    try {
      let uploadUrl = await uploadFile(file);
      onChange(uploadUrl);
    } catch (err: any) {
      toast.error(err.message);
    }

    setTempImage(undefined);
  };

  const onClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  // If we're in the middle of uploading a new picture, show that instead
  const imageSrc = tempImage ? URL.createObjectURL(tempImage) : current;

  return (
    <Box align="center" justify="center">
      <Box
        align="center"
        className={classNames(
          "account-picture-picker",
          tempImage && "account-picture-loading"
        )}
        justify="center"
        onClick={onClick}
      >
        <Avatar
          variant="profile"
          name={name || ""}
          color={color || ""}
          picture={imageSrc}
        />

        {tempImage ? (
          <Box
            className="account-picture-spinner"
            align="center"
            justify="center"
          >
            <Spinner />
            Uploading
          </Box>
        ) : (
          <Box
            justify="center"
            align="center"
            className="account-change-picture"
          >
            {message}
          </Box>
        )}

        <input
          type="file"
          ref={inputRef}
          accept="image/*"
          className="hidden"
          disabled={disabled}
          multiple
          onChange={onAttach}
        />
      </Box>
    </Box>
  );
};
