import "./Modal.css";

import { Dialog } from "@headlessui/react";
import { ReactNode } from "react";

import { classNames } from "~/util/classNames";

import { Box } from "./Box";

export interface ModalProps {
  open: boolean;
  className?: string;
  onClose(): void;
  children?: ReactNode;
}

export let Modal = ({ open, onClose, children, className }: ModalProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    className={classNames("modal", className)}
  >
    {children}
  </Dialog>
);

export interface ModalPanelProps {
  size?: "sm" | "lg";
  className?: string;
  style?: object;
  children: ReactNode;
}

export let ModalPanel = ({ size, ...props }: ModalPanelProps) => (
  <Dialog.Panel
    {...props}
    className={classNames(
      "modal-panel",
      size && `modal-panel-${size}`,
      props.className
    )}
  />
);

export let ModalTitle = ({ children }: { children: ReactNode }) => (
  <Dialog.Title className="modal-title">{children}</Dialog.Title>
);

export let ModalDescription = ({ children }: { children: ReactNode }) => (
  <Dialog.Description className="modal-description" as="div">
    {children}
  </Dialog.Description>
);

export let ModalError = ({ children }: { children: ReactNode }) => (
  <Box className="modal-error" direction="row" justify="center">
    {children}
  </Box>
);
