import { AnchorHTMLAttributes } from "react";

import { ExternalLink as Icon } from "~/components/Icons";

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export const ExternalLink = ({ children, ...props }: AnchorProps) => {
  return (
    <a
      className="external-link underline"
      {...props}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children ?? props.href} <Icon />
    </a>
  );
};
