import { createSelector } from "reselect";

import * as Global from "~/store/bundles/Global";
import { Action, AppState } from "~/store";
import { BaseAction, emptyAction, EmptyAction } from "~/store/shared";
import { BaseObject } from "~/store/traits";
import { Color } from "~/store/traits/Color";
import assert from "~/util/assert";
import { filterObject } from "~/util/filterObject";
import { Point } from "~/util/geometry";
import { createShallowEqualSelector } from "~/util/selectors";

import * as Client from "./Client";

/**
 * Type representing a `User` object
 */
export interface User extends BaseObject.BaseObject, Color {
  type: "user";
  name: string;
  cursor: Point;
  online: boolean;
  picture?: string;
  huddle: null | {
    huddleId: string;
    status: "connecting" | "connected";
    muted: boolean;
  };
}

/**
 * Type guard for narrowing a `BaseObject`'s type to `User`.
 *
 * @param {BaseObject} object - The object whose type we want to narrow.
 * @returns {boolean} Whether or not the object is of type `User`
 */
export const isUser = (object: BaseObject.BaseObject): object is User => {
  return object.type === "user";
};

/**
 * Swap out any IDs according to a provided lookup table.
 *
 * @param {User} user - The user object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with an `id` string and returns the string to replace it.
 * @returns {User} The modified user with ids replaced.
 */
const swapIds = (user: User, lookup: (id: string) => string): User => {
  return {
    ...user,
    id: lookup(user.id),
  };
};

/**
 * Fork a user by swapping out ids using a provided lookup table.
 *
 * @param {User} user - The user object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with an `id` string and returns the string to replace it.
 * @returns {User} The modified user with ids replaced.
 */
export const fork = swapIds;

/**
 * Remove any secondary information that we wouldn't want to appear, in a
 * template/fork.
 *
 * In the case of Users, this is a no-op
 *
 * @param {User} user - User to convert into template
 * @returns {User} Template created from the provided User object.
 */
export const template = (user: User): User => user;

/**
 * Union of all possible `User` action types.
 */
export type UserAction =
  | AddAction
  | MoveCursorAction
  | RestoreAction
  | UpdateAction
  | JoinHuddleAction
  | LeaveHuddleAction
  | SetMutedAction
  | RemoveAction;

export interface AddAction extends BaseAction {
  type: "user/add";
  data: User;
}

/**
 * Type that describes the named arguments for adding a user.
 */
interface AddArgs {
  /**
   * The id for the to-be-added user.
   */
  id: string;
  /**
   * The name of the user
   */
  name: string;
  /**
   * The color to be associated with the user cursor.
   */
  color: string;

  /**
   * An optional picture for registered users
   */
  picture?: string;
}

/**
 * Action creator for adding a user to the app state.
 *
 * @param {AddArgs} args - The parameters for the user to be added.
 * @returns {AddAction} The action to be dispatched to the store.
 */
export const add = ({ id, name, color, picture }: AddArgs): AddAction => {
  return {
    type: "user/add",
    data: {
      id,
      type: "user",
      name,
      color,
      cursor: { x: 0, y: 0 },
      picture,
      online: true,
      huddle: null,
    },
    effects: { broadcast: true, persist: true },
  };
};

export interface MoveCursorAction extends BaseAction {
  type: "user/move_cursor";
  data: Pick<User, "id" | "cursor">;
}

/**
 * Action creator for updating a user's cursor position in the app state.
 *
 * @param {string} id - The id of the user whose cursor should be updated.
 * @param {Position} position - The current cursor position, in *screen coordinates*.
 * @returns Action thunk to be dispatched to the store.
 */
export const moveCursor =
  (id: string, position: { x: number; y: number }) =>
  (state: AppState): MoveCursorAction => {
    let scene = Client.getScene(state);
    let scenePosition = Client.toSceneCoordinates(scene, position);

    return {
      type: "user/move_cursor",
      data: { id, cursor: scenePosition },
      effects: { broadcast: true },
    };
  };

export interface RestoreAction extends BaseAction {
  type: "user/restore";
  data: Pick<User, "id" | "online" | "huddle">;
}

/**
 * Set an existing (offline) user to be `online`.
 *
 * @param {string} id - The id of the user we want to set as `online`
 * @returns {RestoreAction} The action to be dispatched to the store.
 */
export const restore = (id: string): RestoreAction => {
  return {
    type: "user/restore" as const,
    data: { id, online: true, huddle: null },
    effects: { broadcast: true, persist: true },
  };
};

export interface RemoveAction extends BaseAction {
  type: "user/remove";
  data: Pick<User, "id" | "online" | "huddle">;
}

export interface UpdateAction extends BaseAction {
  type: "user/update";
  data: Pick<User, "id" | "online">;
}

/**
 * Update an existing user record
 *
 * @param params - The parameters to update
 * @returns {UpdateAction} The action to be dispatched to the store.
 */
export const update = (params: {
  id: string;
  name: string;
  color: string;
  picture?: string;
}): UpdateAction => {
  return {
    type: "user/update" as const,
    data: { ...params, online: true },
    effects: { broadcast: true, persist: true },
  };
};

export interface RemoveAction extends BaseAction {
  type: "user/remove";
  data: Pick<User, "id" | "online" | "huddle">;
}

/**
 * Remove a user by setting its status to "offline".
 *
 * Users don't actually get removed, since we need to be able to access their
 * data later on. We simply remove/release all related entities.
 *
 * @param {string} userId - The id of the user we want to set as offline.
 * @returns {RemoveAction} The action to be dispatched to the store.
 */
export const remove = (userId: string): RemoveAction => {
  return {
    type: "user/remove" as const,
    data: { id: userId, online: false, huddle: null },
    effects: {
      broadcast: true,
      persist: true,
      dispatch: [Global.releaseAllBy(userId)],
    },
  };
};

export interface JoinHuddleAction extends BaseAction {
  type: "user/join_huddle";
  data: Pick<User, "id" | "huddle">;
}

export const joinHuddle = (
  userId: string,
  huddleId: string
): JoinHuddleAction => {
  return {
    type: "user/join_huddle",
    data: {
      id: userId,
      huddle: { huddleId, status: "connected", muted: false },
    },
    effects: { broadcast: true, persist: true },
  };
};

export interface LeaveHuddleAction extends BaseAction {
  type: "user/leave_huddle";
  data: Pick<User, "id" | "huddle">;
}

export const leaveHuddle = (userId: string): LeaveHuddleAction => {
  return {
    type: "user/leave_huddle",
    data: { id: userId, huddle: null },
    effects: { broadcast: true, persist: true },
  };
};

export interface SetMutedAction extends BaseAction {
  type: "user/set_muted";
  data: Pick<User, "id" | "huddle">;
}

export const setMuted =
  (userId: string, muted: boolean) =>
  (state: AppState): SetMutedAction | EmptyAction => {
    const { huddle } = getById(userId)(state);
    if (!huddle) return emptyAction();

    return {
      type: "user/set_muted",
      data: { id: userId, huddle: { ...huddle, muted } },
      effects: { broadcast: true, persist: true },
    };
  };

/**
 * Reducer that processes any `user` actions.
 *
 * @param {AppState["objects"]} [state] - The current `objects` state
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["objects"]} The updated state slice.
 */
export const reducer = (
  state: AppState["objects"] = {},
  action: Action
): AppState["objects"] => {
  switch (action.type) {
    case "user/add":
      return { ...state, [action.data.id]: action.data };

    case "user/remove":
    case "user/restore":
    case "user/update":
    case "user/join_huddle":
    case "user/leave_huddle":
    case "user/set_muted":
    case "user/move_cursor": {
      const object = state[action.data.id];
      assert(isUser(object), "Object is not a User.");

      return { ...state, [object.id]: { ...state[object.id], ...action.data } };
    }

    default: {
      return state;
    }
  }
};

/**
 * Selector for getting all the users in the board state, keyed by their id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, User>} A record of all users in the app state.
 */
export const getUsers = createShallowEqualSelector(
  BaseObject.getAll,
  (objects) => filterObject(objects, isUser)
);

/**
 * Selector for getting all the users in the board state, keyed by their id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, User>} A record of all users in the app state.
 */
export const getUsersWithoutCursor = createShallowEqualSelector(
  getUsers,
  (users) => Object.values(users).map(({ cursor, ...rest }) => ({ ...rest }))
);

/**
 * Selector for getting all users in the board state that are currently online.
 *
 * @param {AppState} state - The current app state.
 * @returns {User[]} An array of users that are online.
 */
export const getOnline = createSelector(getUsers, (users) => {
  return Object.values(users).filter((user) => user.online);
});

/**
 * Selector for getting all users in the board state that are currently online,
 * omitting their cursor positions.
 *
 * This is useful for situations where we don't want cursor moves to trigger
 * re-renders.
 *
 * @param {AppState} state - The current app state.
 * @returns {User[]} An array of users that are online.
 */
export const getOnlineWithoutCursor = createSelector(
  getUsersWithoutCursor,
  (users) => users.filter((user) => user.online)
);

/**
 * Selector creator for getting a user with a given user ID.
 *
 * @param {string} id - The id of the user we want to look up.
 * @returns A selector for getting the user.
 */
export const getById = (id: string) =>
  createSelector(getUsers, (users) => users[id]);
/**
 * Selector creator for getting a user with a given user ID, omitting
 * the cursor information for better performance.
 *
 * Because this selector does a shallowEqual check on its result, this
 * doesn't trigger any re-renders or downstream recalculations because the
 * user moved his cursor.
 *
 * @param {string} id - The id of the user we want to look up.
 * @returns A selector for getting the user.
 */
export const getWithoutCursor = (id: string) =>
  createShallowEqualSelector(getById(id), (user) => {
    if (user) {
      let { cursor, ...rest } = user;
      return { ...rest };
    }
  });

/**
 * Get all users except for the current user.
 *
 * Slightly more performant selector that doesn't change or trigger
 * re-renders when the user moves his cursor.
 *
 * @param {string} id - The id of the user we want to look up.
 * @returns A selector for getting the user.
 */
export const getOtherOnlineUsers = createShallowEqualSelector(
  getOnline,
  (state: AppState) => Client.getUserId(state),
  (users, userId) => Object.values(users).filter((user) => user.id !== userId)
);

/**
 * Selector creator for getting the cursor of a user with a given id.
 *
 * @param {string} id - The id of the user whose cursor we want to look up.
 * @returns A selector for getting the cursor.
 */
export const getCursor = (id: string) =>
  createSelector(getById(id), (user) => user?.cursor ?? { x: 0, y: 0 });

/**
 * Selector that returns all users who are currently in a huddle.
 */
export const getUsersInHuddle = createShallowEqualSelector(getOnline, (users) =>
  users.filter((user) => user.huddle)
);
