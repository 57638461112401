import "./BoardList.css";

import { MouseEvent, ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { format as timeAgo } from "timeago.js";

import * as Api from "~/util/BoardClient";
import { useAuth } from "~/context/AuthContext";

import * as Icons from "./Icons";
import { confirm } from "./AlertDialog";
import { BoardInfoEditorModal } from "./BoardInfoEditor";
import { Box } from "./Box";
import { Menu, MenuButton, MenuItem, MenuItems } from "./Menu";
import { Panel } from "./Panel";
import { Tag } from "./Tag";

export type Board = {
  id: string;
  createdBy: string;
  name: string;
  description: string;
  lastVisitedAt?: string;
  template: boolean;
  invite?: string;
};

export let BoardList = ({ children }: { children: ReactNode }) => (
  <div className="board-list">{children}</div>
);

export interface BoardListItemProps {
  board: Board;
  onUpdate(): void;
  onDelete(): void;
}

export let BoardListItem = ({
  board,
  onDelete,
  onUpdate,
}: BoardListItemProps) => {
  let auth = useAuth();
  let [editorIsOpen, setEditorIsOpen] = useState(false);

  let handleEdit = (event: MouseEvent) => {
    // Prevent the click propagating up to the board item (which would
    // navigate to the board).
    //
    // TODO: This also prevents the menu from closing after the click.
    // Should really move the Menu so that it renders inside a portal and
    // the click event has no chance of bubbling up to the board preview.
    event.preventDefault();

    // Open editor.
    setEditorIsOpen(true);
  };

  let handleDelete = async (event: MouseEvent) => {
    // Prevent the click propagating up to the board item (which would
    // navigate to the board).
    event.preventDefault();

    let ok = await confirm({
      title: "Delete Board",
      description: (
        <>
          <p>
            No one will be able to access the board or its contents after it is
            deleted.
          </p>
          <p>
            Are you sure you want to delete "<strong>{board.name}</strong>"?
          </p>
        </>
      ),
      okButtonText: "Delete",
      okButtonVariant: "danger",
    });

    if (ok) {
      await Api.deleteBoard(board.id);
      // Refresh the account meta to reflect the
      // newly-deleted board.
      await auth.refresh();
      onDelete();
    }
  };

  let handleUpdate = () => {
    setEditorIsOpen(false);
    onUpdate();
  };

  let handleCancelEdit = () => {
    setEditorIsOpen(false);
  };

  let isCreator = board.createdBy === auth.account?.id;

  return (
    <Link to={`/${board.id}`} key={board.id}>
      <BoardInfoEditorModal
        board={board}
        open={editorIsOpen}
        onUpdate={handleUpdate}
        onClose={handleCancelEdit}
      />
      <Panel className="board-list-item" dataCy="board-list-item">
        <div className="board-list-item-name">{board.name}</div>
        <div className="board-list-item-description">{board.description}</div>
        <Box
          className="board-list-item-meta"
          direction="row"
          align="center"
          justify="between"
          gap={2}
        >
          {board.template ? (
            <Tag color="blue">
              <Icons.Package /> Template
            </Tag>
          ) : board.invite ? (
            <Tag color="green">
              <Icons.Mail /> Invited
            </Tag>
          ) : (
            board.lastVisitedAt && (
              <>Last visited {timeAgo(board.lastVisitedAt)}</>
            )
          )}
          {isCreator && (
            <div className="board-list-item-more">
              <Menu placement="bottom-start">
                <MenuButton>
                  <button
                    data-cy="board-list-item-menu-button"
                    className="board-list-item-more-button"
                  >
                    <Icons.Settings strokeWidth={2} />
                  </button>
                </MenuButton>
                <MenuItems>
                  <MenuItem data-cy="edit-menu-item" onClick={handleEdit}>
                    <Icons.Edit /> Edit
                  </MenuItem>
                  <MenuItem data-cy="delete-menu-item" onClick={handleDelete}>
                    <Icons.Trash /> Delete
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          )}
        </Box>
      </Panel>
    </Link>
  );
};
