import "./Sticky.css";

import { memo } from "react";
import { useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Comments from "~/store/bundles/Comments";
import * as Stickers from "~/store/bundles/Sticker";
import * as Stickies from "~/store/bundles/Sticky";
import { BoardType, useConfig } from "~/context/ConfigContext";
import { Sticker } from "~/renderers/html/Sticker";
import { StickerGroup } from "~/renderers/html/StickerGroup";
import { classNames } from "~/util/classNames";
import usePermission from "~/util/usePermission";

import { ControlOverlay, Control } from "./ControlOverlay";
import { OpenComments } from "./OpenComments";
import { ConnectTarget } from "./shared/ConnectTarget";
import { ConnectionAnchors } from "./shared/ConnectionAnchors";
import { ContextMenuTarget } from "./shared/ContextMenuTarget";
import { EditTarget } from "./shared/EditTarget";
import { EditableText } from "./shared/EditableText";
import { GrabTarget } from "./shared/GrabTarget";
import { ObjectElements } from "./shared/ObjectElements";
import { Position } from "./shared/Position";
import ResizeHandle from "./shared/ResizeHandle";
import { SelectTarget } from "./shared/SelectTarget";
import { ShowcaseTarget } from "./shared/ShowcaseTarget";
import { useHoverTarget } from "./shared/useHoverTarget";

export const Sticky = memo(({ sticky }: { sticky: Stickies.Sticky }) => {
  const {
    id,
    color,
    position,
    width,
    height,
    locked,
    votes: stickerIds,
    editing: isEditing,
  } = sticky;
  const userId = useSelector(Client.getUserId);
  const stickers = useSelector(Stickers.getManyByIdCollapsed(stickerIds));
  const isSelected = useSelector(Client.getIsSelected(id));
  const isGrabbing = userId === sticky.grab?.userId;
  const can = usePermission();
  const comments = useSelector(Comments.getByParentId(sticky.id));
  const isResizing = !!sticky.resize;
  const isCreating = !!sticky.creating;
  const isTemplate = useConfig().type === BoardType.Template;
  const hasComments = comments.length > 0;
  const isUserBusy = useSelector(Client.getUserIsBusy(userId));

  const { hover, HoverTarget } = useHoverTarget(id);

  const style = {
    transform: `translate(${position.x}px, ${position.y}px)`,
    width: `${width}px`,
    height: `${height}px`,
  };

  const className = classNames(
    "sticky__wrapper",
    isSelected ? "selected sticky--selected" : "",
    isGrabbing ? "grabbing cursor-grabbing" : locked ? "locked" : "cursor-grab",
    color
  );

  return (
    <HoverTarget>
      <ConnectTarget id={id} data-id={id} style={style} className={className}>
        <EditTarget id={id}>
          <GrabTarget id={id}>
            <SelectTarget id={id}>
              <ShowcaseTarget id={id} userId={sticky.createdBy}>
                <ContextMenuTarget id={id}>
                  <div className="sticky__background" />
                  <div className="sticky__drop-shadow"></div>
                  <EditableText
                    id={id}
                    className="sticky__content"
                    disabled={isUserBusy}
                  />
                </ContextMenuTarget>
              </ShowcaseTarget>
            </SelectTarget>
          </GrabTarget>
        </EditTarget>

        <ObjectElements>
          {can("edit", sticky) && !isResizing && hover && !locked && (
            <ConnectionAnchors objectId={id} />
          )}

          {can("edit", sticky) && isSelected && !locked && (
            <>
              <Position region="top-right">
                <ResizeHandle id={id} corner="top-right" />
              </Position>
              <Position region="top-left">
                <ResizeHandle id={id} corner="top-left" />
              </Position>
              <Position region="bottom-right">
                <ResizeHandle id={id} corner="bottom-right" />
              </Position>
              <Position region="bottom-left">
                <ResizeHandle id={id} corner="bottom-left" />
              </Position>
            </>
          )}

          {!isTemplate && !isCreating && !isResizing && (
            <ControlOverlay>
              <Control visible={hover || hasComments} style={style}>
                <Position region="top-right">
                  <OpenComments id={id} commentCount={comments.length} />
                </Position>
              </Control>
            </ControlOverlay>
          )}

          {!isEditing &&
            stickers.map((sticker) => {
              return Stickers.isStickerGroup(sticker) ? (
                <StickerGroup
                  key={Stickers.getCollapseByKey(sticker)}
                  stickerGroup={sticker}
                />
              ) : (
                <Sticker key={sticker.id} sticker={sticker} />
              );
            })}
        </ObjectElements>
      </ConnectTarget>
    </HoverTarget>
  );
});

Sticky.displayName = "Sticky";
