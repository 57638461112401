import React, { useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";

import { NotFound as FourOhFour } from "~/components/404";
import { useAuth } from "~/context/AuthContext";
import { BoardType, Permission } from "~/context/ConfigContext";
import { createAppState } from "~/store";
import { useGetFullBoard } from "~/util/BoardClient";
import uid from "~/util/uid";

import { BoardWithProviders } from "./Board";
import { Box } from "./Box";

type Props = {
  boardId: string;
};

const TemplateEditor = React.memo(({ boardId }: Props) => {
  const { account } = useAuth();
  const response = useGetFullBoard(boardId);
  const userId = useMemo(uid, []);

  if (response.status === "loading") return null;
  if (response.status === "failed") return <FourOhFour />;
  if (!response.data.template) return <Navigate to={`/${boardId}`} />;
  if (response.data.createdBy !== account?.id)
    return <Navigate to={`/t/${boardId}`} />;

  const {
    data: {
      name,
      description,
      objects,
      createdBy,
      template,
      comments,
      auth,
      font,
      plan,
    },
  } = response;

  const initialState = createAppState({
    userId: auth.userId ?? userId,
    board: {
      boardId,
      name,
      description,
      createdBy,
      template,
      font,
      plan,
    },
    objects,
    comments,
  });

  const config = {
    userId: auth.userId ?? userId,
    type: BoardType.Template,
    permission: Permission.Write,
    websocket: true,
    boardId,
  };

  return (
    <Box>
      <BoardWithProviders config={config} initialState={initialState} />
    </Box>
  );
});

export const TemplateEditorWrapper = () => {
  const { boardId } = useParams();
  if (!boardId) return null;

  return <TemplateEditor boardId={boardId} />;
};
