import "./ObjectElements.css";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export let ObjectElements = ({ children, ...props }: Props) => (
  <div className="object__ui-elements" {...props}>
    {children}
  </div>
);
