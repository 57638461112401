import React, { Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router";

import { useAuth } from "~/context/AuthContext";
import { useBoardPath, useBoardId, useShowIntroModal } from "~/util/hooks";

import { AnnouncementModal } from "./AnnouncementModal";
import { BoardSettingsModal } from "./BoardSettingsModal";
import { ChangeLogModal } from "./ChangeLogModal";
import { ConvertToTemplateModal } from "./ConvertToTemplateModal";
import { FeatureFlagsModal } from "./FeatureFlagsModal";
import { FeedbackModal } from "./FeedbackModal";
import { HelpModal } from "./HelpModal";
import { KeybindingsModal } from "./KeybindingsModal";
import { LazyLoadPreviewModal } from "./LazyLoadPreviewModal";
import { PlanUpgradeModal } from "./PlanUpgradeModal";
import { HuddlesUpsellModal } from "./StickyStudioPlus";

const ExportModal = React.lazy(() => import("./ExportModal"));
const OnboardingTour = React.lazy(() => import("./OnboardingTour"));

export let BoardRoutes = () => {
  let auth = useAuth();
  let boardId = useBoardId();
  let boardPath = useBoardPath();
  let navigate = useNavigate();
  let backToBoard = () => navigate(boardPath);
  useShowIntroModal();

  let onPlanUpgrade = () => {
    // Re-authenticate to pick up new account context.
    auth.authenticate();
    // TODO: Show a welcome message.
    backToBoard();
  };

  let onCreateTemplate = (templateId: string) => {
    navigate(`/template/${templateId}`);
  };

  return (
    <Routes>
      <Route
        path="pro"
        element={
          <PlanUpgradeModal
            open
            onUpgrade={onPlanUpgrade}
            onClose={backToBoard}
          />
        }
      />
      <Route path="help" element={<HelpModal open onClose={backToBoard} />} />

      <Route
        path="huddles"
        element={<HuddlesUpsellModal open onClose={backToBoard} />}
      />

      <Route
        path="feedback"
        element={<FeedbackModal open onClose={backToBoard} />}
      />

      <Route
        path="flags"
        element={
          <FeatureFlagsModal
            open
            onClose={() => {
              backToBoard();
              // Reload the window when the modal is closed, so that any feature
              // flag UI can update.
              window.location.reload();
            }}
          />
        }
      />

      <Route
        path="convert"
        element={
          <ConvertToTemplateModal
            open
            boardId={boardId}
            onClose={backToBoard}
            onCreate={onCreateTemplate}
          />
        }
      />

      <Route
        path="export"
        element={
          <LazyLoadPreviewModal onClose={backToBoard}>
            <ExportModal open onClose={backToBoard} />
          </LazyLoadPreviewModal>
        }
      />

      <Route
        path="tour"
        element={
          <Suspense fallback={<span>Loading...</span>}>
            <OnboardingTour />
          </Suspense>
        }
      />
      <Route
        path="announce/:slug"
        element={<AnnouncementModal open onClose={() => backToBoard()} />}
      />
      <Route
        path="settings"
        element={
          <BoardSettingsModal open boardId={boardId} onClose={backToBoard} />
        }
      />

      <Route
        path="changelog"
        element={<ChangeLogModal open onClose={backToBoard} />}
      />

      <Route
        path="shortcuts"
        element={<KeybindingsModal open onClose={backToBoard} />}
      />
    </Routes>
  );
};
