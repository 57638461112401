import React from "react";

import LazyLoadPreview from "./LazyLoadPreview";
import Legal from "./Legal";

const PrivacyPage = () => {
  // Lazy-load
  let Privacy = React.lazy(() => import("~/docs/privacy"));
  return (
    <Legal title="Privacy Policy">
      <LazyLoadPreview>
        <Privacy />
      </LazyLoadPreview>
    </Legal>
  );
};

export default PrivacyPage;
