import { Middleware, Dispatch } from "redux";

import { Action, AppState } from "~/store";
import { getRelatedConnections } from "~/store/bundles/Connection";

import * as BaseObject from "./BaseObject";
import * as Delete from "./Delete";

/**
 * Interface that describes objects that can be connected
 */
export interface Connect extends Delete.Delete {}

const CONNECTABLE_TYPES = ["sticky", "label", "shape", "image"];

/**
 * Type guard that checks whether an object (BaseObject) implements the
 * Connect trait.
 */
export const isConnect = (object: BaseObject.BaseObject): object is Connect => {
  return CONNECTABLE_TYPES.includes(object.type);
};

/**
 * Get Connect object with given id.
 */
export const getById = (id: string) => (state: AppState) => {
  const object = BaseObject.getById(id)(state);
  if (object && isConnect(object)) return object;
};

/**
 * Redux middleware that is used to intercept general actions to inject
 * `Connect` specific behavior.
 *
 * @param  store - The redux store object
 * @returns The return value of the `next` middleware.
 */
export const middleware: Middleware<{}, AppState, Dispatch<any>> =
  ({ dispatch, getState }) =>
  (next) =>
  (action: Action) => {
    let additionalActions: Action[] = [];
    const state = getState();

    // Dispatch any additional behaviors
    switch (action.type) {
      /*
       * When a connectable object is removed, remove any associated connections.
       */
      case "delete/delete": {
        const object = getById(action.data.id)(state);
        if (!object) break;

        const connectionIds = getRelatedConnections(object.id)(state).map(
          (conn) => conn.id
        );

        const additionalIds = [...connectionIds];

        return dispatch(
          Delete.deleteMany([object.id, ...additionalIds])(state)
        );
      }

      /*
       * When a connectable object is removed, remove any associated connections.
       */
      case "delete/delete_many": {
        const ids = Object.keys(action.data);

        const additionalIds = ids.flatMap((id) => {
          const object = getById(id)(state);
          if (!object) return [];

          const connectionIds = getRelatedConnections(object.id)(state).map(
            (conn) => conn.id
          );

          return [...connectionIds];
        });

        // NOTE: We pass this new action on to the next middleware instead
        // of running it through the dispatch chain again so we don't end up
        // in an infinite loop
        return next(Delete.deleteMany([...ids, ...additionalIds])(state));
      }
    }

    // Process the actual action first
    const result = next(action);

    // Dispatch additional actions
    additionalActions.forEach(dispatch);

    // Return result of original action
    return result;
  };
