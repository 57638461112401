import { useSelector } from "react-redux";

import * as Connection from "~/store/bundles/Connection";
import { useConfig } from "~/context/ConfigContext";
import { classNames } from "~/util/classNames";

import * as Icons from "./Icons";
import { Text } from "./Typography";

export let CompleteConnectionPrompt = () => {
  const { userId } = useConfig();
  const creatingConnections = useSelector(Connection.getCreatingBy(userId));
  const enabled = creatingConnections.length > 0;

  return (
    <Text
      className={classNames(
        "transition-opacity transition-300 text-center",
        enabled ? "opacity-100" : "opacity-0"
      )}
      size={0}
    >
      <Icons.ConnectionRight /> Select another sticky to complete the connection
    </Text>
  );
};
