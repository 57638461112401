import { useState } from "react";

import * as Icons from "./Icons";
import { Box } from "./Box";
import { Button } from "./Button";
import { ModalPanel, ModalTitle, ModalDescription, Modal } from "./Modal";
import { TextWithLinks } from "./TextWithLinks";

export let SandboxWarningModal = () => {
  let [open, setOpen] = useState(true);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalPanel size="sm">
        <ModalTitle>
          <Icons.Logo /> Try Sticky Studio
        </ModalTitle>
        <ModalDescription>
          <TextWithLinks>
            <div style={{ textAlign: "center" }}>
              This is a temporary board where you can try out Sticky Studio.
              Your changes won't be saved, but we'll create a "Try Sticky
              Studio" board with whatever you create here when you sign up.
            </div>
          </TextWithLinks>
        </ModalDescription>
        <Box direction="row" justify="center">
          <Button variant="primary" onClick={() => setOpen(false)}>
            I understand!
          </Button>
        </Box>
      </ModalPanel>
    </Modal>
  );
};
