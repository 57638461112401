import { Outlet } from "react-router-dom";

import {
  Page,
  Footer,
  Header,
  HeaderLogo,
  Section,
  HeaderNavigation,
} from "./Page";

export const AuthLayout = () => {
  return (
    <Page>
      <Header>
        <HeaderLogo />
        <HeaderNavigation />
      </Header>
      <Section>
        <Outlet />
      </Section>
      <Footer />
    </Page>
  );
};
