import type { Peer } from "peerjs";
import { Socket } from "socket.io-client";

import uid from "./uid";

/**
 * Additional data related to each participant's connection.
 */
type ParticipantData = {
  audioStream: MediaStream;
};

/**
 * Object that holds all the state associated to a huddle
 */
export type Huddle = {
  /**
   * The Huddle peer id by which others can address us.
   */
  id: string;

  /**
   * PeerJS connection
   */
  peer: Peer;

  /**
   * The Media Stream Track associated the local audio input
   */
  localAudio: MediaStream;

  /**
   * The WebAudio API context.
   */
  audioContext: AudioContext;

  /**
   * List of all the current connections and their audio setings
   */
  participants: Map<string, ParticipantData>;

  /**
   * The socket that will be used to communicate the joining/leaving
   */
  socket: Socket;
};

/**
 * Create a blank huddle context
 */
export const createHuddle = async (socket: Socket): Promise<Huddle> => {
  const constraints: MediaStreamConstraints = {
    audio: {
      autoGainControl: true,
      noiseSuppression: true,
      echoCancellation: true,
    },
  };

  try {
    let { Peer } = await import("peerjs");

    let stream = await navigator.mediaDevices.getUserMedia(constraints);
    let huddleId = uid();
    let peer = new Peer(huddleId);

    let huddle = {
      id: huddleId,
      peer,
      localAudio: stream,
      audioContext: new AudioContext(),
      participants: new Map(),
      socket,
    };

    peer.on("open", (huddleId) => {
      socket.emit("huddle/join", { huddleId });
    });

    peer.on("call", (call) => {
      call.on("stream", function (stream) {
        addStream(huddle, call.peer, stream);
      });

      call.answer(stream);
    });

    socket.on("huddle/join", async ({ huddleId }) => {
      const call = huddle.peer.call(huddleId, huddle.localAudio);

      call.on("stream", function (stream) {
        addStream(huddle, huddleId, stream);
      });
    });

    return huddle;
  } catch {
    throw new Error("Could not access audio device");
  }
};

/**
 * Clean up all the resources after a huddle context is no longer needed
 *
 * @param huddle - The huddle to clean up
 */
export const cleanupHuddle = async (huddle: Huddle) => {
  const { audioContext, peer, socket } = huddle;

  socket.removeAllListeners("huddle/join");

  peer.destroy();

  if (audioContext.state !== "closed") {
    audioContext.close();
  }
};

/**
 * When an audio track is attached to the WebRTC connection, grab it and pipe it
 * into a Web Audio context.
 *
 * @param huddle - The current huddle context
 * @param from - The huddleId of the other party
 * @param stream - The remote audio stream
 */
const addStream = async (
  { audioContext, participants }: Huddle,
  from: string,
  stream: MediaStream
) => {
  const source = audioContext.createMediaStreamSource(stream);
  source.connect(audioContext.destination);

  // TODO: Add analyser and panner nodes here?
  participants.set(from, {
    audioStream: stream,
  });

  // NOTE: Feeding WebRTC audio into the Web Audio API directly doesn't work in
  // Chrome. Workaround is to assign the stream to an HTML audio element to
  // force the audio to get rendered.
  // More info at
  // https://bugs.chromium.org/p/chromium/issues/detail?id=121673#c127
  // https://bugs.chromium.org/p/chromium/issues/detail?id=933677#c26
  if (/Chrome/.test(navigator.userAgent)) {
    new Audio().srcObject = stream;
  }
};
