import React, { ReactNode } from "react";

import { useAccount } from "~/context/AuthContext";

import * as Icons from "./Icons";
import { Menu, MenuButton, MenuDivider, MenuItem, MenuItems } from "./Menu";

type DashboardMenuSection = {
  title?: ReactNode;
  grow?: boolean;
  items?: {
    to: string;
    className?: string;
    title?: ReactNode;
    end?: boolean;
    before?: ReactNode;
    after?: ReactNode;
    dataCy?: string;
  }[];
};

export const menu: DashboardMenuSection[] = [
  {
    items: [
      { to: "/account", title: "Account", before: <Icons.User />, end: true },
      { to: "/account/billing", title: "Billing", before: <BillingIcon /> },
    ],
  },
  {
    title: "Boards",
    items: [
      { to: "/boards", title: "All boards", end: true },
      { to: "/boards/own", title: "My Boards", after: <BoardCounter /> },
      { to: "/boards/templates", title: "Templates" },
    ],
  },
  {
    grow: true,
  },
  {
    items: [
      {
        to: "/logout",
        title: "Sign out",
        before: <Icons.LogOut />,
        dataCy: "sign-out-button",
      },
    ],
  },
];

export const DashboardMenu = ({ children }: { children: ReactNode }) => {
  return (
    <div className="dashboard-menu">
      <Menu placement="bottom-start">
        <MenuButton>{children}</MenuButton>
        <MenuItems>
          {menu.map((section, sectionIndex) => (
            <React.Fragment key={`section-${sectionIndex}`}>
              {section.items?.map((item, itemIndex) => (
                <MenuItem
                  key={`section-${sectionIndex}-${itemIndex}`}
                  className={item.className}
                  to={item.to}
                  data-cy={item.dataCy}
                >
                  {item.title} {item.after && item.after}
                </MenuItem>
              ))}
              <MenuDivider />
            </React.Fragment>
          ))}
        </MenuItems>
      </Menu>
    </div>
  );
};

function BoardCounter() {
  let account = useAccount();
  let { boards = 0, maxBoards = 0 } = account?.meta ?? {};

  // If `maxBoards` is null, that means the user has no limit, which probably
  // means they're on a paid plan.
  if (maxBoards == null) return null;

  return (
    <>
      ({boards}/{maxBoards})
    </>
  );
}

function BillingIcon() {
  let account = useAccount();
  let isUnpaid =
    account?.billingStatus === "unpaid" ||
    account?.billingStatus === "past_due";
  return isUnpaid ? (
    <Icons.AlertTriangle color="var(--red-3)" />
  ) : (
    <Icons.CreditCard />
  );
}
