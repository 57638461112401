import "./Scene.css";

import { useSelector } from "react-redux";

import * as Boards from "~/store/bundles/Board";
import * as Client from "~/store/bundles/Client";
import * as Connections from "~/store/bundles/Connection";
import * as Images from "~/store/bundles/Image";
import * as Labels from "~/store/bundles/Label";
import * as Lines from "~/store/bundles/Line";
import * as Shapes from "~/store/bundles/Shape";
import * as Stickers from "~/store/bundles/Sticker";
import * as Stickies from "~/store/bundles/Sticky";
import * as Users from "~/store/bundles/User";
import * as Vertices from "~/store/bundles/Vertex";
import { BoardObject } from "~/renderers/html/BoardObject";
import { isCreating } from "~/store/traits/Create";
import { isEnabled } from "~/util/FeatureFlags";

import { Overlay } from "./Overlay";

export const Scene = () => {
  const origin = useSelector(Client.getOrigin);
  const scale = useSelector(Client.getScale);

  const objects = [
    ...useSelector(Images.getAllOrdered),
    ...useSelector(Shapes.getAllOrdered),
    ...useSelector(isEnabled("lines") ? Lines.getAllOrdered : () => []),
    ...useSelector(isEnabled("lines") ? Vertices.getAll : () => []),
    ...useSelector(Connections.getAllOrdered),
    ...useSelector(Labels.getAllOrdered),
    ...useSelector(Stickies.getAllOrdered),
    ...useSelector(Users.getOtherOnlineUsers),

    // Get all dangling stickers
    ...useSelector(Stickers.getAllOrdered).filter(Stickers.isDangling),
  ];

  const font = useSelector(Boards.getFont);

  let style = {
    transform: `translate(${origin.x}px, ${origin.y}px) scale(${scale})`,
    transformOrigin: "top left",
  };

  return (
    <>
      <div className="scene" style={style}>
        {Object.values(objects)
          .filter((object) => !isCreating(object))
          .map((object) => (
            <BoardObject key={object.id} data={object} />
          ))}
      </div>

      {/* Render objects being dragged off of the drawer in a separate overlay layer using a Portal */}
      <Overlay id="overlay">
        <div className={`scene-overlay scene-overlay--${font}`} style={style}>
          {Object.values(objects)
            .filter(isCreating)
            .map((object) => (
              <BoardObject key={object.id} data={object} />
            ))}
        </div>
      </Overlay>
    </>
  );
};
