import { createSelector } from "reselect";

import assert from "~/util/assert";
import { createShallowEqualSelector } from "~/util/selectors";

import * as Alive from "./Alive";
import * as BaseObject from "./BaseObject";
import { AppState, State, Action as AppAction } from "../index";
import { BaseAction, EmptyAction, emptyAction } from "../shared";
import { Trait } from "./Trait";

export interface CreateMetadata {
  to?: string | null;
}

/**
 * Interface that describes objects that can be in a "creating" state.
 */
export interface Create extends Trait {
  creating: string | null;
}

/**
 * Type guard that checks whether an object (BaseObject) implements the Create
 * trait.
 */
export const isCreate = <T extends BaseObject.BaseObject>(
  object: T
): object is T & Create => "creating" in object;

/**
 * Schema for actions returned by the `Create.startCreating()` action creator.
 */
export interface StartCreatingAction extends BaseAction {
  type: "create/start_creating";
  data: Pick<Create, "id" | "creating">;
  metadata?: CreateMetadata | null;
}

/**
 * Action creator that marks a given object as in creation.
 */
export const startCreating = (
  id: string,
  userId: string
): StartCreatingAction => {
  return {
    type: "create/start_creating",
    data: { id, creating: userId },
    effects: {
      broadcast: true,
      persist: true,
      dispatch: [Alive.touch(id)],
    },
  };
};

/**
 * Schema for actions returned by the `Create.finishCreating()` action creator.
 */
export interface FinishCreatingAction extends BaseAction {
  type: "create/finish_creating";
  data: Pick<
    Create,
    "id" | "creating" | "createdAt" | "createdBy" | "updatedAt" | "updatedBy"
  >;
  metadata: CreateMetadata | null;
}

/**
 * Action creator that clears a given object as in creation.
 */
export const finishCreating =
  (id: string, opts?: { to: string }) =>
  (state: AppState): FinishCreatingAction | EmptyAction => {
    const object = getById(id)(state);
    if (!object) return emptyAction();

    const userId = object?.creating ?? undefined;
    const now = Date.now();

    return {
      type: "create/finish_creating",
      data: {
        id,
        creating: null,
        createdAt: now,
        createdBy: userId,
        updatedAt: now,
        updatedBy: userId,
      },
      metadata: {
        to: opts?.to,
      },
      effects: {
        broadcast: true,
        persist: true,
      },
    };
  };

/**
 * Return a list of finishCreating actions for each object currently being
 * created by a given user.
 */
export const releaseAllBy = (userId: string) => (state: AppState) => {
  const creating = getCreatingBy(userId)(state);
  return creating.map((obj) => finishCreating(obj.id)(state));
};

/**
 * Action creator that cancels the creation of a set of objects by removing them
 * from the board.
 *
 * @param ids - The list of object ids to cancel
 * @returns The action thunk to dispatch to the store.
 */
export const cancelCreatingBy =
  (userId: string) =>
  (state: AppState): BaseObject.RemoveManyAction => {
    let objectIds = getCreatingBy(userId)(state).map((obj) => obj.id);
    return BaseObject.removeMany(objectIds);
  };

/**
 * Union type of all Create-related actions.
 */
export type Action = StartCreatingAction | FinishCreatingAction;

/**
 * Get all creatable objects from an app state.
 */
export const getAll = createShallowEqualSelector(BaseObject.getAll, (objects) =>
  Object.values(objects).filter(isCreate)
);

/**
 * Get Create object with given id.
 */
export const getById = (id: string) => (state: AppState) => {
  const object = BaseObject.getById(id)(state);
  if (object && isCreate(object)) return object;
};

/**
 * Selector creator for getting all objects currently being created by the
 * provided user id.
 *
 * @param {string} userId - The user id creating the objects.
 * @returns Selector that returns the desired objects.
 */
export const getCreatingBy = (userId: string) =>
  createSelector(getAll, (objects) =>
    objects.filter(({ creating }) => creating === userId)
  );

/**
 * Reducer that handles any Create-related actions
 */
export const reducer = (state: State = {}, action: AppAction): State => {
  switch (action.type) {
    case "create/start_creating":
    case "create/finish_creating": {
      const object = state[action.data.id];
      assert(isCreate(object), "Object does not implement the Create trait.");

      return {
        ...state,
        [action.data.id]: { ...object, ...action.data },
      };
    }
    default:
      return state;
  }
};

/**
 * Helper that checks whether an object is in the middle of being created
 */
export const isCreating = (object: BaseObject.BaseObject) => {
  return isCreate(object) && !!object.creating;
};
