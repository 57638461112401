import { clone } from "lodash";
import { createSelector } from "reselect";

import { BaseAction } from "~/store/shared";
import { UpdateMany } from "~/util/types";

import { AppState, State, Action as AppAction } from "../index";

/**
 * Base interface all object types inherit from
 */
export interface BaseObject {
  id: string;
  type: string;

  /**
   * User who created the object.
   */
  createdBy?: string;

  /**
   * User who updated the object.
   */
  updatedBy?: string;

  /**
   * Timestamp when the object was created.
   */
  createdAt?: number;

  /**
   * Timestamp when the object was created.
   */
  updatedAt?: number;
}

/**
 * Type guard that checks whether an object implements the BaseObject interface.
 */
export const isBaseObject = <T extends {}>(
  object: T
): object is T & BaseObject => "id" in object;

/**
 * Get all objects from state.
 */
export const getAll = (state: AppState) => state.objects;

/**
 * Get object with given `id` from state.
 */
export const getById = (id: string) => (state: AppState) => {
  let object = state.objects[id];
  if (object) return object;
};

/**
 * Selector creator for getting all objects created by the provided user id.
 *
 * @param {string} userId - The id of the user who created the stickies.
 * @returns Selector that returns the desired stickers.
 */
export const getCreatedBy = (userId: string) =>
  createSelector(getAll, (objects) => {
    return Object.values(objects)
      .filter((s) => s.createdBy === userId)
      .map((s) => s.id);
  });

export interface InsertAction extends BaseAction {
  type: "base_object/insert";
  data: BaseObject;
}

/**
 * Action creator for inserting a BaseObject into the app state wholesale.
 *
 * @param {BaseObject} object - The `BaseObject` object to be inserted.
 * @returns {InsertAction} The action to be dispatched to the store.
 */
export const insert = (object: BaseObject): InsertAction => {
  return {
    type: "base_object/insert" as const,
    data: {
      ...object,
    },
    effects: { broadcast: true, persist: true },
  };
};

export interface InsertManyAction extends BaseAction {
  type: "base_object/insert_many";
  data: Record<string, BaseObject>;
}

/**
 * Action creator for inserting a collection of BaseObjects into the app state
 * wholesale.
 *
 * @param {BaseObject[]} objects - The `BaseObject` objects to be inserted.
 * @returns {InsertManyAction} The action to be dispatched to the store.
 */
export const insertMany = (objects: BaseObject[]): InsertManyAction => {
  const data: UpdateMany<BaseObject, keyof BaseObject> = {};

  for (const object of objects) {
    data[object.id] = { ...object };
  }

  return {
    type: "base_object/insert_many",
    data,
    effects: { broadcast: true, persist: true },
  };
};

export interface RemoveManyAction extends BaseAction {
  type: "base_object/remove_many";
  data: { ids: string[] };
  effects: { broadcast: true; persist: true };
}

/**
 * Action creator for hard deleting a collection of BaseObjects from the app state
 *
 * @param {string[]} ids - The ids of the objects to be deleted.
 * @returns {RemoveManyAction} The action to be dispatched to the store.
 */
export const removeMany = (ids: string[]): RemoveManyAction => {
  return {
    type: "base_object/remove_many",
    data: { ids },
    effects: { broadcast: true, persist: true },
  };
};

export interface UpdateManyAction extends BaseAction {
  type: "base_object/update_many";
  data: Record<string, BaseObject>;
}

/**
 * Action creator for inserting a collection of BaseObjects into the app state
 * wholesale.
 */
export const updateMany = (objects: BaseObject[]): UpdateManyAction => {
  const data: UpdateMany<BaseObject, keyof BaseObject> = {};

  for (const object of objects) {
    data[object.id] = { ...object };
  }

  return {
    type: "base_object/update_many",
    data,
    effects: { broadcast: true, persist: true },
  };
};

/**
 * Union type of all BaseObject actions.
 */
export type Action =
  | InsertAction
  | InsertManyAction
  | UpdateManyAction
  | RemoveManyAction;

/**
 * Reducer that handles any BaseObject-related actions
 */
export const reducer = (state: State = {}, action: AppAction): State => {
  switch (action.type) {
    case "base_object/insert": {
      return { ...state, [action.data.id]: action.data };
    }

    case "base_object/insert_many":
    case "base_object/update_many": {
      return { ...state, ...action.data };
    }
    case "base_object/remove_many": {
      const stateClone = clone(state);
      for (let id of action.data.ids) {
        delete stateClone[id];
      }
      return stateClone;
    }

    default:
      return state;
  }
};
