import { orderBy } from "lodash";
import { Middleware, Dispatch } from "redux";
import { createSelector } from "reselect";

import * as Client from "~/store/bundles/Client";
import * as Vertex from "~/store/bundles/Vertex";
import * as Rect from "~/util/geometry/rectangle";
import { Action, AppState } from "~/store";
import { BaseAction } from "~/store/shared";
import {
  BaseObject,
  Create,
  Color,
  Grab,
  Lock,
  Delete,
  Alive,
  Position,
} from "~/store/traits";
import assert from "~/util/assert";
import { filterObject } from "~/util/filterObject";

/**
 * Interface that defines a `Line` object.
 */
export interface Line
  extends BaseObject.BaseObject,
    Create.Create,
    Color.Color,
    Grab.Grab,
    Lock.Lock,
    Delete.Delete,
    Alive.Alive {
  type: "line";
  vertices: string[];
}

/**
 * Type guard for narrowing a `BaseObject` type to a `Line`.
 *
 * @param {BaseObject.BaseObject} object - The object whose type we want to
 * narrow.
 * @returns {boolean} Whether or not the object is of type `Line`
 */
export const isLine = (object: BaseObject.BaseObject): object is Line => {
  return object.type === "line";
};

/**
 * Swap out any IDs according to a provided lookup table.
 *
 * @param {Line} line - The line object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Line} The modified line with ids replaced.
 */
const swapIds = (line: Line, lookup: (id: string) => string): Line => {
  return {
    ...line,
    id: lookup(line.id),
    vertices: line.vertices.map(lookup),
    creating: line.creating ? lookup(line.creating) : null,
    grab: line.grab ? { ...line.grab, userId: lookup(line.grab.userId) } : null,
  };
};

/**
 * Remove any secondary information that we wouldn't want to appear, in a
 * template/fork.
 *
 * @param {Line} line - Line to convert into a template
 * @returns {Line} Template created from the provided `Line` object.
 */
export const template = (line: Line): Line => ({
  ...line,
  creating: null,
  grab: null,
});

/**
 * Fork a line by swapping out ids using a provided lookup table.
 *
 * @param {Line} line - The line object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Line} The modified line with ids replaced.
 */
export const fork = swapIds;

/**
 * Union of all possible `Line` actions.
 */
export type LineAction =
  | AddAction
  | ReleaseAction
  | ReleaseAllByAction
  | InsertAction;

export interface AddAction extends BaseAction {
  type: "line/add";
  data: Line;
}

/**
 * Type that describes the named params for adding a line.
 */
interface AddParams {
  /**
   * The id for the to-be-added line.
   */
  id: string;
  /**
   * The id of the vertex the line originates from.
   */
  from: string;
  /**
   * The id of the vertex the line extends to.
   */
  to: string;
  /**
   * The color of the line.
   */
  color: string;
}

/**
 * Action creator for adding a line to the app state.
 *
 * @param {AddParams} params - The parameters for the line to be added.
 * @returns The action thunk to be dispatched to the store.
 */
export const add =
  ({ id, from, to, color }: AddParams) =>
  (state: AppState): AddAction => {
    let fromVertex = Vertex.getById(from)(state);
    let toVertex = Vertex.getById(to)(state);

    let { position } = Rect.getBoundingRectFromPoints([
      fromVertex.position,
      toVertex.position,
    ]);

    return {
      type: "line/add",
      data: {
        type: "line",
        id,
        vertices: [from, to],
        position,
        color,
        locked: false,
        deleted: false,
        grab: null,
        creating: null,
        keepAlive: null,
      },
      effects: {
        broadcast: true,
        persist: true,
      },
    };
  };

export interface ReleaseAction extends BaseAction {
  type: "line/release";
  data: Pick<Line, "id" | "grab">;
}

/**
 * Action creator for releasing a line.
 *
 * Clears the `grabbing` and `resizing` fields of the line, used when
 * `keepAlive` timer runs out or the user disconnects.
 *
 * @param {string} id - The id of the line to release.
 * @returns {ReleaseAction} The action to be dispatched to the store.
 */
export const release = (id: string): ReleaseAction => {
  return {
    type: "line/release",
    data: { id, grab: null },
    effects: { broadcast: true, persist: true },
  };
};

export interface ReleaseAllByAction extends BaseAction {
  type: "line/release_all_by";
}

/**
 * Action creator for releasing all lines that are being manipulated by a
 * certain user.
 *
 * @param {string} userId - The id of the user interacting with lines we want
 * to release.
 * @returns The action thunk to be dispatched to the store.
 */
export const releaseAllBy =
  (userId: string) =>
  (state: AppState): ReleaseAllByAction => {
    return {
      type: "line/release_all_by",
      effects: {
        dispatch: [
          ...getCreatingBy(userId)(state).map(({ id }) =>
            Create.finishCreating(id)
          ),
          ...getGrabbedBy(userId)(state).map(({ id }) =>
            Grab.finishGrabbing(id)
          ),
        ],
      },
    };
  };

export interface InsertAction extends BaseAction {
  type: "line/insert";
  data: Line;
}

/**
 * Action creator for inserting a line into the app state wholesale.
 *
 * @param {Line} line - The `Line` object to be inserted.
 * @returns {InsertAction} The action to be dispatched to the store.
 */
export const insert = (line: Line): InsertAction => {
  return {
    type: "line/insert",
    data: {
      ...line,
    },
    effects: { broadcast: true, persist: true },
  };
};

/**
 * Reducer that processes any `Line` actions.
 *
 * @param {AppState["objects"]} [state] - The current `objects` state.
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["objects"]} The updated state slice.
 */
export const reducer = (
  state: AppState["objects"] = {},
  action: Action
): AppState["objects"] => {
  switch (action.type) {
    case "line/add":
    case "line/insert":
      return { ...state, [action.data.id]: action.data };

    case "line/release": {
      const object = state[action.data.id];
      assert(isLine(object), "Object is not a Line.");

      return { ...state, [object.id]: { ...state[object.id], ...action.data } };
    }

    default:
      return state;
  }
};

/**
 * Redux middleware that is used to intercept general actions to inject `Line`
 * specific behavior.
 *
 * @param  store - The redux store object
 * @returns The return value of the `next` middleware.
 */
export const middleware: Middleware<{}, AppState, Dispatch<any>> =
  ({ dispatch, getState }) =>
  (next) =>
  (action: Action) => {
    let additionalActions: Action[] = [];
    const state = getState();

    // Dispatch any additional behaviors
    switch (action.type) {
      /*
       * Release line whose `keepAlive` timer has expired.
       *
       * Unset the creating, grabbing, and resizing properties.
       */
      case "alive/release_expired": {
        for (const id in action.data) {
          const line = getById(id)(state);
          if (!line || line.deleted) continue;

          if (line.grab) additionalActions.push(Grab.finishGrabbing(id)(state));

          if (line.creating)
            additionalActions.push(Create.finishCreating(id)(state));
        }

        break;
      }

      case "grab/move": {
        const scene = Client.getScene(state);

        const line = getById(action.data.id)(state);
        if (!line || line.deleted) break;

        const delta = {
          x: action.data.position.x - line.position.x,
          y: action.data.position.y - line.position.y,
        };

        const vertexActions = line.vertices.map((vertexId) => {
          const vertex = Vertex.getById(vertexId)(state);

          // Because of the dumb way we implemented position(), we need to
          // calculate the new position, convert it back to screen coordinates,
          // so Position.position() can re-convert it back to scene coordinates.
          // :facepalm:
          const pseudoPosition = Client.toScreenCoordinates(scene, {
            x: vertex.position.x + delta.x,
            y: vertex.position.y + delta.y,
          });

          return Position.position(vertexId, pseudoPosition)(state);
        });

        additionalActions = [...additionalActions, ...vertexActions];

        break;
      }

      case "grab/move_many": {
        const scene = Client.getScene(state);

        for (const id in action.data) {
          const line = getById(id)(state);
          if (!line || line.deleted) continue;

          const delta = {
            x: action.data[id].position.x - line.position.x,
            y: action.data[id].position.y - line.position.y,
          };

          const vertexActions = line.vertices.map((vertexId) => {
            const vertex = Vertex.getById(vertexId)(state);

            // Because of the dumb way we implemented position(), we need to
            // calculate the new position, convert it back to screen coordinates,
            // so Position.position() can re-convert it back to scene coordinates.
            // :facepalm:
            const pseudoPosition = Client.toScreenCoordinates(scene, {
              x: vertex.position.x + delta.x,
              y: vertex.position.y + delta.y,
            });

            return Position.position(vertexId, pseudoPosition)(state);
          });

          additionalActions = [...additionalActions, ...vertexActions];
        }

        break;
      }

      /*
       * When a line is removed, remove its vertices.
       */
      case "delete/delete": {
        const line = getById(action.data.id)(state);
        if (!line) break;

        return dispatch(Delete.deleteMany([line.id, ...line.vertices])(state));
      }

      /*
       * When a line is removed, remove its vertices.
       */
      case "delete/delete_many": {
        const ids = Object.keys(action.data);

        const additionalIds = ids.flatMap((id) => {
          const line = getById(id)(state);
          if (!line) return [];

          return line.vertices;
        });

        // NOTE: We pass this new action on to the next middleware instead
        // of running it through the dispatch chain again so we don't end up
        // in an infinite loop
        return next(Delete.deleteMany([...ids, ...additionalIds])(state));
      }

      /*
       * When a line is locked, lock its vertices.
       */
      case "lock/lock": {
        const line = getById(action.data.id)(state);
        if (!line) break;

        const lockActions = line.vertices.map(Lock.lock);
        additionalActions = [...additionalActions, ...lockActions];

        break;
      }

      /*
       * When a line is unlocked, unlock its vertices.
       */
      case "lock/unlock": {
        const line = getById(action.data.id)(state);
        if (!line) break;

        const unlockActions = line.vertices.map(Lock.unlock);
        additionalActions = [...additionalActions, ...unlockActions];

        break;
      }

      /*
       * When a line is selected, select its vertices.
       */
      case "client/select":
      case "client/select_many":
      case "client/select_only": {
        const ids = action.data.selected;

        const additionalIds = ids.flatMap((id) => {
          const line = getById(id)(state);
          if (!line) return [];

          return line.vertices;
        });

        // NOTE: We pass this new action on to the next middleware instead
        // of running it through the dispatch chain again so we don't end up
        // in an infinite loop
        return next(Client.selectMany([...ids, ...additionalIds]));
      }

      /*
       * When a line is deselected, deselect its vertices.
       */
      case "client/unselect": {
        const line = getById(action?.metadata?.unselected ?? "")(state);
        if (!line) break;

        const additionalIds = line.vertices;

        // NOTE: We pass this new action on to the next middleware instead
        // of running it through the dispatch chain again so we don't end up
        // in an infinite loop
        return next(Client.unselectMany([line.id, ...additionalIds])(state));
      }

      case "client/unselect_many": {
        const ids = action.data.selected;

        const additionalIds = ids.flatMap((id) => {
          const line = getById(id)(state);
          if (!line) return [];

          return line.vertices;
        });

        // NOTE: We pass this new action on to the next middleware instead
        // of running it through the dispatch chain again so we don't end up
        // in an infinite loop
        return next(Client.unselectMany([...ids, ...additionalIds])(state));
      }
    }

    // Process the actual action first
    const result = next(action);

    // Dispatch additional actions
    additionalActions.forEach(dispatch);

    // Return result of original action
    return result;
  };

/**
 * Selector that returns a record of all lines in the board state, keyed by
 * their id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, Line>} A record of all lines in the app state.
 */
export const getLines = createSelector(BaseObject.getAll, (objects) =>
  filterObject(objects, isLine)
);

/**
 * Selector that returns an array of all lines, removing the deleted ones.
 *
 * @param {AppState} state - The current app state.
 * @returns An array of (non-deleted) lines.
 */
export const getAll = createSelector(getLines, (lines) =>
  Object.values(lines).filter((line) => !line.deleted)
);

/**
 * Selector that returns an array of all undeleted lines ordered by date last updated.
 * Locked lines are placed below unlocked ones
 *
 * @param {AppState} state - The current app state.
 * @returns An ordered array of (non-deleted) lines.
 */
// Even though we already use z-index on the board to place locked objects below
// we stille need to do it in the ordering because the SVG renderer can't use z-index
// and selected object all receive the same z-index so this is required to preserve the
// ordering within a selection.
export const getAllOrdered = createSelector(getAll, (lines) =>
  orderBy(lines, ["locked", "updatedAt"], ["desc", "asc"])
);

/**
 * Selector creator for getting an array of lines that are _not_ in the middle
 * of being created by the given user.
 *
 * @param {string} userId - The id of the user whose "creating" lines we want to
 * filter out.
 * @returns Selector that returns the lines not being created by the given user.
 */
export const getNotCreatingBy = (userId: string) =>
  createSelector(getAll, (lines) => {
    return lines.filter((s) => s.creating !== userId);
  });

/**
 * Selector creator for getting the line with a given id.
 *
 * @param {string} id - The id of the line we want to get
 * @returns Selector that returns the line.
 */
export const getById = (id: string) =>
  createSelector(getLines, (lines) => lines[id]);

/**
 * Selector creator for getting all lines currently being grabbed by the
 * provided user id.
 *
 * @param {string} userId - The user id grabbing the lines.
 * @returns Selector that returns the desired lines.
 */
export const getGrabbedBy = (userId: string) =>
  createSelector(getAll, (lines) =>
    lines.filter(({ grab }) => grab?.userId === userId)
  );

/**
 * Selector creator for getting all lines currently being created by the
 * provided user id.
 *
 * @param {string} userId - The user id creating the lines.
 * @returns Selector that returns the desired lines.
 */
export const getCreatingBy = (userId: string) =>
  createSelector(getAll, (lines) =>
    lines.filter(({ creating }) => creating === userId)
  );

/**
 * Selector for getting all users that are in the middle of interacting with a
 * line (i.e., grabbing, resizing, etc...)
 *
 * @param {AppState} state - The current app state.
 * @returns {string[]} Array of ids of users that are currently interacting with
 * a line.
 */
export const getBusyUsers = createSelector(getAll, (lines) =>
  lines
    .flatMap((line) => [line.grab?.userId, line.creating])
    .filter((user) => user)
);

/**
 * Get lines inside a selection area.
 *
 * @param {Area} area - The area for which we wish to find the contained
 * connections.
 * @returns Selector returning the contained connections.
 */
export const getInsideArea =
  (area: {
    position: { x: number; y: number };
    width: number;
    height: number;
  }) =>
  (state: AppState): Line[] => {
    return getAll(state).filter(({ vertices: vertexIds }) => {
      const vertices = vertexIds.map((id) => Vertex.getById(id)(state));
      const bounds = Rect.getBoundingRectFromPoints(
        vertices.map((vertex) => vertex.position)
      );
      return Rect.overlaps(area, bounds);
    });
  };
