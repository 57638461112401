import Bugsnag from "@bugsnag/js";
import { useCallback } from "react";

import { AppState } from "~/store";

type BoardStash = Pick<AppState, "objects" | "comments">;

export const useBoardStash = () => {
  const stash = useCallback((state: BoardStash) => {
    localStorage.setItem("stash", JSON.stringify(state));
  }, []);

  const load = useCallback(() => {
    try {
      const contents = localStorage.getItem("stash");
      if (!contents) return;

      let stash: BoardStash = JSON.parse(contents);
      let objects = Object.values(stash.objects);
      let comments = Object.values(stash.comments);

      const user = objects.find((object) => object.type === "user");
      if (!user) return;

      // Don't include the user, as one will be created with
      // the right id as long as a membership for the board/user
      // combination is created.
      objects = objects.filter(({ type }) => type !== "user");

      const userId = user.id;

      return {
        userId,
        objects,
        comments,
      };
    } catch (err: any) {
      Bugsnag.notify(err);
    } finally {
      localStorage.removeItem("stash");
    }
  }, []);

  return {
    stash,
    load,
  };
};
