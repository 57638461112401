import React, { Suspense } from "react";

import { Spinner } from "./Spinner";

type Props = {
  children: React.ReactNode;
  preview?: React.ReactNode;
};

const LazyLoadPreview = (props: Props) => {
  let fallback = props.preview || <CenteredSpinner />;

  return <Suspense fallback={fallback}>{props.children}</Suspense>;
};

let CenteredSpinner = () => (
  <div className="flex justify-center items-center p-20">
    <Spinner />
  </div>
);

export default LazyLoadPreview;
