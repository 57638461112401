import { FC, HTMLAttributes, useState, useEffect, useCallback } from "react";
import { isDesktop } from "react-device-detect";
import { useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";

type DivProps = HTMLAttributes<HTMLDivElement>;

export const useHoverTarget = (id: string) => {
  const [hover, setHover] = useState(false);
  const selected = useSelector(Client.getIsSelected(id));

  // On mobile, toggle the hover state based on the
  // object's selected state in the absence of an
  // actual hover events.
  useEffect(() => {
    if (isDesktop) return;
    setHover(selected);
  }, [selected]);

  const HoverTarget = useCallback<FC<DivProps>>(
    (props) => (
      <div
        {...props}
        onPointerEnter={
          isDesktop
            ? (event) => {
                setHover(true);
                props.onPointerEnter?.(event);
              }
            : undefined
        }
        onPointerLeave={
          isDesktop
            ? (event) => {
                setHover(false);
                props.onPointerLeave?.(event);
              }
            : undefined
        }
      />
    ),
    [setHover]
  );

  return { hover, HoverTarget };
};
