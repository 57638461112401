import React, { HTMLAttributes } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Connection from "~/store/bundles/Connection";
import * as Create from "~/store/traits/Create";
import { classNames } from "~/util/classNames";

interface Props extends HTMLAttributes<HTMLDivElement> {
  id: string;
  className?: string;
}

export const ConnectTarget = ({ id, className, children, ...props }: Props) => {
  const dispatch = useDispatch();
  const { getState } = useStore();
  const userId = useSelector(Client.getUserId);
  const isConnectionTarget = useSelector(
    Connection.getIsCreationTarget(id, userId)
  );

  const onPointerDownCapture: React.PointerEventHandler<HTMLDivElement> = (
    event
  ) => {
    // Finish connection
    const creatingConnections = Connection.getCreatingBy(userId)(getState());
    creatingConnections.forEach((connection) => {
      dispatch(Create.finishCreating(connection.id, { to: id }));
    });

    if (props.onPointerDownCapture) {
      props.onPointerDownCapture(event);
    }

    if (event.button !== 0) return;
  };

  const onPointerUp: React.PointerEventHandler<HTMLDivElement> = (event) => {
    const creatingConnections = Connection.getCreatingBy(userId)(getState());
    creatingConnections.forEach((connection) => {
      event.stopPropagation(); //Stop App-level mouseup from removing connection
      dispatch(Create.finishCreating(connection.id, { to: id }));
    });

    if (props.onPointerUp) {
      props.onPointerUp(event);
    }
  };

  const onPointerEnter: React.PointerEventHandler<HTMLDivElement> = (event) => {
    // Snap to object anchors
    const creatingConnections = Connection.getCreatingBy(userId)(getState());
    creatingConnections.forEach((connection) => {
      dispatch(Connection.setToId(connection.id, id));
    });

    if (props.onPointerEnter) {
      props.onPointerEnter(event);
    }
  };

  const onPointerLeave: React.PointerEventHandler<HTMLDivElement> = (event) => {
    // Snap to object anchors
    const creatingConnections = Connection.getCreatingBy(userId)(getState());
    creatingConnections.forEach((connection) => {
      dispatch(Connection.clearToId(connection.id));
    });

    if (props.onPointerLeave) {
      props.onPointerLeave(event);
    }
  };

  return (
    <div
      {...props}
      className={classNames(
        "connectable__wrapper",
        isConnectionTarget && "connectable--connection-target",
        className
      )}
      onPointerUp={onPointerUp}
      onPointerDownCapture={onPointerDownCapture}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      {children}
    </div>
  );
};

ConnectTarget.displayName = "ConnectTarget";
