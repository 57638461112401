import "./StickyFan.css";

import { CSSProperties, useEffect, useState } from "react";

import { Point } from "~/util/geometry";

interface StickyFanProps {
  rotateOffset?: number;
  translateOffset?: number;
  stickyTypes?: string[];
  onAddSticky(type: string, position: Point): void;
  expanded?: boolean;
}

function useAnimationSync<T>(currentValue: T, defaultValue: T): T {
  let [value, setValue] = useState(defaultValue);

  useEffect(() => {
    let handle = requestAnimationFrame(() => setValue(currentValue));
    return () => cancelAnimationFrame(handle);
  }, [currentValue]);

  return value;
}

export let StickyFan = ({
  rotateOffset = 10,
  translateOffset = 50,
  stickyTypes = ["sticky1", "sticky2", "sticky3", "sticky4"],
  expanded = true,
  onAddSticky,
}: StickyFanProps) => {
  // Keeps the value in sync with the expanded prop, but the delay gives us
  // time to animate the initial state.
  let fanned = useAnimationSync(expanded, false);

  let middleIndex = (stickyTypes.length - 1) / 2;
  let middleTranslate = middleIndex * translateOffset;
  let middleRotation = middleIndex * rotateOffset;

  function getTransformStyles(index: number): CSSProperties {
    if (!fanned) index = middleIndex;
    let stepsFromCenter = Math.floor(Math.abs(index - middleIndex));
    let translateX = index * translateOffset - middleTranslate;
    let translateY = stepsFromCenter * (translateOffset / 4);
    let rotate = index * rotateOffset - middleRotation;
    return {
      transform: `translate(${translateX}px, ${translateY}px) rotate(${rotate}deg)`,
    };
  }

  return (
    <div className="sticky-fan" aria-expanded={fanned}>
      {stickyTypes.map((type, index) => (
        <div
          key={type}
          className="sticky-fan-item"
          style={getTransformStyles(index)}
          onPointerDown={(event) => {
            onAddSticky(type, { x: event.clientX, y: event.clientY });
          }}
          // Prevent the user from releasing a sticky onto the fan
          // causing it to render below them onscreen.
          onPointerUp={(event) => event.stopPropagation()}
        >
          <div className={`sticky ${type}`} />
        </div>
      ))}
    </div>
  );
};
