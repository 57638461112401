import React, { Suspense } from "react";

import { BoardModal } from "./BoardModal";
import { ModalPanel } from "./Modal";
import { Spinner } from "./Spinner";

type Props = {
  children: React.ReactNode;
  onClose(): void;
};

export const LazyLoadPreviewModal = (props: Props) => {
  let fallback = (
    <BoardModal open onClose={props.onClose}>
      <ModalPanel size="sm">
        <CenteredSpinner />
      </ModalPanel>
    </BoardModal>
  );

  return <Suspense fallback={fallback}>{props.children}</Suspense>;
};

let CenteredSpinner = () => (
  <div className="flex justify-center items-center p-20">
    <Spinner />
  </div>
);
