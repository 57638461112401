import * as Client from "~/store/bundles/Client";
import assert from "~/util/assert";
import { UpdateMany } from "~/util/types";

import * as BaseObject from "./BaseObject";
import * as Lock from "./Lock";
import { AppState, State, Action as AppAction } from "../index";
import { BaseAction, EmptyAction, emptyAction } from "../shared";
import { Trait } from "./Trait";

/**
 * Interface that describes objects that can be deleted.
 */
export interface Delete extends Trait {
  deleted: boolean;
}

/**
 * Type guard that checks whether an object (BaseObject) implements the Delete
 * trait.
 */
export const isDelete = <T extends BaseObject.BaseObject>(
  object: T
): object is T & Delete =>
  ["sticky", "connection", "label", "image", "shape", "vote", "line"].includes(
    object.type
  );

/**
 * Schema for actions returned by the `Delete.remove()` action creator.
 */
export interface DeleteAction extends BaseAction {
  type: "delete/delete";
  data: Pick<Delete, "id" | "deleted">;
}

/**
 * Action creator that deletes an object with a given `id`.
 */
export const deleteObject =
  (id: string) =>
  (state: AppState): DeleteAction | EmptyAction => {
    const object = getById(id)(state);
    if (!object) return emptyAction();

    return {
      type: "delete/delete",
      data: { id, deleted: true },
      effects: {
        broadcast: true,
        persist: true,
        dispatch: [
          Client.unselect(id)(state),
          Client.addUndoSnapshot([object]),
        ],
      },
    };
  };

/**
 * Schema for actions returned by the `Delete.remove()` action creator.
 */
export interface DeleteManyAction extends BaseAction {
  type: "delete/delete_many";
  data: UpdateMany<Delete, "deleted">;
}

/**
 * Action creator that deletes multiple objects at once.
 */
export const deleteMany =
  (ids: string[]) =>
  (state: AppState): DeleteManyAction | EmptyAction => {
    const objects = (
      ids.map((id) => getById(id)(state)).filter((obj) => obj) as Delete[]
    ).filter((obj) => !(Lock.isLock(obj) && obj.locked));

    // Dispatching deleteMany with no objects is a no-op and shouldn't
    // create an undo snapshot.
    if (objects.length === 0) {
      return emptyAction();
    }

    const data = objects.reduce(
      (data, object) => ({ ...data, [object.id]: { deleted: true } }),
      {}
    );

    return {
      type: "delete/delete_many",
      data,
      effects: {
        broadcast: true,
        persist: true,
        dispatch: [Client.selectMany([]), Client.addUndoSnapshot(objects)],
      },
    };
  };

/**
 * Union type of all Delete related actions.
 */
export type Action = DeleteAction | DeleteManyAction;

/**
 * Get Delete object with given id.
 */
export const getById = (id: string) => (state: AppState) => {
  const object = BaseObject.getById(id)(state);
  if (object && isDelete(object)) return object;
};

/**
 * Reducer that handles any Delete-related actions
 */
export const reducer = (state: State = {}, action: AppAction): State => {
  switch (action.type) {
    case "delete/delete": {
      const object = state[action.data.id];
      assert(isDelete(object), "Object does not implement the Delete trait.");

      return {
        ...state,
        [action.data.id]: { ...object, ...action.data },
      };
    }
    case "delete/delete_many": {
      let newState = { ...state };

      for (const id in action.data) {
        const object = newState[id];
        assert(isDelete(object), "Object does not implement the Delete trait.");

        newState[id] = { ...newState[id], ...action.data[id] };
      }

      return newState;
    }
    default:
      return state;
  }
};
