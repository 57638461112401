import "./FeaturedTemplateList.css";

import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { classNames } from "~/util/classNames";

import * as Icons from "./Icons";
import { Box } from "./Box";
import { Carousel } from "./Carousel";
import { Panel } from "./Panel";
import { Tag } from "./Tag";

export let FeaturedTemplateList = ({ children }: { children: ReactNode }) => (
  <Carousel className="featured-template-list" itemWidth={240} gap={16}>
    {children}
  </Carousel>
);

export type FeaturedTemplate = {
  id: string;
  boardId: string;
  name: string;
  description?: string;
  thumbnail: string;
  creator?: string;
};

export interface FeaturedTemplateListItemProps {
  template: FeaturedTemplate;
}

export let FeaturedTemplateListItem = ({
  template,
}: FeaturedTemplateListItemProps) => {
  const hasDescription = !!template.description;
  return (
    <Link to={`/template/${template.boardId}`} key={template.boardId}>
      <Panel
        className={classNames(
          "featured-template-list-item",
          hasDescription && "has-description"
        )}
      >
        <div className="featured-template-list-item-name">{template.name}</div>
        <div className="featured-template-list-item-body">
          <div className="featured-template-list-item-thumbnail">
            <img
              src={template.thumbnail}
              alt={`Preview of ${template.name} template`}
            />
          </div>
          <div className="featured-template-list-item-description">
            {template.description}
          </div>
        </div>
        <Box
          className="featured-template-list-item-meta"
          direction="row"
          align="center"
          justify="between"
          gap={2}
        >
          <Tag color="green">
            <Icons.Star /> Featured
          </Tag>
          {template.creator && (
            <div className="featured-template-list-item-creator">
              {template.creator}
            </div>
          )}
        </Box>
      </Panel>
    </Link>
  );
};
