import "./Line.css";

import React from "react";
import { useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Comments from "~/store/bundles/Comments";
import * as Lines from "~/store/bundles/Line";
import * as Vertex from "~/store/bundles/Vertex";
import * as Rect from "~/util/geometry/rectangle";
import * as Vector from "~/util/geometry/vector";
import { BoardType, useConfig } from "~/context/ConfigContext";
import { classNames } from "~/util/classNames";

import { ControlOverlay } from "./ControlOverlay";
import { Control } from "./ControlOverlay";
import { OpenComments } from "./OpenComments";
import { ContextMenuTarget } from "./shared/ContextMenuTarget";
import { GrabTarget } from "./shared/GrabTarget";
import { ObjectElements } from "./shared/ObjectElements";
import { Position } from "./shared/Position";
import { SelectTarget } from "./shared/SelectTarget";
import { ShowcaseTarget } from "./shared/ShowcaseTarget";
import { useHoverTarget } from "./shared/useHoverTarget";

export const Line = React.memo(({ line }: { line: Lines.Line }) => {
  const userId = useSelector(Client.getUserId);
  const from = useSelector(Vertex.getById(line.vertices[0]));
  const to = useSelector(
    Vertex.getById(line.vertices[line.vertices.length - 1])
  );
  const isSelected = useSelector(Client.getIsSelected(line.id));
  const isGrabbing = userId === line.grab?.userId;
  const isCreating = !!line.creating;
  const isGrabbingVertex =
    userId === from.grab?.userId || userId === to.grab?.userId;
  const isTemplate = useConfig().type === BoardType.Template;
  const comments = useSelector(Comments.getByParentId(line.id));
  const hasComments = comments.length > 0;
  const isResizing = isGrabbingVertex;
  const { hover, HoverTarget } = useHoverTarget(line.id);

  const { locked } = line;

  let isStraight =
    Math.max(from.position.x, to.position.x) -
      Math.min(from.position.x, to.position.x) <
      20 ||
    Math.max(from.position.y, to.position.y) -
      Math.min(from.position.y, to.position.y) <
      20;

  let className = classNames(
    "line__wrapper group pointer-events-none",
    isSelected && "selected line--selected rounded",
    isStraight && "line--straight",
    isGrabbing ? "cursor-grabbing grabbing" : locked ? "locked" : "cursor-grab",
    isResizing && "resizing"
  );

  const highlighted = isSelected;

  let vertices = [from.position, to.position];

  let { position, width, height } = Rect.getBoundingRectFromPoints(vertices);

  const style = {
    transform: `translate(${position.x}px, ${position.y}px)`,
    width: `${width}px`,
    height: `${height}px`,
  };

  // Normalize vertices to a {0,0} coordinate space so they line up w/
  // bounding box.
  vertices = vertices.map((vertex) => Vector.sub(vertex, position));

  return (
    <div className={className} style={style}>
      <HoverTarget>
        <SelectTarget id={line.id}>
          <ContextMenuTarget id={line.id}>
            <GrabTarget id={line.id}>
              <ShowcaseTarget id={line.id} userId={line.createdBy}>
                <SVGLine {...{ vertices, highlighted }} />
              </ShowcaseTarget>
            </GrabTarget>
          </ContextMenuTarget>
        </SelectTarget>

        <ObjectElements>
          {!isTemplate && !isResizing && !isCreating && (
            <ControlOverlay>
              <Control visible={hover || hasComments} style={style}>
                <Position region="top-right">
                  <div className="absolute translate-x-1/3 -translate-y-2/3">
                    <OpenComments id={line.id} commentCount={comments.length} />
                  </div>
                </Position>
              </Control>
            </ControlOverlay>
          )}
        </ObjectElements>
      </HoverTarget>
    </div>
  );
});

Line.displayName = "Line";

const SVGLine = ({
  vertices,
  highlighted,
}: {
  vertices: Vector.Point[];
  highlighted: boolean;
}) => {
  const from = vertices[0];
  const to = vertices[vertices.length - 1];

  return (
    <svg className="stroke-[10px] overflow-visible">
      <rect
        className="stroke-[50px] text-transparent fill-current"
        x={Math.min(from.x, to.x)}
        y={Math.min(from.y, to.y)}
        width={Math.abs(to.x - from.x)}
        height={Math.abs(to.y - from.y)}
        rx={4}
        pointerEvents="stroke"
        stroke={"transparent"}
      />
      <line
        x1={from.x}
        y1={from.y}
        x2={to.x}
        y2={to.y}
        stroke={highlighted ? "var(--blue-3)" : "var(--grey-3)"}
        strokeLinecap="square"
        pointerEvents="stroke"
      />
    </svg>
  );
};
