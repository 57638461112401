import * as Client from "~/store/bundles/Client";
import { Point } from "~/util/geometry";

import * as Grab from "./Grab";
import { fork } from "../";
import { AppState } from "../index";
import { BaseAction, emptyAction, EmptyAction } from "../shared";
import {
  BaseObject,
  getById as getBaseObjectById,
  insert,
  insertMany,
} from "./BaseObject";
import { Trait } from "./Trait";

/**
 * Interface that describes objects that can be inserted.
 */
export interface Duplicate extends Trait {}

const DUPLICATABLE_TYPES = [
  "sticky",
  "label",
  "connection",
  "shape",
  "image",
  "line",
];

/**
 * Type guard that checks whether an object (BaseObject) implements the Lock
 * trait.
 */
export const isDuplicate = <T extends BaseObject>(
  object: T
): object is T & Duplicate => {
  return DUPLICATABLE_TYPES.includes(object.type);
};

export interface DuplicateAction extends BaseAction {
  type: "duplicate/duplicate";
}

/**
 * Action creator that duplicates an object.
 *
 * Essentially forks and inserts an existing object.
 *
 * @param {string} id - The id of the object we want to duplicate
 * @param {Point} position - The cursor position used to start grabbing the
 * newly created object.
 * @returns The action thunk to dispatch to the store.
 */
export const duplicate =
  (id: string, position: Point) =>
  (state: AppState): DuplicateAction | EmptyAction => {
    const object = getById(id)(state);
    if (!object) return emptyAction();

    const client = Client.getClient(state);
    const [dupe] = fork([object]);
    const action = insert(dupe);

    return {
      type: "duplicate/duplicate",
      effects: {
        dispatch: [
          action,
          Grab.isGrab(dupe)
            ? Grab.startGrabbing(dupe.id, client.userId, position)
            : emptyAction(),
        ],
      },
    };
  };

export interface DuplicateSelectionAction extends BaseAction {
  type: "duplicate/duplicate_selection";
}

/**
 * Action creator that duplicates the current selection.
 *
 * @returns {DuplicateSelectionAction} Action to be dispatched to the store.
 */
export const duplicateSelection =
  (userId: string, position: Point) =>
  (state: AppState): DuplicateSelectionAction => {
    const selected = Client.getSelectedObjects(state);
    const objects = fork(selected);

    return {
      type: "duplicate/duplicate_selection",
      effects: {
        dispatch: [
          insertMany(objects),
          Client.selectMany(objects.map(({ id }) => id)),
          Grab.startGrabbingSelection(userId, position),
        ],
      },
    };
  };

/**
 * Union type of all Duplicate related actions.
 */
export type Action = DuplicateAction | DuplicateSelectionAction;

/**
 * Get all duplicatable objects from an app state.
 */
export const getAll = (state: AppState) =>
  Object.values(state.objects).filter(isDuplicate);

/**
 * Get Duplicate object with given id.
 */
export const getById = (id: string) => (state: AppState) => {
  const object = getBaseObjectById(id)(state);
  if (object && isDuplicate(object)) return object;
};
