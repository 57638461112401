import { Plan } from "~/store/bundles/Board";

import { getJson, postJson } from "./ApiClient";

interface CardData {
  brand: string | undefined;
  last4: string | undefined;
  exp_month: number | undefined;
  exp_year: number | undefined;
}

export async function getCard() {
  let response = await getJson<CardData | undefined>("/billing/card");

  if (response.status === "failed") {
    throw new Error(response.msg);
  }

  if (response.status === "success") {
    return response.data;
  }
}

export async function updateCard(tokenId: string) {
  let response = await postJson("/billing/card", { token: tokenId });

  if (response.status === "failed") {
    throw new Error(response.msg);
  }

  if (response.status === "success") {
    return response.data;
  }
}

export async function updatePlan(plan: Plan) {
  let response = await postJson("/billing/plan", { plan });

  if (response.status === "failed") {
    throw new Error(response.msg);
  }

  if (response.status === "success") {
    return response.data;
  }
}
