import { ReactNode } from "react";

import { classNames } from "~/util/classNames";

export const RoundButton = ({
  children,
  className,
  onClick = () => {},
}: {
  children: ReactNode;
  onClick?: React.MouseEventHandler;
  className?: string;
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        className,
        "bg-white text-gray-900 rounded-full",
        "overflow-visible",
        "shadow-md hover:shadow-lg",
        "min-h-[36px] min-w-[36px]",
        "p-1 leading-none",
        "transition hover:scale-125"
      )}
    >
      {children}
    </button>
  );
};
