import "./NameForm.css";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import * as Client from "~/store/bundles/Client";
import * as User from "~/store/bundles/User";
import { generateRandomUser } from "~/util/user";

import * as Icons from "./Icons";
import { Box } from "./Box";
import { Button } from "./Button";
import { ColorPicker } from "./ColorPicker";
import { Modal, ModalDescription, ModalTitle, ModalPanel } from "./Modal";
import { TextInput } from "./TextInput";
import { Text } from "./Typography";

interface FormProps {
  className: string;
}

const NameForm = (): JSX.Element => {
  const location = useLocation();
  return (
    <Modal open={true} onClose={() => {}} className="name-form-modal">
      <ModalPanel className="name-form-panel">
        <ModalTitle>
          <Box direction="row" justify="center" align="center" gap={2}>
            <Icons.Logo />
            Hi there!
          </Box>
        </ModalTitle>
        <ModalDescription>
          <p>
            {` Before you join, please supply your name and favorite color, so others
          can recognize your mouse pointer on the canvas.`}
          </p>

          <p>
            {`
          If you'd rather not, no worries, we'll just pick an anonymous name for
          you.`}
          </p>
        </ModalDescription>

        <Form className="with-mt--lg mx-auto flex flex-col" />
        <Text as="p" variant="muted" size={0} className="name-form-login">
          <Link to="/login" state={{ from: location }}>
            Have an account?
          </Link>
        </Text>
      </ModalPanel>
    </Modal>
  );
};

const Form = ({ className }: FormProps) => {
  let [name, setName] = useState("");
  let [color, setColor] = useState("");
  let dispatch = useDispatch();
  let userId = useSelector(Client.getUserId);
  let { name: defaultName, color: defaultColor } = generateRandomUser();

  let onClick = () => {
    dispatch(
      User.add({
        id: userId,
        name: name || defaultName,
        color: color || defaultColor,
      })
    );
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    // Stop keyboard events from triggering window-wide actions (like backspace
    // navigating back).
    event.stopPropagation();
  };

  return (
    <form onSubmit={(evt) => evt.preventDefault()} {...{ className }}>
      <TextInput
        type="text"
        placeholder={defaultName}
        onChange={(event) => setName(event.target.value)}
        onKeyDown={onKeyDown}
        value={name}
      />
      <ColorPicker
        selectedColor={color}
        onClick={setColor}
        className="name-form-colors"
      ></ColorPicker>
      <Button
        type="submit"
        className="name-form-submit"
        variant="primary"
        onClick={onClick}
      >
        {"Let's go!"}
      </Button>
    </form>
  );
};

export default NameForm;
