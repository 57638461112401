import { COLORS } from "~/util/constants";

interface ColorPickerProps {
  selectedColor: string;
  onClick(color: string): void;
  className?: string;
}

export const ColorPicker = ({
  className,
  selectedColor,
  onClick,
}: ColorPickerProps) => {
  return (
    <>
      {/* Two rows of colors on mobile */}
      <div
        aria-hidden="true"
        className={`${className} flex md:hidden flex-col gap-4`}
      >
        <div className="flex flex-wrap items-center justify-center gap-4">
          {COLORS.slice(0, Math.ceil(COLORS.length / 2)).map((color) => (
            <button
              type="button"
              key={color}
              className={`
                rounded-full h-8 w-8 transition
                ${
                  color === selectedColor
                    ? "ring-2 ring-offset-4 ring-gray-600"
                    : ""
                }
              `}
              style={{ background: color }}
              onClick={() => onClick(color)}
            ></button>
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-4">
          {COLORS.slice(Math.ceil(COLORS.length / 2)).map((color) => (
            <button
              type="button"
              key={color}
              className={`
                rounded-full h-8 w-8 transition
                ${
                  color === selectedColor
                    ? "ring-2 ring-offset-4 ring-gray-600"
                    : ""
                }
              `}
              style={{ background: color }}
              onClick={() => onClick(color)}
            ></button>
          ))}
        </div>
      </div>

      {/* One row as soon as space is available */}
      <div
        className={`${className} hidden md:flex flex-wrap items-center justify-center gap-4`}
      >
        {COLORS.map((color) => (
          <button
            type="button"
            key={color}
            className={`
              rounded-full h-8 w-8 transition
              ${
                color === selectedColor
                  ? "ring-2 ring-offset-4 ring-gray-600"
                  : ""
              }
            `}
            style={{ background: color }}
            onClick={() => onClick(color)}
          ></button>
        ))}
      </div>
    </>
  );
};
