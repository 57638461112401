import { SVGProps } from "react";

export const Spinner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className="spinner animate-spin"
    {...props}
  >
    <path
      d="M14 2C16.7763 2 19.4666 2.96263 21.6127 4.72387C23.7588 6.48512 25.2278 8.936 25.7694 11.6589C26.311 14.3818 25.8918 17.2083 24.5831 19.6568C23.2743 22.1052 21.1571 24.0241 18.5922 25.0866C16.0273 26.149 13.1733 26.2892 10.5166 25.4833C7.85987 24.6774 5.56478 22.9752 4.02237 20.6668C2.47996 18.3585 1.78567 15.5867 2.05779 12.8238C2.32991 10.0609 3.55161 7.47783 5.51472 5.51472"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
