import { useState, useEffect } from "react";

import * as config from "../config";

export type FetchResponse<T = any> =
  | { status: "success"; data: T }
  | { status: "failed"; msg?: string };

export type Fetch<T = any> = FetchResponse<T> | { status: "loading" };

type HttpMethod = "get" | "post" | "delete" | "put";

export const postJson = async <T = any>(
  endpoint: string,
  payload: any = {},
  method: HttpMethod = "post"
): Promise<FetchResponse<T>> => {
  const response = await fetch(config.API_URL + endpoint, {
    method: method,
    credentials: "include",
    mode: "cors",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json", Version: config.VERSION },
  });

  const json = await response.json();

  // If the server denied our request because of a version mismatch, refresh
  // the page to get the latest bundle.
  if (response.ok === false && json.msg === "refresh client") {
    window.location.reload();
  }

  return response.ok
    ? { status: "success", data: json?.data as T }
    : { status: "failed", msg: json.msg };
};

export const getJson = async <T = any>(
  endpoint: string
): Promise<FetchResponse<T>> => {
  const response = await fetch(config.API_URL + endpoint, {
    method: "get",
    credentials: "include",
    mode: "cors",
    headers: { "Content-Type": "application/json", Version: config.VERSION },
  });

  const json = await response.json();

  // If the server denied our request because of a version mismatch, refresh
  // the page to get the latest bundle.
  if (response.ok === false && json.msg === "refresh client") {
    window.location.reload();
  }

  return response.ok
    ? { status: "success", data: json.data as T }
    : { status: "failed", msg: json.msg };
};

/*
 * Make an API (GET) request and return the response. The API location is added
 * automatically, so only relative endpoint locations are needed, eg. "/login".
 */
export const useFetch = <T>(url: string) => {
  const [response, setResponse] = useState<Fetch<T>>({
    status: "loading",
  });

  useEffect(() => {
    getJson<T>(url).then(setResponse);
  }, [url]);

  return response;
};

/**
 * Variation of useFetch hook that allows the user to refetch fresh data.
 */
export const useRefreshableFetch = <T>(url: string) => {
  const [shouldFetch, setShouldFetch] = useState(true);
  const [response, setResponse] = useState<Fetch<T>>({
    status: "loading",
  });

  useEffect(() => {
    if (shouldFetch) {
      setResponse({ status: "loading" });
      getJson<T>(url).then(setResponse);
      setShouldFetch(false);
    }
  }, [url, shouldFetch]);

  const refresh = () => setShouldFetch(true);

  return { response, refresh };
};
