import "./Box.css";

import { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";

import { classNames } from "~/util/classNames";

export type Size = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface BoxProps<T extends ElementType = "div"> {
  as?: T;
  children?: ReactNode;
  m?: Size;
  p?: Size;
  gap?: Size;
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  wrap?: true | "reverse";
  justify?: "start" | "center" | "end" | "between" | "around" | "evenly";
  align?: "start" | "center" | "end" | "baseline" | "stretch";
  grow?: boolean;
}

export let Box = <T extends ElementType = "div">({
  as,
  m,
  p,
  gap,
  direction,
  wrap,
  justify,
  align,
  grow,
  ...props
}: BoxProps<T> & ComponentPropsWithoutRef<T>) => {
  let Component = as || "div";

  let className = classNames(
    "box",
    m && `box-m${m}`,
    p && `box-p${p}`,
    gap && `box-gap${gap}`,
    justify && `box-justify-${justify}`,
    align && `box-align-${align}`,
    direction && `box-${direction}`,
    wrap === true && `box-wrap`,
    wrap === "reverse" && `box-wrap-reverse`,
    grow && "box-grow",
    props.className
  );

  return <Component {...props} className={className} />;
};

export type HStackProps<T extends ElementType = "div"> = Omit<
  BoxProps<T>,
  "direction"
>;

export let HStack = <T extends ElementType = "div">({
  as,
  ...props
}: HStackProps<T> & ComponentPropsWithoutRef<T>) => {
  return <Box {...props} direction="row" align="center" />;
};

export type VStackProps<T extends ElementType = "div"> = Omit<
  BoxProps<T>,
  "direction"
>;

export let VStack = <T extends ElementType = "div">({
  as,
  ...props
}: VStackProps<T> & ComponentPropsWithoutRef<T>) => {
  return <Box {...props} direction="column" justify="center" />;
};
