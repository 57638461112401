import {
  Formik,
  FormikErrors,
  FormikHelpers,
  useField,
  useFormikContext,
} from "formik";
import { useState } from "react";

import * as BoardApi from "~/util/BoardClient";
import { AppState, template } from "~/store";

import * as Icons from "./Icons";
import { Box } from "./Box";
import { Button } from "./Button";
import {
  FormField,
  FormFieldHeader,
  FormFieldLabel,
  FormFieldMessage,
  FormFieldValidationMessage,
} from "./FormField";
import {
  ModalPanel,
  ModalProps,
  ModalTitle,
  ModalDescription,
  Modal,
} from "./Modal";
import { TextArea } from "./TextArea";
import { TextInput } from "./TextInput";

interface Values {
  name: string;
  description: string;
}

interface ConvertToBoardModalProps extends ModalProps {
  initialState: AppState;
  onCreate(boardId: string): void;
}

export let ConvertToBoardModal = (props: ConvertToBoardModalProps) => {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <ModalPanel size="lg">
        <ModalTitle>
          <Box direction="row" justify="center" align="center" gap={2}>
            <Icons.Package /> Create Board
          </Box>
        </ModalTitle>
        <ModalDescription>
          Add a name and description for this board so you'll have an easier
          time finding it later.
        </ModalDescription>
        <ConvertToBoardModalBody {...props} />
      </ModalPanel>
    </Modal>
  );
};

let ConvertToBoardModalBody = (props: ConvertToBoardModalProps) => {
  let [error, setError] = useState<string | undefined>();
  let initialValues: Values = {
    name: "",
    description: "",
  };

  async function createBoard(values: Values): Promise<string> {
    let { name, description } = values;
    let response = await BoardApi.createBoard(
      name,
      description,
      template(props.initialState)
    );

    if (response.status === "success") {
      return response.data.id;
    } else if (response.status === "failed") {
      throw new Error(response.msg);
    } else {
      throw new Error("Something went wrong");
    }
  }

  async function onSubmit(values: Values, helpers: FormikHelpers<Values>) {
    let boardId: string | undefined;

    try {
      setError(undefined);
      boardId = await createBoard(values);
    } catch (err: any) {
      setError(err.message);
    } finally {
      helpers.setSubmitting(false);
    }

    if (boardId) {
      props.onCreate(boardId);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit }) => (
        <Box as="form" onSubmit={handleSubmit} gap={6}>
          <ConvertToBoardNameField />
          <ConvertToBoardDescriptionField />
          <ConvertToBoardButton />
          {error}
        </Box>
      )}
    </Formik>
  );
};

let ConvertToBoardButton = () => {
  let { isSubmitting } = useFormikContext();

  return (
    <Button type="submit" variant="primary" loading={isSubmitting}>
      Create Board
    </Button>
  );
};

let ConvertToBoardNameField = () => {
  let [field] = useField("name");

  return (
    <FormField>
      <FormFieldHeader>
        <FormFieldLabel>Board name</FormFieldLabel>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldHeader>
      <TextInput {...field} autoFocus />
    </FormField>
  );
};

let ConvertToBoardDescriptionField = () => {
  let [field] = useField("description");

  return (
    <FormField>
      <FormFieldHeader>
        <FormFieldLabel>Board description</FormFieldLabel>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldHeader>
      <TextArea
        variant="grey"
        placeholder="Add a short description explaining the purpose of this board."
        {...field}
      />
    </FormField>
  );
};

function validate(values: Values) {
  let errors: FormikErrors<Values> = {};

  if (values.name === "") errors.name = "Board name is required";

  if (values.name.length > 200) errors.name = "Board name is too long";

  if (values.description.length > 2000)
    errors.description = "Description is too long";

  return errors;
}
