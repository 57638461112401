import React from "react";

import LazyLoadPreview from "./LazyLoadPreview";
import Legal from "./Legal";

const TermsPage = () => {
  // Lazy-load
  let Terms = React.lazy(() => import("~/docs/terms"));
  return (
    <Legal title="Terms of Service">
      <LazyLoadPreview>
        <Terms />
      </LazyLoadPreview>
    </Legal>
  );
};
export default TermsPage;
