import { Dispatch } from "react";

import * as Image from "~/store/bundles/Image";
import * as Upload from "~/store/bundles/Upload";
import * as Create from "~/store/traits/Create";
import { Grab } from "~/store/traits";
import { Point } from "~/util/geometry";

import uid from "./uid";

export const INITIAL_IMAGE_WIDTH = 300;
export const INITIAL_IMAGE_HEIGHT = 300;

interface UploadImageProps {
  file: File;
  dispatch: Dispatch<any>;
  userId: string;
  position: Point;
}

export const uploadImageFromFile = (props: UploadImageProps) => {
  let imageId = uid();
  let uploadId = uid();

  props.dispatch(
    Upload.add({ id: uploadId, provider: "cloudinary", file: props.file })
  );

  props.dispatch(
    Image.add({
      id: imageId,
      mousePosition: props.position,
      width: INITIAL_IMAGE_WIDTH,
      height: INITIAL_IMAGE_HEIGHT,
      uploadId,
    })
  );
  props.dispatch(Create.startCreating(imageId, props.userId));
  props.dispatch(Grab.startGrabbing(imageId, props.userId, props.position));
};
