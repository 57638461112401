import * as Alive from "~/store/traits/Alive";
import assert from "~/util/assert";

import * as BaseObject from "./BaseObject";
import * as Lock from "./Lock";
import { AppState, State, Action as AppAction } from "../index";
import { BaseAction, emptyAction, EmptyAction } from "../shared";
import { Trait } from "./Trait";

/**
 * Interface that describes objects that come with editable content.
 */
export interface Edit extends Trait {
  content: string;
  draft: string;
  editing: string | null;
}

/**
 * Type guard that checks whether an object (BaseObject) implements the Edit
 * trait.
 */
export const isEdit = <T extends BaseObject.BaseObject>(
  object: T
): object is T & Edit => "editing" in object;

/**
 * Schema for actions returned by the `Edit.startEditing` action creator.
 */
export interface StartEditingAction extends BaseAction {
  type: "edit/start_editing";
  data: Pick<Edit, "id" | "editing" | "draft">;
  metadata: { id: string; userId: string; editMode: "canvas" | "profile" };
}

/**
 * Action creator that edits an object with a given `id`.
 */
export const startEditing =
  (id: string, userId: string, editMode: "canvas" | "profile" = "canvas") =>
  (state: AppState): StartEditingAction | EmptyAction => {
    let object = getById(id)(state);
    if (!object) return emptyAction();
    if (Lock.isLock(object) && object.locked) return emptyAction();

    return {
      type: "edit/start_editing",
      data: { id, editing: userId, draft: object.content },
      effects: {
        broadcast: true,
        persist: true,
        dispatch: [Alive.touch(id)],
      },
      metadata: { id, userId, editMode },
    };
  };

/**
 * Schema for actions returned by the `Edit.finishEditing` action creator.
 */
export interface FinishEditingAction extends BaseAction {
  type: "edit/finish_editing";
  data: Pick<
    Edit,
    "id" | "editing" | "content" | "draft" | "updatedAt" | "updatedBy"
  >;
}

/**
 * Action creator that ends an edit for the object with `id`.
 */
export const finishEditing =
  (id: string) =>
  (state: AppState): FinishEditingAction | EmptyAction => {
    let object = getById(id)(state);
    if (!object) return emptyAction();
    if (Lock.isLock(object) && object.locked) return emptyAction();

    return {
      type: "edit/finish_editing",
      data: {
        id,
        editing: null,
        content: object.draft,
        draft: "",
        updatedAt: Date.now(),
        updatedBy: object?.editing ?? undefined,
      },
      effects: { broadcast: true, persist: true },
    };
  };

/**
 * Schema for actions returned by the `Edit.setContent` action creator.
 */
export interface UpdateDraftAction extends BaseAction {
  type: "edit/update_draft";
  data: Pick<Edit, "id" | "draft">;
}

/**
 * Action creator that updates the draft for an Edit object with given `id`.
 */
export const updateDraft =
  (id: string, value: string) =>
  (state: AppState): UpdateDraftAction | EmptyAction => {
    const object = getById(id)(state);
    if (!object) return emptyAction();

    return {
      type: "edit/update_draft",
      data: { id, draft: value },
      effects: {
        broadcast: true,
        persist: true,
        dispatch: [Alive.touch(id)],
      },
    };
  };

/**
 * Return a list of finishEditing actions for each object currently being
 * edited by a given user.
 */
export const releaseAllBy = (userId: string) => (state: AppState) => {
  const editing = getEditingBy(userId)(state);
  return editing.map((obj) => finishEditing(obj.id));
};

/**
 * Union type of all Edit related actions.
 */
export type Action =
  | StartEditingAction
  | FinishEditingAction
  | UpdateDraftAction;

/**
 * Get all editable objects from an app state.
 */
export const getAll = (state: AppState) =>
  Object.values(state.objects).filter(isEdit);

/**
 * Get Edit object with given id.
 */
export const getById = (id: string) => (state: AppState) => {
  const object = BaseObject.getById(id)(state);
  if (object && isEdit(object)) return object;
};

/**
 * Get objects that are being edited by a given userId.
 */
export const getEditingBy = (userId: string) => (state: AppState) => {
  return getAll(state).filter(({ editing }) => editing === userId);
};

/**
 * Reducer that handles any Edit-related actions
 */
export const reducer = (state: State = {}, action: AppAction): State => {
  switch (action.type) {
    case "edit/start_editing":
    case "edit/finish_editing":
    case "edit/update_draft": {
      const object = state[action.data.id];
      assert(isEdit(object), "Object does not implement the Edit trait.");

      return {
        ...state,
        [action.data.id]: { ...object, ...action.data },
      };
    }
    default:
      return state;
  }
};
