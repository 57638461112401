import "./BoardControls.css";

import { Popover } from "@headlessui/react";
import isEqual from "lodash/isEqual";
import { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Link } from "react-router-dom";

import * as Board from "~/store/bundles/Board";
import * as BoardObject from "~/store/bundles/BoardObject";
import * as Client from "~/store/bundles/Client";
import * as Comments from "~/store/bundles/Comments";
import * as Sticker from "~/store/bundles/Sticker";
import * as Sticky from "~/store/bundles/Sticky";
import * as User from "~/store/bundles/User";
import * as Create from "~/store/traits/Create";
import * as Grab from "~/store/traits/Grab";
import { useAuth } from "~/context/AuthContext";
import { BoardType, useConfig } from "~/context/ConfigContext";
import { useHuddle } from "~/context/HuddleContext";
import { useBoardStash } from "~/hooks/useBoardStash";
import { getOnlineWithoutCursor as getActiveUsers } from "~/store/bundles/User";
import { BaseObject } from "~/store/traits";
import { isEnabled } from "~/util/FeatureFlags";
import { classNames } from "~/util/classNames";
import { Point } from "~/util/geometry";
import { useHandleClickDrag, useWindowEventListener } from "~/util/hooks";
import uid from "~/util/uid";
import usePermission from "~/util/usePermission";

import * as Icons from "./Icons";
import { Avatar } from "./Avatar";
import {
  Bottom,
  BottomLeft,
  BottomRight,
  Top,
  TopLeft,
  TopRight,
} from "./BoardControlRegions";
import { BoardDock } from "./BoardDock";
import { BoardMenu } from "./BoardMenu";
import { BoardSharingOptions } from "./BoardSharingOptions";
import { BoardToolbar } from "./BoardToolbar";
import { Box } from "./Box";
import { Button, ButtonGroup, IconButton } from "./Button";
import { CommentThread } from "./CommentThread";
import { CommentsButton } from "./CommentsButton";
import { CompleteConnectionPrompt } from "./CompleteConnectionPrompt";
import { Panel } from "./Panel";
import { Spinner } from "./Spinner";
import { StickyFan } from "./StickyFan";
import { CommentsUpsell } from "./StickyStudioPlus";
import { Tooltip } from "./Tooltip";

export let BoardControls = () => {
  const can = usePermission();
  const config = useConfig();
  const editable = can("edit");
  const { type: boardType, websocket } = config;
  const { account } = useAuth();

  // Use the `websocket` config option to distinguish betwween
  // viewing a template and editing one.
  const isTemplateEditor = boardType === BoardType.Template && websocket;

  // Show the dock if we're viewing a board or editing a template.
  const showDock =
    boardType === BoardType.Board ||
    boardType === BoardType.Sandbox ||
    isTemplateEditor;

  return (
    <>
      <TopLeft direction="row">
        <BoardMenuControl />
        <BoardZoomControls />
      </TopLeft>
      <Top>
        <>
          {editable && <BoardToolbar />}
          {isMobile && <CompleteConnectionPrompt />}
        </>
      </Top>
      <TopRight align="end" direction="column">
        <Box direction="row" gap={2}>
          {boardType === BoardType.Sandbox && !account && <SignupControl />}
          {boardType === BoardType.Board && <HuddleControl />}
          {boardType === BoardType.Board && <BoardSharingControl />}
          {isTemplateEditor && <ExitTemplateControl />}
        </Box>
        <ActiveUsersControl />
      </TopRight>
      <BottomLeft></BottomLeft>
      {showDock && (
        <Bottom>
          {isEnabled("stickyfan") ? <StickyFanControl /> : <BoardDock />}
        </Bottom>
      )}
      <BottomRight>
        <CommentThreadControl />
      </BottomRight>
    </>
  );
};

const HuddleControl = () => {
  const plan = useSelector(Board.getPlan);
  const [loading, setLoading] = useState(false);
  const { userId, boardId } = useConfig();
  const { huddle } = useSelector(User.getById(userId));

  const [hovering, setHovering] = useState(false);
  const { joinHuddle, leaveHuddle, setMuted } = useHuddle();
  const dispatch = useDispatch();

  const onJoin = async () => {
    setLoading(true);
    try {
      let huddleId = await joinHuddle();
      dispatch(User.joinHuddle(userId, huddleId));
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const onLeave = async () => {
    try {
      await leaveHuddle();
      dispatch(User.leaveHuddle(userId));
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const onMute = () => {
    try {
      dispatch(User.setMuted(userId, true));
      setMuted(true);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const onUnmute = () => {
    try {
      dispatch(User.setMuted(userId, false));
      setMuted(false);
    } catch (err: any) {
      toast.error(err.message);
    }
  };
  const onPointerEnter = () => setHovering(true);
  const onPointerLeave = () => setHovering(false);

  if (plan === "free") {
    return (
      <Tooltip label="Huddle" position="bottom">
        <Link to={`/${boardId}/huddles`}>
          <IconButton aria-label="Huddles" icon={<Icons.Headphones />} />
        </Link>
      </Tooltip>
    );
  }

  return (
    <ButtonGroup direction="row" className="huddle-controls">
      {huddle ? (
        <>
          {hovering ? (
            huddle.muted ? (
              <Tooltip label="Unmute microphone" position="bottom">
                <IconButton
                  aria-label="Unmute Microphone"
                  icon={<Icons.Mic />}
                  onClick={onUnmute}
                  onPointerEnter={onPointerEnter}
                  onPointerLeave={onPointerLeave}
                />
              </Tooltip>
            ) : (
              <Tooltip label="Mute microphone" position="bottom">
                <IconButton
                  role="tooltip"
                  aria-label="Mute Microphone"
                  icon={<Icons.MicOff />}
                  onClick={onMute}
                  onPointerEnter={onPointerEnter}
                  onPointerLeave={onPointerLeave}
                />
              </Tooltip>
            )
          ) : huddle.muted ? (
            <IconButton
              className="huddle-muted"
              aria-label="In a Huddle (muted)"
              icon={<Icons.MicOff />}
              onPointerEnter={onPointerEnter}
              onPointerLeave={onPointerLeave}
            />
          ) : (
            <IconButton
              className="huddle-active"
              aria-label="In a Huddle "
              icon={<Icons.Mic />}
              onPointerEnter={onPointerEnter}
              onPointerLeave={onPointerLeave}
            />
          )}
          <Tooltip label="Leave Huddle" position="bottom">
            <IconButton
              className="huddle-leave"
              aria-label="Leave Huddle"
              icon={<Icons.LogOut />}
              onClick={onLeave}
            />
          </Tooltip>
        </>
      ) : loading ? (
        <IconButton
          aria-label="Joining huddle"
          icon={<Spinner />}
          disabled={true}
        />
      ) : (
        <Tooltip label="Huddle" position="bottom">
          <IconButton
            className="huddle-inactive"
            aria-label="Huddle"
            icon={<Icons.Headphones />}
            onClick={onJoin}
          />
        </Tooltip>
      )}
    </ButtonGroup>
  );
};

export let BoardMenuControl = () => (
  <BoardMenu>
    <IconButton icon={<Icons.Menu />} aria-label="Menu" />
  </BoardMenu>
);

export let BoardZoomControls = () => {
  let dispatch = useDispatch();
  let [expanded, setExpanded] = useState(false);
  let zoomIn = () => dispatch(Client.scale(1.25));
  let zoomOut = () => dispatch(Client.scale(0.75));
  let zoomFit = () => dispatch(Client.zoomToFitBoard());

  let onZoomInClick = () => {
    // Clear the selection before expanding so the board
    // toolbar doesn't overlap and obscure the expanded
    // zoom controls.
    dispatch(Client.unselectAll());
    setExpanded(true);
  };

  return (
    <ButtonGroup>
      {expanded ? (
        <Tooltip label="Hide" position="bottom">
          <IconButton
            icon={<Icons.ChevronLeft />}
            aria-label="Hide"
            onClick={() => setExpanded(false)}
          />
        </Tooltip>
      ) : (
        <Tooltip label="Zoom" position="bottom">
          <IconButton
            icon={<Icons.ZoomIn />}
            aria-label="Zoom"
            onClick={onZoomInClick}
          />
        </Tooltip>
      )}
      {expanded && (
        <>
          <Tooltip label="Zoom Fit" position="bottom">
            <IconButton
              icon={<Icons.Minimize />}
              aria-label="Zoom Fit"
              onClick={zoomFit}
            />
          </Tooltip>
          <Tooltip label="Zoom In" position="bottom">
            <IconButton
              icon={<Icons.ZoomIn />}
              aria-label="Zoom In"
              onClick={zoomIn}
            />
          </Tooltip>
          <Tooltip label="Zoom Out" position="bottom">
            <IconButton
              icon={<Icons.ZoomOut />}
              aria-label="Zoom Out"
              onClick={zoomOut}
            />
          </Tooltip>
        </>
      )}
    </ButtonGroup>
  );
};

export let StickyFanControl = () => {
  let dispatch = useDispatch();
  let userId = useSelector(Client.getUserId);

  let addSticky = (type: string, position: Point) => {
    let id = uid();
    dispatch(
      Sticky.add({
        id,
        mousePosition: position,
        width: 200,
        height: 200,
        color: type,
      })
    );
    dispatch(Create.startCreating(id, userId));
    dispatch(Grab.startGrabbing(id, userId, position));
  };

  return (
    <StickyFan onAddSticky={addSticky} translateOffset={80} rotateOffset={10} />
  );
};

export let CommentThreadControl = () => {
  const dispatch = useDispatch();
  const objectId = useSelector(Client.getCommentObjectId);
  const boardId = useSelector(Board.getBoardId);
  const boardComments = useSelector(Comments.getByBoardId(boardId));
  const plan = useSelector(Board.getPlan);
  const ref = useRef<HTMLDivElement>(null);

  const isTemplate = useConfig().type === BoardType.Template;

  const onPointerDown = (event: React.PointerEvent) => {
    const isOutside = !ref.current?.contains(event.target as HTMLElement);

    if (isOutside) {
      dispatch(Client.unsetCommentObjectId());
    }
  };

  useWindowEventListener("click", onPointerDown, false);

  if (!objectId) {
    return !isTemplate ? (
      <CommentsButton id={boardId} commentCount={boardComments.length} />
    ) : null;
  }

  return (
    <div ref={ref} className="comment-thread-control">
      {plan === "free" ? (
        <Panel>
          <CommentsUpsell />
        </Panel>
      ) : (
        <CommentThread id={objectId} />
      )}
    </div>
  );
};

export let BoardSharingControl = () => (
  <Popover className="board-sharing-control">
    <Popover.Button as="div">
      <Tooltip label="Share" position="bottom-end">
        <IconButton icon={<Icons.UserPlus />} aria-label="Share" />
      </Tooltip>
    </Popover.Button>
    <Popover.Panel className="board-sharing-control-popup">
      <BoardSharingOptions />
    </Popover.Panel>
  </Popover>
);

export let ActiveUsersControl = () => {
  let users = useSelector(getActiveUsers, isEqual);

  // No need for context if there's only 1 user.
  if (users.length === 1) return null;

  return (
    <Box className="active-users-control" gap={2}>
      {users.map((user) => (
        <ActiveUserControl key={user.id} user={user} />
      ))}
    </Box>
  );
};

type ActiveUserControlProps = {
  user: Omit<User.User, "cursor">;
};

const ActiveUserControl = ({ user }: ActiveUserControlProps) => {
  let dispatch = useDispatch();
  let { getState } = useStore();
  let showcasedUserId = useSelector(Client.getShowcaseUserId);
  let userId = useSelector(Client.getUserId);

  let hasShowcase = showcasedUserId !== null;

  const onDrag = (event: React.PointerEvent) => {
    const id = uid();
    const position = { x: event.clientX, y: event.clientY };
    const options: Sticker.AddAvatarOptions = {
      type: "avatar",
      userId: user.id,
    };

    dispatch(Sticker.add({ id, mousePosition: position, userId, options }));
    dispatch(Create.startCreating(id, userId));
    dispatch(Grab.startGrabbing(id, userId, position));
  };

  const onPointerEnter = (userId: string) => {
    const objectIds = BaseObject.getCreatedBy(userId)(getState());
    dispatch(Client.setShowcaseObjectIds(objectIds));
  };

  const onPointerLeave = () => {
    dispatch(Client.unsetShowcaseObjectIds());
  };

  const onClickDrag = (event: React.PointerEvent, isDrag: boolean) => {
    if (isDrag) {
      onDrag(event);
    } else {
      // Prevent users from finding themselves
      if (user.id === userId) return;
      const cursor = User.getCursor(user.id)(getState());
      dispatch(Client.centerOn(cursor));
    }
  };

  let { onPointerDown, onPointerUp } = useHandleClickDrag(onClickDrag);

  return (
    <Box
      align="center"
      key={user.id}
      className="cursor-pointer relative"
      onPointerEnter={() => onPointerEnter(user.id)}
      onPointerLeave={onPointerLeave}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
    >
      <Tooltip label={user.name} position="left">
        <Avatar
          className={classNames(
            hasShowcase && showcasedUserId !== user.id && "avatar--unfocused"
          )}
          name={user.name}
          color={user.color}
          picture={user.picture}
        />
      </Tooltip>

      {user.huddle &&
        (user.huddle.muted ? (
          <Box className="huddle-badge muted">
            <Tooltip label="In a huddle (muted)" position="left">
              <Icons.MicOff />
            </Tooltip>
          </Box>
        ) : (
          <Box className="huddle-badge">
            <Tooltip label="In a huddle" position="left">
              <Icons.Mic />
            </Tooltip>
          </Box>
        ))}
    </Box>
  );
};

export let SignupControl = () => {
  const { stash } = useBoardStash();
  const objects = useSelector(BoardObject.getAll);
  const comments = useSelector(Comments.getComments);

  const onClick = () => {
    // Stash board contents in local storage to
    // be restored on account creation.
    stash({ objects, comments });
  };

  return (
    <Link to="/signup" onClick={onClick}>
      <Button variant="primary">Sign Up</Button>
    </Link>
  );
};

export let ExitTemplateControl = () => {
  let boardId = useSelector(Board.getBoardId);
  return (
    <Link to={`/template/${boardId}`}>
      <Button variant="white">Done</Button>
    </Link>
  );
};
