import "./Comment.css";

import { ReactNode } from "react";
import { format as formatTime } from "timeago.js";

import * as Icons from "./Icons";

export { CommentEditor } from "./CommentEditor";

export const CommentWrapper = ({ children }: { children: ReactNode }) => (
  <div className="comment">{children}</div>
);

export const CommentAuthor = ({
  color,
  children,
}: {
  color: string;
  children: ReactNode;
}) => (
  <div className="comment-author">
    <div className="comment-author-dot" style={{ background: color }} />
    <strong>{children}</strong>
  </div>
);

export const CommentHeader = ({ children }: { children: ReactNode }) => (
  <header className="comment-header">{children}</header>
);

export const CommentTimestamp = ({ timestamp }: { timestamp: string }) => (
  <time className="comment-timestamp">
    <small>{formatTime(timestamp)}</small>
  </time>
);

export const CommentBody = ({ children }: { children: ReactNode }) => (
  <div className="comment-body">{children}</div>
);

export const CommentActions = ({ children }: { children: ReactNode }) => (
  <div className="comment-actions">{children}</div>
);

export const CommentAction = ({
  onClick,
  children,
}: {
  onClick(event: React.MouseEvent): void;
  children: ReactNode;
}) => (
  <button className="comment-action" onClick={onClick}>
    {children}
  </button>
);

export const CommentReplyAction = ({
  onReply,
}: {
  onReply(event?: React.MouseEvent): void;
}) => (
  <CommentAction onClick={onReply}>
    <Icons.MessageCircle />
    Reply
  </CommentAction>
);

export const CommentEditAction = ({
  onEdit,
}: {
  onEdit(event?: React.MouseEvent): void;
}) => (
  <CommentAction onClick={onEdit}>
    <Icons.Edit />
  </CommentAction>
);

export const CommentDeleteAction = ({
  onDelete,
}: {
  onDelete(event?: React.MouseEvent): void;
}) => (
  <CommentAction onClick={onDelete}>
    <Icons.Trash />
  </CommentAction>
);
