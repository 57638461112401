import "./Landing.css";

import { CSSProperties, ReactNode, useMemo } from "react";

let highlightColorAliases: Record<string, string> = {
  red: "var(--red-1)",
  yellow: "var(--yellow-1)",
  blue: "var(--blue-1)",
  green: "var(--green-1)",
};

export let Highlight = ({
  color,
  children,
}: {
  color: string;
  children: ReactNode;
}) => {
  let strokeStyles = useMemo(
    (): CSSProperties => ({
      background: highlightColorAliases[color] ?? color,
      transform: `rotate(${Math.random() * 5 - 2.5}deg)`,
      borderTopLeftRadius: 5 + Math.random() * 30 + "%",
      borderTopRightRadius: 5 + Math.random() * 30 + "%",
      borderBottomLeftRadius: 5 + Math.random() * 30 + "%",
      borderBottomRightRadius: 5 + Math.random() * 30 + "%",
    }),
    [color]
  );

  return (
    <span className="landing-highlight">
      <span className="landing-highlight-stroke" style={strokeStyles} />
      <span className="landing-highlight-inner">{children}</span>
    </span>
  );
};
