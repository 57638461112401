import { clamp, clampToCircle } from ".";
import { isPoint, Point } from "./vector";

export interface Rectangle {
  position: Point;
  width: number;
  height: number;
}

export const isRectangle = (object: any): object is Rectangle => {
  return (
    object.hasOwnProperty("position") &&
    isPoint(object.position) &&
    object.hasOwnProperty("width") &&
    object.hasOwnProperty("height")
  );
};

/**
 * Return the x coordinate of the left edge of a rectangle
 */
export const left = (rect: Rectangle) => rect.position.x;

/**
 * Return the x coordinate of the right edge of a rectangle
 */
export const right = (rect: Rectangle) => rect.position.x + rect.width;

/**
 * Return the y coordinate of the top edge of a rectangle
 */
export const top = (rect: Rectangle) => rect.position.y;

/**
 * Return the y coordinate of the bottom edge of a rectangle
 */
export const bottom = (rect: Rectangle) => rect.position.y + rect.height;

/**
 * Given a rectangle, return its center.
 */
export const getCenter = ({
  position: { x, y },
  width,
  height,
}: Rectangle): Point => {
  return { x: x + width / 2, y: y + height / 2 };
};

/**
 * Given a rectangle and a padding, return a padded rectangle.
 */
export const padded = (rect: Rectangle, padding: number): Rectangle => {
  return {
    position: {
      x: rect.position.x - padding / 2,
      y: rect.position.y - padding / 2,
    },
    width: rect.width + padding,
    height: rect.height + padding,
  };
};

/**
 * Given a rectangle, return the four corner points.
 */
export const getCorners = (rect: Rectangle): Point[] => [
  { x: left(rect), y: top(rect) },
  { x: right(rect), y: top(rect) },
  { x: left(rect), y: bottom(rect) },
  { x: right(rect), y: bottom(rect) },
];

/**
 * Given a collection of rectangles, construct a bounding box.
 */
export const getBoundingRect = (rects: Rectangle[]): Rectangle => {
  if (rects.length === 0) {
    return { position: { x: 0, y: 0 }, width: 0, height: 0 };
  }

  const minX = Math.min(...rects.map(left));
  const minY = Math.min(...rects.map(top));
  const maxX = Math.max(...rects.map(right));
  const maxY = Math.max(...rects.map(bottom));

  return {
    position: { x: minX, y: minY },
    width: maxX - minX,
    height: maxY - minY,
  };
};

/**
 * Given a collection of points, construct a bounding box.
 */
export const getBoundingRectFromPoints = (points: Point[]): Rectangle => {
  if (points.length === 0) {
    return { position: { x: 0, y: 0 }, width: 0, height: 0 };
  }

  const minX = Math.min(...points.map(({ x }) => x));
  const minY = Math.min(...points.map(({ y }) => y));
  const maxX = Math.max(...points.map(({ x }) => x));
  const maxY = Math.max(...points.map(({ y }) => y));

  return {
    position: { x: minX, y: minY },
    width: maxX - minX,
    height: maxY - minY,
  };
};

/**
 * Given a DOMRect, return a bounding box.
 */
export const getBoundingRectFromDOMRect = (bounds: DOMRect): Rectangle => ({
  position: { x: bounds.x, y: bounds.y },
  width: bounds.width,
  height: bounds.height,
});

/**
 * Check whether a rectangle is enclosed in another
 *
 * @param rect1 - The containing rectangle
 * @param rect2 - The rectangle we want to test for containment in `rect1`.
 * @returns Whether `rect2` is contained in `rect1`
 */
export const containsRect = (rect1: Rectangle, rect2: Rectangle) => {
  return getCorners(rect2).every((point) => containsPoint(rect1, point));
};

/**
 * Check whether two rectangles share any overlap
 */
export const overlaps = (rect1: Rectangle, rect2: Rectangle) => {
  return !(
    right(rect1) < left(rect2) ||
    bottom(rect1) < top(rect2) ||
    left(rect1) > right(rect2) ||
    top(rect1) > bottom(rect2)
  );
};

/**
 * Helper that checks whether a point falls inside an area.
 */
export const containsPoint = (rect: Rectangle, { x, y }: Point) =>
  left(rect) <= x && x <= right(rect) && top(rect) <= y && y <= bottom(rect);

/**
 * Center a given rectangle on a point
 */
export const centerOn = (rect: Rectangle, point: Point) => {
  return {
    position: {
      x: point.x - rect.width / 2,
      y: point.y - rect.height / 2,
    },
    width: rect.width,
    height: rect.height,
  };
};

/**
 * Given a bounding rectangle, clamp a point to lie within it.
 */
export const clampToRect = (bounds: Rectangle, point: Point): Point => {
  const { position, width, height } = bounds;
  return {
    x: clamp(position.x, position.x + width, point.x),
    y: clamp(position.y, position.y + height, point.y),
  };
};

/**
 * Given a bounding rectangle, clamp a second rectangle to lie within it.
 */
export const clampBox = (bounds: Rectangle, box: Rectangle): Rectangle => {
  let x = clamp(
    bounds.position.x,
    bounds.position.x + bounds.width - box.width,
    box.position.x
  );

  let y = clamp(
    bounds.position.y,
    bounds.position.y + bounds.height - box.height,
    box.position.y
  );

  return { position: { x, y }, width: box.width, height: box.height };
};

/**
 * A Rectangle including a border radius
 */
export type RoundedRectangle = Rectangle & { radius: number };

/**
 * Pad a rounded rectangle, taking into account the border radius
 */
export const padRounded = (rect: RoundedRectangle, padding: number) => {
  return {
    ...padded(rect, padding),
    radius: rect.radius + padding / 2,
  };
};

/**
 * Given a *rouded* bounding rectangle, clamp a point to lie within it.
 */
export const clampToRounded = (
  bounds: RoundedRectangle,
  point: Point
): Point => {
  let { position, width, height, radius } = bounds;

  // If the cursor is in the top-left, clamp to that border radius
  const topLeftInner = {
    x: position.x + radius,
    y: position.y + radius,
  };

  if (point.x <= topLeftInner.x && point.y <= topLeftInner.y) {
    return clampToCircle({ c: topLeftInner, r: radius }, point);
  }

  // If the cursor is in the top-right, clamp to that border radius
  const topRightInner = {
    x: position.x + width - radius,
    y: position.y + radius,
  };

  if (point.x >= topRightInner.x && point.y <= topRightInner.y) {
    return clampToCircle({ c: topRightInner, r: radius }, point);
  }

  // If the cursor is in the bottom-left, clamp to that border radius
  const bottomLeftInner = {
    x: position.x + radius,
    y: position.y + height - radius,
  };

  if (point.x <= bottomLeftInner.x && point.y >= bottomLeftInner.y) {
    return clampToCircle({ c: bottomLeftInner, r: radius }, point);
  }

  // If the cursor is in the bottom-right, clamp to that border radius
  const bottomRightInner = {
    x: position.x + width - radius,
    y: position.y + height - radius,
  };

  if (point.x >= bottomRightInner.x && point.y >= bottomRightInner.y) {
    return clampToCircle({ c: bottomRightInner, r: radius }, point);
  }

  // Else, just clamp to the box edges
  return clampToRect(bounds, point);
};

/**
 * Normalize a rectangle with negative width/height by returning an equivalent
 * rectangle with positive width/height at a different position
 */
export const normalized = (rect: Rectangle): Rectangle => {
  const {
    position: { x, y },
    width,
    height,
  } = rect;

  return {
    position: {
      x: width < 0 ? x + width : x,
      y: height < 0 ? y + height : y,
    },
    width: Math.abs(width),
    height: Math.abs(height),
  };
};
