/**
 * Record that lists supported feature flags along with their default values if
 * no value is found in localStorage.
 */
export const flags = {
  alpha: false, // Enables all other feature flags
  stickyfan: false, // Experimental sticky fan ui
  lines: false, // Opt-in to line object
} as const;

export type Flag = keyof typeof flags;

export function isEnabled(flag: Flag): boolean {
  let value = localStorage.getItem(`flags.${flag}`);
  if (localStorage.getItem(`flags.alpha`) === "true") {
    return true;
  } else if (value != null) {
    return value === "true";
  } else {
    return flags[flag] ?? false;
  }
}

export function enable(flag: Flag): void {
  return localStorage.setItem(`flags.${flag}`, "true");
}

export function disable(flag: Flag): void {
  return localStorage.setItem(`flags.${flag}`, "false");
}

export function clear(flag: Flag): void {
  return localStorage.removeItem(`flags.${flag}`);
}

export function reset() {
  for (let flag of Object.keys(flags) as Flag[]) {
    clear(flag);
  }
}

export function addBodyFlagAttribute() {
  let value = (Object.keys(flags) as Flag[]).filter(isEnabled).join(" ");
  document.body.setAttribute("data-feature-flags", value);
}

export function parseFromQuery(queryString: string) {
  let query = new URLSearchParams(queryString);
  for (let key of Object.keys(flags) as Flag[]) {
    let value = query.get(key);
    if (value === "on") enable(key);
    if (value === "off") disable(key);
  }
}
