import "./EditableText.css";

import React, { CSSProperties } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as User from "~/store/bundles/User";
import Linked from "~/components/Linked";
import { Edit, Resize } from "~/store/traits";
import { classNames } from "~/util/classNames";

type Props = {
  className?: string;
  id: string;
  defaultValue?: string;
  height?: number;
  disabled?: boolean;
};

export const EditableText = React.memo(
  ({
    id,
    className,
    defaultValue = "",
    height = undefined,
    disabled = false,
  }: Props) => {
    const object = useSelector(Edit.getById(id));
    const dispatch = useDispatch();
    const { getState } = useStore();
    const userId = useSelector(Client.getUserId);
    if (!object) return null;

    const { editing, content } = object;

    const author = editing ? User.getById(editing)(getState()) : null;

    const isEditing = object.editing === userId;

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const content = event.target.value;

      dispatch(Edit.updateDraft(id, content));

      // Resize if the content would overflow the object
      if (!Resize.isResize(object)) return;
      const { height } = object;
      const newHeight = event.target.scrollHeight;

      if (newHeight > height) {
        dispatch(Resize.grow(id, { x: 0, y: newHeight - height }));
      }
    };

    const onKeyDown = (event: React.KeyboardEvent) => {
      if (
        (event.key === "Enter" && !event.shiftKey) ||
        event.key === "Escape"
      ) {
        dispatch(Edit.finishEditing(id));
      }
    };

    const onFocus = (event: React.FocusEvent) =>
      (event.currentTarget as HTMLTextAreaElement).select();

    const onBlur = () => {
      dispatch(Edit.finishEditing(id));
    };

    const onPointerDown = (event: React.PointerEvent) => {
      event.stopPropagation();
    };

    const style: CSSProperties | undefined = height ? { height } : undefined;

    return isEditing ? (
      <textarea
        className={`${className} editable-text`}
        defaultValue={content || defaultValue}
        autoFocus
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onPointerDown={onPointerDown}
        style={style}
      />
    ) : author ? (
      <div
        className={`${className} editable-text editable-text--editing`}
        style={{
          borderColor: author.color,
        }}
      >
        {`(${author.name} is editing this.)`}
      </div>
    ) : (
      <div
        className={classNames(
          className,
          "editable-text",
          disabled && "editable-text-disabled"
        )}
      >
        <Linked>{content || defaultValue}</Linked>
      </div>
    );
  }
);
