import "./404.css";

import { Link } from "react-router-dom";

import { Box } from "./Box";
import { Button } from "./Button";
import {
  Page,
  Header,
  HeaderLogo,
  HeaderNavigation,
  Footer,
  Section,
} from "./Page";
import { Panel } from "./Panel";
import { Heading, Text } from "./Typography";

export const NotFound = () => {
  return (
    <Page className="not-found">
      <Header>
        <HeaderLogo />
        <HeaderNavigation />
      </Header>
      <Section>
        <Box align="center" gap={8}>
          <Panel size="lg">
            <Box gap={7} align="center">
              <Heading level={1} className="not-found-title">
                Whoops...
              </Heading>
              <Text as="p" className="not-found-body">
                We're very sorry, but we can't seem to find the page you're
                looking for.
              </Text>
              <Link to="/" className="flex justify-center">
                <Button variant="primary">Return to Sticky Studio</Button>
              </Link>
            </Box>
          </Panel>
        </Box>
      </Section>
      <Footer></Footer>
    </Page>
  );
};
