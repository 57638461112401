import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

import { BUGSNAG_API_KEY, NODE_ENV } from "../config";

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: NODE_ENV,
  enabledReleaseStages: ["production"],
});

export const ErrorBoundary =
  Bugsnag.getPlugin("react")!.createErrorBoundary(React);
