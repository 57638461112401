import "./TextInput.css";

import { forwardRef, InputHTMLAttributes } from "react";

import { classNames } from "~/util/classNames";

type InputProps = InputHTMLAttributes<HTMLInputElement>;

export interface TextInputProps extends InputProps {
  variant?: "white" | "grey";
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ variant = "grey", type = "text", ...props }, ref) => (
    <input
      {...props}
      ref={ref}
      type={type}
      className={classNames(
        "text-input",
        variant && `text-input-${variant}`,
        props.className
      )}
    />
  )
);

TextInput.displayName = "TextInput";
