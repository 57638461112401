import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import * as Board from "~/store/bundles/Board";

import { postJson, useRefreshableFetch as useFetch } from "./ApiClient";
import uid from "./uid";

export interface Invite {
  id: string;
  createdAt: string;
  email: string;
  firstName?: string;
  lastName?: string;
  userId?: string;
}

/**
 * Hook that forms the main way in which we interact with Invites (fetch, add,
 * remove, etc...)
 */
export const useInvites = () => {
  const boardId = useSelector(Board.getBoardId);
  const [isLoading, setIsLoading] = useState(true);
  const [invites, setInvites] = useState<Invite[]>([]);
  const { response, refresh } = useFetch<Invite[]>(
    `/invite/${boardId}?rich=true`
  );

  /**
   * Create and send out an invite to a given email address.
   *
   * @param email - The email address to create the invite for
   */
  const add = async (email: string) => {
    // Optimistically add a temporary invite record
    const temp = createTempInvite(email);
    setInvites((invites) => [...invites, temp]);

    // Create the actual invite
    const result = await postJson<Invite>("/invite/create", { boardId, email });

    // Remove the optimistically added entry
    setInvites((invites) => invites.filter((inv) => inv.id !== temp.id));

    if (result.status === "success") {
      setInvites((invites) => [...invites, result.data]);
    } else {
      toast.error(
        result.msg ?? "Something went wrong, please try again later."
      );
    }
  };

  /**
   * Remove an invite with the provided id.
   *
   * @param id - The id of the invite to remove.
   */
  const remove = async (id: string) => {
    // Remove the invite optimistically
    setInvites((invites) => invites.filter((inv) => inv.id !== id));

    let result = await postJson(`/invite/${id}`, {}, "delete");

    if (result.status === "failed") {
      toast.error(
        result.msg ?? "Something went wrong, please try again later."
      );

      // In case of failure, we refresh the list to restore the invite we
      // removed optimistically.
      refresh();
    }
  };

  useEffect(() => {
    if (response.status === "success") {
      setInvites(response.data);
    }

    setIsLoading(false);
  }, [response]);

  return { isLoading, invites, add, remove, refresh };
};

/**
 * Helper that creates a mock invite for a given email, so we have something
 * to insert optimistically.
 */
const createTempInvite = (email: string): Invite => {
  return {
    id: uid(),
    email,
    createdAt: new Date(Date.now()).toISOString(),
  };
};
