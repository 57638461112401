import { useSelector, useDispatch } from "react-redux";

import * as Client from "~/store/bundles/Client";
import { Resize } from "~/store/traits";
import { Corner } from "~/store/traits/Resize";

import { Handle } from "./Handle";

const ResizeHandle = ({ id, corner }: { id: string; corner: Corner }) => {
  let dispatch = useDispatch();
  let userId = useSelector(Client.getUserId);

  const onPointerDown = (event: React.PointerEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!userId) return;
    const position = { x: event.clientX, y: event.clientY };

    dispatch(Resize.startResizing(id, userId, position, corner));
  };

  return (
    <Handle
      cursor={`${asDirection(corner)}-resize`}
      onPointerDown={onPointerDown}
    />
  );
};

/**
 * Helper that maps a corner to a given ordinal direction
 * (e.g., top-right -> north-west)
 */
const asDirection = (corner: Corner): "ne" | "se" | "nw" | "sw" => {
  switch (corner) {
    case "top-left":
      return "nw";
    case "top-right":
      return "ne";
    case "bottom-left":
      return "sw";
    case "bottom-right":
      return "se";
  }
};

export default ResizeHandle;
