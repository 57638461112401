import "./Landing.css";

import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import landingBoardSrc from "~/assets/marketing/landing-board.png";
import { useClassOnChange } from "~/hooks/useClassOnChange";
import { classNames } from "~/util/classNames";
import { useOrientation } from "~/util/hooks";
import { pick } from "~/util/random";

import * as Icons from "./Icons";
import { Box, HStack } from "./Box";
import { Button } from "./Button";
import { Logo } from "./Icons";
import { Highlight } from "./Marketing";

export const Landing = () => {
  let orientation = useOrientation();

  return (
    <Box className="landing" gap={8}>
      <Box as="header" p={8}>
        <Box as="nav" direction="row" align="center" justify="between">
          <HStack gap={2} className="landing-name">
            <Logo width={24} height={24} /> Sticky Studio
          </HStack>
          <Link to="/login">
            <Button variant="primary">Sign in</Button>
          </Link>
        </Box>
      </Box>
      <Box as="section" align="center">
        <h1 className="landing-tagline">Get unstuck, together</h1>
        <h3 className="landing-pitch">
          A collaborative canvas for <WhatDoesItDo />
        </h3>
      </Box>
      <Box
        as="section"
        align="center"
        gap={5}
        p={3}
        className="landing-container"
      >
        <Link to="/try">
          <Button variant="primary">Try it!</Button>
        </Link>
        <img
          className="landing-screenshot"
          src={landingBoardSrc}
          alt="Screenshot of Sticky Studio"
        />
      </Box>
      <Box
        as="section"
        direction={orientation === "landscape" ? "row" : "column"}
        justify="around"
        align="center"
        className="landing-container"
        p={8}
        gap={orientation === "landscape" ? 2 : 6}
      >
        <LandingStep>
          <LandingHeading>
            <Highlight color="var(--red-1)">Get Started</Highlight>
          </LandingHeading>
          <LandingText>Create a board for a conversation</LandingText>
        </LandingStep>
        <LandingStep>
          <LandingHeading>
            <Highlight color="var(--blue-1)">Get Together</Highlight>
          </LandingHeading>
          <LandingText>
            Invite other people to collaborate in realtime
          </LandingText>
        </LandingStep>
        <LandingStep>
          <LandingHeading>
            <Highlight color="var(--yellow-1)">Get Unstuck</Highlight>
          </LandingHeading>
          <LandingText>
            Get ideas out of your heads and onto the board
          </LandingText>
        </LandingStep>
      </Box>
      <Box as="section" align="center" gap={6} m={10}>
        <LandingHeading>A tiny tool for big ideas</LandingHeading>
        <LandingText>
          You can place stickies, draw connections, and vote together.
        </LandingText>
        <LandingText>
          <strong>It's deliberately simple.</strong>
        </LandingText>
        <LandingText>
          Take notes, create plans, and make sense of messy topics that matter.
        </LandingText>
        <Link to="/signup">
          <Button variant="primary">Sign up</Button>
        </Link>
      </Box>
      <Box as="section" align="center" gap={4} m={10}>
        <LandingHeading>
          Sticky Studio <Highlight color="var(--yellow-1)">Plus</Highlight>
        </LandingHeading>
        <LandingText>
          The free version is great, but we also need to keep the lights on.
        </LandingText>
        <LandingText>
          Here’s what you get for{" "}
          <Highlight color="var(--yellow-1)">
            <strong>$5</strong>
          </Highlight>{" "}
          a month.
        </LandingText>
        <Box direction="row" gap={3} p={8} wrap align="center" justify="center">
          <LandingPlusFeature
            name="Unlimited Boards"
            icon={<Icons.PlusSquare />}
            color="green"
          />
          <LandingPlusFeature
            name="Invite-only Boards"
            icon={<Icons.UserCheck />}
            color="yellow"
          />
          <LandingPlusFeature
            name="Comments"
            icon={<Icons.MessageCircle />}
            color="blue"
          />
          <LandingPlusFeature
            name="Permissions"
            icon={<Icons.Lock />}
            color="green"
          />
          <LandingPlusFeature
            name="Exports"
            icon={<Icons.Package />}
            color="red"
          />
          <LandingPlusFeature
            name="Avatars"
            icon={<Icons.User />}
            color="yellow"
          />
          <LandingPlusFeature
            name="Templates"
            icon={<Icons.Copy />}
            color="red"
          />
          <LandingPlusFeature
            name="Huddles"
            icon={<Icons.Mic />}
            color="blue"
          />
        </Box>
        <Link to="/signup">
          <Button variant="primary">Sign up</Button>
        </Link>
      </Box>
      <Box
        as="footer"
        className="landing-footer landing-container"
        direction="row"
        justify="between"
        p={8}
      >
        <HStack gap={2}>
          <Logo width={24} height={24} /> Sticky Studio{" "}
          <span className="landing-credit">
            A tool by <a href="https://kumu.io">Kumu</a>
          </span>
        </HStack>
        <HStack gap={4}>
          <a href="mailto:support@sticky.studio">Contact</a>
          <Link to="/privacy">Privacy</Link>
          <Link to="/terms">Terms</Link>
        </HStack>
      </Box>
    </Box>
  );
};

let LandingStep = ({ children }: { children: ReactNode }) => (
  <Box className="landing-step" gap={2}>
    {children}
  </Box>
);

let LandingHeading = ({ children }: { children: ReactNode }) => (
  <div className="landing-heading">{children}</div>
);

let LandingText = ({ children }: { children: ReactNode }) => (
  <div className="landing-text">{children}</div>
);

let LandingPlusFeature = ({
  name,
  color,
  icon,
}: {
  name: ReactNode;
  color: "blue" | "red" | "yellow" | "green";
  icon: ReactNode;
}) => (
  <Box
    className={`landing-plus-feature landing-plus-feature-${color}`}
    align="center"
    gap={2}
  >
    <Box
      className="landing-plus-feature-circle"
      align="center"
      justify="center"
    >
      {icon}
    </Box>
    <Highlight color={`var(--${color}-1)`}>
      <strong>{name}</strong>
    </Highlight>
  </Box>
);

let WhatDoesItDo = () => {
  let color = useRandom([
    "var(--yellow-1)",
    "var(--red-1)",
    "var(--blue-1)",
    "var(--green-1)",
  ]);
  let purpose = useRandom([
    "untangling complexity",
    "messy topics",
    "making sense",
    "finding consensus",
    "taking notes",
    "organizing thoughts",
    "mapping systems",
    "complex issues",
    "explorable conversations",
    "team retros",
  ]);
  let className = useClassOnChange({
    value: purpose,
    className: "landing-pop",
  });
  return (
    <span className={classNames("landing-purpose", className)}>
      <Highlight color={color}>{purpose}</Highlight>
    </span>
  );
};

/**
 * Returns a different random value from `array` every 5 seconds.
 */
function useRandom<T>(array: T[]): T {
  let [value, setValue] = useState(array[0]);
  useEffect(() => {
    let interval = setInterval(() => {
      setValue(pick(array));
    }, 5000);
    return () => clearInterval(interval);
  }, [setValue, array]);
  return value;
}
