import React, { useMemo } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { NotFound as FourOhFour } from "~/components/404";
import { useAuth } from "~/context/AuthContext";
import { Config, BoardType, Permission } from "~/context/ConfigContext";
import { parseSelectionHash } from "~/hooks/useSelectionHash";
import { createAppState } from "~/store";
import { useGetFullBoard } from "~/util/BoardClient";
import uid from "~/util/uid";

import { BoardWithProviders } from "./Board";

type Props = {
  boardId: string;
};

const BoardEditor = React.memo(({ boardId }: Props) => {
  const { account } = useAuth();
  const location = useLocation();
  const response = useGetFullBoard(boardId);
  const userId = useMemo(uid, []);

  if (response.status === "loading") return null;
  if (response.status === "failed") return <FourOhFour />;
  if (response.data.template) return <Navigate to={`/template/${boardId}`} />;

  const {
    data: {
      name,
      description,
      createdBy,
      objects,
      permissions,
      template,
      comments,
      auth,
      font,
      plan,
    },
  } = response;

  const board = {
    boardId,
    name,
    description,
    createdBy,
    template,
    font,
    plan,
  };

  const selection = parseSelectionHash(location.hash);

  const initialState = createAppState({
    userId: auth.userId ?? userId,
    board,
    objects,
    comments,
    selection,
  });
  const isOwner = account && createdBy === account.id;

  const config: Config = {
    userId: auth.userId ?? userId,
    type: BoardType.Board,
    permission: isOwner ? Permission.Write : permissions,
    websocket: true,
    boardId,
  };

  return <BoardWithProviders config={config} initialState={initialState} />;
});

export const BoardEditorWrapper = () => {
  const { boardId } = useParams();
  if (!boardId) return null;

  return <BoardEditor boardId={boardId} />;
};
