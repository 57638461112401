export interface Point {
  x: number;
  y: number;
}
export const isPoint = (object: any): object is Point => {
  return object.hasOwnProperty("x") && object.hasOwnProperty("y");
};

export interface Vector {
  x: number;
  y: number;
}

export const isVector = (object: any): object is Vector => {
  return object.hasOwnProperty("x") && object.hasOwnProperty("y");
};

/**
 * Subtract two vectors
 */
export const sub = (v1: Vector, v2: Vector): Vector => {
  return { x: v1.x - v2.x, y: v1.y - v2.y };
};

/**
 * Add two vectors
 */
export const add = (v1: Vector, v2: Vector): Vector => {
  return { x: v1.x + v2.x, y: v1.y + v2.y };
};

/**
 * Get the vector between two points.
 */
export const vec = (p1: Point, p2: Point): Vector => {
  return { x: p2.x - p1.x, y: p2.y - p1.y };
};

/**
 * Get the 2-norm (the Euclidean length) of a vector.
 */
export const norm = (vec: Vector) => {
  return Math.sqrt(vec.x * vec.x + vec.y * vec.y);
};

/**
 * Get the Euclidean distance between two points.
 */
export const dist = (p1: Point, p2: Point) => {
  return norm(vec(p1, p2));
};

/**
 * Normalize a vector to have unit length.
 */
export const normalize = (vec: Vector) => {
  let d = norm(vec);
  return { x: vec.x / d, y: vec.y / d };
};

/**
 * Scale a vector by a given scalar.
 */
export const scale = (vec: Vector, s: number) => {
  return { x: s * vec.x, y: s * vec.y };
};

/**
 * Given a vector, get it's angle with the x-axis (in degrees!).
 */
export const angleFromDir = ({ x, y }: Vector) => {
  return ((Math.atan2(y, x) / Math.PI) * 180 + 360) % 360;
};

/**
 * Given a vector, get the right-handed normal vector with unit length
 */
export const normal = ({ x, y }: Vector) => normalize({ x: -y, y: x });

/**
 * Get the dot product between two vectors.
 */
export const dot = (v1: Vector, v2: Vector) => v1.x * v2.x + v1.y * v2.y;

/**
 * Get the (2D) cross product between two vectors.
 */
export const cross = (v1: Vector, v2: Vector) => v1.x * v2.y - v1.y * v2.x;

/**
 * Rotate a vector by an angle (in radians).
 */
export const rotate = (v: Vector, theta: number) => {
  return {
    x: v.x * Math.cos(theta) - v.y * Math.sin(theta),
    y: v.x * Math.sin(theta) + v.y * Math.cos(theta),
  };
};

/**
 * Return the sign of the (acute) angle subtended by two vectors.
 */
export const handedness = (v1: Vector, v2: Vector) =>
  Math.sign(cross(v1, v2)) as 1 | -1;

/**
 * Calculate the right-handed angle between two vectors. Returns a value between
 * 0 and 2pi.
 */
export const angle = (v1: Vector, v2: Vector) => {
  let u1 = normalize(v1);
  let u2 = normalize(v2);
  let angle = Math.acos(dot(u1, u2));
  let chirality = handedness(u1, u2);

  return chirality === 1 ? angle : 2 * Math.PI - angle;
};
