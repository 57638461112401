import { Middleware } from "redux";

import { Permission } from "~/context/ConfigContext";
import { CommentAction } from "~/store/bundles/Comments";
import { Action } from "~/util/types";

/**
 * Enforce a permission level by dropping disallowed actions
 */

const COMMENT_ACTIONS = new Set<CommentAction["type"]>([
  "comment/create",
  "comment/remove",
  "comment/update",
  "websocket/create_comment",
]);

/**
 * Predicate that tests whether or not an action is permitted given a permission
 * level.
 */
const isAllowed = (permission: Permission, action: Action) => {
  switch (permission) {
    case Permission.NoAccess:
      return false;

    case Permission.ReadOnly:
      return isReadOnlyAllowedAction(action);

    case Permission.Comment:
      return isCommentAllowedAction(action);

    case Permission.EditOwned:
    case Permission.Write:
      return true;

    default:
      return false;
  }
};

/**
 * Predicate that returns true for any action that is allowed in read-only mode.
 *
 * Currently, this means any client-related action (panning, zooming, selecting,
 * ...) or user-related action (setting user to online/offline, ...).
 */
const isReadOnlyAllowedAction = (action: Action) => {
  return (
    /^client\/\S+/.test(action.type) || // Client-related actions
    /^user\/\S+/.test(action.type)
  ); // User related actions
};

/**
 * Predicate that returns true for any action that is allowed in comment-only
 * mode.
 *
 * This means: any actions that are allowed in read-only mode _and_
 * certain comment-specific actions.
 */
const isCommentAllowedAction = (action: Action) => {
  return (
    isReadOnlyAllowedAction(action) ||
    COMMENT_ACTIONS.has(action.type as CommentAction["type"])
  );
};

/**
 * Middleware factory that takes a permission level and returns a middleware that
 * drops any disallowed actions according to those permissions.
 * */
const restrictActions =
  (permission: Permission): Middleware =>
  () =>
  (next) =>
  (action: Action) => {
    return isAllowed(permission, action) ? next(action) : action;
  };

export default restrictActions;
