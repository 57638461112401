import { createSelector } from "reselect";

import { Action, AppState } from "~/store";
import { BaseAction, BaseEffects } from "~/store/shared";

export type Font = "handwritten" | "serif" | "sans-serif";
export type Plan = "free" | "plus";

export const DEFAULT_FONT: Font = "handwritten";

/**
 * Type that describes the `board` related slice of the redux state.
 *
 * @typedef {Board} The board state
 * @property {string} id - The board id.
 * @property {string} name - The name of the board.
 * @property {string} description - A longer description of the board contents.
 * @property {string} [createdBy] - The account ID that created the board.
 * @property {boolean} [template] - Whether or not the board is a template.
 */
export interface Board {
  id: string;
  name: string;
  description: string;
  createdBy?: string;
  template?: boolean;
  font?: Font;
  plan: Plan;
}

/**
 * Named parameters to the createState function.
 */
type Params = {
  boardId: string;
  name: string;
  description: string;
  createdBy?: string;
  template?: boolean;
  plan: Plan;
  font?: Font;
};

/**
 * Create a board state
 *
 * Take board parameters and turn it into a `board` state slice.
 *
 * @param id - The board id.
 * @param name - The name of the board.
 * @param description - A longer description of the board contents.
 * @param [createdBy] - The account ID that created the board.
 * @param [template] - Whether or not the board is a template.
 * @param [accessMode] - The access mode set for the board.
 * @param [font] - The font setting for the board.
 * @returns {Board} The `board` state slice.
 */
export const createState = ({
  boardId,
  name,
  description,
  createdBy,
  template,
  font,
  plan,
}: Params): Board => ({
  id: boardId,
  name: name ?? "My board",
  description: description ?? "This is my Sticky Studio board",
  createdBy,
  template,
  font: font ?? "handwritten",
  plan,
});

/**
 * Swap out any ids according to a provided lookup table.
 *
 * @param {Board} board - The board state slice to modify.
 * @param {(id: string) => string} lookup - A function that can be called with an `id` string and returns the string to replace it.
 * @returns {Board} The new `board` state slice with the ids replaced.
 */
const swapIds = (board: Board, lookup: (id: string) => string): Board => {
  return { ...board, id: lookup(board.id) };
};

/**
 * Fork an entire board state, swapping out ids using a provided lookup table.
 *
 * @param {Board} board - The board state slice to modify.
 * @param {(id: string) => string} lookup - A function that can be called with an `id` string and returns the string to replace it.
 * @returns {Board} The new `board` state slice with the ids replaced.
 */
export const fork = swapIds;

export interface SetNameAction extends BaseAction {
  type: "board/set_name";
  data: Pick<Board, "name">;
}

/**
 * Action creator for setting the board name.
 *
 * @param {string} name - The new name we want to set for the board.
 * @returns {SetNameAction} The action to be dispatched to the store.
 */
export const setName = (name: string): SetNameAction => {
  return {
    type: "board/set_name",
    data: { name },
    effects: { broadcast: true, persist: true },
  };
};

export interface SetDescriptionAction extends BaseAction {
  type: "board/set_description";
  data: Pick<Board, "description">;
}

/**
 * Action creator for setting the board description.
 *
 * @param {string} description - The new description we want to set for the board.
 * @returns {SetDescriptionAction} The action to be dispatched to the store.
 */
export const setDescription = (description: string): SetDescriptionAction => {
  return {
    type: "board/set_description",
    data: { description },
    effects: { broadcast: true, persist: true },
  };
};

export interface SetFontAction extends BaseAction {
  type: "board/set_font";
  data: Pick<Board, "font">;
}

export interface SetPlanAction extends BaseAction {
  type: "board/set_plan";
  data: Pick<Board, "plan">;
}

/**
 * Action creator for setting the board font.
 *
 * @param {Font} font - The new font we want to set for the board.
 * @returns {SetFontction} The action to be dispatched to the store.
 */
export const setFont = (font: Font): SetFontAction => {
  return {
    type: "board/set_font",
    data: { font },
    effects: { broadcast: true, persist: true },
  };
};

type SetInfoEffects = Omit<BaseEffects, "persist">;

export interface SetInfoAction extends BaseAction<SetInfoEffects> {
  type: "board/set_info";
  data: Pick<Board, "name" | "description" | "font">;
}

/**
 * Type that describes the named params for updating board info.
 *
 * @typedef {SetInfoParams} The options object
 * @property {string} name - The name of the board.
 * @property {string} description - A longer description of the board contents.
 * @property {Font} font - The font for the board.
 */
interface SetInfoParams {
  name: string;
  description: string;
  font?: Font;
}

/**
 * Action creator for setting the board name, description and font.
 *
 * @param {SetInfoParams} The options object containing updated values.
 * @returns {SetInfoAction} The action to be dispatched to the store.
 */
export const setInfo = ({
  name,
  description,
  font,
}: SetInfoParams): SetInfoAction => {
  return {
    type: "board/set_info",
    data: { name, description, font },
    effects: { broadcast: true },
  };
};

/**
 * Action creator for setting the board name, description and font.
 *
 * @param params The options object containing updated values.
 * @returns The action to be dispatched to the store.
 */
export const setPlan = (plan: Plan): SetPlanAction => {
  return {
    type: "board/set_plan",
    data: { plan },
    effects: { broadcast: true },
  };
};

/**
 * Union type that represents all Board actions
 */
export type BoardAction =
  | SetNameAction
  | SetDescriptionAction
  | SetFontAction
  | SetInfoAction
  | SetPlanAction;

/**
 * Reducer that processes any `board` related actions
 *
 * @param {AppState["board"]} [state] - The current `board` state slice.
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["board"]} The updated state slice.
 */
export const reducer = (
  state: AppState["board"] = {} as any,
  action: Action
): AppState["board"] => {
  switch (action.type) {
    case "board/set_name":
    case "board/set_description":
    case "board/set_font":
    case "board/set_info":
    case "board/set_plan":
      return { ...state, ...action.data };

    default:
      return state;
  }
};

/**
 * Selector for getting the `board` slice from the app state.
 *
 * @param {AppState} state - The app state
 * @returns {Board} The state slice corresponding to the Board data.
 */
export const getBoard = (state: AppState): Board => state.board;

/**
 * Selector that returns the board id.
 *
 * @param {AppState} state  - The current app state.
 * @returns {string} The current board id.
 */
export const getBoardId = createSelector(getBoard, (board) => board.id);

/**
 * Selector that returns the board name.
 *
 * @param {AppState} state - The current app state.
 * @returns {string} The current board name.
 */
export const getName = createSelector(getBoard, (board) => board.name);

/**
 * Selector that returns the board description.
 *
 * @param {AppState} state - The current app state.
 * @returns {string} The current board description.
 */
export const getDescription = createSelector(
  getBoard,
  (board) => board.description
);

/**
 * Selector that returns the account id that created the board
 *
 * @param {AppState} state - The current app state.
 * @returns {string|undefined} The creator's account id.
 */
export const getCreatedBy = createSelector(
  getBoard,
  (board) => board.createdBy
);

/**
 * Selector that returns whether or not the board is a template.
 *
 * @param {AppState} state - The current app state.
 * @returns {boolean|undefined} The board's `template` flag.
 */
export const getTemplate = createSelector(getBoard, (board) => board.template);

/**
 * Selector that returns the board font.
 *
 * @param {AppState} state - The current app state.
 * @returns {Font} The current board font.
 */
export const getFont = createSelector(getBoard, (board) => board.font);

/**
 * Selector that returns the board plan.
 *
 * @param state - The current app state.
 * @returns The current board font.
 */
export const getPlan = createSelector(getBoard, (board) => board.plan);
