import "./Avatar.css";

import { classNames } from "~/util/classNames";
import { getFirstLetter } from "~/util/user";

export interface AvatarProps {
  name: string;
  color: string;
  picture?: string;
  variant?: "inline" | "profile";
  className?: string;
}

export let Avatar = (props: AvatarProps) => {
  return props.picture ? (
    <PictureAvatar {...props} />
  ) : (
    <ColorAvatar {...props} />
  );
};

const ColorAvatar = (props: AvatarProps) => {
  const className = classNames(
    props.className,
    "avatar",
    props.variant && `avatar-${props.variant}`
  );

  return (
    <div className={className} style={{ backgroundColor: props.color }}>
      {getFirstLetter(props.name)}
    </div>
  );
};

const PictureAvatar = (props: AvatarProps) => {
  let className = classNames(
    props.className,
    "avatar",
    props.variant && `avatar-${props.variant}`,
    props.picture && `avatar-picture`
  );

  return (
    <div className={className} style={{ borderColor: props.color }}>
      <img alt="User Avatar" src={props.picture} />
    </div>
  );
};
