import { AppState } from ".";

/**
 * Effects that can be present on any action.
 */
export interface BaseEffects {
  persist?: boolean;
  broadcast?: boolean;
  dispatch?: (BaseAction | ThunkAction)[];
}

/**
 * The base action type that all other actions must extend.
 */
export interface BaseAction<Effects = BaseEffects> {
  type: string;
  effects?: Effects;
}

/**
 * Effect that sets the `keepAlive` field.
 */
export interface KeepAliveEffect {
  keepAlive: boolean;
}

/**
 * Action that accepts the app state before returning.
 */
export type ThunkAction = (state: AppState) => ThunkAction | BaseAction;

/**
 * An empty action for action creators that return early.
 */
export interface EmptyAction extends BaseAction {
  type: "noop";
}

/**
 * Creates an empty action for action creators that return early.
 */
export const emptyAction = (): EmptyAction => {
  return { type: "noop" };
};
