import "./TextArea.css";

import { forwardRef, TextareaHTMLAttributes } from "react";

import { classNames } from "~/util/classNames";

type NativeTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export interface TextAreaProps extends NativeTextareaProps {
  variant?: "white" | "grey";
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ variant = "grey", ...props }, ref) => (
    <textarea
      {...props}
      ref={ref}
      className={classNames(
        "text-area",
        variant && `text-area-${variant}`,
        props.className
      )}
    />
  )
);

TextArea.displayName = "TextArea";
