import "./Select.css";

import { Listbox } from "@headlessui/react";
import { ReactNode } from "react";

import { classNames } from "~/util/classNames";

import * as Icons from "./Icons";

interface SelectProps<T> {
  value: T;
  onChange(value: T): void;
  children?: ReactNode | undefined;
  disabled?: boolean;
}

export function Select<T>(props: SelectProps<T>) {
  return (
    <Listbox
      as="div"
      className={classNames("select", props.disabled && "select-disabled")}
      {...props}
    />
  );
}

interface SelectButtonProps {
  open?: boolean;
  disabled?: boolean;
  children: ReactNode;
}

export let SelectButton = ({ children, ...props }: SelectButtonProps) => (
  <Listbox.Button {...props} className="select-button">
    {children}
    <Icons.ChevronDown className="select-button-icon" />
  </Listbox.Button>
);

export let SelectOptions = ({ children }: { children: ReactNode }) => (
  <Listbox.Options className="select-options">{children}</Listbox.Options>
);

interface SelectOptionProps {
  value: any;
  disabled?: boolean;
  children: ReactNode;
}

export let SelectOption = ({
  value,
  disabled,
  children,
}: SelectOptionProps) => (
  <Listbox.Option value={value} disabled={disabled}>
    {({ active, selected, disabled }) => (
      <div
        className={classNames(
          "select-option",
          active && "select-option-active",
          selected && "select-option-selected",
          disabled && "select-option-disabled"
        )}
      >
        <div className="select-option-check">{selected && <Icons.Check />}</div>
        {children}
      </div>
    )}
  </Listbox.Option>
);

export let SelectLabel = ({ children }: { children: ReactNode }) => (
  <Listbox.Label className="select-label">{children}</Listbox.Label>
);
