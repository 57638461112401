import "./Typography.css";

import { ComponentPropsWithoutRef, ElementType, HTMLAttributes } from "react";

import { classNames } from "~/util/classNames";

export interface TextProps<T extends ElementType = "span"> {
  as?: T;
  size?: 0 | 1 | 2;
  variant?: "muted" | "danger";
}

export let Text = <T extends ElementType = "span">({
  as,
  size = 1,
  variant,
  ...props
}: TextProps<T> & ComponentPropsWithoutRef<T>) => {
  let Component = as || "span";

  return (
    <Component
      {...props}
      className={classNames(
        "typography-text",
        `typography-text-${size}`,
        variant && `typography-text-${variant}`,
        props.className
      )}
    />
  );
};

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
}

export let Heading = ({ level = 1, ...props }: HeadingProps) => {
  let Component = `h${level}` as "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

  return (
    <Component
      {...props}
      className={classNames("typography-heading", props.className)}
    />
  );
};
