import "./Shortcut.css";

import { useKeybinding } from "~/context/KeybindingsContext";
import { isApple } from "~/util/isApple";

type Props = {
  name: string;
};

export const Shortcut = ({ name }: Props) => {
  const shortcut = useKeybinding(name);

  if (!shortcut) return null;

  const { key, ctrl, alt, shift } = shortcut.combinations[0];

  return (
    <kbd className="shortcut">
      {ctrl ? (
        <span>
          <CommandKey /> +{" "}
        </span>
      ) : null}
      {alt ? <span>Alt + </span> : null}
      {shift ? <span>Shift + </span> : null}
      <span>{key}</span>
    </kbd>
  );
};

/**
 * Render the correct "cmd" key for the user's platform (ie. CMD on Mac, CTRL
 * on Windows/Linux)
 */
const CommandKey = () => {
  return isApple ? <span>⌘</span> : <span>Ctrl</span>;
};
