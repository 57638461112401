import { useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { createBoard } from "~/util/BoardClient";
import { FathomEvents, trackAnalyticsEvent } from "~/util/analytics";
import { useHasReachedBoardLimit } from "~/util/hooks";

/**
 * Creates a new board then redirects the user to it. Designed to be rendered
 * inside the /new route.
 */
export let CreateBoardAndRedirect = () => {
  let initialized = useRef<boolean>(false);

  let hasReachedBoardLimit = useHasReachedBoardLimit();
  let navigate = useNavigate();

  useEffect(() => {
    // Skip default create and redirect behavior if user has reached
    // board limit.
    if (hasReachedBoardLimit) return;

    // Don't re-create the board if this isn't the first time the
    // effect has been run.
    if (initialized.current) return;

    async function create() {
      let board = await createBoard(
        "New board",
        "This board was created from /new"
      );

      if (board.status === "success") {
        trackAnalyticsEvent(FathomEvents.CreateBoard);
        // Push the user through to the board settings so they can give the board a
        // name. Replace the current route so navigating backwards in the history
        // stack won't inadvertently result in additional boards being created.
        navigate(`/${board.data.id}/settings`, { replace: true });
      } else {
        // If we can't make the board through the API for any reason, redirect
        // to the normal new boards flow.
        navigate(`/boards/new`);
      }
    }

    create();

    initialized.current = true;
  }, [navigate, hasReachedBoardLimit, initialized]);

  if (hasReachedBoardLimit) {
    // The dashboard will show the board limit message
    return <Navigate to="/boards" />;
  }

  return null;
};
