import { Middleware, Dispatch } from "redux";
import { createSelector } from "reselect";

import * as Client from "~/store/bundles/Client";
import { Action, AppState } from "~/store";
import { BaseAction } from "~/store/shared";
import { BaseObject, Grab, Lock, Delete, Alive } from "~/store/traits";
import assert from "~/util/assert";
import { filterObject } from "~/util/filterObject";
import { Point } from "~/util/geometry";

/**
 * Interface that defines a `Vertex` object.
 */
export interface Vertex
  extends BaseObject.BaseObject,
    Grab.Grab,
    Lock.Lock,
    Delete.Delete,
    Alive.Alive {
  type: "vertex";
  parent: string;
}

/**
 * Type guard for narrowing a `BaseObject` type to a `Vertex`.
 *
 * @param {BaseObject.BaseObject} object - The object whose type we want to
 * narrow.
 * @returns {boolean} Whether or not the object is of type `Vertex`
 */
export const isVertex = (object: BaseObject.BaseObject): object is Vertex => {
  return object.type === "vertex";
};

/**
 * Swap out any IDs according to a provided lookup table.
 *
 * @param {Vertex} vertex - The vertex object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Vertex} The modified vertex with ids replaced.
 */
const swapIds = (vertex: Vertex, lookup: (id: string) => string): Vertex => {
  return {
    ...vertex,
    id: lookup(vertex.id),
    parent: lookup(vertex.parent),
    grab: vertex.grab
      ? { ...vertex.grab, userId: lookup(vertex.grab.userId) }
      : null,
  };
};

/**
 * Remove any secondary information that we wouldn't want to appear, in a
 * template/fork.
 *
 * @param {Vertex} vertex - Vertex to convert into a template
 * @returns {Vertex} Template created from the provided `Vertex` object.
 */
export const template = (vertex: Vertex): Vertex => ({
  ...vertex,
  grab: null,
});

/**
 * Fork a vertex by swapping out ids using a provided lookup table.
 *
 * @param {Vertex} vertex - The vertex object to be modified.
 * @param {(id: string) => string} lookup - A function that can be called with
 * an `id` string and returns the string to replace it.
 * @returns {Vertex} The modified vertex with ids replaced.
 */
export const fork = swapIds;

/**
 * Union of all possible `Vertex` actions.
 */
export type VertexAction =
  | AddAction
  | ReleaseAction
  | ReleaseAllByAction
  | InsertAction;

export interface AddAction extends BaseAction {
  type: "vertex/add";
  data: Vertex;
}

/**
 * Type that describes the named params for adding a vertex.
 */
interface AddParams {
  /**
   * The id for the to-be-added vertex.
   */
  id: string;
  /**
   * The id of the object the vertex belongs to.
   */
  parent: string;
  /**
   * The screen position to be used for placing
   */
  position: Point;
}

/**
 * Action creator for adding a vertex to the app state.
 *
 * @param {AddParams} params - The parameters for the vertex to be added.
 * @returns The action thunk to be dispatched to the store.
 */
export const add =
  ({ id, parent, position }: AddParams) =>
  (state: AppState): AddAction => {
    let scene = Client.getScene(state);
    let scenePosition = Client.toSceneCoordinates(scene, position);

    return {
      type: "vertex/add",
      data: {
        type: "vertex",
        id,
        parent,
        position: scenePosition,
        locked: false,
        deleted: false,
        grab: null,
        keepAlive: null,
      },
      effects: {
        broadcast: true,
        persist: true,
      },
    };
  };

export interface ReleaseAction extends BaseAction {
  type: "vertex/release";
  data: Pick<Vertex, "id" | "grab">;
}

/**
 * Action creator for releasing a vertex.
 *
 * Clears the `grabbing` field of the vertex, used when
 * `keepAlive` timer runs out or the user disconnects.
 *
 * @param {string} id - The id of the vertex to release.
 * @returns {ReleaseAction} The action to be dispatched to the store.
 */
export const release = (id: string): ReleaseAction => {
  return {
    type: "vertex/release",
    data: { id, grab: null },
    effects: { broadcast: true, persist: true },
  };
};

export interface ReleaseAllByAction extends BaseAction {
  type: "vertex/release_all_by";
}

/**
 * Action creator for releasing all vertices that are being manipulated by a
 * certain user.
 *
 * @param {string} userId - The id of the user interacting with vertices we want
 * to release.
 * @returns The action thunk to be dispatched to the store.
 */
export const releaseAllBy =
  (userId: string) =>
  (state: AppState): ReleaseAllByAction => {
    return {
      type: "vertex/release_all_by",
      effects: {
        dispatch: [
          ...getGrabbedBy(userId)(state).map(({ id }) =>
            Grab.finishGrabbing(id)
          ),
        ],
      },
    };
  };

export interface InsertAction extends BaseAction {
  type: "vertex/insert";
  data: Vertex;
}

/**
 * Action creator for inserting a vertex into the app state wholesale.
 *
 * @param {Vertex} vertex - The `Vertex` object to be inserted.
 * @returns {InsertAction} The action to be dispatched to the store.
 */
export const insert = (vertex: Vertex): InsertAction => {
  return {
    type: "vertex/insert",
    data: {
      ...vertex,
    },
    effects: { broadcast: true, persist: true },
  };
};

/**
 * Reducer that processes any `Vertex` actions.
 *
 * @param {AppState["objects"]} [state] - The current `objects` state.
 * @param {Action} action - The action with which to update the state.
 * @returns {AppState["objects"]} The updated state slice.
 */
export const reducer = (
  state: AppState["objects"] = {},
  action: Action
): AppState["objects"] => {
  switch (action.type) {
    case "vertex/add":
    case "vertex/insert":
      return { ...state, [action.data.id]: action.data };

    case "vertex/release": {
      const object = state[action.data.id];
      assert(isVertex(object), "Object is not a Vertex.");

      return { ...state, [object.id]: { ...state[object.id], ...action.data } };
    }

    default:
      return state;
  }
};

/**
 * Redux middleware that is used to intercept general actions to inject `Vertex`
 * specific behavior.
 *
 * @param  store - The redux store object
 * @returns The return value of the `next` middleware.
 */
export const middleware: Middleware<{}, AppState, Dispatch<any>> =
  ({ dispatch, getState }) =>
  (next) =>
  (action: Action) => {
    let additionalActions: Action[] = [];
    const state = getState();

    // Dispatch any additional behaviors
    switch (action.type) {
      /*
       * Release vertex whose `keepAlive` timer has expired.
       *
       * Unset the grabbing properties.
       */
      case "alive/release_expired": {
        for (const id in action.data) {
          const vertex = getById(id)(state);
          if (!vertex || vertex.deleted) continue;

          if (vertex.grab)
            additionalActions.push(Grab.finishGrabbing(id)(state));
        }

        break;
      }
    }

    // Process the actual action first
    const result = next(action);

    // Dispatch additional actions
    additionalActions.forEach(dispatch);

    // Return result of original action
    return result;
  };

/**
 * Selector that returns a record of all vertices in the board state, keyed by
 * their id.
 *
 * @param {AppState} state - The current app state.
 * @returns {Record<string, Vertex>} A record of all vertices in the app state.
 */
export const getVertices = createSelector(BaseObject.getAll, (objects) =>
  filterObject(objects, isVertex)
);

/**
 * Selector that returns an array of all vertices, removing the deleted ones.
 *
 * @param {AppState} state - The current app state.
 * @returns An array of (non-deleted) vertices.
 */
export const getAll = createSelector(getVertices, (vertices) =>
  Object.values(vertices).filter((vertex) => !vertex.deleted)
);

/**
 * Selector creator for getting the vertex with a given id.
 *
 * @param {string} id - The id of the vertex we want to get
 * @returns Selector that returns the vertex.
 */
export const getById = (id: string) =>
  createSelector(getVertices, (vertices) => vertices[id]);

/**
 * Selector creator for getting all vertices currently being grabbed by the
 * provided user id.
 *
 * @param {string} userId - The user id grabbing the vertices.
 * @returns Selector that returns the desired vertices.
 */
export const getGrabbedBy = (userId: string) =>
  createSelector(getAll, (vertices) =>
    vertices.filter(({ grab }) => grab?.userId === userId)
  );

/**
 * Selector for getting all users that are in the middle of interacting with a
 * vertex (i.e., grabbing, etc...)
 *
 * @param {AppState} state - The current app state.
 * @returns {string[]} Array of ids of users that are currently interacting with
 * a vertex.
 */
export const getBusyUsers = createSelector(getAll, (vertices) =>
  vertices
    .flatMap((vertex) => [
      vertex.grab?.userId /*, vertex.creating, vertex.resize?.userId*/,
    ])
    .filter((user) => user)
);
