/**
 * Returns the properties of an object that match a given type guard, with
 * the correct mapped type.
 */
export function filterObject<K extends string, T, U extends T>(
  object: Record<K, T>,
  callback: (value: T, key: K) => value is U
): Record<string, U>;

/**
 * Returns the properties of an object that meet the condition specified in
 * a callback function.
 */
export function filterObject<K extends string, T>(
  object: Record<K, T>,
  callback: (value: T, key: K) => boolean
): Record<string, T>;

export function filterObject<K extends string, T>(
  object: Record<K, T>,
  callback: (value: T, key: K) => boolean
): Record<string, T> {
  // This function is used in lots of heavy store selectors that are hard
  // to memoize and we found that it was a bottleneck, hence the slightly
  // wonky code.

  let keys = Object.keys(object) as K[];
  let filtered: Record<string, T> = {};

  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    let value = object[key];

    if (callback(value, key)) {
      filtered[key] = value;
    }
  }

  return filtered;
}
