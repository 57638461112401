/* eslint-disable */

/*                            Middleware
 *
 * Sticky Studio contains a lot of logic that goes beyond the simple
 * `(action, state) => state` model. We handle all the effectful stuff
 * in middleware. Middleware is roughly divided up into three types:
 *
 * 1. *Effects*: actions can specify extra side effects that should be
 * performed alongside the state update in a separate `effects` field on
 * the dispatched action.
 * Examples: broadcast actions across a websocket, initiate a file upload.
 *
 * 2. *Coeffects*: Additional outside information that the reducer needs to
 * calculate the new state. Actions can supply an object of things they
 * need, and it gets injected into the `data` object along with the rest of
 * the action payload.
 * Examples: read a value from localStorage
 *
 * 3. *General middleware*: Intercept or augment certain actions that aren't
 * necessarily related to state updates.
 * Examples: logging, setting up a websocket connection.
 *
 */
import { Socket } from "socket.io-client";

import * as Connection from "~/store/bundles/Connection";
import * as Image from "~/store/bundles/Image";
import * as Label from "~/store/bundles/Label";
import * as Line from "~/store/bundles/Line";
import * as Shape from "~/store/bundles/Shape";
import * as Sticker from "~/store/bundles/Sticker";
import * as Sticky from "~/store/bundles/Sticky";
import * as Vertex from "~/store/bundles/Vertex";
import * as Connect from "~/store/traits/Connect";
import { NODE_ENV } from "~/config";
import { Config } from "~/context/ConfigContext";

import { bugsnag } from "./bugsnag";
import dispatch from "./dispatch";
import restrictActions from "./restrictActions";
import thunk from "./thunk";
import upload from "./upload";
import websocket from "./websocket/middleware";

const instantiateMiddleware = (config: Config, socket?: Socket) =>
  middlewares(
    thunk, // [Middleware]: Dispatch thunks
    Connection.middleware,
    Image.middleware,
    Label.middleware,
    Shape.middleware,
    Sticky.middleware,
    Sticker.middleware,
    Connect.middleware,
    Line.middleware,
    Vertex.middleware,
    NODE_ENV === "production" && bugsnag(config),
    restrictActions(config.permission), // [Middleware]: Drop actions that aren't allowed
    upload, // [Effect]: Upload to a given provider
    dispatch, // [Effect]: Dispatch additional actions
    config.websocket && socket && websocket(config, socket) // [Effect]: Set up WS connection
  );

/**
 * Thin wrapper around middlewares (a-la `classnames`) that filters out falsey
 * values.
 *
 * Allows one to include middleware conditionally in a more idiomatic way
 *
 * @example
 * const middlware = middlewares(
 *   thunk,
 *   NODE_ENV === "production" && bugsnag(config),
 *   config.websocket && websocket,
 * );
 */
const middlewares = <T>(...wares: (T | false | null | undefined)[]): T[] => {
  return wares.filter((middleware) => !!middleware) as T[];
};

export default instantiateMiddleware;
