import pdfjsWorkerSrc from "pdfjs-dist/build/pdf.worker.js?url";

export async function pdfFileToImage(file: File) {
  const pdf = await fileToPdf(file);
  const type = "image/png";
  const quality = 1;
  const scale = 3;
  const page = await pdf.getPage(1);
  const viewport = page.getViewport({ scale });
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;
  const renderContext = { canvasContext: ctx, viewport: viewport };
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  await page.render(renderContext).promise;
  const blob = await toBlob(canvas, type, quality);
  return new File([blob], file.name);
}

async function readAsArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.result) {
        resolve(fileReader.result as ArrayBuffer);
      } else {
        reject();
      }
    };
    fileReader.onerror = (err) => reject(err);
    fileReader.readAsArrayBuffer(file);
  });
}

async function fileToPdf(file: File) {
  let pdfjs = await import("pdfjs-dist");
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorkerSrc;
  const result = await readAsArrayBuffer(file);
  return pdfjs.getDocument(result).promise;
}

function toBlob(
  canvas: HTMLCanvasElement,
  type: string,
  quality: number
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject();
        }
      },
      type,
      quality
    );
  });
}
