import "./Emoji.css";

import React from "react";

import * as Sticker from "~/store/bundles/Sticker";

import {
  EmojiEyes,
  EmojiSmile,
  EmojiFrown,
  EmojiThinkingFace,
  EmojiThumbsDown,
  EmojiThumbsUp,
  EmojiRaisingHands,
  EmojiHeart,
  EmojiPartyPopper,
  EmojiFire,
  EmojiCheckMark,
} from "./Icons";

export const emojis: Record<Sticker.Shortcode, React.ReactElement> = {
  ":eyes:": <EmojiEyes />,
  ":smile:": <EmojiSmile />,
  ":frown:": <EmojiFrown />,
  ":thinking-face:": <EmojiThinkingFace />,
  ":thumbs-down:": <EmojiThumbsDown />,
  ":thumbs-up:": <EmojiThumbsUp />,
  ":raising-hands:": <EmojiRaisingHands />,
  ":heart:": <EmojiHeart />,
  ":party-popper:": <EmojiPartyPopper />,
  ":fire:": <EmojiFire />,
  ":check-mark:": <EmojiCheckMark />,
};

export const Emoji = ({ shortcode }: { shortcode: Sticker.Shortcode }) => (
  <span className="emoji">{getEmoji(shortcode)}</span>
);

export const getEmoji = (shortcode: Sticker.Shortcode) => emojis[shortcode];
