import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { io, Socket } from "socket.io-client";

import { VERSION, WS_URL } from "~/config";
import log from "~/util/logger";

import { BoardType, useConfig } from "./ConfigContext";

type Context = {
  socket?: Socket;
};

const WebsocketContext = createContext<Context>({});

export const WebsocketProvider = ({ children }: { children: ReactNode }) => {
  // Websocket setup
  log.info("Connecting to host: ", WS_URL);
  const { boardId, userId, type } = useConfig();
  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    if (type === BoardType.Sandbox) return;

    let socket = io(WS_URL, {
      query: { userId, boardId },
      withCredentials: true,
      reconnection: true,
      extraHeaders: { Version: VERSION },
    });

    // If the client and server versions don't match, the server throws an error
    // and we refresh the page.
    socket.on("connect_error", (err: Error) => {
      if (err.message === "refresh client") window.location.reload();
    });

    setSocket(socket);

    return () => {
      socket?.disconnect();
    };
  }, [boardId, userId, type]);

  return (
    <WebsocketContext.Provider value={{ socket }}>
      {children}
    </WebsocketContext.Provider>
  );
};

export const useWebsocket = () => {
  const context = useContext(WebsocketContext);

  if (!context) {
    throw new Error("Can't use websocket unless within a context provider");
  }

  return context.socket;
};
