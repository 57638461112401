import "./Image.css";

import React from "react";
import { useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";
import * as Comments from "~/store/bundles/Comments";
import * as Images from "~/store/bundles/Image";
import * as Upload from "~/store/bundles/Upload";
import { Exclamation as ErrorIcon } from "~/assets/icons";
import { BoardType, useConfig } from "~/context/ConfigContext";
import usePermission from "~/util/usePermission";

import { ControlOverlay, Control } from "./ControlOverlay";
import { OpenComments } from "./OpenComments";
import { ConnectTarget } from "./shared/ConnectTarget";
import { ConnectionAnchors } from "./shared/ConnectionAnchors";
import { ContextMenuTarget } from "./shared/ContextMenuTarget";
import { GrabTarget } from "./shared/GrabTarget";
import { ObjectElements } from "./shared/ObjectElements";
import { Position } from "./shared/Position";
import ResizeHandle from "./shared/ResizeHandle";
import { SelectTarget } from "./shared/SelectTarget";
import { ShowcaseTarget } from "./shared/ShowcaseTarget";
import { useHoverTarget } from "./shared/useHoverTarget";

export const Image = React.memo(({ image }: { image: Images.Image }) => {
  const userId = useSelector(Client.getUserId);
  const isSelected = useSelector(Client.getIsSelected(image.id));
  const upload = useSelector(Upload.getById(image.upload));
  const can = usePermission();
  const isGrabbing = userId === image.grab?.userId;
  const isResizing = !!image.resize;
  const isTemplate = useConfig().type === BoardType.Template;
  const comments = useSelector(Comments.getByParentId(image.id));
  const hasComments = comments.length > 0;
  const { hover, HoverTarget } = useHoverTarget(image.id);

  const { position, width, height, locked } = image;

  let style = {
    transform: `translate(${position.x}px, ${position.y}px)`,
    width: `${width}px`,
    height: `${height}px`,
  };

  let className = `image__wrapper
    overflow-visible width-full height-full
    ${
      isGrabbing
        ? "cursor-grabbing grabbing "
        : locked
        ? "cursor-default locked "
        : "cursor-grab "
    }
    ${isSelected ? "selected rounded ring-4 ring-sky-400 " : ""}
  `;

  return (
    <HoverTarget>
      <ConnectTarget id={image.id} className={className} style={style}>
        <GrabTarget id={image.id}>
          <SelectTarget id={image.id}>
            <ContextMenuTarget id={image.id}>
              <ShowcaseTarget id={image.id} userId={image.createdBy}>
                {renderUpload(upload, width, height)}
              </ShowcaseTarget>
            </ContextMenuTarget>
          </SelectTarget>
        </GrabTarget>

        <ObjectElements>
          {can("edit", image) && !isResizing && hover && !locked && (
            <ConnectionAnchors objectId={image.id} />
          )}

          {!isTemplate && (
            <ControlOverlay>
              <Control visible={hover || hasComments} style={style}>
                <Position region="top-right">
                  <OpenComments id={image.id} commentCount={comments.length} />
                </Position>
              </Control>
            </ControlOverlay>
          )}

          {can("edit", image) && isSelected && !locked && (
            <>
              <Position region="top-right">
                <ResizeHandle id={image.id} corner="top-right" />
              </Position>
              <Position region="top-left">
                <ResizeHandle id={image.id} corner="top-left" />
              </Position>
              <Position region="bottom-right">
                <ResizeHandle id={image.id} corner="bottom-right" />
              </Position>
              <Position region="bottom-left">
                <ResizeHandle id={image.id} corner="bottom-left" />
              </Position>
            </>
          )}
        </ObjectElements>
      </ConnectTarget>
    </HoverTarget>
  );
});

Image.displayName = "Image";

const renderUpload = (upload: Upload.Upload, width: number, height: number) => {
  switch (upload?.status) {
    case "loading": {
      return (
        <svg width={width} height={height}>
          <image
            width={width}
            height={height}
            href={upload.previewUrl}
            className="opacity-50"
          />
        </svg>
      );
    }
    case "success": {
      return (
        <svg width={width} height={height}>
          <image width={width} height={height} href={upload.url} />
        </svg>
      );
    }
    case "failed": {
      return <FailedPlaceholder width={width} height={height} />;
    }
    default: {
      return <FailedPlaceholder width={width} height={height} />;
    }
  }
};

interface PlaceholderProps {
  width: number;
  height: number;
}

const FailedPlaceholder = ({ width, height }: PlaceholderProps) => {
  const WIDTH = 50;
  const HEIGHT = 50;
  return (
    <svg className="image__placeholder" width={width} height={height}>
      <rect
        className="image__placeholder__background"
        width={width}
        height={height}
      />
      <ErrorIcon
        x={width / 2 - WIDTH / 2}
        y={height / 2 - HEIGHT / 2}
        width={WIDTH}
        height={HEIGHT}
      />
      <foreignObject
        className="image__placeholder__text"
        x={width / 2}
        y={height / 2}
        width="200"
        height="75"
      >
        <div>Image upload failed</div>
      </foreignObject>
    </svg>
  );
};
