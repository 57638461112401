import React, { useEffect, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";

import { useConfig } from "~/context/ConfigContext";
import createStore, { AppState } from "~/store";

import { useWebsocket } from "./WebsocketContext";

type Props = {
  initialState: AppState;
  children: React.ReactNode;
};

const Provider = ({ children, initialState }: Props) => {
  const config = useConfig();
  const socket = useWebsocket();
  const [store, setStore] = useState<ReturnType<typeof createStore>>();

  // TODO: Properly memoize these effect dependencies (initialState, config), so
  // we're sure we're not re-initializing the redux state unnecessarily
  // Do we _ever_ want to re-initialize the store with the initialState?
  useEffect(() => {
    const store = createStore(
      initialState,
      config,
      config.websocket ? socket : undefined
    );

    setStore(store);

    return () => {
      store.dispatch({ type: "teardown" });
    };
  }, [socket]); // eslint-disable-line react-hooks/exhaustive-deps

  return store ? <ReduxProvider store={store}>{children}</ReduxProvider> : null;
};

export default Provider;
