import "./ConfirmLogin.css";

import { Formik, FormikErrors, FormikHelpers, useField } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "~/context/AuthContext";
import { confirmLogin } from "~/util/AccountClient";
import { FetchResponse } from "~/util/ApiClient";

import { Box } from "./Box";
import { Button } from "./Button";
import {
  FormField,
  FormFieldHeader,
  FormFieldMessage,
  FormFieldValidationMessage,
} from "./FormField";
import { FormStatus } from "./FormStatus";
import { Divider } from "./Page";
import { Panel } from "./Panel";
import { TextInput } from "./TextInput";
import { Text } from "./Typography";

function validate(values: Values) {
  let errors: FormikErrors<Values> = {};

  if (values.code === "") errors.code = "Code is required";

  return errors;
}

type Values = {
  code: string;
};

const initialValues = {
  code: "",
};

export const ConfirmLogin = () => (
  <Box align="center" gap={8}>
    <Panel className="confirm-login-panel">
      <LoginCodeHeader />
      <Divider />
      <LoginCodeForm />
    </Panel>
  </Box>
);

const LoginCodeHeader = () => (
  <Text size={1}>
    We've sent you an email containing a link you can use to log in. Follow the
    link, or enter the code below.
  </Text>
);

const LoginCodeForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const email = state?.email ?? null;

  const onSubmit = async (
    { code }: Values,
    { setSubmitting, setStatus }: FormikHelpers<Values>
  ) => {
    // If the user got to the /code page without first filling in their
    // email, we redirect them to the login page rather than submitting an empty
    // email address.
    if (!email) {
      navigate("/login");
      return;
    }

    let response: FetchResponse | undefined;

    try {
      response = await confirmLogin({ code, email });
      setStatus(response);
      setSubmitting(false);
    } catch (error: any) {
      setStatus({
        status: "failed",
        msg: error.message ?? "Something went wrong.",
      });
    }

    if (response?.status === "success") {
      await auth.authenticate();
      // Redirect to where ever the user was before hitting the login page.
      const { from = { pathname: "/" } } = location.state ?? {
        from: { pathname: "/" },
      };
      navigate(from, { replace: true });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Box as="form" onSubmit={handleSubmit} gap={3}>
          <LoginConfirmFormCodeField />
          <Button type="submit" variant="primary" loading={isSubmitting}>
            Log In
          </Button>
          <FormStatus />
        </Box>
      )}
    </Formik>
  );
};

const LoginConfirmFormCodeField = () => {
  let [field] = useField("code");

  return (
    <FormField>
      <FormFieldHeader>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldHeader>
      <TextInput {...field} placeholder="Code" autoFocus />
    </FormField>
  );
};
