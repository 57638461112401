import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { getUsersInHuddle } from "~/store/bundles/User";
import { resetFavicon, setFavicon } from "~/util/favicon";
import { cleanupHuddle, createHuddle, Huddle } from "~/util/huddle";

import { useWebsocket } from "./WebsocketContext";

type Context = {
  huddle: Huddle | null;
  setHuddle: (huddle: Huddle | null) => void;
};

const HuddleContext = createContext<Context | null>(null);

export const HuddleProvider = ({ children }: { children: ReactNode }) => {
  const [huddle, setHuddle] = useState<Huddle | null>(null);

  // A huddle gets created and destroyed through user events, but we add a
  // fallback cleanup to make sure resources get freed correctly on unmount.
  useEffect(() => {
    return () => {
      if (huddle) cleanupHuddle(huddle);
    };
  }, [huddle]);

  // Change the favicon when there is an active huddle
  useHuddleFavicon();

  let context = { huddle, setHuddle };

  return (
    <HuddleContext.Provider value={context}>{children}</HuddleContext.Provider>
  );
};

export const useHuddle = () => {
  const context = useContext(HuddleContext);
  const socket = useWebsocket();

  if (!context) {
    throw new Error(
      "Can't use huddle context unless inside a context provider."
    );
  }
  const { huddle, setHuddle } = context;

  /**
   * Set up a new huddle context and return this user's huddle identifier
   * (which is just the socket id).
   */
  const joinHuddle = async (): Promise<string> => {
    if (huddle) throw new Error("Already in a huddle!");
    if (!socket) throw new Error("No websocket connection found for huddle.");

    const newHuddle = await createHuddle(socket);
    setHuddle(newHuddle);

    return newHuddle.id;
  };

  /**
   * Unset the current huddle context, and run clean-up logic (closing the
   * audio context, removing event listener
   */
  const leaveHuddle = async () => {
    if (!huddle) throw new Error("You're not currently in a huddle!");

    cleanupHuddle(huddle);
    setHuddle(null);
  };

  const setMuted = (muted: boolean) => {
    if (!huddle) throw new Error("You're not currently in a huddle!");

    huddle.localAudio.getAudioTracks()[0].enabled = !muted;
  };

  return { joinHuddle, leaveHuddle, setMuted, inHuddle: !!context.huddle };
};

export const useHuddleFavicon = () => {
  const users = useSelector(getUsersInHuddle);

  useEffect(() => {
    if (users.length > 0) {
      setFavicon("/favicon-huddle.svg");
      return () => resetFavicon();
    }
  }, [users]);
};
