import "./Menu.css";

import { FloatingPortal } from "@floating-ui/react";
import { Menu as HeadlessMenu } from "@headlessui/react";
import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";

import { classNames } from "~/util/classNames";

import { FloatProvider, Props, useFloat } from "./FloatProvider";

export const Menu = ({ children, placement }: Props) => (
  <HeadlessMenu as="div" className="menu">
    <FloatProvider placement={placement}>{children}</FloatProvider>
  </HeadlessMenu>
);

export const MenuButton = ({ children }: { children: ReactNode }) => {
  const { setReference } = useFloat();
  return (
    <HeadlessMenu.Button as="div" ref={setReference}>
      {children}
    </HeadlessMenu.Button>
  );
};

type MenuItemsProps = {
  children?: React.ReactNode;
  static?: boolean;
};

export const MenuItems = (props: MenuItemsProps) => {
  const { position, setFloating, strategy, isPositioned } = useFloat();

  return (
    <FloatingPortal>
      <HeadlessMenu.Items
        className="menu-items"
        ref={setFloating}
        style={{
          position: strategy,
          left: position?.x ?? 0,
          top: position?.y ?? 0,
          width: "max-content",
          opacity: isPositioned ? 1 : 0,
        }}
        {...props}
      />
    </FloatingPortal>
  );
};

interface ButtonMenuItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
}

type MenuItemProps = ButtonMenuItemProps | LinkProps;

export const MenuItem = (props: MenuItemProps) => {
  if (isLinkMenuItem(props)) {
    return (
      <HeadlessMenu.Item
        as={Link}
        {...props}
        className={classNames("menu-item")}
      />
    );
  }

  return (
    <HeadlessMenu.Item disabled={props.disabled}>
      {({ active, disabled }) => (
        <button
          {...props}
          disabled={disabled}
          className={classNames(
            "menu-item",
            active && "menu-item-active",
            disabled && "menu-item-disabled"
          )}
        />
      )}
    </HeadlessMenu.Item>
  );
};

const isLinkMenuItem = (props: MenuItemProps): props is LinkProps =>
  "to" in props;

export const MenuDivider = () => <div className="menu-divider" />;
