import "./CommentsButton.css";

import React, { ButtonHTMLAttributes } from "react";
import { useDispatch } from "react-redux";

import * as Client from "~/store/bundles/Client";
import { MessageCircle } from "~/components/Icons";
import { useClassOnChange } from "~/hooks/useClassOnChange";
import { classNames } from "~/util/classNames";

import { RoundButton } from "../renderers/html/RoundButton";
import { Button as DefaultButton } from "./Button";

type NativeButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

interface Props extends NativeButtonProps {
  id: string;
  className?: string;
  commentCount: number;
  rounded?: boolean;
}

export const CommentsButton = ({ commentCount, ...props }: Props) => {
  const { id, className, rounded } = props;
  const dispatch = useDispatch();

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(Client.setCommentObjectId(id));
  };

  // Make the comment icon play a little pop animation every time the number
  // of comments changes.
  const changeClassName = useClassOnChange({
    value: commentCount,
    className: "comments-button--changed",
    durationInMs: 400,
  });

  const Button = rounded ? RoundButton : DefaultButton;

  return (
    <Button
      {...props}
      onClick={onClick}
      className={classNames("comments-button", className, changeClassName)}
    >
      <div className="comments-button-wrapper">
        {commentCount === 0 ? (
          <MessageCircle className="h-5 w-5" />
        ) : (
          <>
            <MessageCircle className="h-4 w-4" />
            <div className="position mx-0.5 -mt-0.5 font-sans font-bold text-sm">
              {commentCount}
            </div>
          </>
        )}
      </div>
    </Button>
  );
};
