import React, { useContext, useEffect, useState } from "react";

import { getJson, postJson } from "~/util/ApiClient";

/**
 * Type that describes the metadata for an account.
 *
 * @property boards - The number of boards
 * the user has created.
 * @property maxBoards - The maximum number
 * of boards a user can create. A value of null means
 * the user has no limit.
 */
export type Metadata = {
  boards: number;
  maxBoards: number | null;
};

export type Account = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  plan: "free" | "plus";
  billingStatus: string;
  pro: boolean;
  locked: boolean;
  coupon: string | null;
  meta: Metadata;
  profilePicture?: string;
  cursorColor?: string;
};

export type Context = {
  authenticated: boolean;
  account: Account | null;
  authenticate(): Promise<void>;
  deauthenticate(): void;
  verify(password: string): Promise<boolean>;
  refresh(): Promise<void>;
};

const defaultContext: Context = {
  authenticated: false,
  account: null,
  authenticate: async () => {},
  deauthenticate: () => {},
  verify: async () => false,
  refresh: async () => {},
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = React.createContext<Context>(defaultContext);

export const AuthProvider = (props: AuthProviderProps) => {
  const [initialized, setInitialized] = useState(false);
  const [account, setAccount] = useState<Account | null>(null);

  const authenticate = async () => {
    let response = await getJson<Account>(`/auth`);

    setAccount(response.status === "success" ? response.data : null);
  };

  const verify = async (password: string) => {
    const response = await postJson("/auth/verify", { password });
    return response.status === "success";
  };

  const deauthenticate = async () => {
    setAccount(null);
  };

  const context = {
    authenticated: !!account,
    account,
    authenticate,
    deauthenticate,
    verify,
    refresh: authenticate,
  };

  useEffect(() => {
    authenticate().then(() => setInitialized(true));
  }, []);

  //TODO: Render a loading spinner here if load time exceeds some threshold.
  return initialized ? (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  ) : null;
};

export const useAuth = () => useContext(AuthContext);

export const useAccount = () => useAuth().account;

export const useCoupon = () => useAuth().account?.coupon;
