import { useMemo } from "react";

import { SandboxWarningModal } from "~/components/SandboxWarningModal";
import { Config, BoardType, Permission } from "~/context/ConfigContext";
import { createAppState } from "~/store";
import uid from "~/util/uid";

import { BoardWithProviders } from "./Board";

const Sandbox = () => {
  const userId = useMemo(uid, []);
  const boardId = uid();
  const initialState = createAppState({
    userId,
    board: {
      // Pretend that the sandbox is a pro board so that people can see
      // features like comments.
      plan: "plus",
      boardId,
      name: "Try Sticky Studio",
      description:
        "Sticky studio is a collaborative workspace where you can solve problems in an interactive way.",
    },
    objects: [],
    comments: [],
  });

  const config: Config = {
    type: BoardType.Sandbox,
    permission: Permission.Write,
    websocket: false,
    userId,
    boardId,
  };

  return (
    <>
      <BoardWithProviders config={config} initialState={initialState} />
      <SandboxWarningModal />
    </>
  );
};

export default Sandbox;
