import React from "react";
import { useSelector } from "react-redux";

import * as Client from "~/store/bundles/Client";

/**
 * Shows children based on the scene's zoom level.
 *
 * @property from Minimum scene zoom level within which to show children.
 * @property to Maximum scene zoom level within which to show children.
 * @property children The children to render inside this component.
 */
export const ShowAtScale = ({
  from,
  to,
  children,
}: {
  from?: number;
  to?: number;
  children?: React.ReactNode;
}) => {
  let scale = useSelector(Client.getScale);
  let visible =
    (from === undefined || scale >= from) && (to === undefined || scale <= to);

  if (!visible) {
    return null;
  }

  return <div>{children}</div>;
};
