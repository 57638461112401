/**
 * React hook that encapsulates permission logic by looking at the surrounding
 * AuthContext and ConfigContext.
 */
import { useSelector } from "react-redux";

import * as Board from "~/store/bundles/Board";
import * as Client from "~/store/bundles/Client";
import { useAuth } from "~/context/AuthContext";
import { useConfig, Permission } from "~/context/ConfigContext";
import { Create } from "~/store/traits";
import { type BaseObject } from "~/store/traits/BaseObject";

type Action =
  | "view"
  | "delete"
  | "comment"
  | "edit"
  | "invite"
  | "change-permission"
  | "change-access";

const usePermission = () => {
  const config = useConfig();
  const auth = useAuth();
  const boardCreator = useSelector(Board.getCreatedBy);
  const isPro = !!auth.account?.pro;
  const isOwner = auth.account?.id === boardCreator;
  const userId = useSelector(Client.getUserId);

  return (action: Action, object?: BaseObject) => {
    switch (action) {
      case "view": {
        return config.permission >= Permission.ReadOnly;
      }

      case "comment": {
        return config.permission >= Permission.Comment;
      }

      case "delete":
      case "edit": {
        if (config.permission === Permission.Write) {
          return true;
        }

        if (config.permission === Permission.EditOwned) {
          if (object && Create.isCreate(object)) {
            return object.createdBy === userId;
          } else {
            return true;
          }
        }

        return false;
      }

      /**
       * Only the board creator, if he is on a paid plan, can change the
       * permissions, access settings or invite other people.
       */
      case "change-permission":
      case "change-access":
      case "invite": {
        return isPro && isOwner;
      }

      default: {
        return false;
      }
    }
  };
};

export default usePermission;
